import React from 'react';
import { ButtonProps } from '@mui/material';
import { ReactComponent as MSIcon } from '../../../../../assets/images/logo-microsoft.svg';
import { useIntl } from 'react-intl';
import { LoginButton } from './LoginButton';

export const MicrosoftLoginButton:React.FC<ButtonProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <LoginButton {...props} startIcon={<MSIcon/>}>
      {formatMessage({ id: 'Log in with Microsoft' })}
    </LoginButton>
  );
};