import { QuestionMark } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { LookupInput } from 'components';
import { Team as TeamEntity, useFindTeamsQuery } from 'gql';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { GenericCustomFieldInputProps } from './CustomFieldInput';
import deburr from 'lodash/deburr';

type Team = Pick<TeamEntity, 'id' | 'name' | 'description'>;

const TeamListItem: React.FC<{
  team: Team;
  selected?: boolean;
  onClick?: () => void;
}> = ({ team, selected, onClick }) => {
  return (
    <ListItem
      key={team.id}
      disablePadding
      sx={{
        backgroundColor: selected ? 'action.hover' : undefined,
      }}
    >
      <ListItemButton onClick={() => onClick?.()}>
        <ListItemText
          primary={team.name}
          secondary={team.description}
          sx={{ ml: 1 }}
        />
      </ListItemButton>
    </ListItem>
  );
};

interface Props extends GenericCustomFieldInputProps {
  multiple?: boolean;
  anchor?: 'left' | 'right';
}

export const TeamLookupInput: React.FC<Props> = ({ value, onChange, error, helperText, label, required, disabled, multiple, anchor }) => {
  const { formatMessage } = useIntl();

  const { data: teamsData, isFetching } = useFindTeamsQuery();
  const teams = useMemo(() => teamsData?.teams ?? [], [teamsData?.teams]);

  const selectedIds = value?.split(',').map(Number).filter(Boolean) ?? [];

  return (
    <LookupInput
      anchor={anchor}
      selectedValues={teams.filter(team => selectedIds.includes(team.id))}
      availableValues={teams}
      getKey={team => team.id}
      multiple={multiple}
      getLabel={team => team.name}
      onChange={teams => onChange(teams.map(team => team.id).join(','))}

      renderListItem={(team, selected, onClick) => (
        <TeamListItem team={team} selected={selected} onClick={onClick} />
      )}

      renderEmptyState={() => (
        <Stack width='100%' alignItems='center' spacing={2} mt={3}>
          <QuestionMark />
          <Typography>
            {formatMessage({ id: 'No teams found' })}
          </Typography>
        </Stack>
      )}

      disabled={disabled}
      required={required}
      label={label}
      error={error}
      helperText={helperText}
      loading={isFetching}
      searchFilter={(team, search) => deburr(team.name).toLowerCase().includes(deburr(search).toLowerCase())}
    />
  );
};