import { setValue } from '@microsoft/applicationinsights-core-js';
import { SettingsOutlined } from '@mui/icons-material';
import { Box, Divider, Stack, Switch, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { getValue } from '@testing-library/user-event/dist/utils';
import { DisplayInformation, DisplayInformationRow, PageTitle, ResponsiveSelect } from 'components';
import dayjs from 'dayjs';
import { ActivityType, EditSettingsInput, useEditSettingsMutation, useGetSettingsQuery } from 'gql';
import { useNotification } from 'hooks/useNotification';
import { activityTypeMessages } from 'modules/activities/messages';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FiscalYearInput } from './FiscalYearInput';

type SettingsActivityType = 'isDistributionEnabled' | 'isInterventionEnabled' | 'isMeetingEnabled' | 'isPresentationEnabled' | 'isRoutineEnabled' | 'isKnownIndividualsEnabled';

export const SettingsPage: React.FC = () => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const { control, reset, formState: { isDirty }, getValues } = useForm<EditSettingsInput>({
    defaultValues: {
      isKnownIndividualsEnabled: true,
      isDistributionEnabled: true,
      isInterventionEnabled: true,
      isMeetingEnabled: true,
      isPresentationEnabled: true,
      isRoutineEnabled: true,
      fiscalYearStart: dayjs().year(2023).month(0).day(1).toISOString()
    }
  });
  const { data: settingsData } = useGetSettingsQuery();

  const MapSettigsActivityType: Record<ActivityType, SettingsActivityType> = {
    [ActivityType.Distribution]: 'isDistributionEnabled',
    [ActivityType.Intervention]: 'isInterventionEnabled',
    [ActivityType.Meeting]: 'isMeetingEnabled',
    [ActivityType.Presentation]: 'isPresentationEnabled',
    [ActivityType.Routine]: 'isRoutineEnabled'
  };

  const editSettings = useEditSettingsMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Settings updated successfully' }));
      queryClient.invalidateQueries(['getSettings']);
      queryClient.invalidateQueries(['getActivityTypesSettings']);
      queryClient.invalidateQueries(['getIndividualsSettings']);
    }
  });

  const handleSave = (settings: EditSettingsInput) => {
    editSettings.mutate({ input: settings });
  };

  useEffect(() => {
    const settings = settingsData?.settings.at(0);
    if (settings) {
      reset({
        isKnownIndividualsEnabled: settings.isKnownIndividualsEnabled,
        isDistributionEnabled: settings.isDistributionEnabled,
        isInterventionEnabled: settings.isInterventionEnabled,
        isMeetingEnabled: settings.isMeetingEnabled,
        isPresentationEnabled: settings.isPresentationEnabled,
        isRoutineEnabled: settings.isRoutineEnabled,
        fiscalYearStart: settings.fiscalYearStart ?? new Date(2023, 0, 1).toISOString()
      });
    }
  }, [reset, settingsData]);

  useEffect(() => {
    if (isDirty) {
      handleSave(getValues());
    }
  }, [isDirty]);

  return (
    <>
      <PageTitle
        icon={<SettingsOutlined />}
        title={formatMessage({ id: 'Settings' })}
      />
      <Divider />
      <DisplayInformation title={formatMessage({ id: 'Reference year' })}
        sx={{
          mt: 3,
        }}
      >
        <DisplayInformationRow>
          <Stack flex='1 1 200px'>
            <Typography variant='subtitle1' fontWeight={700}>{formatMessage({ id: 'Reference year Start' })}</Typography>
            <Typography variant='body2'>{formatMessage({ id: 'This setting allows you to define your reference year start. Default report date period will be set to the current reference year.' })}</Typography>
          </Stack>
          <Stack flex='0 0 auto' direction='row' spacing={2} marginTop={2} minWidth={'20ch'}>
            <Controller
              control={control}
              name='fiscalYearStart'
              render={({ field, }) =>
                <FiscalYearInput
                  value={field.value}
                  onChange={value => field.onChange(value)}
                />
              }
            />
          </Stack>
        </DisplayInformationRow>
      </DisplayInformation>
      <DisplayInformation title={formatMessage({ id: 'Individuals' })}
        sx={{
          mt: 3,
        }}
      >
        <DisplayInformationRow>
          <Stack flex='1 1 200px'>
            <Typography variant='subtitle1' fontWeight={700}>{formatMessage({ id: 'Known individuals' })}</Typography>
            <Typography variant='body2'>{formatMessage({ id: 'This setting allows you to enable or disable the known individuals section when creating a new activity.' })}</Typography>
          </Stack>
          <Controller
            control={control}
            name='isKnownIndividualsEnabled'
            render={({ field }) => (
              <Switch
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </DisplayInformationRow>
      </DisplayInformation>

      <DisplayInformation
        title={formatMessage({ id: 'Activities' })}
        subtitle={formatMessage({ id: 'This setting allows you to configure the activity types available when creating a new activity.' })}
        sx={{
          mt: 3,
        }}
      >
        {Object.values(ActivityType).sort((a, b) => formatMessage(activityTypeMessages[a]).localeCompare(formatMessage(activityTypeMessages[b]))).map(activityType => (
          <DisplayInformationRow key={activityType}>
            <Typography variant='subtitle1'>{formatMessage(activityTypeMessages[activityType])}</Typography>
            <Controller
              control={control}
              name={MapSettigsActivityType[activityType]}
              render={({ field }) => (
                <Switch
                  disabled={editSettings.isLoading}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </DisplayInformationRow>
        ))}
      </DisplayInformation>

    </>
  );
};
