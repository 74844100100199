import React from 'react';
import { Dialog, DialogProps, Slide, styled } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

type Directions = 'up' | 'right';

interface Props extends DialogProps {
  direction: Directions;
}

const RightTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const UpTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: theme.palette.background.default
  }
}));

type TransitionElementType = React.JSXElementConstructor<TransitionProps & {
  children: React.ReactElement;
}>;

export const SlideDialogBase: React.FC<Props> = (props) => {
  const transitionMap: Record<Directions, TransitionElementType> = {
    'up': UpTransition,
    'right': RightTransition
  };

  return (
    <StyledDialog
      {...props}
      fullScreen
      TransitionComponent={transitionMap[props.direction]}
    >
      {props.children}
    </StyledDialog>
  );
};