import React, { useMemo } from 'react';
import { ActivityType, TeamStat } from 'gql';
import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import { entityTypePluralMessages } from 'modules/activities/messages';
import { useActivitySettings } from 'hooks/useActivitySettings';
import _ from 'lodash';
import { EmptyState } from 'components';

interface OwnProps {
  teamStat: TeamStat[]
  isLoading?: boolean
}

export const MemberStatDataGrid: React.FC<OwnProps> = ({ teamStat, isLoading }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { activitiesEnabled } = useActivitySettings();

  const columns = useMemo(() => {

    const columns: GridColDef<TeamStat>[] = [
      {
        field: 'streetOutreachWorkers',
        flex: 1,
        minWidth: 125,
        headerName: formatMessage({ id: 'Street Outreach Workers' }),
        valueGetter: ({ row }) => row.name ?? '',
        renderCell: ({ row }) => row.name ? (
          <Typography variant='subtitle1' fontWeight={500}>{row.name}</Typography>
        ) : <Skeleton variant='text' width={theme.spacing(10)} />
      }
    ];

    const getCount = (row: TeamStat, type: ActivityType) => {
      switch (type) {
        case ActivityType.Distribution:
          return row.distributions;
        case ActivityType.Intervention:
          return row.interventions;
        case ActivityType.Meeting:
          return row.meetings;
        case ActivityType.Presentation:
          return row.presentations;
        case ActivityType.Routine:
          return row.routines;
      }
    };

    activitiesEnabled.map(type =>
      columns.push({
        field: type,
        minWidth: 125,
        headerName: formatMessage(entityTypePluralMessages[type]),
        valueGetter: ({ row }) => row.name ? getCount(row, type) : '',
        renderCell: ({ row }) => row.name ? (
          <Typography variant='subtitle1'>{getCount(row, type)}</Typography>
        ) : <Skeleton variant='text' width={theme.spacing(5)} />,
        align: 'right',
        headerAlign: 'right'
      })
    );

    return columns;
  }, [teamStat]);

  const loadingRow: TeamStat[] = new Array(3).fill(1).map((_, i) => (
    {
      id: i.toString(),
      lastActivityDate: '',
      name: '',
      teamId: 0,
      teamName: '',
      distributions: 0,
      interventions: 0,
      meetings: 0,
      presentations: 0,
      routines: 0
    }
  ));

  const getRows = () => {
    if (isLoading) {
      return loadingRow;
    }

    if (_.isEmpty(_.first(teamStat)?.id)) {
      return [];
    }

    return teamStat;
  };

  return (
    <Box sx={{ height: '50%', width: '100%', pl: 4, mt: 0 }}>
      <DataGrid
        autoHeight={true}
        columns={columns}
        rows={getRows()}
        columnHeaderHeight={34}
        rowHeight={48}
        hideFooter
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        rowSelection={false}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: () => <EmptyState
            title={formatMessage({ id: 'No activities for this team' })}
            subtitle={formatMessage({ id: 'Members of this team have not entered any activity for this period' })}
          />
        }}
        sx={{
          userSelect: 'none',
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            mb: getRows().length === 0 ? 3 : 0,
          }
        }}
      />
    </Box>
  );
};