import React from 'react';
import { ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(({ theme }) => ({
  color: grey[50],
  'svg': {
    color: grey[50]
  },
  '&:hover': {
    background: theme.palette.primary.dark,
  },
  '.active &, .active &:hover': {
    background: theme.palette.primary.dark,
    borderRight: `4px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    'svg': {
      color: theme.palette.secondary.main
    },
  },
  '.collapsed & .MuiListItemText-primary': {
    visibility: 'hidden',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '40px'
  }
}));

interface OwnProps {
  icon?: React.ReactNode;
  title?: string;
  children?: React.ReactNode;
  collapsed?: boolean;
}

export type NavigationMenuItemProps =
  & ListItemButtonProps
  & OwnProps;

export const NavigationMenuItem: React.FC<NavigationMenuItemProps> = (props) => {
  const { icon, title, children, collapsed, ...listItemProps } = props;

  return (
    <StyledListItemButton color="primary" {...listItemProps}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} sx={{ opacity: collapsed ? 0 : 1 }} />
      {children}
    </StyledListItemButton>
  );
};