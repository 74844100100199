import React from 'react';
import { Stack } from '@mui/material';
import { Tenant } from 'gql';
import { useOutletContext } from 'react-router-dom';
import { OrganizationDetails } from '../components/OrganizationDetails';

export const OrganizationDetailsTab: React.FC = () => {
  const tenant = useOutletContext<Tenant>();

  return (
    <Stack sx={{ pt: 2 }}>
      <OrganizationDetails tenant={tenant} />
    </Stack>
  );
};