import { ActivityType, EntityType } from 'gql';
import { defineMessages, MessageDescriptor } from 'react-intl';

export const activityTypeMessages: Record<ActivityType, MessageDescriptor> = defineMessages({
  [ActivityType.Distribution]: { id: 'Distribution' },
  [ActivityType.Intervention]: { id: 'Intervention' },
  [ActivityType.Presentation]: { id: 'Presentation' },
  [ActivityType.Routine]: { id: 'Routine' },
  [ActivityType.Meeting]: { id: 'Meeting' },
});

export const activityAddDialogTitleMessages: Record<ActivityType, MessageDescriptor> = defineMessages({
  [ActivityType.Distribution]: { id: 'Add distribution' },
  [ActivityType.Intervention]: { id: 'Add intervention' },
  [ActivityType.Presentation]: { id: 'Add presentation' },
  [ActivityType.Routine]: { id: 'Add routine' },
  [ActivityType.Meeting]: { id: 'Add meeting' },
});

export const activityEditDialogTitleMessages: Record<ActivityType, MessageDescriptor> = defineMessages({
  [ActivityType.Distribution]: { id: 'Edit distribution' },
  [ActivityType.Intervention]: { id: 'Edit intervention' },
  [ActivityType.Presentation]: { id: 'Edit presentation' },
  [ActivityType.Routine]: { id: 'Edit routine' },
  [ActivityType.Meeting]: { id: 'Edit meeting' },
});

export const activityDescriptionMessages: Record<ActivityType, MessageDescriptor> = defineMessages({
  [ActivityType.Distribution]: { id: 'A simple distribution' },
  [ActivityType.Intervention]: { id: 'An intervention with or without distribution' },
  [ActivityType.Presentation]: { id: 'Information or awareness activity with or without distribution' },
  [ActivityType.Routine]: { id: 'Field observations' },
  [ActivityType.Meeting]: { id: 'Consultation or training activity' },
});

export const entityTypePluralMessages: Record<EntityType, MessageDescriptor> = defineMessages({
  [EntityType.Distribution]: { id: 'Distributions' },
  [EntityType.Intervention]: { id: 'Interventions' },
  [EntityType.Presentation]: { id: 'Presentations' },
  [EntityType.Routine]: { id: 'Routines' },
  [EntityType.Individual]: { id: 'Known individuals' },
  [EntityType.UnknownGroup]: { id: 'Unknown individuals' },
  [EntityType.Meeting]: { id: 'Meetings' },
  [EntityType.Reality]: { id: 'Realities' }
});