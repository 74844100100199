import React from 'react';
import { styled, Typography, TypographyProps } from '@mui/material';

const ApplicationNameTypography = styled(Typography)({
  textTransform: 'uppercase',
  userSelect: 'none'
});

export const ApplicationName: React.FC<TypographyProps> = (typographyProps) => {
  return (
    <ApplicationNameTypography color='primary.main' {...typographyProps}>
      {'Rue-stique'}
    </ApplicationNameTypography>
  );
};