import { Container, ContainerProps, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as UnderConstructionImage } from '../../assets/images/undraw_pair_programming.svg';

const StyledContainer = styled(Container)<ContainerProps>(() => ({
  textAlign: 'center',
  '& svg': {
    maxWidth: '100%',
    maxHeight: '400px',
    height: '100%',
  }
}));

export const UnderConstructionMessage:React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <StyledContainer maxWidth="md" sx={{ paddingTop: 5 }}>
      <UnderConstructionImage />
      <Stack spacing={2} sx={{ textAlign: 'left', marginTop: 5 }}>
        <Typography variant='h5'>{formatMessage({ id: 'We are working hard to deliver this feature to you.' })}</Typography>
        <Typography variant='body1'>{formatMessage({ id: 'If you have any comments or suggestions, please send them to us at {email} and we will get back to you as soon as possible.' }, { email: <a href='mailto: info@rocqtr.org'>info@rocqtr.org</a> })}</Typography>
        <Typography variant='body1'>{formatMessage({ id: 'Thank you for your understanding.' })}</Typography>
      </Stack>
    </StyledContainer>
  );
};