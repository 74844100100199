import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Stack } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { GridColDef, GridColumnVisibilityModel, GridFilterModel } from '@mui/x-data-grid';
import { NewButton, PageTitle, RoutedTabs } from 'components';
import { ActivityFilterInput, CustomFieldValueFilterInput, EntityType, SystemFieldId, useFindCustomFieldsQuery, useGetCurrentUserQuery } from 'gql';
import { useFindActivitiesCancellableQuery } from 'gql/cancellableQueries';
import { useCurrentUserRoles } from 'hooks/useCurrentUserRoles';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { useCustomFieldsSearchUtils } from '../../../utils/dataGridSearch/customFieldsSearch';
import { ActivitiesTable } from '../components/ActivitiesTable';
import { ActivityDialog, ActivityFormValues } from '../components/ActivityDialog';
import { isActivityType } from '../types';
import { useActivityMutations } from '../utils';

const parseVisibilityModelKey = ([key, value]: [string, boolean]) => {
  const [entityType, id] = key.split(';');

  return {
    entityType: entityType as EntityType,
    id,
    visible: value
  };
};

const ralities: Record<string, SystemFieldId> = {
  'accompaniments': SystemFieldId.RealityAccompaniments,
  'approaches': SystemFieldId.RealityApproaches,
  'referrals': SystemFieldId.RealityReferrals,
};

const addSearchParamKey = 'addActivity';
const editSearchParamKey = 'editId';
const activityEntityTypes = [EntityType.Intervention.toString(), EntityType.Distribution.toString(), EntityType.Meeting.toString(), EntityType.Presentation.toString(), EntityType.Routine.toString()];
export const ActivitiesPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [gridFilter, setGridFilter] = useState<GridFilterModel>();

  const [activitiesFilter, setActivitiesFilter] = useState<ActivityFilterInput>();

  const [columnIds, setColumnIds] = useState<string[]>();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>();
  const [includeIndividuals, setIncludeIndividuals] = useState<boolean>();
  const [includeRealities, setIncludeRealities] = useState<boolean>();
  const [includeUnknownGroups, setIncludeUnknownGroups] = useState<boolean>();

  const [filterValues, setFilterValues] = useState<CustomFieldValueFilterInput>();
  const [filterIndividualValues, setFilterIndividualValues] = useState<CustomFieldValueFilterInput>();
  const [filterUnknownGroupValues, setFilterUnknownGroupValues] = useState<CustomFieldValueFilterInput>();
  const [filterRealitiesValues, setFilterRealityValues] = useState<CustomFieldValueFilterInput>();

  useEffect(() => {
    if (!columnIds || !columnVisibilityModel) return;

    const columnsNotInVisibilityModel = columnIds.filter(id => !Object.hasOwn(columnVisibilityModel, id));

    const entries =
      Object.entries(columnVisibilityModel)
        .concat(columnsNotInVisibilityModel.map(id => [id, true]))
        .map(parseVisibilityModelKey);

    const individualValues = entries.filter(v => v.entityType === EntityType.Individual);
    const unknownGroupValues = entries.filter(v => v.entityType === EntityType.UnknownGroup);
    const realitiesValues = entries.filter(v => v.entityType === EntityType.Reality);

    const hasIndividuals = individualValues.some(v => v.visible);
    const hasUnknownGroups = unknownGroupValues.some(v => v.visible);
    const hasRealities = realitiesValues.some(v => v.visible);

    setIncludeIndividuals(hasIndividuals);
    setIncludeUnknownGroups(hasUnknownGroups);
    setIncludeRealities(hasRealities);

    const hiddenFieldValues = entries
      .filter(({ entityType }) => isActivityType(entityType))
      .filter(v => !v.visible)
      .map(v => Number(v.id))
      .filter(Boolean);

    if (hiddenFieldValues.length > 0) {
      setFilterValues({ fieldId: { nin: hiddenFieldValues } });
    } else {
      setFilterValues(undefined);
    }

    const hiddenIndividualFieldIds = individualValues.filter(v => !v.visible).map(v => Number(v.id)).filter(Boolean);
    if (hasIndividuals && hiddenIndividualFieldIds.length > 0) {
      setFilterIndividualValues({ fieldId: { nin: hiddenIndividualFieldIds } });
    } else {
      setFilterIndividualValues(undefined);
    }

    const hiddenUnknownGroupFieldIds = unknownGroupValues.filter(v => !v.visible).map(v => Number(v.id)).filter(Boolean);
    if (hasUnknownGroups && hiddenUnknownGroupFieldIds.length > 0) {
      setFilterUnknownGroupValues({ fieldId: { nin: hiddenUnknownGroupFieldIds } });
    } else {
      setFilterUnknownGroupValues(undefined);
    }

    const hiddenRealityFieldIds = realitiesValues.filter(v => !v.visible).map(v => ralities[v.id]).filter(Boolean);
    if (hasRealities && hiddenRealityFieldIds.length > 0) {
      setFilterRealityValues({
        field: { systemFieldId: { nin: hiddenRealityFieldIds } }
      });
    } else {
      setFilterRealityValues(undefined);
    }
  }, [columnIds, columnVisibilityModel]);

  const { data: currentUser, isFetching: isFetchingUser } = useGetCurrentUserQuery();
  const { data: customFields } = useFindCustomFieldsQuery();

  const { mapGridFilterToCustomValuesFilter } = useCustomFieldsSearchUtils();

  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get('filter');

  useEffect(() => {
    const userTeamIds = currentUser?.me.teams.map(p => p.id);
    let newActivitiesFilter: ActivityFilterInput = { and: [] };

    if (searchTerm !== '') {
      newActivitiesFilter = {
        and: [{
          or: [
            { customFieldValues: { some: { stringValue: { startsWith: searchTerm } } } },
            { customFieldValues: { some: { lookupTeamValue: { name: { startsWith: searchTerm } } } } },
            { customFieldValues: { some: { lookupUserValues: { some: { firstName: { startsWith: searchTerm } } } } } },
            { customFieldValues: { some: { lookupUserValues: { some: { lastName: { startsWith: searchTerm } } } } } },
            { customFieldValues: { some: { selectionValues: { some: { i18n: { some: { name: { startsWith: searchTerm } } } } } } } },
            { realities: { some: { theme: { i18n: { some: { name: { startsWith: searchTerm } } } } } } },
            { realities: { some: { specification: { i18n: { some: { name: { startsWith: searchTerm } } } } } } },
            { realities: { some: { values: { some: { stringValue: { startsWith: searchTerm } } } } } },
            { realities: { some: { values: { some: { selectionValues: { some: { i18n: { some: { name: { startsWith: searchTerm } } } } } } } } } },
            { individuals: { some: { customFieldValues: { some: { stringValue: { startsWith: searchTerm } } } } } },
            { individuals: { some: { customFieldValues: { some: { selectionValues: { some: { i18n: { some: { name: { startsWith: searchTerm } } } } } } } } } },
          ]

        }, newActivitiesFilter]
      };
    }

    if (activeTab == null) {
      newActivitiesFilter =
      {
        and: [{
          createdBy: { id: { eq: currentUser?.me.id } }
        }, newActivitiesFilter]
      };
    } else if (activeTab === 'teams') {
      newActivitiesFilter =
      {
        and: [{
          owningTeamId: { in: userTeamIds ?? [] }
        }, newActivitiesFilter]
      };
    }

    if (gridFilter && gridFilter.items.length > 0 && gridFilter.items[0].value != null) {
      const activitiesFilters = gridFilter.items.filter(p => activityEntityTypes.indexOf(p.field.split(';')[0]) !== -1);
      const activityTypeFilters = gridFilter.items.find(p => p.field.split(';')[1] == 'type');
      const individualsFilters = gridFilter.items.filter(p => p.field.split(';')[0] == EntityType.Individual.toString());
      const themeFilter = gridFilter.items.find(p => p.field.split(';')[0] == EntityType.Reality.toString() && p.field.split(';')[1] == 'theme');
      const specificationFilter = gridFilter.items.find(p => p.field.split(';')[0] == EntityType.Reality.toString() && p.field.split(';')[1] == 'specification');
      const referralsFilter = gridFilter.items.find(p => p.field.split(';')[0] == EntityType.Reality.toString() && p.field.split(';')[1] == 'referrals');
      const accompanimentsFilter = gridFilter.items.find(p => p.field.split(';')[0] == EntityType.Reality.toString() && p.field.split(';')[1] == 'accompaniments');
      const approachesFilter = gridFilter.items.find(p => p.field.split(';')[0] == EntityType.Reality.toString() && p.field.split(';')[1] == 'approaches');

      const filters = [newActivitiesFilter];

      if (activitiesFilters.length > 0) {
        filters.push({ customFieldValues: mapGridFilterToCustomValuesFilter(activitiesFilters, customFields?.customFields ?? []) });
      }

      if (individualsFilters.length > 0) {
        filters.push({ individuals: { some: { customFieldValues: mapGridFilterToCustomValuesFilter(individualsFilters, customFields?.customFields ?? []) } } });
      }

      if (themeFilter) {
        filters.push({ realities: { some: { theme: { i18n: { some: { name: { startsWith: themeFilter.value } } } } } } });
      }

      if (specificationFilter) {
        filters.push({ realities: { some: { specification: { i18n: { some: { name: { startsWith: specificationFilter.value } } } } } } });
      }

      if (referralsFilter) {
        filters.push({
          realities: {
            some: {
              values: {
                some: {
                  and: [
                    { selectionValues: { some: { i18n: { some: { name: { startsWith: referralsFilter.value } } } } } },
                    { field: { systemFieldId: { eq: SystemFieldId.RealityReferrals } } }
                  ]
                }
              }
            }
          }
        });
      }

      if (accompanimentsFilter) {
        filters.push({
          realities: {
            some: {
              values: {
                some: {
                  and: [
                    { selectionValues: { some: { i18n: { some: { name: { startsWith: accompanimentsFilter.value } } } } } },
                    { field: { systemFieldId: { eq: SystemFieldId.RealityAccompaniments } } }
                  ]
                }
              }
            }
          }
        });
      }

      if (approachesFilter) {
        filters.push({
          realities: {
            some: {
              values: {
                some: {
                  and: [
                    { selectionValues: { some: { i18n: { some: { name: { startsWith: approachesFilter.value } } } } } },
                    { field: { systemFieldId: { eq: SystemFieldId.RealityApproaches } } }
                  ]
                }
              }
            }
          }
        });
      }

      if (activityTypeFilters) {
        filters.push({ type: { eq: activityTypeFilters.value } });
      }

      newActivitiesFilter = {
        and: filters
      };
    }

    setActivitiesFilter(newActivitiesFilter);
  }, [activeTab, currentUser?.me.id, currentUser?.me.teams, customFields, gridFilter, mapGridFilterToCustomValuesFilter, searchTerm]);


  const { data: activitiesQuery, isFetching: isFetchingActivities } = useFindActivitiesCancellableQuery({
    pageSize: pageSize,
    skip: page * pageSize,
    filter: activitiesFilter,
    includeIndividuals, includeRealities, includeUnknownGroups,
    filterValues, filterIndividualValues, filterRealitiesValues, filterUnknownGroupValues
  }, {
    enabled: Boolean(activitiesFilter),
    queryKey: ['findActivities', activitiesFilter, includeIndividuals, includeRealities, includeUnknownGroups, filterValues, filterIndividualValues, filterRealitiesValues, filterUnknownGroupValues]
  });
  const activities = useMemo(() => activitiesQuery?.activities?.items ?? [], [activitiesQuery?.activities?.items]);

  const handleDataGridFilter = (filter: GridFilterModel) => {
    setGridFilter(filter?.items.length > 0 ? filter : undefined);
  };

  const handleSearch = useMemo(() => debounce((searchTerm: string) => setSearchTerm(searchTerm), 500), []);

  const addingIntervention = searchParams.get(addSearchParamKey) === 'true';
  const editingInterventionId = Number(searchParams.get(editSearchParamKey)) || undefined;

  const isInterventionDialogOpen = Boolean(editingInterventionId) || addingIntervention;

  const handleClose = () => {
    searchParams.delete(addSearchParamKey);
    searchParams.delete(editSearchParamKey);
    setSearchParams(searchParams);
  };

  const handleAddIntervention = () => {
    searchParams.set(addSearchParamKey, 'true');
    setSearchParams(searchParams);
  };

  const { submitActivity, isSubmitting } = useActivityMutations({
    onSuccess: handleClose
  });

  const onInterventionSubmit = (activityFormValues: ActivityFormValues) => {
    submitActivity(activityFormValues, editingInterventionId);
  };

  const { isAdministrator } = useCurrentUserRoles();

  const tabs = useMemo(() => {
    const tabs = [{
      navigationString: 'mine',
      displayString: formatMessage({ id: 'Mine' })
    }];

    tabs.push({
      navigationString: 'teams',
      displayString: formatMessage({ id: 'My Teams' }),
    });

    if (isAdministrator) {
      tabs.push({
        navigationString: 'all',
        displayString: formatMessage({ id: 'All' }),
      });
    }

    return tabs;
  }, [formatMessage, isAdministrator]);

  const onColumnsChange = (c: GridColDef[]) =>
    setColumnIds(c.map(p => p.field));

  return (
    <Stack mb={{ xs: 9, md: 0 }}>
      <PageTitle icon={<QuestionAnswerIcon />} title={formatMessage({ id: 'Activities' })}
        actionButton={(
          <NewButton onClick={handleAddIntervention} />
        )}
      />

      <RoutedTabs
        thin
        navigationStyle='queryParam'
        queryParam='filter'
        defaultNavigation='mine'
        tabs={tabs}
        sx={{ mb: 1 }}
        variant='scrollable'
        scrollButtons='auto'
      />

      <ActivitiesTable
        onFilter={handleDataGridFilter}
        height={560}
        onSearch={handleSearch}
        loading={isFetchingActivities || isFetchingUser}
        activities={activities}
        paginationMode='server'
        pagination={true}
        paginationModel={{ page: page, pageSize: pageSize }}
        rowCount={activitiesQuery?.activities?.totalCount ?? 0}
        pageSizeOptions={[10, 25, 50, 100]}
        onPaginationModelChange={(model) => { setPage(model.page); setPageSize(model.pageSize); }}
        onEdit={id => {
          searchParams.set(editSearchParamKey, id.toString());
          setSearchParams(searchParams);
        }}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        onColumnsChange={onColumnsChange}
      />

      <ActivityDialog
        isOpen={isInterventionDialogOpen}
        isLoading={isSubmitting}
        onClose={handleClose}
        onSubmit={onInterventionSubmit}
        editingActivityId={editingInterventionId}
      />
    </Stack>
  );
};