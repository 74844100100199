import React from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { DisplayInformation, DisplayInformationRow } from 'components';
import { Tenant } from 'gql';
import { OrganizationDialog } from '../OrganizationDialog';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

interface OwnProps {
  tenant: Tenant;
}

export const OrganizationDetails: React.FC<OwnProps> = (props) => {
  const { formatMessage } = useIntl();
  const { tenant } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const isDialogVisible = searchParams.get('edit') === 'true';

  const handleOpenDialog = () => {
    searchParams.set('edit', 'true');
    setSearchParams(searchParams);
  };

  const handleCloseDialog = () => {
    searchParams.delete('edit');
    setSearchParams(searchParams);
  };

  return (
    <DisplayInformation title={formatMessage({ id: 'Information' })} onEdit={handleOpenDialog}>
      <DisplayInformationRow label={formatMessage({ id: 'Name' })}>
        <Typography variant='body2'>
          {tenant.name}
        </Typography>
      </DisplayInformationRow>
      <DisplayInformationRow label={formatMessage({ id: 'Identifier' })}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='body2'>
            stats.rocqtr.org/<b>{tenant.identifier}</b>
          </Typography>
        </Stack>
      </DisplayInformationRow>
      <DisplayInformationRow label={formatMessage({ id: 'Region' })}>
        <Typography variant='body2'>
          {tenant.region}
        </Typography>
      </DisplayInformationRow>
      <DisplayInformationRow label={formatMessage({ id: 'City' })}>
        <Typography variant='body2'>
          {tenant.town}
        </Typography>
      </DisplayInformationRow>
      <DisplayInformationRow label={formatMessage({ id: 'Telephone' })}>
        <Link color='info.main' variant='body2' href={`tel:${tenant.telephone}`}>
          {tenant.telephone}
        </Link>
        <Typography variant='body2'>{tenant.extensionTelephone ? `, ext. ${tenant.extensionTelephone}` : ''}</Typography>
      </DisplayInformationRow>
      <DisplayInformationRow label={formatMessage({ id: 'Email' })}>
        <Link color='info.main' variant='body2' href={`mailto:${tenant.email}`}>
          {tenant.email}
        </Link>
      </DisplayInformationRow>
      <OrganizationDialog
        organization={tenant}
        open={isDialogVisible}
        handleClose={handleCloseDialog}
      />
    </DisplayInformation>
  );
};