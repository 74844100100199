import { ActivityType, useGetActivityTypesSettingsQuery } from 'gql';

export const useActivitySettings = () => {
  const { data: activityTypeSettingsData } = useGetActivityTypesSettingsQuery();

  const activityTypeSettings = activityTypeSettingsData?.settings.at(0);
  const activitiesEnabled = [];

  if (activityTypeSettings?.isDistributionEnabled) {
    activitiesEnabled.push(ActivityType.Distribution);
  }

  if (activityTypeSettings?.isInterventionEnabled) {
    activitiesEnabled.push(ActivityType.Intervention);
  }

  if (activityTypeSettings?.isMeetingEnabled) {
    activitiesEnabled.push(ActivityType.Meeting);
  }

  if (activityTypeSettings?.isPresentationEnabled) {
    activitiesEnabled.push(ActivityType.Presentation);
  }

  if (activityTypeSettings?.isRoutineEnabled) {
    activitiesEnabled.push(ActivityType.Routine);
  }

  return { activitiesEnabled };
};