import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { Collapse, List, ListProps, styled } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { NavigationMenuItem, NavigationMenuItemProps } from './NavigationMenuItem';
import { isNavigationMenuCollapsedState } from '../../../atoms';

interface OwnProps {
  icon: React.ReactNode;
  title: string;
  children?: React.ReactNode;
}

export type NavigationMenuSubMenuProps =
  & OwnProps;

const StyledSubMenuItem = styled(NavigationMenuItem)<NavigationMenuItemProps>(() => ({
  '&.expanded': {
    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 5px 0px rgba(0,0,0,0.14)',
    zIndex: 1
  },
}));

const StyledSubMenuChildren = styled(List)<ListProps>(() => ({
  background: 'rgba(0, 0, 0, 0.2)'
}));

export const NavigationMenuSubMenu: React.FC<NavigationMenuSubMenuProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNavigationMenuCollapsed, setIsNavigationMenuCollapsed] = useRecoilState(isNavigationMenuCollapsedState);

  const canOpen = !isNavigationMenuCollapsed;
  const buttonClassName = isOpen && canOpen ? 'expanded' : 'collapsed';

  const toggleSubMenu = () => {
    setIsOpen(!isOpen);

    if (isNavigationMenuCollapsed) {
      setIsNavigationMenuCollapsed(false);
      setIsOpen(true);
    }
  };

  return (
    <>
      <StyledSubMenuItem className={buttonClassName} icon={props.icon} title={props.title} onClick={toggleSubMenu}>
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </StyledSubMenuItem>

      <Collapse in={isOpen && canOpen} timeout="auto">
        <StyledSubMenuChildren disablePadding>
          {props.children}
        </StyledSubMenuChildren>
      </Collapse>
    </>
  );
};