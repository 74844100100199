import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RestorationPage } from './views/RestorationPage';
import { ActivityPage } from 'modules/activities/views/ActivityPage';

export const routes: RouteObject[] = [
  { path: 'restoration', element: <RestorationPage /> },
  { path: 'restoration/:activityId', element: <ActivityPage /> },
];

export default routes;