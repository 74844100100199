import { Edit, Summarize } from '@mui/icons-material';
import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { PageTitle } from 'components';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  title?: string;
  onChange: (title: string) => void;
}

export const ReportPageTitle: React.FC<Props> = ({ title, onChange }) => {
  const { formatMessage } = useIntl();

  const [isEditingTitle, setEditingTitle] = useState(false);

  const [editedTitle, setEditedTitle] = useState(title);

  useEffect(() => {
    setEditedTitle(title);
  }, [title]);

  const saveTitle = () => {
    if (editedTitle) {
      setEditingTitle(false);
      onChange(editedTitle);
    } else {
      setEditingTitle(false);
      setEditedTitle(title);
    }
  };

  const titleElement = !title
    ? formatMessage({ id: 'New report' })
    : !isEditingTitle
      ? (
        <Stack direction='row'>
          <Typography
            onClick={() => setEditingTitle(true)}
            fontSize={32}
            sx={{ ':hover': { textDecoration: 'underline' } }}
          >
            {title}
          </Typography>
          <IconButton disableRipple color='primary' onClick={() => setEditingTitle(true)}>
            <Edit />
          </IconButton>
        </Stack>
      )
      : (
        <TextField
          autoFocus
          variant='standard'
          defaultValue={editedTitle ?? ''}
          onBlur={saveTitle}
          error={!editedTitle}
          onChange={e => setEditedTitle(e.target.value)}
          inputProps={{ style: { fontSize: 32 } }}
          size='small'
        />
      );

  return (
    <PageTitle
      icon={<Summarize />}
      title={titleElement}
    />
  );
};