import { PageTitle } from 'components';
import { useFindDeletedActivitiesQuery } from 'gql';
import React from 'react';
import { useIntl } from 'react-intl';
import { Stack } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { ActivitiesTable } from 'modules/activities/components/ActivitiesTable';

export const RestorationPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const { data: activitiesQuery, isFetching: isFetchingActivities } = useFindDeletedActivitiesQuery();

  return (
    <Stack mb={{ xs: 9, md: 0 }}>
      <PageTitle icon={<DeleteOutline />} title={formatMessage({ id: 'Recycle Bin' })} subtitle={formatMessage({ id: 'Activities' })} />

      <ActivitiesTable
        loading={isFetchingActivities}
        activities={activitiesQuery?.deletedActivities ?? []}
      />
    </Stack>
  );
};