import React, { useMemo } from 'react';
import { ChartData } from 'chart.js';
import { ActivityType, useGetActivitiesCountByDateInDateRangeQuery, useGetSettingsQuery } from 'gql';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { TimeRange } from '../DropdownFilter';
import { useActivitySettings } from 'hooks/useActivitySettings';
import dayjs, { Dayjs } from 'dayjs';
import { ActivityTypeColor } from 'modules/activities/types';
import { useIntl } from 'react-intl';
import { Paper, Skeleton, useTheme } from '@mui/material';
import _ from 'lodash';
import { Filter } from 'modules/home/hooks/useGetActivitiesFilter';
import { dashboardQueryOptions } from '../../utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface OwnProps {
  activitiesFilter: Filter
  selectedTimeRange: TimeRange
}

export const ActivityChart: React.FC<OwnProps> = (props) => {

  const { activitiesEnabled } = useActivitySettings();
  const { locale } = useIntl();
  const theme = useTheme();

  const { data: activitiesData, isFetching: isActivitiesLoading } = useGetActivitiesCountByDateInDateRangeQuery(props.activitiesFilter, dashboardQueryOptions());
  const activities = activitiesData?.activitiesCountByDate ?? [];

  const { data: settingsData, isFetching: isSettingsLoading } = useGetSettingsQuery();
  const fiscalYearStart = settingsData?.settings.at(0)?.fiscalYearStart ?? dayjs().startOf('year');

  const getNumberOfActivities = (forType: ActivityType, forDate: Dayjs, dateUnit: dayjs.OpUnitType) => {
    return _(activities)
      .filter(a => a.activityType === forType && dayjs(a.date).isSame(forDate, dateUnit))
      .sumBy(activity => activity.count);
  };

  const isLoading = isActivitiesLoading || isSettingsLoading;

  const chartData: ChartData<'line'> = useMemo(() => {
    let dataSize = 0;
    let dateFormat = '';
    let unitToSubstract: dayjs.ManipulateType = 'days';

    const chartData: ChartData<'line'> = {
      datasets: [],
      labels: []
    };

    switch (props.selectedTimeRange) {
      case TimeRange.last7Days:
        dateFormat = 'ddd';
        dataSize = 6;
        break;
      case TimeRange.lastMonth:
        dateFormat = 'DD MMM';
        dataSize = 29;
        break;
      case TimeRange.beginFiscalYear:
        dateFormat = 'MMM';
        unitToSubstract = 'months';
        dataSize = dayjs().diff(dayjs(fiscalYearStart), 'M');
        break;
    }

    const labels: string[] = [];

    for (let i = dataSize; i >= 0; i--) {
      const date = dayjs().subtract(i, unitToSubstract);
      labels.push(date.format(dateFormat));
    }

    chartData.labels = labels;

    activitiesEnabled.forEach(type => {
      const data: number[] = [];

      for (let i = dataSize; i >= 0; i--) {
        const date = dayjs().subtract(i, unitToSubstract);
        data.push(getNumberOfActivities(type, date, unitToSubstract));
      }

      chartData.datasets.push({
        data: data,
        borderColor: ActivityTypeColor[type],
        label: type
      });

    });

    chartData.labels = labels;

    return chartData;
  }, [props.selectedTimeRange, locale, activitiesEnabled]);

  return (
    <>{isLoading ?
      <Skeleton variant='rounded' width='100%' height={theme.spacing(40)} />
      :
      <Paper sx={{ px: 1, py: 1, width: '100%', height: theme.spacing(40) }}>
        <Line data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              line: {
                tension: 0.4
              }
            },
            plugins: {
              legend: {
                labels: {
                  color: theme.palette.text.primary
                }
              }
            },
            scales: {
              x: {
                ticks: {
                  color: theme.palette.text.primary
                }
              },
              y: {
                ticks: {
                  color: theme.palette.text.primary,
                  stepSize: 1
                },
                beginAtZero: true
              },
            },
          }}
        />
      </Paper>
    }
    </>
  );
};

