import { Avatar, AvatarProps, Typography, TypographyVariant } from '@mui/material';
import React from 'react';
import { getAvatarColor, getAvatarTextColor, getInitials } from '../../utils/userUtils';
import PersonIcon from '@mui/icons-material/Person';

interface OwnProps {
  displayName: string | undefined;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'xlarge' | 'default';
  textVariant?: TypographyVariant;
}

export type UserAvatarProps =
  & AvatarProps
  & OwnProps;

export const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const { displayName, size, textVariant, ...avatarProps } = props;

  let length: string | undefined = undefined;
  let fontSize: string | undefined = undefined;
  switch (size) {
    case 'tiny':
      length = '16px';
      fontSize = '11px';
      break;
    case 'small':
      length = '24px';
      fontSize = '14px';
      break;
    case 'medium':
      length = '32px';
      fontSize = '16px';
      break;
    case 'large':
      length = '62px';
      fontSize = '32px';
      break;
    case 'xlarge':
      length = '96px';
      fontSize = '48px';
      break;
    case 'default':
      fontSize = '18px';
      break;
  }

  if (!displayName) {
    return (
      <Avatar sx={{ width: length, height: length }} {...avatarProps}>
        <PersonIcon />
      </Avatar>
    );
  }

  const avatarColor = getAvatarColor(displayName);
  const textColor = getAvatarTextColor(displayName);

  return (
    <Avatar
      {...avatarProps}
      sx={{
        backgroundColor: avatarColor,
        WebkitTextFillColor: textColor,
        width: length,
        height: length,
        ...avatarProps.sx
      }}>
      <Typography variant={textVariant} fontSize={fontSize} color={textColor}>{getInitials(displayName)}</Typography>
    </Avatar>
  );
};

UserAvatar.defaultProps = {
  size: 'medium'
};