import { Close } from '@mui/icons-material';
import React from 'react';
import { ConfirmCloseDialog } from '../ConfirmCloseDialog';
import { SlideDialog, SlideDialogProps } from './SlideDialog';

export interface FormDialogProps extends Omit<SlideDialogProps, 'topbar'> {
  isFormDirty: boolean;
  title: string;
  disabled?: boolean;
}

export const FormDialog: React.FC<FormDialogProps> = ({ children, open, onClose, title, isFormDirty, disabled, ...dialogProps }) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);

  const onConfirmClose = () => {
    setIsConfirmDialogOpen(false);
    onClose && onClose({}, 'escapeKeyDown');
  };

  const onCloseRequested = () => isFormDirty ? setIsConfirmDialogOpen(true) : onConfirmClose();

  return (
    <>
      <SlideDialog {...dialogProps}
        open={open || isConfirmDialogOpen}
        onClose={onCloseRequested}
        topbar={{
          disabled: disabled,
          leftIcon: (<Close />),
          leftIconAction: onCloseRequested,
          title: title
        }}>
        {children}
      </SlideDialog>
      <ConfirmCloseDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={onConfirmClose}
      />
    </>
  );
};