import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useGetTenantByIdQuery } from 'gql';
import { useParams } from 'react-router';
import { Outlet } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RoutedTabs } from 'components';

export const OrganizationPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { tenantId } = useParams();
  const { data: data, isFetched } = useGetTenantByIdQuery({ tenantId: tenantId ?? '' });
  const tenant = data?.tenants[0];

  if (tenant == null) {
    if (isFetched)
      throw new Error(formatMessage({ id: 'Tenant not found' }));

    return null;
  }

  return (
    <Stack>
      <Typography variant='h4' fontWeight={500}>{tenant?.name}</Typography>

      <RoutedTabs tabs={[
        { displayString: formatMessage({ id: 'Details' }), navigationString: 'details' },
      ]} />

      <Outlet context={tenant} />
    </Stack>
  );
};