import { Stack, TextField, Typography } from '@mui/material';
import { NumericalFilterInput as NumericalFilter } from 'gql';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  value?: NumericalFilter | null;
  onChange: (value: NumericalFilter) => void;
  disabled?: boolean;
}

export const NumericalFilterField: React.FC<Props> = ({ value, onChange, disabled }) => {
  const { formatMessage } = useIntl();

  const stringToNumber = (value?: string) => {
    if (!value || isNaN(Number(value))) {
      return undefined;
    } else {
      return Number(value);
    }
  };

  return (
    <Stack direction={'row'} spacing={2} alignItems='center'>
      <TextField
        label={formatMessage({ id: 'Min' })}
        fullWidth
        onChange={min => {
          const number = stringToNumber(min.target.value);
          onChange({ min: number, max: value?.max });
        }}
        disabled={disabled}
      />

      <Typography variant='subtitle1'>
        {formatMessage({ id: 'to' })}
      </Typography>

      <TextField
        label={formatMessage({ id: 'Max' })}
        fullWidth
        onChange={max => {
          const number = stringToNumber(max.target.value);
          onChange({ min: value?.min, max: number });
        }}
        disabled={disabled}
      />
    </Stack>
  );
};