import React from 'react';
import { AppBar, IconButton, Stack, styled, Toolbar, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useRecoilState } from 'recoil';
import { ApplicationLogo } from '../../../../../components';
import { isNavigationMenuOpenState, isSettingsMenuOpenState } from '../../../atoms';
import { UserAvatar } from 'components';
import { useGetCurrentUserQuery } from 'gql';
import { useResponsive } from 'hooks';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.appBar.default,
  zIndex: theme.zIndex.drawer + 1
}));

export const TopBar: React.FC = () => {
  const { isMobile } = useResponsive();
  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useRecoilState(isNavigationMenuOpenState);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useRecoilState(isSettingsMenuOpenState);

  const { data: currentUser } = useGetCurrentUserQuery();
  const toggleNavigationMenu = () => setIsNavigationMenuOpen(!isNavigationMenuOpen);
  const toggleSettingsMenu = () => setIsSettingsMenuOpen(!isSettingsMenuOpen);

  return (
    <StyledAppBar
      color="default"
      position="sticky"
      elevation={1}
    >
      <Toolbar disableGutters sx={{ paddingX: 2.5 }}>
        <Stack direction='row' width='100%' alignItems='center'>
          {isMobile && (
            <IconButton edge="start" color="inherit" onClick={toggleNavigationMenu}>
              <MenuIcon />
            </IconButton>
          )}

          <ApplicationLogo size={isMobile ? 'small' : 'medium'} pl={1} textColor='primary' />
        </Stack>

        <IconButton onClick={toggleSettingsMenu}>
          <UserAvatar displayName={currentUser?.me?.displayName} />
        </IconButton>
      </Toolbar>
    </StyledAppBar>
  );
};