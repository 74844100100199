import { Chip, ChipProps, styled } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import React from 'react';

const StyledChip = styled(Chip)<ChipProps>(() => ({
  color: blueGrey[500],
  backgroundColor: blueGrey[50]
}));

export const SmallChip: React.FC<ChipProps> = (props) => {
  return (
    <StyledChip size='small' {...props} />
  );
};