import React from 'react';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { Stack } from '@mui/system';
import { Box, Button, Divider, Paper, PaperProps, styled, Typography, useTheme } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

interface OwnProps extends DataGridProps {
  noDataMessage: string
  sectionTitle: string
  bottomMessage?: string
  buttonLabel?: string
  onClickButton?: () => void
  defaultHeight?: boolean
}

const StyledPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  width: '100%',
  '& .MuiDataGrid-root': {
    borderRadius: '0 0 4px 4px',
    border: 'none',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  },
  minHeight: theme.spacing(40)
}));

export const DashboardSimpleTable: React.FC<OwnProps> = (props) => {
  const theme = useTheme();
  const { noDataMessage, sectionTitle, buttonLabel, bottomMessage, onClickButton, defaultHeight, ...dataGridProps } = props;
  return (
    <Stack>
      <StyledPaper sx={{ minHeight: defaultHeight ? theme.spacing(40) : 'auto' }}>
        <Stack direction='row' px={2} py={0.5} justifyContent='space-between' alignItems='center' minHeight='40px'>
          <Typography variant='h6'>
            {sectionTitle}
          </Typography>
          {onClickButton && buttonLabel &&
            <Button onClick={onClickButton} endIcon={<ArrowForward />}
              sx={{
                color: theme.palette.text.primary,
                '& .MuiButton-endIcon': {
                  color: theme.palette.primary.main
                }
              }}
            >
              {buttonLabel}
            </Button>
          }
        </Stack>
        <Box sx={{ height: 'auto', width: '100%' }}>
          <DataGrid
            {...dataGridProps}
            autoHeight={true}
            columnHeaderHeight={34}
            rowHeight={48}
            localeText={{
              noRowsLabel: noDataMessage,
            }}
            hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableRowSelectionOnClick
            sx={{
              '& .MuiDataGrid-cell:first-of-type': {
                padding: 0,
              },
              '& .MuiDataGrid-cell': {
                cursor: 'pointer',
              }
            }}
          />
        </Box>
      </StyledPaper >
      {
        bottomMessage &&
        <Typography variant='body2' mt={1}>{bottomMessage}</Typography>
      }
    </Stack>
  );
};