import { Divider, LinearProgress, ListItem, ListItemButton, ListItemText, Paper, Stack, Switch, Typography } from '@mui/material';
import { LookupInput } from 'components';
import { ReportGenderBreakdown, ReportParametersInput, useGetIndividualsSettingsQuery } from 'gql';
import { reportGenderBreakdownDescriptionMessages, reportGenderBreakdownMessages } from 'modules/reports/messages';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface Props {
  disabled?: boolean;
}

export const ReportParameters: React.FC<Props> = ({ disabled }) => {
  const { formatMessage } = useIntl();

  const { control } = useFormContext<ReportParametersInput>();
  const genderBreakdown = useWatch({ control, name: 'genderBreakdown' });
  const includeUnknownIndividuals = useWatch({ control, name: 'includeUnknownIndividuals' });

  const { data: settingsData, isFetching: isSettingsFetching } = useGetIndividualsSettingsQuery();
  const settings = settingsData?.settings.at(0);

  const includeUnknownIndividualsDisabled = Boolean(settings) && !settings?.isKnownIndividualsEnabled;

  return (
    <Paper>
      <LinearProgress style={{ visibility: isSettingsFetching ? 'visible' : 'hidden' }} />
      <Typography variant="h6" p={2}>
        {formatMessage({ id: 'Parameters' })}
      </Typography>
      <Divider />

      <Stack direction='row' justifyContent='space-between' p={2}>
        <Stack>
          <Typography>
            {formatMessage({ id: 'Include unknown individuals' })}
          </Typography>
          <Typography variant='subtitle2' color='text.secondary'>
            {includeUnknownIndividualsDisabled && (
              formatMessage({ id: 'Because known individuals are disabled, unknown individuals will always be included.' })
            )}
            {(!includeUnknownIndividualsDisabled && includeUnknownIndividuals) && (
              formatMessage({ id: 'Unknown individuals will be included in the generated statistics' })
            )}
            {(!includeUnknownIndividualsDisabled && !includeUnknownIndividuals) && (
              formatMessage({ id: 'Unknown individuals will not be included in the generated statistics' })
            )}
          </Typography>
        </Stack>

        <Controller
          control={control}
          name='includeUnknownIndividuals'
          render={({ field: { onChange, value } }) => (
            <Switch
              disabled={disabled || includeUnknownIndividualsDisabled}
              checked={value ?? false}
              onChange={(_, checked) => onChange(checked)}
            />
          )}
        />
      </Stack>

      <Divider />

      <Stack alignItems='center' direction='row' justifyContent='space-between' p={2}>
        <Stack>
          <Typography>
            {formatMessage({ id: 'Gender breakdown' })}
          </Typography>
          <Typography variant='subtitle2' color='text.secondary'>
            {formatMessage(reportGenderBreakdownDescriptionMessages[genderBreakdown])}
          </Typography>
        </Stack>

        <Controller
          control={control}
          name='genderBreakdown'
          render={({ field: { onChange, value } }) => (
            <LookupInput
              selectedValues={[value]}
              availableValues={Object.values(ReportGenderBreakdown)}
              onChange={a => onChange(a.at(0) ?? ReportGenderBreakdown.None)}
              getKey={a => a}
              getLabel={a => formatMessage(reportGenderBreakdownMessages[a])}
              title={formatMessage({ id: 'Gender breakdown' })}
              renderListItem={(a, selected, onClick) => (
                <ListItem disablePadding>
                  <ListItemButton onClick={onClick} selected={selected}>
                    <ListItemText
                      primary={formatMessage(reportGenderBreakdownMessages[a])}
                      secondary={formatMessage(reportGenderBreakdownDescriptionMessages[a])}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              SelectProps={{
                size: 'small',
              }}
              disableFullWidth
            />
          )}
        />
      </Stack>
    </Paper>
  );
};
