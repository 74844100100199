import { QuestionMark } from '@mui/icons-material';
import { ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { LookupInputDrawer } from 'components';
import { ConfirmButton } from 'components/Buttons';
import { Report, useDeleteModelMutation, useGetModelsQuery } from 'gql';
import deburr from 'lodash/deburr';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

interface OwnProps {
  loadReportModel: (model?: Report) => void;
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}

export const ReportLoadDrawer: React.FC<OwnProps> = (props) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const { data: modelsData, isFetching } = useGetModelsQuery();
  const models = useMemo(() => modelsData?.models ?? [], [modelsData?.models]);

  const deleteModel = useDeleteModelMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['getModels']);
      props.loadReportModel();
    }
  });

  const handleDelete = (modelId: number) => {
    deleteModel.mutate({ input: { id: modelId } });
  };

  return (
    <LookupInputDrawer
      open={props.isOpen}
      setOpen={props.setOpen}
      label={formatMessage({ id: 'Select a model to load' })}
      loading={isFetching}
      selectedValues={[]}
      availableValues={models}
      onChange={models => props.loadReportModel(models.at(0))}
      getKey={model => model.id}
      getLabel={model => model.name}
      renderListItem={(model, _, onClick) => (
        <ListItem
          key={model.id}
          disablePadding
          secondaryAction={
            <ConfirmButton
              buttonVariant='iconButton'
              onConfirm={() => handleDelete(model.id)}
              confirmTitle={formatMessage({ id: 'Delete this model' })}
              confirmText={formatMessage({ id: 'Are you sure you want to delete this model?' })}
            />
          }
        >
          <ListItemButton onClick={onClick}>
            <Stack>
              <ListItemText primary={model.name} />
            </Stack>
          </ListItemButton>
        </ListItem>
      )}
      renderEmptyState={() => (
        <Stack width='100%' alignItems='center' spacing={2} mt={3}>
          <QuestionMark />
          <Typography>
            {formatMessage({ id: 'No models found' })}
          </Typography>
        </Stack>
      )}
      searchFilter={(model, search) => deburr(model.name).toLowerCase().includes(deburr(search).toLowerCase())}
    />
  );
};