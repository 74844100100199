import React from 'react';
import { AddTeamInput, Team, useAddTeamMutation, useEditTeamMutation } from 'gql';
import { Close } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { TeamForm } from '../TeamForm';
import { SlideDialog } from 'components/Dialogs/SlideDialog';

interface OwnProps {
  team?: Team;
  open: boolean,
  handleClose: () => void;
}

export const TeamDialog: React.FC<OwnProps> = (props) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const addTeamMutation = useAddTeamMutation({
    onSuccess: () => {
      props.handleClose();
      return queryClient.invalidateQueries(['findTeams']);
    }
  });

  const updateTeamMutation = useEditTeamMutation({
    onSuccess: () => {
      props.handleClose();
      return queryClient.invalidateQueries(['getTeamById']);
    }
  });

  const isSubmitting = (addTeamMutation.isLoading || updateTeamMutation.isLoading);

  const submitForm = (teamData: AddTeamInput) => {
    if (props.team?.id) {
      updateTeamMutation.mutate({
        input: {
          id: props.team.id,
          ...teamData
        }
      });
    } else {
      addTeamMutation.mutate({ input: teamData });
    }
  };

  return (
    <SlideDialog
      open={props.open}
      onClose={props.handleClose}
      topbar={{
        leftIcon: <Close />,
        leftIconAction: props.handleClose,
        title: (props.team ? formatMessage({ id: 'Edit team' }) : formatMessage({ id: 'Add team' })),
        disabled: isSubmitting,
      }}
    >
      <TeamForm
        disabled={isSubmitting}
        team={props.team}
        onSubmit={submitForm}
        onClose={props.handleClose}
      />
    </SlideDialog>
  );
};
