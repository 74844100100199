import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Divider, LinearProgress, Paper, Typography } from '@mui/material';
import { DistributionCategoryDrawer } from '../components/CategoryDrawer';
import { useOutletContext } from 'react-router-dom';
import { useFindDistributionCategoriesQuery } from 'gql';
import { DraggableCategoriesList } from '../components/DraggableCategoriesList';

type BooleanSetter = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

export const DistributionCategoriesPage: React.FC = () => {
  const { formatMessage } = useIntl();

  const [_isDrawerOpen, setIsDrawerOpen] = useOutletContext<BooleanSetter>();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
  const isDrawerOpen = _isDrawerOpen || selectedCategoryId !== undefined;

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedCategoryId(undefined);
  };

  const { data: categoryData } = useFindDistributionCategoriesQuery({
    input: { id: { eq: selectedCategoryId } }
  }, { enabled: Boolean(selectedCategoryId) });
  const category = categoryData?.categories.at(0);

  return <>
    <LinearProgress sx={{ visibility: 'hidden' }} />

    <Paper>
      <Typography variant='h5' p={2}>{formatMessage({ id: 'Categories' })}</Typography>
      <Divider />

      <DraggableCategoriesList
        onCategoryClicked={setSelectedCategoryId}
      />

      <DistributionCategoryDrawer
        open={isDrawerOpen}
        category={selectedCategoryId ? category : undefined}
        onClose={closeDrawer}
      />
    </Paper>
  </>;
};