import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { useMemo } from 'react';
import { RealityInput, useRealitiesDataQuery } from '../../../../../../gql';
import { DeleteIconButton } from 'components/DeleteIconButton';

export const RealityListItem: React.FC<{
  reality: RealityInput,
  onDelete?: () => void;
  selected?: boolean;
  onClick?: () => void;
}> = ({ onDelete, selected, onClick, reality }) => {
  const { data: realitiesData } = useRealitiesDataQuery();

  const theme = useMemo(() => realitiesData?.themes.find(p => p.id === reality.themeId), [realitiesData?.themes, reality.themeId]);
  const specification = useMemo(() => theme?.specifications.find(p => p.id === reality.specificationId), [reality.specificationId, theme?.specifications]);

  return (
    <ListItem
      secondaryAction={onDelete ? <DeleteIconButton edge="end" onClick={onDelete} /> : undefined}
      disablePadding={Boolean(onClick)}
      sx={{ backgroundColor: selected ? 'action.selected' : undefined }}
    >
      <ListItemButton onClick={onClick}>
        <ListItemText
          primary={theme?.name}
          secondary={specification?.name}
        />
      </ListItemButton>
    </ListItem>
  );
};