import { SxProps, Tabs, TabsProps } from '@mui/material';
import merge from 'lodash/merge';
import React from 'react';

interface ErrorTabsProps extends TabsProps {
  error?: boolean;
}

export const ErrorTabs: React.FC<ErrorTabsProps> = ({ error, children, ...props }) => {
  const errorProps: SxProps = {
    '& .MuiTabs-indicator': {
      backgroundColor: 'error.main'
    },
    '& .MuiTouchRipple-root': {
      color: 'error.main'
    },
    '& .MuiTab-root.Mui-selected': {
      color: 'error.main'
    },
  };

  merge(errorProps, props.sx);

  return (
    <Tabs {...props}
      sx={error ? errorProps : (props.sx)}
    >
      {children}
    </Tabs>
  );
};