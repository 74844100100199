import React, { } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { UnexpectedErrorPage } from '../views';
import applicationRoutes from '../routes';
import customizationsRoutes from '../../customizations/routes';
import individualsRoutes from '../../individuals/routes';
import activitiesRoutes from '../../activities/routes';
import organizationsRoutes from '../../organizations/routes';
import homeRoutes from '../../home/routes';
import teamsRoutes from '../../teams/routes';
import usersRoutes from '../../users/routes';
import reportsRoutes from '../../reports/routes';
import authenticationRoutes from '../../authentication/routes';
import settingsRoutes from '../../settings/routes';
import restorationRoutes from '../../restoration/routes';
import { getTenantIdentifier } from 'utils/tenantUtils';
import { QueryProvider } from './QueryProvider';
import { TenantLayout } from '../layouts/TenantLayout';
import { AuthorizedRoutes } from './AuthorizedRoutes';
import { BuiltInRoles } from 'gql';
import { ClearbackProvider } from './ClearbackProvider';

const tenantIdentifier = getTenantIdentifier();

export const router = createBrowserRouter([
  {
    path: '/',
    element: <QueryProvider />,
    errorElement: <UnexpectedErrorPage />,
    children: [
      ...applicationRoutes,
      ...authenticationRoutes,
      {
        path: tenantIdentifier,
        element: (
          <ClearbackProvider>
            <TenantLayout />
          </ClearbackProvider>
        ),
        errorElement: <UnexpectedErrorPage />,
        children: [
          ...individualsRoutes,
          ...activitiesRoutes,
          ...homeRoutes,
          {
            element: <AuthorizedRoutes roles={[BuiltInRoles.GlobalAdministrator, BuiltInRoles.TenantAdministrator, BuiltInRoles.Analyst]} />,
            children: [
              ...reportsRoutes,
            ]
          },
          {
            element: <AuthorizedRoutes roles={[BuiltInRoles.GlobalAdministrator]} />,
            children: [
              ...organizationsRoutes,
            ]
          },
          {
            element: <AuthorizedRoutes roles={[BuiltInRoles.GlobalAdministrator, BuiltInRoles.TenantAdministrator]} />,
            children: [
              ...teamsRoutes,
              ...usersRoutes,
              ...customizationsRoutes,
              ...settingsRoutes,
              ...restorationRoutes
            ]
          },
        ]
      }
    ]
  },
]);

export const RoutesProvider: React.FC = () => {
  return (
    <RouterProvider router={router} />
  );
};