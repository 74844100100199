
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { FindActivitiesDocument, FindActivitiesQuery, FindActivitiesQueryVariables, FindIndividualsDocument, FindIndividualsQuery, FindIndividualsQueryVariables } from 'gql';
import { fetchData } from './graphql-fetcher';

export const useFindIndividualsCancellableQuery = <
  TData = FindIndividualsQuery,
  TError = unknown
>(variables: FindIndividualsQueryVariables, options?: UseQueryOptions<FindIndividualsQuery, TError, TData>) => useQuery<FindIndividualsQuery, TError, TData>(
  ['findIndividuals', variables],
  async ({ signal }) => fetchData<FindIndividualsQuery, FindIndividualsQueryVariables>(FindIndividualsDocument, variables, undefined, signal)(),
  options
);

export const useFindActivitiesCancellableQuery = <
  TData = FindActivitiesQuery,
  TError = unknown
>(variables: FindActivitiesQueryVariables, options?: UseQueryOptions<FindActivitiesQuery, TError, TData>) => useQuery<FindActivitiesQuery, TError, TData>(['findActivities', variables],
  async ({ signal }) => fetchData<FindActivitiesQuery, FindActivitiesQueryVariables>(FindActivitiesDocument, variables, undefined, signal)(),
  options
);

