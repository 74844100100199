import { ThemeOptions } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import type { } from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    appBar: Partial<TypeBackground>;
  }
  interface PaletteOptions {
    appBar: Partial<TypeBackground>;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteColor extends ColorPartial { }
}

export const commonThemeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          lineHeight: 'initial',
        }
      }
    },
  }
};

const lightTheme: ThemeOptions = {
  ...commonThemeOptions,
  palette: {
    mode: 'light',
    primary: {
      main: '#40A1AB',
      light: '#76D2DD',
      dark: '#00727C',
      '800': '#239099',
      contrastText: '#fff'
    },
    secondary: {
      main: '#F6D50E',
      light: '#FFFF55',
      dark: '#BFA400',
      contrastText: '#000'
    },
    warning: {
      main: '#ED6C02'
    },
    error: {
      main: '#F6380E',
    },
    background: {
      default: grey[50],
    },
    appBar: {
      default: '#fff',
    },
  },
  typography: {
    subtitle2: {
      color: blueGrey[500]
    }
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        },
        columnHeaders: {
          borderBottom: 'none',
          backgroundColor: blueGrey[50],
        },
      }
    },
  },
};

const darkTheme: ThemeOptions = {
  ...commonThemeOptions,
  palette: {
    mode: 'dark',
    primary: {
      main: '#40A1AB',
      light: '#76D2DD',
      dark: '#00727C',
      '800': '#239099',
      contrastText: '#fff'
    },
    secondary: {
      main: '#F6D50E',
      light: '#FFFF55',
      dark: '#BFA400',
      contrastText: '#000'
    },
    background: {
      default: blueGrey[900],
      paper: blueGrey[800],
    },
    appBar: {
      default: blueGrey[800],
    }
  },
  typography: {
    subtitle2: {
      color: blueGrey[500]
    }
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 1
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          borderBottom: 'none',
          backgroundColor: blueGrey[800],
        },
        footerContainer: {
          borderTop: '1px solid #4b5961'
        },
        cell: {
          borderBottom: '1px solid #4b5961'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          color: '#4bc6d8',
          borderColor: '#4bc6d8',
        },
        text: {
          color: '#4bc6d8',
          borderColor: '#4bc6d8',
        }
      }
    }
  },
};


export const allThemes: Record<string, ThemeOptions> = {
  default: lightTheme,
  light: lightTheme,
  dark: darkTheme,
};