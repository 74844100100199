import { CancelButton } from '../../../../components';
import { Close } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { Button, Stack } from '@mui/material';
import { UnknownGroupInput, EntityType, useFindSectionsQuery } from '../../../../gql';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { CustomFieldInputForm } from 'modules/customizations/components/CustomFieldInputForm';
import { SlideDialog } from 'components/Dialogs/SlideDialog';
import { FieldValueInput } from 'utils/customFieldsUtils';

export interface UnknownGroupFormValues {
  id?: number;
  values: FieldValueInput[];
}

interface Props {
  individual?: UnknownGroupFormValues;
  isOpen: boolean;
  onCancel: () => void;
  onSubmitSuccess: (unknownGroup: UnknownGroupFormValues) => void;
}

export const UnknownGroupDialog: React.FC<Props> = ({ individual, isOpen, onCancel, onSubmitSuccess }) => {
  const { formatMessage } = useIntl();

  const form = useForm<UnknownGroupFormValues>();

  const onSubmit = async (data: UnknownGroupFormValues) => {
    onSubmitSuccess(data);
  };

  const { data: sectionsData, isFetched: isSectionsFetched } = useFindSectionsQuery({
    filter: { entityType: { eq: EntityType.UnknownGroup } }
  }, { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false });
  const sections = sectionsData?.sections ?? [];


  useEffect(() => {
    if (isOpen) {
      if (individual) {
        form.reset(individual);
      } else {
        form.reset({});
      }
    }
  }, [individual, isOpen, form]);


  return (
    <SlideDialog
      open={isOpen}
      topbar={{
        leftIcon: <Close />,
        leftIconAction: onCancel,
        title: individual ? formatMessage({ id: 'Edit unknown individual' }) : formatMessage({ id: 'Create unknown individual' }),
      }}
    >
      <FormProvider {...form}>
        <CustomFieldInputForm<UnknownGroupInput>
          sections={sections}
          loading={!isSectionsFetched}
        />
      </FormProvider>

      <Stack justifyContent='right' direction='row' mt={2} spacing={1}>
        <CancelButton onClick={onCancel}>
          {formatMessage({ id: 'Cancel' })}
        </CancelButton>
        <Button color='primary' variant='contained' onClick={form.handleSubmit(onSubmit)}>
          {formatMessage({ id: 'Save' })}
        </Button>
      </Stack>
    </SlideDialog>
  );
};