import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { RealityInput, useRealitiesDataQuery } from '../../../../../../../gql';

interface Props {
  stepIndex: number;
  activeStep: number;
  onStepComplete: () => void;
}

export const ReferralStep: React.FC<Props> = () => {
  const realitiesQuery = useRealitiesDataQuery();

  const { control, getValues } = useFormContext<RealityInput>();
  const { formatMessage } = useIntl();

  return (
    <Controller
      name="referralIds"
      control={control}
      rules={{
        validate: (referralIds) =>
          getValues('accompanimentIds').length === 0
            && getValues('approachIds').length === 0
            && referralIds.length === 0
            ? formatMessage({ id: 'A reality must contain at least one approach, one accompaniment or one referral.' })
            : true
      }}
      render={({ field }) => (
        <Stack spacing={4}>
          <FormGroup>
            {realitiesQuery.data?.referrals.map((referral, index) => (
              <FormControlLabel
                key={referral.id}
                name={`${field.name}[${index}]`}
                control={<Checkbox checked={field.value && field.value.includes(referral.id)} />}
                onChange={(e, checked) => {
                  if (checked) {
                    field.onChange([...field.value, referral.id]);
                  } else {
                    field.onChange(
                      field.value.filter((item) => item !== referral.id)
                    );
                  }
                }}
                label={referral.name} />
            ))}
          </FormGroup>
        </Stack>
      )}
    />
  );
};