import React from 'react';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { Autocomplete, TextField } from '@mui/material';
import { ActivityType } from 'gql';
import { activityTypeMessages } from 'modules/activities/messages';
import { useIntl } from 'react-intl';
import { useActivitySettings } from 'hooks/useActivitySettings';


export const ActivityTypeFilterInput: React.FC<GridFilterInputValueProps> = (props) => {
  const { formatMessage } = useIntl();
  const { item, applyValue, focusElementRef } = props;
  const { activitiesEnabled } = useActivitySettings();

  const types = Object.values(activitiesEnabled).map(type => {
    return {
      label: formatMessage(activityTypeMessages[type]),
      id: type
    };
  });

  const handleFilterChange = (newValue: ActivityType) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Autocomplete
      options={types}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      inputValue={item.value ? formatMessage(activityTypeMessages[item.value as ActivityType]) : ''}
      renderInput={(params) =>
        <TextField
          variant='standard'
          label={formatMessage({ id: 'Value' })}
          inputRef={focusElementRef}
          {...params}
        />
      }
      onChange={(_, data) => data ? handleFilterChange(data?.id) : ''}
    />
  );
};