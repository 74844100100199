import { AppBar, Divider, List, ListItem, ListItemButton, ListItemText, Tab, Tabs } from '@mui/material';
import { EntityType, useFindCustomFieldsQuery } from 'gql';
import deburr from 'lodash/deburr';
import { entityTypePluralMessages } from 'modules/activities/messages';
import { customFieldTypeMessages } from 'modules/customizations/messages';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

const entityTypes = Object.values(EntityType);

interface Props {
  filter: string;
  currentEntityType: EntityType;
  selectedCustomFieldId?: number;
  onSelectedFieldIdChange?: (id: number) => void;
}

export const LinkCustomFieldForm: React.FC<Props> = ({ filter, currentEntityType, selectedCustomFieldId, onSelectedFieldIdChange }) => {
  const { formatMessage } = useIntl();

  const { data: customFieldsData } = useFindCustomFieldsQuery({
    filter: {
      isSystemField: { eq: false },
      entityTypes: { none: { entityType: { eq: currentEntityType } } }
    }
  });
  const customFields = customFieldsData?.customFields ?? [];

  const [currentTab, setCurrentTab] = useState<'all' | EntityType>('all');
  const tabs: ('all' | EntityType)[] = ['all', ...entityTypes.filter(e => e !== currentEntityType)];

  const customFieldFilterFunction = ({ name, entityTypes }: typeof customFields[number]) => {
    if (filter && !deburr(name).toLowerCase().includes(deburr(filter).toLowerCase())) {
      return false;
    }
    if (currentTab === 'all') {
      return true;
    } else {
      return entityTypes.some(e => e.entityType === currentTab);
    }
  };

  const filteredCustomFields = customFields.filter(customFieldFilterFunction);

  return <>
    <AppBar
      elevation={16}
      color='inherit'
      sx={{ position: 'sticky', top: 0, boxShadow: 'none' }}
    >
      <Tabs
        variant='scrollable'
        scrollButtons='auto'
        value={currentTab}
        onChange={(_, newTab) => setCurrentTab(newTab)}
      >
        {tabs.map((entityType) => (
          <Tab
            key={entityType}
            value={entityType}
            label={entityType === 'all'
              ? formatMessage({ id: 'All' })
              : formatMessage(entityTypePluralMessages[entityType])}
          />
        ))}
      </Tabs>
      <Divider />
    </AppBar>
    <List disablePadding>
      {filteredCustomFields.map((customField) => (
        <ListItem key={customField.id} disablePadding>
          <ListItemButton selected={customField.id === selectedCustomFieldId} onClick={() => onSelectedFieldIdChange?.(customField.id)}>
            <ListItemText
              primary={customField.name}
              secondary={formatMessage(customFieldTypeMessages[customField.customFieldType])}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </>;
};