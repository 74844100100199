import { Box, Stack } from '@mui/material';
import React from 'react';

interface IProp {
  icon: React.ReactNode,
  children?: React.ReactNode;
}

export const FormRowWithIcon: React.FC<IProp> = ({ icon, children }) => {
  return (
    <Stack direction='row'>
      <Box sx={{ p: 2, display: { xs: 'none', md: 'block' } }}>
        {icon}
      </Box>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} maxWidth='100%' flexGrow={1}>
        {children}
      </Stack>
    </Stack>
  );
};