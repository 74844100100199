import { Button, Container, Stack } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { ErrorDetails } from '../components/ErrorDetails';
import { ErrorLayout } from '../layouts/ErrorLayout';
import { ReactComponent as UnauthorizedErrorImage } from '../../../assets/images/unauthorized-error.svg';
import { useNavigate } from 'react-router-dom';


export const UnauthorizedErrorPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const navigateToPreviousPage = () => navigate(-1);
  const navigateToLoginPage = () => navigate('/');

  return (
    <ErrorLayout>
      <Container maxWidth="sm">
        <ErrorDetails
          image={<UnauthorizedErrorImage />}
          title={formatMessage({ id: 'Sorry...' })}
          message={formatMessage({ id: 'You are not authorized to access this resource with your current user account. You can either go back to the previous page or login with a different user account.' })}
          errorCode="401"
        />

        <Stack direction="row" spacing={2} sx={{ marginTop: 3 }}>
          <Button variant="outlined" onClick={navigateToPreviousPage}>{formatMessage({ id: 'Previous page' })}</Button>
          <Button variant="contained" onClick={navigateToLoginPage}>{formatMessage({ id: 'Login page' })}</Button>
        </Stack>
      </Container>
    </ErrorLayout>
  );
};