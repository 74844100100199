
import { Button, ButtonProps, styled } from '@mui/material';

export const LoginButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.text.primary,
  borderColor: theme.palette.grey[300],
  borderRadius: '2px',
  borderStyle: 'solid',
  borderWidth: '1px',
  marginBottom: '20px',
  justifyContent: 'flex-start',
  padding: 0,
  '& .MuiButton-startIcon':{
    padding: '15px',
    margin: '0px 10px 0px 0px',
    background: theme.palette.common.white,
    borderColor: theme.palette.grey[300],
    borderRadius: '0px',
    borderStyle: 'solid',
    borderWidth: '0px 1px 0px 0px',
  },
  '&:hover':{
    background: theme.palette.grey[100],
  }
}));
