import React, { useEffect } from 'react';
import { Box, Drawer, DrawerProps, styled, Toolbar } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupsIcon from '@mui/icons-material/Groups';
import ViewListIcon from '@mui/icons-material/ViewList';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PeopleIcon from '@mui/icons-material/People';

import { NavigationMenuLink } from './NavigationMenuLink';
import { NavigationMenuSection } from './NavigationMenuSection';
import { NavigationMenuSubMenu } from './NavigationMenuSubMenu';
import { useResponsive } from 'hooks';
import { isNavigationMenuCollapsedState, isNavigationMenuOpenState } from '../../../atoms';
import { useLocation } from 'react-router-dom';
import { NavigationMenuCollapser } from './NavigationMenuCollapser';
import { getTenantIdentifier } from 'utils/tenantUtils';
import { useIntl } from 'react-intl';
import { useGetSettingsQuery } from 'gql';
import { DeleteOutline, SettingsOutlined, SummarizeOutlined } from '@mui/icons-material';
import { useCurrentUserRoles } from 'hooks/useCurrentUserRoles';

export const expandedMenuWidth = 280;
export const collapsedMenuWidth = 56;

type CollapsibleDrawerProps =
  & DrawerProps
  & { collapsed: boolean, isMobile: boolean; };

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'collapsed' && prop !== 'isMobile'
})<CollapsibleDrawerProps>(({ theme, collapsed, isMobile }) => ({
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: collapsed ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    width: collapsed && !isMobile ? `${collapsedMenuWidth}px` : `${expandedMenuWidth}px`,
    background: theme.palette.primary[800],
    borderRight: 'none',
    boxShadow: theme.shadows[4],
    boxSizing: 'border-box',
  },
  '& .collapsed h6': {
    visibility: 'hidden'
  },
  '& nav': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }
}));

export const NavigationMenu: React.FC = () => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();
  const location = useLocation();

  const { data: settingsData } = useGetSettingsQuery();
  const { isKnownIndividualsEnabled, isDistributionEnabled, isRoutineEnabled, isPresentationEnabled, isInterventionEnabled, isMeetingEnabled } = settingsData?.settings.at(0) ?? {};

  const { isGlobalAdministrator, isAdministrator, isAnalyst } = useCurrentUserRoles();

  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useRecoilState(isNavigationMenuOpenState);
  const isNavigationMenuCollapsed = useRecoilValue(isNavigationMenuCollapsedState);

  const tenantIdentifier = getTenantIdentifier();
  const prefixWithTenant = (path: string) => `/${tenantIdentifier}/${path}`;

  useEffect(() => {
    setIsNavigationMenuOpen(false);
  }, [location, setIsNavigationMenuOpen]);

  return (
    <StyledDrawer
      open={isMobile ? isNavigationMenuOpen : true}
      onClose={() => setIsNavigationMenuOpen(false)}
      collapsed={isNavigationMenuCollapsed}
      isMobile={isMobile}
      anchor="left"
      variant={isMobile ? 'temporary' : 'permanent'}
    >
      <Toolbar sx={{ marginBottom: 1 }} />

      <Box component="nav" className={isNavigationMenuCollapsed && !isMobile ? 'collapsed' : ''}>
        <NavigationMenuLink icon={<DashboardIcon />} title={formatMessage({ id: 'Home' })} to={prefixWithTenant('')} />
        <NavigationMenuLink icon={<QuestionAnswerIcon />} title={formatMessage({ id: 'Activities' })} to={prefixWithTenant('activities')} />
        {isKnownIndividualsEnabled &&
          <NavigationMenuLink icon={<PeopleIcon />} title={formatMessage({ id: 'Known individuals' })} to={prefixWithTenant('individuals')} />
        }

        {(isAnalyst || isAdministrator) && <>
          <NavigationMenuLink icon={<SummarizeOutlined />} title={formatMessage({ id: 'Reports' })} to={prefixWithTenant('reports')} />
        </>}

        {isAdministrator && <>
          <NavigationMenuSection title={formatMessage({ id: 'Administration' })}>
            <NavigationMenuLink icon={<ManageAccountsIcon />} title={formatMessage({ id: 'Users' })} to={prefixWithTenant('users')} />
            <NavigationMenuLink icon={<GroupsIcon />} title={formatMessage({ id: 'Teams' })} to={prefixWithTenant('teams')} />
            {isGlobalAdministrator && <NavigationMenuLink icon={<CorporateFareIcon />} title={formatMessage({ id: 'Organizations' })} to={prefixWithTenant('organizations')} />}

            <NavigationMenuSubMenu icon={<ViewListIcon />} title={formatMessage({ id: 'Customizations' })}>
              {isDistributionEnabled && <NavigationMenuLink title={formatMessage({ id: 'Distributions' })} to={prefixWithTenant('customizations/distributions/fields')} />}
              <NavigationMenuLink title={formatMessage({ id: 'Individuals' })} to={isKnownIndividualsEnabled ? prefixWithTenant('customizations/individuals/known-individuals') : prefixWithTenant('customizations/individuals/unknown-individuals')} />
              {isRoutineEnabled && <NavigationMenuLink title={formatMessage({ id: 'Routines' })} to={prefixWithTenant('customizations/routines')} />}
              {isPresentationEnabled && <NavigationMenuLink title={formatMessage({ id: 'Presentations' })} to={prefixWithTenant('customizations/presentations')} />}
              {isInterventionEnabled && <NavigationMenuLink title={formatMessage({ id: 'Interventions' })} to={prefixWithTenant('customizations/interventions')} />}
              {isMeetingEnabled && <NavigationMenuLink title={formatMessage({ id: 'Meetings' })} to={prefixWithTenant('customizations/meetings')} />}
              <NavigationMenuLink title={formatMessage({ id: 'Realities' })} to={prefixWithTenant('customizations/realities')} />
            </NavigationMenuSubMenu>
            <NavigationMenuLink icon={<SettingsOutlined />} title={formatMessage({ id: 'Settings' })} to={prefixWithTenant('settings')} />
            <NavigationMenuLink icon={<DeleteOutline />} title={formatMessage({ id: 'Recycle Bin' })} to={prefixWithTenant('restoration')} />
          </NavigationMenuSection>
        </>}

        {!isMobile &&
          <NavigationMenuSection sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flexGrow: 1 }}>
            <NavigationMenuCollapser sx={{ flexGrow: 0, paddingTop: 2 }} />
          </NavigationMenuSection>
        }
      </Box>
    </StyledDrawer>
  );
};