import React from 'react';
import { DataTable, DataTableProps, UserAvatar } from 'components';
import { ApplicationUser, Team } from 'gql';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { AvatarGroup, Typography } from '@mui/material';
import { useResponsive } from 'hooks';

type TeamsTableProps =
  & Omit<DataTableProps, 'columns' | 'rows' | 'noSearchResultsMessage' | 'noDataMessage'>
  & { teams: Team[]; };

export const TeamsTable: React.FC<TeamsTableProps> = (props) => {
  const { formatMessage } = useIntl();
  const { teams, ...searchTableProps } = props;
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: formatMessage({ id: 'Name' }),
      flex: 50,
      renderCell: (params) => (
        <Typography color='info.main' fontWeight={500}>{params.row.name}</Typography>
      )
    },
    {
      field: 'members',
      headerName: formatMessage({ id: 'Members' }),
      flex: 80,
      renderCell: (params) => (
        <AvatarGroup
          max={isMobile ? 3 : 8}
          sx={{ '& .MuiAvatar-root': { height: '32px', width: '32px', fontSize: 12 } }}
        >
          {params.row.members?.map((member: ApplicationUser) => {
            return <UserAvatar key={member.id} displayName={member.displayName} size='medium' />;
          })}
        </AvatarGroup>
      ),
    }
  ];

  const rows = teams.map(team => ({
    id: team.id,
    name: team.name,
    members: team.members,
  }));

  const handleOnCellClick = (params: GridCellParams) => {
    if (params.field === 'name') {
      navigate(`${params.id}/details`);
    }
  };

  return (
    <DataTable
      {...searchTableProps}
      columns={columns}
      rows={rows}
      hideFooter
      disableColumnSelector
      onCellClick={handleOnCellClick}
      noDataMessage={formatMessage({ id: 'There are no teams yet' })}
      noSearchResultsMessage={formatMessage({ id: 'No teams found' })}
      rowHeight={60}
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        }
      }}
      sx={{
        '& .MuiDataGrid-cell:first-of-type': {
          cursor: 'pointer'
        }
      }}
    />
  );
};