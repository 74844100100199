import React, { ReactNode } from 'react';
import { Divider, IconButton, Stack, TextField, TextFieldProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Clear } from '@mui/icons-material';

type SearchTextFieldProps = TextFieldProps & {
  onClear?: () => void;
  actionButtonAdornment?: ReactNode;
};

export const SearchTextField: React.FC<SearchTextFieldProps> = ({ onClear, actionButtonAdornment, ...props }) => {
  const endAdornment = React.useMemo(() => {
    const endAdornment: ReactNode[] = [];
    if (onClear && props.value) {
      endAdornment.push(
        <IconButton onClick={onClear}>
          <Clear />
        </IconButton>
      );
    }
    if (actionButtonAdornment) {
      endAdornment.push((
        <Divider orientation='vertical' />
      ));
      endAdornment.push(actionButtonAdornment);
    }
    return endAdornment;
  }, [actionButtonAdornment, onClear, props.value]);

  return (
    <TextField
      fullWidth
      InputProps={{
        startAdornment: (
          <SearchIcon fontSize='small' sx={{ marginRight: 2 }} />
        ),
        endAdornment: (
          <Stack direction='row' spacing={1}>
            {endAdornment.map((adornment, index) => <div key={index}>{adornment}</div>)}
          </Stack>
        ),
        ...props.InputProps
      }}
      {...props}
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        },
        ...props.sx
      }}
    />
  );
};