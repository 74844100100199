import { useResponsive } from 'hooks';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

type Props = Omit<TextFieldProps, 'onChange'> & {
  onChange: (value: string) => void;
  maxDate?: Dayjs;
  minDate?: Dayjs;
};

export const ResponsiveDatePicker: React.FC<Props> = ({ maxDate, minDate, ...props }) => {
  const { isMobile } = useResponsive();
  const { value, onChange } = props;

  if (!isMobile) {
    return (
      <DesktopDatePicker<Dayjs>
        {...props}
        minDate={minDate}
        maxDate={maxDate}
        defaultValue={undefined}
        onError={undefined}
        slotProps={{
          textField: {
            fullWidth: props.fullWidth,
            error: props.error,
            required: props.required,
            helperText: props.helperText,
          },
          openPickerButton: {
            color: props.error ? 'error' : undefined,
          }
        }}
        value={value && dayjs(value as string).isValid() ? dayjs(value as string).utc() : null}
        onChange={day => onChange(day?.isValid() ? day.utc().format() : '')}
      />
    );
  } else {
    return (
      <TextField
        type='date'
        inputProps={{
          min: minDate?.utc().format('YYYY-MM-DD'),
          max: maxDate?.utc().format('YYYY-MM-DD'),
          ...props.inputProps
        }}
        InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
        {...props}
        onChange={e => onChange(e.target.value)}
      />
    );
  }
};