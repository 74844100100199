import React, { ReactNode, useEffect } from 'react';
import { FormRowWithIcon, LookupInput, PaperForm } from 'components';
import { Checkbox, ListItem, ListItemButton, ListItemText, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { BuiltInRoles, InviteUserInput, useFindTeamsQuery } from 'gql';
import { useIntl } from 'react-intl';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Email, Groups, Person, Shield } from '@mui/icons-material';
import { isEmailValid } from 'utils/validationUtils';
import { userRoleDescriptions, userRoleMessages } from 'modules/users/messages';

interface Props {
  allowedRoles: BuiltInRoles[];
  disabled?: boolean;
  isInvitingAdmin?: boolean;
}

export const UserInviteForm: React.FC<Props> = ({ allowedRoles, disabled, isInvitingAdmin }) => {
  const { formatMessage } = useIntl();
  const { control, setValue } = useFormContext<InviteUserInput>();

  const { data: teamsData } = useFindTeamsQuery();
  const teams = teamsData?.teams ?? [];

  const { data: defaultTeamData } = useFindTeamsQuery({ filter: { isDefault: { eq: true } } });
  const defaultTeam = defaultTeamData?.teams[0];

  useEffect(() => {
    setValue('teamIds', defaultTeam ? [defaultTeam.id] : []);
  }, [defaultTeam, setValue]);

  const emailField = (
    <Controller
      control={control}
      name='email'
      rules={{
        validate: (value) => isEmailValid(value) ? undefined : formatMessage({ id: 'Please enter a valid email address.' }),
        required: formatMessage({ id: 'The email is required' })
      }}
      render={({ field, fieldState }) => (
        <TextField
          disabled={disabled}
          {...field}
          fullWidth
          required
          label={formatMessage({ id: 'Email' })}
          error={fieldState.error?.message != null}
          helperText={fieldState.error?.message}
        />
      )}
    />
  );

  const nameField = <>
    <Controller
      control={control}
      name='firstName'
      rules={{ required: formatMessage({ id: 'The fist name is required' }) }}
      render={({ field, fieldState }) => (
        <TextField
          disabled={disabled}
          {...field}
          fullWidth
          required
          label={formatMessage({ id: 'First name' })}
          error={fieldState.error?.message != null}
          helperText={fieldState.error?.message}
        />
      )}
    />
    <Controller
      control={control}
      name='lastName'
      rules={{ required: formatMessage({ id: 'The last name is required' }) }}
      render={({ field, fieldState }) => (
        <TextField
          disabled={disabled}
          {...field}
          fullWidth
          required
          label={formatMessage({ id: 'Last name' })}
          error={fieldState.error?.message != null}
          helperText={fieldState.error?.message}
        />
      )}
    />
  </>;

  const teamField = (
    <Controller
      control={control}
      name='teamIds'
      rules={{ required: formatMessage({ id: 'The team is required' }) }}
      render={({ field, fieldState: { error } }) => (
        <LookupInput
          multiple
          disabled={disabled}
          disableDrawerChips
          required
          label={formatMessage({ id: 'Teams' })}
          error={Boolean(error)}
          helperText={error?.message}
          selectedValues={teams.filter(t => field.value.includes(t.id))}
          availableValues={teams}
          onChange={teams => field.onChange(teams.map(t => t.id))}
          getKey={t => t.id}
          getLabel={t => t.name}
          searchFilter={(t, search) => t.name.toLowerCase().includes(search.toLowerCase())}
          renderListItem={(team, selected, onClick) => (
            <ListItem
              disablePadding
              sx={{ backgroundColor: selected ? 'action.hover' : undefined }}
              secondaryAction={(
                <Checkbox checked={selected} onClick={onClick} />
              )}
            >
              <ListItemButton onClick={onClick}>
                <ListItemText primary={team.name} />
              </ListItemButton>
            </ListItem>
          )}
        />
      )}
    />
  );

  const roleField = (
    <Controller
      control={control}
      name='role'
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          disabled={disabled || allowedRoles.length === 1}
          fullWidth
          select
          label={formatMessage({ id: 'Role' })}
          error={fieldState.error?.message != null}
          helperText={fieldState.error?.message}
          sx={{ textOverflow: 'ellipsis', width: { xs: '250px', sm: '100%' } }}
        >
          {allowedRoles.map((role) => (
            <MenuItem value={role} key={role}>{formatMessage(userRoleMessages[role])}</MenuItem>
          ))}
        </TextField>
      )}
    />
  );

  const role = useWatch({ control, name: 'role' });

  return (
    <PaperForm sx={{ px: { xs: 2, md: 3 } }}>
      <Stack spacing={3}>
        <FormRowWithIcon icon={<Email />}>
          {emailField}
        </FormRowWithIcon>
        <FormRowWithIcon icon={<Person />}>
          {nameField}
        </FormRowWithIcon>
        {!isInvitingAdmin &&
          <FormRowWithIcon icon={<Groups />}>
            {teamField}
          </FormRowWithIcon>
        }
        <FormRowWithIcon icon={<Shield />}>
          <Stack width='100%'>
            {roleField}

            {(role && !isInvitingAdmin) && (
              <Typography mt={2} variant='body2'>
                {formatMessage({ id: 'A user with this role can:' })}

                <ul>
                  {formatMessage<ReactNode>(userRoleDescriptions[role], {
                    li: msg => <li>{msg}</li>
                  })}
                </ul>
              </Typography>
            )}
          </Stack>
        </FormRowWithIcon>
      </Stack>
    </PaperForm >
  );
};