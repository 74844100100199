import { Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  label?: string;
  children?: ReactNode;
}

export const FilterInputLabel: React.FC<Props> = ({ label, children }) => {
  return (
    <Stack spacing={0.5}>
      {label && (
        <Typography variant='subtitle1'>{label}</Typography>
      )}
      {children}
    </Stack>
  );
};