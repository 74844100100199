import React, { useEffect, useState } from 'react';
import { IconButton, Menu, Stack } from '@mui/material';
import { EntityType, useAddSectionMutation, useDeleteSectionMutation, useEditSectionMutation, useFindSectionsQuery } from 'gql';
import { useIntl } from 'react-intl';
import { FormDrawer } from 'components';
import { CustomFieldSectionForm, FormValues } from '../CustomFieldSectionForm/CustomFieldSectionForm';
import { CustomizationFormHeader } from './CustomizationFormHeader';
import { useQueryClient } from '@tanstack/react-query';
import { ConfirmButton } from 'components/Buttons';
import { MoreVert } from '@mui/icons-material';
import { FormProvider, useForm } from 'react-hook-form';

interface OwnProps {
  sectionId?: number;
  entityType: EntityType;
  isOpen: boolean;
  onClose: () => void;
}

export const CustomizationSectionDrawer: React.FC<OwnProps> = (props) => {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const formMethods = useForm<FormValues>({
    defaultValues: {
      name: ''
    }
  });

  const { data: customSectionsData } = useFindSectionsQuery({
    filter: { id: { eq: props.sectionId } }
  }, { enabled: Boolean(props.sectionId) });
  const section = props.sectionId ? customSectionsData?.sections[0] : undefined;

  const deleteSectionMutation = useDeleteSectionMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['findSections']);
      props.onClose();
    }
  });

  const editSection = useEditSectionMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['findSections']);
      props.onClose();
    }
  });

  const addSection = useAddSectionMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['findSections']);
      props.onClose();
    }
  });

  const handleSubmit = (values: FormValues) => {
    if (section) {
      editSection.mutate({ input: { id: section?.id, name: values.name } });
    } else {
      addSection.mutate({ input: { name: values.name, entityType: props.entityType } });
      formMethods.reset();
    }
  };

  const handleDelete = () => {
    if (section) {
      deleteSectionMutation.mutate({ id: section.id });
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    if (section) {
      formMethods.reset({
        name: section.name,
      });
    } else {
      formMethods.reset({
        name: '',
      });
    }
  }, [formMethods, section, props.sectionId]);

  const disabled = addSection.isLoading || deleteSectionMutation.isLoading || editSection.isLoading;

  return (
    <FormDrawer
      disablePadding
      isFormDirty={formMethods.formState.isDirty}
      header={(
        <Stack p={2} width='100%'>
          <CustomizationFormHeader editMode={Boolean(section)} customizationType='section' />
        </Stack>
      )}
      open={props.isOpen}
      onClose={props.onClose}
      onSave={formMethods.handleSubmit(handleSubmit)}
      actionButtons={Boolean(section) && (
        <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
          <MoreVert />
        </IconButton>
      )}
      showFooter
      disabledSaveButton={disabled}
      saveButtonMessage={section ? formatMessage({ id: 'Save' }) : formatMessage({ id: 'Add' })}
    >
      <FormProvider {...formMethods}>
        <CustomFieldSectionForm
          disabled={disabled}
        />
      </FormProvider>

      <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <ConfirmButton
          buttonVariant='menuItem'
          confirmTitle={formatMessage({ id: 'Are you sure you want to delete this entry?' })}
          confirmText={formatMessage({ id: 'This action cannot be undone.' })}
          onConfirm={handleDelete}
          onCancel={() => setAnchorEl(null)}
        />
      </Menu>
    </FormDrawer>
  );
};