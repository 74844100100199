import { ListItemText, ListItemTextProps, SxProps, Theme, Typography } from '@mui/material';
import { DistributionItemDto } from 'gql';
import React from 'react';

interface OwnProps {
  item: Pick<DistributionItemDto, 'name' | 'description' | 'unit'>;
  onViewDetails?: () => void;
}

export type DistributionListItemTextProps =
  & ListItemTextProps
  & OwnProps;

export const DistributionListItemText: React.FC<DistributionListItemTextProps> = (props) => {
  const { item, onViewDetails: onMoreDetailsClick, ...listItemTextProps } = props;
  const hasMoreDetails = item.description && onMoreDetailsClick != null;
  const moreDetailsStyle: SxProps<Theme> = {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    cursor: 'help'
  };

  return (
    <ListItemText
      primary={(
        <Typography
          variant='body2'
          sx={hasMoreDetails ? moreDetailsStyle : undefined }
          onClick={hasMoreDetails ? onMoreDetailsClick : undefined}
        >
          {item.name}
        </Typography>
      )}
      secondary={item.unit}
      {...listItemTextProps}
    />
  );
};