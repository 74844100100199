import { List, ListProps } from '@mui/material';
import { EmptyState } from 'components';
import { defineMessage, useIntl } from 'react-intl';
import { DraggableFieldListItem } from './DraggableFieldListItem';
import React from 'react';
import { CustomFieldDto, CustomFieldEntityType } from 'gql';

type CustomField = Pick<CustomFieldDto, 'id' | 'name' | 'description' | 'isRequired' | 'isSystemField' | 'customFieldType' | 'status'>;

type FieldEntityType = Pick<CustomFieldEntityType, 'id'> & {
  customFieldDto: CustomField;
};

interface Props extends ListProps {
  fieldEntityTypes: FieldEntityType[];
  disabled?: boolean;
  onFieldClick?: (fieldId: number) => void;
}

export const FieldList = React.forwardRef<HTMLUListElement, Props>(({ fieldEntityTypes, disabled, onFieldClick, children, ...props }, ref) => {
  const { formatMessage } = useIntl();
  return (
    <List {...props} ref={ref} disablePadding>
      {(fieldEntityTypes.length === 0 && !disabled) &&
        <EmptyState
          title={formatMessage({ id: 'No field for this section' })}
          subtitle={formatMessage({ id: 'Before customizing this section, you must first add a field' })}
        />
      }
      {fieldEntityTypes.map((fieldEntityType, index) => (
        <DraggableFieldListItem
          key={`field_${fieldEntityType.id}`}
          draggableId={`field_${fieldEntityType.id}`}
          field={fieldEntityType.customFieldDto}
          index={index}
          divider={index !== fieldEntityTypes.length - 1}
          onClick={() => onFieldClick?.(fieldEntityType.customFieldDto.id)}
          isDragDisabled={disabled}
        />
      ))}

      {children}
    </List>
  );
});

FieldList.displayName = 'FieldList';