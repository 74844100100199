import React, { ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const ClearbackProvider: React.FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('clearback')) {
      searchParams.delete('clearback');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return <>{children}</>;
};