import { Button, Container, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { ErrorDetails } from '../components/ErrorDetails';
import { ErrorLayout } from '../layouts/ErrorLayout';
import { ReactComponent as UnexpectedErrorImage } from '../../../assets/images/unexpected-error.svg';
import { useIntl } from 'react-intl';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import dayjs from 'dayjs';

interface RouterError extends Error {
  statusText: string;
  message: string;
}

export const UnexpectedErrorPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const exception: RouterError | undefined = useRouteError() as RouterError;
  const appInsights = useAppInsightsContext();

  const correlationId = crypto.randomUUID();
  const timestamp = dayjs().format('YYYY-MM-DD HH:mm:ss');

  useEffect(() => {
    if (exception != null) {
      appInsights.trackException({
        exception,
        severityLevel: SeverityLevel.Error,
      }, { correlationId, timestamp });
    }
  }, [appInsights, exception, correlationId, timestamp]);

  const navigateToPreviousPage = () => navigate(-1);
  const navigateToHomePage = () => navigate('/');

  return (
    <ErrorLayout>
      <Container maxWidth="sm">
        <ErrorDetails
          image={<UnexpectedErrorImage />}
          title={formatMessage({ id: 'Sorry...' })}
          message={formatMessage({ id: 'An unexpected error has occurred while processing your request. You can either go back to the previous page and try again in a few minutes, or navigate to the home page.' })}
          errorCode={exception?.statusText || exception?.message || 'Unknown'}
          correlationId={exception != null ? correlationId : undefined}
          timestamp={timestamp}
        />

        <Stack direction="row" spacing={2} sx={{ marginTop: 3 }}>
          <Button variant="outlined" onClick={navigateToPreviousPage}>{formatMessage({ id: 'Previous page' })}</Button>
          <Button variant="contained" onClick={navigateToHomePage}>{formatMessage({ id: 'Home page' })}</Button>
        </Stack>
      </Container>
    </ErrorLayout>
  );
};