import { Edit, Restore } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { DisableableTooltip } from 'components';
import { ConfirmButton } from 'components/Buttons';
import dayjs from 'dayjs';
import { Activity as ActivityEntity, useDeleteActivityMutation, useGetCurrentUserQuery, useRestoreActivityMutation } from 'gql';
import { useCurrentUserRoles } from 'hooks/useCurrentUserRoles';
import { useNotification } from 'hooks/useNotification';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getTenantIdentifier } from 'utils/tenantUtils';

type Activity = Pick<ActivityEntity, 'id' | 'createdAt' | 'createdById' | 'isDeleted'>;

interface Props {
  activity: Activity;
  onEdit?: () => void;
  hideDelete?: boolean;
}

const isOlderThanAMonth = (date?: string) => date ? dayjs(date).isBefore(dayjs().subtract(1, 'month')) : true;

export const ActivityActions: React.FC<Props> = ({ activity, onEdit, hideDelete }) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { notifySuccess } = useNotification();

  const { data: currentUserData } = useGetCurrentUserQuery();
  const currentUser = currentUserData?.me;

  const { isAdministrator } = useCurrentUserRoles();
  const canEdit = isAdministrator || (currentUser?.id === activity.createdById && !isOlderThanAMonth(activity.createdAt));

  const canDelete = isAdministrator || currentUser?.id === activity.createdById;

  const onClickHandler = (callback?: () => void) => () => {
    callback?.();
  };

  const activityDeleteMutation = useDeleteActivityMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['findActivities']);
      queryClient.invalidateQueries(['getActivityById']);
      queryClient.invalidateQueries(['findDeletedActivities']);
      notifySuccess(formatMessage({ id: 'Activity deleted successfully' }));
      navigate(`/${getTenantIdentifier()}/activities`);
    }
  });

  const activityRestoreMutation = useRestoreActivityMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['findActivities']);
      queryClient.invalidateQueries(['getActivityById']);
      queryClient.invalidateQueries(['findDeletedActivities']);
      notifySuccess(formatMessage({ id: 'Activity restored successfully' }));
      navigate(`/${getTenantIdentifier()}/activities`);
    }
  });

  const onRestoreHandler = () => {
    activityRestoreMutation.mutate({ input: { id: activity.id } });
  };

  const onDeleteHandler = () => {
    activityDeleteMutation.mutate({ input: { id: activity.id } });
  };

  return <>
    {onEdit && (
      <DisableableTooltip
        title={currentUser?.id !== activity.createdById
          ? formatMessage({ id: 'Only the creator of an activity can edit it.' })
          : formatMessage({ id: 'Activities older than a month cannot be edited' })}
        disabled={canEdit}
        enterTouchDelay={0}
        disableInteractive
      >
        <IconButton onClick={onClickHandler(onEdit)} disabled={!canEdit}>
          <Edit />
        </IconButton>
      </DisableableTooltip>
    )}

    {(!hideDelete && !activity.isDeleted) && (
      <DisableableTooltip
        title={formatMessage({ id: 'Only the creator of an activity can delete it.' })}
        disabled={canDelete}
        enterTouchDelay={0}
        disableInteractive
      >
        <ConfirmButton
          buttonVariant='iconButton'
          confirmText={formatMessage({ id: 'Deleted activities will not be included in generated reports. Are you sure?' })}
          confirmTitle={formatMessage({ id: 'Delete activity' })}
          onConfirm={onClickHandler(onDeleteHandler)}
          loading={activityDeleteMutation.isLoading}
          disabled={!canDelete}
        />
      </DisableableTooltip>
    )}

    {(activity.isDeleted && isAdministrator) && (
      <ConfirmButton
        buttonVariant='iconButton'
        confirmText={formatMessage({ id: 'This will include this activity in future statistics, and allow teams to view it again.' })}
        confirmTitle={formatMessage({ id: 'Restore activity?' })}
        onConfirm={onClickHandler(onRestoreHandler)}
        loading={activityRestoreMutation.isLoading}
        outerButtonIcon={<Restore />}
        outerButtonText={formatMessage({ id: 'Restore' })}
        confirmButtonText={formatMessage({ id: 'Restore' })}
        confirmColor='info'
        tooltip={formatMessage({ id: 'Restore activity' })}
      />
    )}
  </>;
};