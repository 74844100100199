import React, { useMemo } from 'react';
import { ActivityType, TeamStat } from 'gql';
import _ from 'lodash';
import { TeamHeader, TeamStatHeader } from './TeamStatHeader';
import dayjs from 'dayjs';
import { MemberStatDataGrid } from './MemberStatDataGrid';
import { Stack } from '@mui/material';

interface OwnProps {
  teamsStat: TeamStat[]
}

export const TeamStatTable: React.FC<OwnProps> = ({ teamsStat }) => {
  const teamHeader = useMemo(() => {
    const teamName = _.first(teamsStat)?.teamName ?? '';
    const lastActivityDate = _.maxBy(teamsStat, m => m.lastActivityDate)?.lastActivityDate;

    const teamHeader: TeamHeader = {
      TeamName: teamName,
      lastActivityDate: lastActivityDate && dayjs(lastActivityDate).format('ddd, DD MMMM YYYY'),
      activitiesCount: {
        [ActivityType.Distribution]: _.sumBy(teamsStat, t => t.distributions),
        [ActivityType.Intervention]: _.sumBy(teamsStat, t => t.interventions),
        [ActivityType.Meeting]: _.sumBy(teamsStat, t => t.meetings),
        [ActivityType.Presentation]: _.sumBy(teamsStat, t => t.presentations),
        [ActivityType.Routine]: _.sumBy(teamsStat, t => t.routines),
      }
    };

    return teamHeader;
  }, [teamsStat]);

  return (
    <Stack>
      <TeamStatHeader
        teamHeader={teamHeader}
      />
      <MemberStatDataGrid
        teamStat={teamsStat}
      />
    </Stack>
  );
};