import React from 'react';
import { Stack } from '@mui/system';
import { Chip, Skeleton, Typography, useTheme } from '@mui/material';
import _ from 'lodash';
import { DeltaChip } from './DeltaChip';

interface OwnProps {
  title: string
  totalCount: number
  previousTotalCount?: number
  isLoading: boolean
  children: React.ReactNode
}


export const CountStatsSection: React.FC<OwnProps> = (props) => {
  const theme = useTheme();

  const deltaComponent = () => (
    <>
      {props.isLoading
        ?
        <Skeleton variant='circular' width={theme.spacing(3)} height={theme.spacing(3)} />
        :
        <DeltaChip currentValue={props.totalCount} previousValue={props.previousTotalCount ?? 0} />
      }
    </>
  );

  return (
    <Stack spacing={2}>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Typography variant='h5'>{props.title}</Typography>
        {props.isLoading
          ?
          <Skeleton variant='circular' width={theme.spacing(3)} height={theme.spacing(3)} />
          :
          <Chip label={props.totalCount} color='primary' size='small' />
        }
        {props.previousTotalCount != undefined && deltaComponent()}
      </Stack>
      <Stack direction='row' gap={2} flexWrap='wrap'>
        {props.children}
      </Stack>
    </Stack>
  );
};