import { EntityType } from 'gql';
import React from 'react';
import { RouteObject } from 'react-router-dom';
import { EntityFieldsPage } from './views';
import { RealitiesPage } from '../realities/views/RealitiesPage';
import { DistributionFieldsPage } from 'modules/distributions/views/DistributionFieldsPage';
import { DistributionCategoriesPage } from 'modules/distributions/views/DistributionCategoriesPage';
import { DistributionItemsPage } from 'modules/distributions/views/DistributionItemsPage';
import { DistributionConfigurationPageLayout } from 'modules/distributions/layouts/DistributionConfigurationPageLayout';
import { IndividualsConfigurationPageLayout } from 'modules/individuals/layouts/IndividualsConfigurationPageLayout';
import { KnownIndividualsConfigurationPage } from 'modules/individuals/views/KnownIndividualsConfigurationPage';
import { UnknownIndividualsConfigurationPage } from 'modules/individuals/views/UnknownIndividualsConfigurationPage';

export const routes: RouteObject[] = [
  {
    path: 'customizations', children: [
      {
        path: 'individuals', element: <IndividualsConfigurationPageLayout />, children: [
          { path: 'known-individuals', element: <KnownIndividualsConfigurationPage /> },
          { path: 'unknown-individuals', element: <UnknownIndividualsConfigurationPage /> },
        ]
      },
      { path: 'routines', element: <EntityFieldsPage entityType={EntityType.Routine} /> },
      { path: 'presentations', element: <EntityFieldsPage entityType={EntityType.Presentation} /> },
      { path: 'interventions', element: <EntityFieldsPage entityType={EntityType.Intervention} /> },
      { path: 'meetings', element: <EntityFieldsPage entityType={EntityType.Meeting} /> },
      { path: 'realities', element: <RealitiesPage /> },
      {
        path: 'distributions', element: <DistributionConfigurationPageLayout />, children: [
          { path: 'fields', element: <DistributionFieldsPage /> },
          { path: 'categories', element: <DistributionCategoriesPage /> },
          { path: 'items', element: <DistributionItemsPage /> },
        ]
      }
    ]
  },
];

export default routes;