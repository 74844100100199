import { MoreVert, Save } from '@mui/icons-material';
import { FormControlLabel, FormGroup, IconButton, ListItem, ListItemButton, ListItemText, Menu, Stack, Switch, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ConfirmCloseDialog, FormDrawer, ResponsiveDrawer, ResponsiveSelect } from 'components';
import { ConfirmButton } from 'components/Buttons';
import { DistributionItemDto, useAddDistrubtionItemMutation, useDeleteDistributionItemMutation, useEditDistrubtionItemMutation, useFindDistributionCategoriesQuery } from 'gql';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

type Item = Pick<DistributionItemDto, 'id' | 'name' | 'description' | 'unit' | 'categoryIds' | 'isDisabled'>;

interface Props {
  item?: Item;
  open: boolean;
  onClose: () => void;
}

export interface ItemFormValues {
  name: string;
  description: string;
  unit: string;
  isDisabled: boolean;
  categoryIds: number[];
}

export const DistributionItemDrawer: React.FC<Props> = ({ item, open, onClose }) => {
  const { formatMessage } = useIntl();

  const isEditing = Boolean(item);

  const { data: categoriesData } = useFindDistributionCategoriesQuery();
  const categories = categoriesData?.categories ?? [];

  const form = useForm<ItemFormValues>({
    defaultValues: {
      name: ''
    }
  });
  const { reset, control, handleSubmit, formState: { isDirty } } = form;

  useEffect(() => {
    reset({
      name: item?.name ?? '',
      description: item?.description ?? '',
      unit: item?.unit ?? formatMessage({ id: 'Units' }),
      isDisabled: item?.isDisabled ?? false,
      categoryIds: item?.categoryIds ?? [],
    });
  }, [item?.categoryIds, item?.name, reset, open, item?.unit, item?.isDisabled, item?.description, formatMessage]);


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const queryClient = useQueryClient();

  const onSuccess = () => {
    queryClient.invalidateQueries(['findDistributionItems']);
    onClose();
  };

  const addMutation = useAddDistrubtionItemMutation({ onSuccess });
  const editMutation = useEditDistrubtionItemMutation({ onSuccess });

  const onSubmit = (values: ItemFormValues) => {
    if (item) {
      editMutation.mutate({
        input: {
          id: item.id,
          name: values.name,
          description: values.description,
          unit: values.unit,
          isDisabled: values.isDisabled,
          categoryIds: values.categoryIds
        }
      });
    } else {
      addMutation.mutate({
        input: {
          name: values.name,
          description: values.description,
          unit: values.unit,
          categoryIds: values.categoryIds
        }
      });
    }
  };

  const deleteMutation = useDeleteDistributionItemMutation({ onSuccess });

  const onDelete = () => {
    setAnchorEl(null);
    if (!item) return;
    deleteMutation.mutate({ input: { id: item.id } });
  };

  const disabled = addMutation.isLoading || editMutation.isLoading || deleteMutation.isLoading;

  return <>
    <FormDrawer
      showFooter
      onSave={handleSubmit(onSubmit)}
      isFormDirty={isDirty}
      onClose={onClose}
      open={open}
      header={item !== undefined ? formatMessage({ id: 'Edit item' }) : formatMessage({ id: 'New item' })}
      actionButtons={<>
        {isEditing && (
          <IconButton onClick={handleMenuClick} disabled={disabled}>
            <MoreVert />
          </IconButton>
        )}
      </>}
    >
      <Stack spacing={3} pt={3}>
        <Controller
          control={control}
          name='name'
          rules={{ required: formatMessage({ id: 'This field is required' }) }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              autoFocus
              variant='outlined'
              label={formatMessage({ id: 'Name' })}
              {...field}
              required
              disabled={disabled}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='description'
          render={({ field, fieldState: { error } }) => (
            <TextField
              variant='outlined'
              label={formatMessage({ id: 'Description' })}
              {...field}
              disabled={disabled}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='unit'
          rules={{ required: formatMessage({ id: 'This field is required' }) }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              variant='outlined'
              label={formatMessage({ id: 'Unit' })}
              required
              {...field}
              disabled={disabled}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='categoryIds'
          render={({ field, fieldState: { error } }) => (
            <ResponsiveSelect
              multiple
              selectedValues={categories.filter(c => field.value.includes(c.id))}
              availableValues={categories}
              onChange={(values) => field.onChange(values.map(v => v.id))}
              getKey={c => c.id}
              getLabel={c => c.name}
              searchFilter={(c, search) => c.name.toLowerCase().includes(search.toLowerCase())}
              renderListItem={(category, selected, onClick) => (
                <ListItem disablePadding>
                  <ListItemButton onClick={onClick} selected={selected}>
                    <ListItemText primary={category.name} />
                  </ListItemButton>
                </ListItem>
              )}
              label={formatMessage({ id: 'Categories' })}
              error={Boolean(error)}
              helperText={error?.message}
              disabled={disabled}
            />
          )}
        />

        <Controller
          control={control}
          name='isDisabled'
          render={({ field }) => (
            <FormGroup>
              <FormControlLabel
                control={(
                  <Switch
                    disabled={disabled || !isEditing}
                    checked={!field.value}
                    onChange={(_, checked) => field.onChange(!checked)}
                  />
                )}
                label={formatMessage({ id: 'Active' })}
                sx={{ pl: 2 }}
              />
            </FormGroup>
          )}
        />

        <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
          <ConfirmButton
            buttonVariant='menuItem'
            onConfirm={onDelete}
            confirmTitle={formatMessage({ id: 'Delete item?' })}
            confirmText={formatMessage({ id: 'This action cannot be undone. Are you sure?' })}
          />
        </Menu>
      </Stack>
    </FormDrawer>
  </>;
};