import { Chip, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, DataTableProps, StatusChip } from 'components';
import { DistributionItemDto, DistributionValue, useFindDistributionCategoriesQuery } from 'gql';
import { useResponsive } from 'hooks';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

type Item = Pick<DistributionItemDto, 'id' | 'name' | 'unit' | 'categoryIds' | 'isDisabled'>;

interface Props extends Omit<DataTableProps, 'columns' | 'rows'> {
  items: Item[];
  distributions?: Pick<DistributionValue, 'distributedAmount' | 'itemId'>[];
  loading?: boolean;
  onItemClick?: (item: Item) => void;
}


export const ItemsTable: React.FC<Props> = ({ loading, items, distributions, onItemClick, ...dataTableProps }) => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();

  const { data: categoriesData, isFetching } = useFindDistributionCategoriesQuery();
  const categories = categoriesData?.categories ?? [];

  const findCategoryById = (id: number) =>
    categories.find(category => category.id === id);

  const [searchValues, setSearchValues] = useState('');

  const mobileCols: (GridColDef<Item> | null)[] = [
    {
      field: 'name',
      headerName: formatMessage({ id: 'Name' }),
      valueGetter: ({ row }) => row.name,
      minWidth: 0,
      flex: 1,
      renderCell: ({ row }) => (
        <Stack>
          <Typography>{row.name}</Typography>
          <Typography variant='subtitle2' color='text.secondary'>{row.unit}</Typography>
        </Stack>
      )
    },
    distributions ? {
      field: 'amount',
      align: 'right',
      headerAlign: 'right',
      minWidth: 0,
      headerName: formatMessage({ id: 'Amount' }),
      valueGetter: ({ row }) => distributions.find(d => d.itemId === row.id)?.distributedAmount ?? 0
    } : null,
    !distributions ? {
      field: 'isActive',
      headerName: '',
      align: 'right',
      valueGetter: ({ row }) => row.isDisabled,
      renderCell: ({ row }) => (
        <StatusChip size='small' status={row.isDisabled ? 'inactive' : 'active'} />
      )
    } : null
  ];

  const desktopCols: (GridColDef<Item> | null)[] = [
    {
      field: 'name',
      flex: 10,
      headerName: formatMessage({ id: 'Name' }),
      valueGetter: ({ row }) => row.name
    },
    {
      field: 'unit',
      flex: 5,
      headerName: formatMessage({ id: 'Unit' }),
      valueGetter: ({ row }) => row.unit
    },
    distributions ? {
      field: 'distributedAmount',
      flex: 5,
      headerName: formatMessage({ id: 'Amount' }),
      valueGetter: ({ row }) => distributions?.find(d => d.itemId === row.id)?.distributedAmount ?? 0
    } : null,
    {
      field: 'categories',
      flex: 30,
      sortable: false,
      headerName: formatMessage({ id: 'Categories' }),
      renderCell: ({ row }) => row.categoryIds.map(id => (
        <Chip key={id} label={findCategoryById(id)?.name} sx={{ mr: 1 }} />
      ))
    },
    {
      field: 'isActive',
      headerName: formatMessage({ id: 'Status' }),
      valueGetter: ({ row }) => row.isDisabled,
      renderCell: ({ row }) => (
        <StatusChip size='small' status={row.isDisabled ? 'inactive' : 'active'} />
      )
    }
  ];

  const cols = (isMobile ? mobileCols : desktopCols).filter(Boolean) as GridColDef[];

  const filteredItems = items.filter(item => item.name.toLowerCase().includes(searchValues.toLowerCase()));

  return (
    <DataTable
      rowHeight={64}
      height={500}
      onRowClick={(params) => onItemClick?.(params.row)}
      onSearch={setSearchValues}
      noSearchResultsMessage={formatMessage({ id: 'No items found' })}
      noDataMessage={formatMessage({ id: 'There are no items' })}
      disableColumnSelector
      columns={cols}
      hideFooter
      loading={loading || isFetching}
      rows={filteredItems}
      sx={{
        '& .MuiDataGrid-row': {
          cursor: onItemClick ? 'pointer' : undefined
        }
      }}
      {...dataTableProps}
    />
  );
};