import React, { useCallback } from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useIntl } from 'react-intl';
import { Box, LinearProgress } from '@mui/material';
import { EntityType, SectionReorderInput, useFindSectionsQuery, useReorderFieldsMutation } from 'gql';
import { PageTitle } from 'components/PageTitle';
import { entityTypePluralMessages } from 'modules/activities/messages';
import { DraggableFieldList } from '../components/DraggableFieldList';
import { NewButton } from 'components';
import { CustomizationFieldDrawer, CustomizationSectionDrawer } from '../components/CustomizationDrawer';
import { useSearchParams } from 'react-router-dom';

const searchParamKeys = {
  selectedField: 'fieldId',
  selectedSection: 'sectionId',
  addField: 'addField',
  addSection: 'addSection'
};

export const EntityFieldsPage: React.FC<{
  entityType: EntityType;
}> = ({ entityType }) => {
  const { formatMessage } = useIntl();

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedFieldId = Number(searchParams.get(searchParamKeys.selectedField)) || undefined;
  const selectedSectionId = Number(searchParams.get(searchParamKeys.selectedSection)) || undefined;
  const addField = searchParams.get(searchParamKeys.addField) === 'true';
  const addSection = searchParams.get(searchParamKeys.addSection) === 'true';

  const shouldShowFieldDrawer = addField || Boolean(selectedFieldId);
  const shouldShowSectionDrawer = !shouldShowFieldDrawer && (addSection || Boolean(selectedSectionId));

  const { data: sections, isFetching, refetch } = useFindSectionsQuery({
    filter: { entityType: { eq: entityType } }
  });

  const handleClose = () => {
    refetch();
    searchParams.delete(searchParamKeys.selectedField);
    searchParams.delete(searchParamKeys.selectedSection);
    searchParams.delete(searchParamKeys.addField);
    searchParams.delete(searchParamKeys.addSection);
    setSearchParams(searchParams);
  };

  const handleNewSection = () => {
    searchParams.set(searchParamKeys.addSection, 'true');
    setSearchParams(searchParams);
  };

  const handleNewField = (sectionId: number) => {
    searchParams.set(searchParamKeys.selectedSection, sectionId.toString());
    searchParams.set(searchParamKeys.addField, 'true');
    setSearchParams(searchParams);
  };

  const handleEditField = (fieldId: number) => {
    searchParams.set(searchParamKeys.selectedField, fieldId.toString());
    setSearchParams(searchParams);
  };

  const handleEditSection = (sectionId: number) => {
    searchParams.set(searchParamKeys.selectedSection, sectionId.toString());
    setSearchParams(searchParams);
  };

  const reorderMutation = useReorderFieldsMutation({
    onSuccess: () => {
      refetch();
    }
  });

  const onFieldsDragged = useCallback((ordering: SectionReorderInput[]) => {
    reorderMutation.mutate({ input: { ordering, entityType } });
  }, [entityType, reorderMutation]);

  return (
    <>
      <PageTitle
        icon={<ViewListIcon />}
        title={formatMessage({ id: 'Customization' })}
        subtitle={formatMessage(entityTypePluralMessages[entityType])}
        actionButton={(
          <NewButton label={formatMessage({ id: 'Add section' })} onClick={handleNewSection} />
        )}
      />

      <CustomizationFieldDrawer
        sectionId={selectedSectionId}
        customFieldId={selectedFieldId}
        isOpen={shouldShowFieldDrawer}
        onClose={handleClose}
        entityType={entityType}
      />

      <CustomizationSectionDrawer
        sectionId={selectedSectionId}
        entityType={entityType}
        isOpen={shouldShowSectionDrawer}
        onClose={handleClose}
      />

      <Box sx={{ mt: 3 }}>
        <LinearProgress sx={{ visibility: (isFetching || reorderMutation.isLoading) ? 'visible' : 'hidden' }} />
        <DraggableFieldList
          sections={sections?.sections || []}
          onFieldClick={handleEditField}
          onSectionClick={handleEditSection}
          disabled={isFetching || reorderMutation.isLoading}
          onChange={onFieldsDragged}
          onNewfieldButtonClick={handleNewField}
        />
      </Box>
    </>
  );
};