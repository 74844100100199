import React, { useState } from 'react';
import { useFindDistributionItemsQuery } from 'gql';
import { useOutletContext } from 'react-router-dom';
import { DistributionItemDrawer } from '../components/ItemDrawer';
import { ItemsTable } from '../components/ItemsTable';
import { Box } from '@mui/material';


type BooleanUseState = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

export const DistributionItemsPage: React.FC = () => {
  const { data: itemsData, isFetching: isItemsFetching } = useFindDistributionItemsQuery();
  const items = itemsData?.items || [];

  const loading = isItemsFetching;

  const [_isDrawerOpen, setIsDrawerOpen] = useOutletContext<BooleanUseState>();
  const [selectedItemId, setSelectedItemId] = useState<number>();
  const isDrawerOpen = _isDrawerOpen || selectedItemId !== undefined;

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedItemId(undefined);
  };

  return <>
    <Box sx={{ pt: 0.5 }}>
      <ItemsTable
        items={items}
        loading={loading}
        onItemClick={item => setSelectedItemId(item.id)}
      />
    </Box>

    <DistributionItemDrawer
      open={isDrawerOpen}
      onClose={closeDrawer}
      item={items.find(i => i.id === selectedItemId)}
    />
  </>;
};