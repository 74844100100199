export const getTenantIdentifier = () => {
  const value = window.location.pathname.split('/')[1];

  if (!value || value === 'login') return undefined;

  return value;
};

export const administrativeRegionsQuebec = [
  { label: 'Abitibi-Témiscamingue', id: 'Abitibi-Témiscamingue' },
  { label: 'Bas Saint-Laurent', id: 'Bas Saint-Laurent' },
  { label: 'Capitale Nationale', id: 'Capitale Nationale' },
  { label: 'Centre du Québec', id: 'Centre du Québec' },
  { label: 'Chaudière-Appalaches', id: 'Chaudière-Appalaches' },
  { label: 'Côte-Nord', id: 'Côte-Nord' },
  { label: 'Estrie', id: 'Estrie' },
  { label: 'Gaspésie–Îles-de-la-Madeleine', id: 'Gaspésie–Îles-de-la-Madeleine' },
  { label: 'Lanaudière', id: 'Lanaudière' },
  { label: 'Laurentides', id: 'Laurentides' },
  { label: 'Laval', id: 'Laval' },
  { label: 'Mauricie', id: 'Mauricie' },
  { label: 'Montérégie', id: 'Montérégie' },
  { label: 'Montréal', id: 'Montréal' },
  { label: 'Nord-du-Québec', id: 'Nord-du-Québec' },
  { label: 'Outaouais', id: 'Outaouais' },
  { label: 'Saguenay Lac Saint-Jean', id: 'Saguenay Lac Saint-Jean' },
];