import React from 'react';
import { Chip } from '@mui/material';
import { BuiltInRoles } from 'gql';
import { useIntl } from 'react-intl';
import { userRoleMessages } from 'modules/users/messages';

export const UserRoleChip: React.FC<{
  role: string;
}> = ({ role }) => {
  const { formatMessage } = useIntl();

  return (
    <Chip label={formatMessage(userRoleMessages[role as BuiltInRoles])} />
  );
};