import { Paper, Typography, Divider, Stack, ListItem, ListItemButton, ListItemText, LinearProgress } from '@mui/material';
import { EntityType, ReportFieldFilterInput, ReportParametersInput, useFindCustomFieldsQuery, useFindThemesWithSpecificationsQuery } from 'gql';
import React, { useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FilterInput } from '../FilterInput';
import { LookupInput } from 'components';

interface Props {
  disabled?: boolean;
}

export const ReportRealitiesSection: React.FC<Props> = ({ disabled }) => {
  const { formatMessage } = useIntl();

  const { control, setValue } = useFormContext<ReportParametersInput>();
  const { fields: fieldFilters, append, update } = useFieldArray({ control, name: 'filters.realitiesFieldFilters' });
  const selectedThemeIds = useWatch({ control, name: 'filters.themeIds' });
  const selectedSpecificationIds = useWatch({ control, name: 'filters.specificationIds' });

  const onChange = (customFieldFilterInput: ReportFieldFilterInput) => {
    const index = fieldFilters.findIndex(f => f.customFieldId === customFieldFilterInput.customFieldId);
    if (index === -1) {
      append(customFieldFilterInput);
    } else {
      update(index, customFieldFilterInput);
    }
  };

  const { data: themesData } = useFindThemesWithSpecificationsQuery();
  const themes = useMemo(() => themesData?.themes ?? [], [themesData?.themes]);

  const availableSpecifications = useMemo(() => themes.filter(theme => selectedThemeIds?.includes(theme.id)).flatMap(theme => theme.specifications), [themes, selectedThemeIds]);

  useEffect(() => {
    setValue('filters.specificationIds', availableSpecifications.filter(spec => selectedSpecificationIds?.includes(spec.id)).map(spec => spec.id));
  }, [availableSpecifications, setValue]);

  const { data: fieldsData, isFetching } = useFindCustomFieldsQuery({
    filter: {
      entityTypes: {
        some: {
          entityType: {
            eq: EntityType.Reality
          }
        }
      }
    }
  });
  const fields = useMemo(() => fieldsData?.customFields ?? [], [fieldsData]);

  return (
    <Paper>
      <Typography variant='h6' p={2} pb={1}>{formatMessage({ id: 'Realities' })}</Typography>

      <LinearProgress style={{ visibility: isFetching ? 'visible' : 'hidden' }} />
      <Divider />

      <Stack p={2} spacing={2}>
        <Controller
          control={control}
          name='filters.themeIds'
          render={({ field }) => (
            <LookupInput
              multiple
              label={formatMessage({ id: 'Themes' })}
              availableValues={themes}
              getKey={theme => theme.id}
              getLabel={theme => theme.name}
              searchFilter={(theme, searchValue) => theme.name.includes(searchValue)}
              renderListItem={(theme, selected, onClick) => (
                <ListItem disablePadding>
                  <ListItemButton selected={selected} onClick={onClick}>
                    <ListItemText
                      primary={theme.name}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              selectedValues={themes.filter(theme => field.value?.includes(theme.id))}
              onChange={themes => field.onChange(themes.map(theme => theme.id))}
              disabled={disabled}
            />
          )}
        />

        {availableSpecifications.length > 0 && (
          <Controller
            control={control}
            name='filters.specificationIds'
            render={({ field }) => (
              <LookupInput
                multiple
                label={formatMessage({ id: 'Specifications' })}
                availableValues={availableSpecifications}
                getKey={spec => spec.id}
                getLabel={spec => spec.name}
                searchFilter={(spec, searchValue) => spec.name.includes(searchValue)}
                renderListItem={(spec, selected, onClick) => (
                  <ListItem disablePadding>
                    <ListItemButton selected={selected} onClick={onClick}>
                      <ListItemText
                        primary={spec.name}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                selectedValues={availableSpecifications.filter(spec => field.value?.includes(spec.id))}
                onChange={specs => field.onChange(specs.map(spec => spec.id))}
                disabled={disabled}
              />
            )}
          />
        )}

        {fields.map(field => (
          <FilterInput
            key={field.id}
            customField={field}
            value={fieldFilters.find(f => f.customFieldId === field.id) ?? { customFieldId: field.id }}
            onChange={onChange}
            disabled={disabled}
          />
        ))}
      </Stack>
    </Paper>
  );
};