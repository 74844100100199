import { DocumentScannerOutlined, Person } from '@mui/icons-material';
import { Paper, Stack, LinearProgress, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ReportParametersInput, useGetReportCountQuery } from 'gql';
import { useIntl } from 'react-intl';

export const ReportCountPaper: React.FC = () => {
  const { formatMessage } = useIntl();

  const { control, getValues } = useFormContext<ReportParametersInput>();

  const formValues = useWatch({ control, name: 'filters' });
  const includeUnknownIndividuals = useWatch({ control, name: 'includeUnknownIndividuals' });
  const hasPeriod = Boolean(formValues.period.from) && Boolean(formValues.period.to);
  const { data: reportCountData, isFetching: isReportCountFetching, refetch } = useGetReportCountQuery({
    input: getValues()
  }, {
    enabled: hasPeriod,
    refetchOnMount: true,
    staleTime: 0,
    onError: () => {
      // Do nothing
    }
  });

  useEffect(() => {
    refetch();
  }, [includeUnknownIndividuals, refetch]);

  const { activitiesCount, individualsCount } = reportCountData?.reportCount ?? {};

  const errorMessage = () => {
    if (!hasPeriod) {
      return formatMessage({ id: 'Select a start and end date' });
    }
  };

  return (
    <Paper>
      <Stack>
        <LinearProgress style={{ visibility: isReportCountFetching ? 'visible' : 'hidden' }} />
        <ListItem divider={Boolean(individualsCount)}>
          <ListItemIcon>
            <DocumentScannerOutlined />
          </ListItemIcon>
          <ListItemText
            primary={activitiesCount ?? errorMessage()}
            secondary={formatMessage({ id: 'Activities' })}
          />
        </ListItem>

        {individualsCount != null && (
          <ListItem>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText
              primary={individualsCount}
              secondary={formatMessage({ id: 'Individuals' })}
            />
          </ListItem>
        )}
      </Stack>
    </Paper>
  );
};