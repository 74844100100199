import { MoreVert, Save } from '@mui/icons-material';
import { IconButton, Menu, Stack, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ConfirmCloseDialog, FormDrawer, ResponsiveDrawer } from 'components';
import { ConfirmButton } from 'components/Buttons';
import { DistributionCategoryDto, useAddDistrubtionCategoryMutation, useDeleteDistributionCategoryMutation, useEditDistrubtionCategoryMutation } from 'gql';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

type Category = Pick<DistributionCategoryDto, 'id' | 'name'>;

interface Props {
  category?: Category;
  open: boolean;
  onClose: () => void;
}

export interface CategoryFormValues {
  name: string;
  isDisabled: boolean;
}

export const DistributionCategoryDrawer: React.FC<Props> = ({ category, open, onClose }) => {
  const { formatMessage } = useIntl();

  const isEditing = Boolean(category);

  const form = useForm<CategoryFormValues>();
  const { reset, control, handleSubmit, formState: { isDirty } } = form;

  useEffect(() => {
    reset({
      name: category?.name ?? ''
    });
  }, [category?.name, reset, open]);


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const queryClient = useQueryClient();

  const onSuccess = () => {
    queryClient.invalidateQueries(['findDistributionCategories']);
    onClose();
  };

  const addMutation = useAddDistrubtionCategoryMutation({ onSuccess });
  const editMutation = useEditDistrubtionCategoryMutation({ onSuccess });

  const onSubmit = (values: CategoryFormValues) => {
    if (category) {
      editMutation.mutate({
        input: {
          id: category.id,
          name: values.name,
        }
      });
    } else {
      addMutation.mutate({
        input: {
          name: values.name
        }
      });
    }
  };

  const deleteMutation = useDeleteDistributionCategoryMutation({ onSuccess });

  const onDelete = () => {
    setAnchorEl(null);
    if (!category) return;
    deleteMutation.mutate({ input: { id: category.id } });
  };

  const disabled = addMutation.isLoading || editMutation.isLoading || deleteMutation.isLoading;

  return <>
    <FormDrawer
      open={open}
      onSave={handleSubmit(onSubmit)}
      showFooter
      header={category !== undefined ? formatMessage({ id: 'Edit category' }) : formatMessage({ id: 'New category' })}
      onClose={onClose}
      actionButtons={<>
        {isEditing && (
          <IconButton onClick={handleMenuClick} disabled={disabled}>
            <MoreVert />
          </IconButton>
        )}
      </>}
    >
      <Stack spacing={3} pt={3}>
        <Controller
          control={control}
          name='name'
          rules={{ required: formatMessage({ id: 'This field is required' }) }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              variant='outlined'
              label={formatMessage({ id: 'Name' })}
              {...field}
              required
              disabled={disabled}
              error={Boolean(error)}
              helperText={error?.message}
              autoFocus
            />
          )}
        />

        <Menu open={menuOpen} anchorEl={anchorEl} onClose={handleMenuClose}>
          <ConfirmButton
            buttonVariant='menuItem'
            onConfirm={onDelete}
            confirmTitle={formatMessage({ id: 'Delete this category?' })}
            confirmText={formatMessage({ id: 'This action cannot be undone.' })}
          />
        </Menu>
      </Stack>
    </FormDrawer>
  </>;
};