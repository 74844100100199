import React from 'react';
import { AppBar, IconButton, styled, Toolbar, Typography } from '@mui/material';

export interface DialogTopBarProps {
  leftIcon: React.ReactNode,
  leftIconAction: () => void,
  rightIcon?: React.ReactNode;
  rightIconAction?: () => void,
  title: string;
  disabled?: boolean;
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  '& .MuiTypography-root': {
    textTransform: 'uppercase'
  },
  '& .MuiIconButton-root': {
    color: theme.palette.common.white,
  }
}));

export const DialogTopBar: React.FC<DialogTopBarProps> = (props) => {
  return (
    <AppBar position='sticky'>
      <StyledToolbar sx={{ display: 'flex', flexDirection: 'row', px: { xs: 1, md: 2 } }}>
        <IconButton
          onClick={props.leftIconAction}
          disabled={props.disabled}
          sx={{ mr: { xs: 1, md: 2 } }}
        >
          {props.leftIcon}
        </IconButton>
        <Typography variant='h6' overflow='hidden' textOverflow='ellipsis' noWrap flexGrow={1}>
          {props.title}
        </Typography>
        {props.rightIcon &&
          <IconButton
            onClick={props.rightIconAction}
            disabled={props.disabled}
          >
            {props.rightIcon}
          </IconButton>
        }
      </StyledToolbar>
    </AppBar>
  );
};