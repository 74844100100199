import { Close } from '@mui/icons-material';
import { DialogProps, Stack } from '@mui/material';
import { SlideDialog } from 'components/Dialogs/SlideDialog';
import { CustomFieldInputForm } from 'modules/customizations/components/CustomFieldInputForm';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { CancelButton } from '../../../../components';
import { EntityType, SystemFieldId, useFindSectionsQuery } from '../../../../gql';
import { LoadingButton } from '@mui/lab';
import { AddIndividualInput, EditIndividualInput } from 'gql/subscriptions';

interface Props extends DialogProps {
  disableTeamSelect?: boolean;
  individual?: AddIndividualInput;
  loading?: boolean;
  onCancel: () => void;
  onSuccess: (individual: EditIndividualInput) => void;
}

export const IndividualDialog: React.FC<Props> = ({ individual, onCancel, onSuccess, loading, disableTeamSelect, ...dialogProps }) => {
  const { formatMessage } = useIntl();

  const form = useForm<EditIndividualInput>();

  const { data: sectionsData, isFetched: isSectionsFetched } = useFindSectionsQuery({
    filter: { entityType: { eq: EntityType.Individual } }
  }, { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false });
  const sections = sectionsData?.sections ?? [];

  useEffect(() => {
    if (dialogProps.open) {
      if (individual) {
        form.reset(individual);
      } else {
        form.reset({ id: undefined, values: [] });
      }
    }
  }, [individual, form, dialogProps.open]);

  const onSubmit = (data: EditIndividualInput) => {
    onSuccess({
      id: data.id,
      values: data.values.map(v => ({
        ...v,
        displayString: v.value ?? ''
      }))
    });
  };

  return (
    <SlideDialog
      topbar={{
        leftIcon: <Close />,
        leftIconAction: onCancel,
        title: individual ? formatMessage({ id: 'Edit Individual' }) : formatMessage({ id: 'Create Individual' }),
      }}
      {...dialogProps}
    >
      <FormProvider {...form}>
        <CustomFieldInputForm<EditIndividualInput>
          shouldRenderField={f => !(disableTeamSelect && f.systemFieldId === SystemFieldId.Team)}
          sections={sections}
          disabled={loading}
          loading={!isSectionsFetched}
        />
      </FormProvider>

      <Stack justifyContent='right' direction='row' mt={2} spacing={1}>
        <CancelButton onClick={onCancel} disabled={loading}>
          {formatMessage({ id: 'Cancel' })}
        </CancelButton>
        <LoadingButton color='primary' variant='contained' onClick={form.handleSubmit(onSubmit)} loading={loading}>
          {formatMessage({ id: 'Save' })}
        </LoadingButton>
      </Stack>
    </SlideDialog>
  );
};