import { Stack, Typography } from '@mui/material';
import { BuiltInRoles, useGetCurrentUserQuery } from 'gql';
import React from 'react';
import { useIntl } from 'react-intl';
import { UserAvatar } from '../../../../../../components/UserAvatar';
import { SignOutButton } from './SignOutButton';
import { userRoleMessages } from 'modules/users/messages';

export const UserSettingsSection: React.FC = () => {
  const { formatMessage } = useIntl();
  const { data: currentUser } = useGetCurrentUserQuery();
  const displayName = currentUser?.me?.displayName;
  const currentRole = currentUser?.me.roles.at(0)?.name as BuiltInRoles | undefined;

  return (
    <Stack alignItems="center" padding={2} gap={2}>
      <UserAvatar displayName={displayName} size='xlarge' textVariant='h3' />

      <Stack alignItems="center">
        <Typography variant='h6'>{displayName}</Typography>
        {currentRole && (
          <Typography variant='subtitle1' color='text.secondary'>{formatMessage(userRoleMessages[currentUser?.me.roles[0].name as BuiltInRoles])}</Typography>
        )}
      </Stack>

      <SignOutButton />
    </Stack>
  );
};