import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import React from 'react';
import { DeleteIconButton } from 'components/DeleteIconButton';
import { DistributionItemDto, DistributionValueInput } from 'gql';
import { useIntl } from 'react-intl';

export const DistributionListItem: React.FC<{
    item?: Pick<DistributionItemDto, 'name' | 'unit' | 'description'>
    itemValue: DistributionValueInput
    onDelete?: () => void;
    onClick?: () => void;
  }> = ({ onDelete, onClick, item, itemValue }) => {
    const { formatMessage } = useIntl();
    return (
      <ListItem
        secondaryAction={onDelete ? <DeleteIconButton edge="end" onClick={onDelete} /> : undefined}
        disablePadding={Boolean(onClick)}
      >
        <ListItemButton onClick={onClick}>
          <ListItemText
            primary={item?.name}
            secondary={formatMessage({ id: '{unit}: {value}' }, { unit: item?.unit, value: itemValue.distributedAmount })}
          />
        </ListItemButton>
      </ListItem>
    );
  };