import { Button, Divider, LinearProgress, List, ListItem, ListItemIcon, Paper, Stack, styled, TextField, Typography } from '@mui/material';
import { RealitySpecificationDto, RealityThemeDto } from 'gql';
import React from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Add, DragHandle, Edit } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { DeleteIconButton } from 'components/DeleteIconButton';

type Theme = Pick<RealityThemeDto, 'id' | 'name'> & {
  specifications: Pick<RealitySpecificationDto, 'id' | 'name'>[];
};

interface Props {
  theme?: Theme;
  loading?: boolean;
  disabled?: boolean;
}

export interface SpecificationsFormValues {
  specifications: {
    specificationId?: number;
    name: string;
  }[];
}

const HoverListItem = styled(ListItem)(({ theme }) => ({
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  [theme.breakpoints.up('lg')]: {
    '&:hover .MuiIconButton-root': {
      visibility: 'visible'
    },
    '& .MuiIconButton-root': {
      visibility: 'hidden'
    },
  }
}));

export const ThemeSpecificationsForm: React.FC<Props> = ({ theme, disabled, loading }) => {
  const { formatMessage } = useIntl();

  const { control, register } = useFormContext<SpecificationsFormValues>();

  const { fields, move, remove, prepend } = useFieldArray({ control, name: 'specifications' });

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result || !result.destination) return;

    move(result.source.index, result.destination.index);
  };

  const addSpecification = () => {
    prepend({ name: '' });
  };

  if (loading) {
    return (
      <LinearProgress />
    );
  }

  return <>
    <Stack p={2}>
      <Stack direction='row' alignItems='center' spacing={2} mb={2}>
        <Edit />
        <Typography variant='h6'>
          {formatMessage({ id: 'Theme Details' })}
        </Typography>
      </Stack>

      <TextField
        disabled
        label={formatMessage({ id: 'Theme Name' })}
        value={theme?.name}
      />
    </Stack>

    <Divider />
    <Stack direction='row' alignItems='center' justifyContent='space-between' pr={2}>
      <Typography p={2} variant='h6'>
        {formatMessage({ id: 'Specifications' })}
      </Typography>
      <Button variant='outlined' startIcon={<Add />} onClick={addSpecification} disabled={disabled}>
        {formatMessage({ id: 'Add' })}
      </Button>
    </Stack>

    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='specifications'>{(provided) => (
        <List disablePadding {...provided.droppableProps} ref={provided.innerRef}>
          {fields.map((specification, index) => (
            <Draggable isDragDisabled={disabled} key={specification.id} index={index} draggableId={specification.id}>{(provided, snapshot) => (
              <Paper square
                elevation={16}
                sx={{ boxShadow: 'none' }}
                {...provided.draggableProps}
                ref={provided.innerRef}
              >
                <HoverListItem
                  disablePadding
                  divider={index !== fields.length - 1}
                  sx={{ backgroundColor: snapshot.isDragging ? 'action.hover' : undefined }}
                >
                  <ListItemIcon {...provided.dragHandleProps} sx={{ minWidth: '24px', pr: 2 }}>
                    <DragHandle />
                  </ListItemIcon>

                  <TextField
                    {...register(`specifications.${index}.name` as const)}
                    fullWidth
                    disabled={disabled}
                    onBlur={e => {
                      if (e.target.value === '') {
                        remove(index);
                      }
                    }}
                  />

                  <DeleteIconButton
                    edge="end"
                    disabled={disabled}
                    sx={{ height: '40px', width: '40px', ml: 1 }}
                    onClick={() => remove(index)}
                  />
                </HoverListItem>
              </Paper>
            )}</Draggable>
          ))}

          {provided.placeholder}
        </List>
      )}</Droppable>
    </DragDropContext>
  </>;
};