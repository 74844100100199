import { ArrowRight } from '@mui/icons-material';
import { Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select, styled, ChipProps, SelectProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { LookupInputDrawer } from './LookupInputDrawer';

export interface LookupInputProps<T> {
  selectedValues: T[];
  availableValues: T[];
  onChange: (value: T[]) => void;
  getKey: (value: T) => React.Key;
  getLabel: (value: T) => string;
  renderAsChips?: boolean;
  searchMode?: 'client' | 'server';
  searchFilter?: (value: T, search: string) => boolean;
  hasMoreResults?: boolean;
  isLoading?: boolean;
  onServerSearch?: (searchTerm: string) => void;

  renderListItem: (value: T, selected: boolean, onClick: () => void) => ReactNode;
  getChipProps?: (value: T) => ChipProps;

  renderEmptyState?: () => ReactNode;
  disabled?: boolean;

  label?: string;
  title?: string;
  error?: boolean;
  loading?: boolean;
  multiple?: boolean;
  required?: boolean;
  disableDrawerChips?: boolean;
  helperText?: ReactNode | number | string | null;
  anchor?: 'left' | 'right';

  SelectProps?: SelectProps;
  disableFullWidth?: boolean;
}

export const LookupInputValueChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  height: '28px'
}));

export const LookupInput = <T,>(props: LookupInputProps<T>) => {
  const [open, setOpen] = React.useState(false);
  const {
    selectedValues, availableValues, disabled, getKey, getChipProps, renderAsChips, getLabel,
    label, error, helperText, required
  } = props;

  const selectedKeys = selectedValues.map(getKey);

  return <>
    <FormControl fullWidth={!props.disableFullWidth}>
      <InputLabel error={error} required={required}>{label}</InputLabel>
      <Select
        open={open}
        label={label}
        MenuProps={{ style: { display: 'none' } }}
        onClick={() => setOpen(true && !disabled)}
        multiple={true}
        value={selectedKeys}
        error={error}
        disabled={disabled}
        required={required}
        IconComponent={ArrowRight}
        renderValue={() => renderAsChips
          ? selectedValues.map(value => (
            <LookupInputValueChip key={getKey(value)} label={getLabel(value)} {...getChipProps?.(value)} />
          ))
          : selectedValues.map(getLabel).join(', ')
        }
        {...props.SelectProps}
      >
        {!required && (
          <MenuItem value={''} />
        )}
        {availableValues.map(value => (
          <MenuItem key={getKey(value)} value={getKey(value)} />
        ))}
      </Select>

      {helperText && (
        <FormHelperText error={Boolean(error)}>{helperText}</FormHelperText>
      )}

      <LookupInputDrawer
        open={open}
        setOpen={setOpen}
        {...props}
      />
    </FormControl>
  </>;
};