import React from 'react';
import { useIntl } from 'react-intl';
import { Stack } from '@mui/material';
import { Close, SendOutlined } from '@mui/icons-material';
import { FormProvider, useForm } from 'react-hook-form';
import { UserInviteForm } from '../UserInviteForm';
import { useQueryClient } from '@tanstack/react-query';
import { BuiltInRoles, InviteUserInput, useInviteUserMutation } from 'gql';
import { LoadingButton } from '@mui/lab';
import { SlideDialog } from 'components/Dialogs/SlideDialog';

interface IProps {
  open: boolean,
  onClose: () => void;
  defaultRole?: BuiltInRoles;
  allowedRoles: BuiltInRoles[];
  defaultTenantId?: string;
  title?: string;
  onInviteComplete?: () => void;
}

export const UserInviteDialog: React.FC<IProps> = (props) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const inviteFormMethods = useForm<InviteUserInput>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      teamIds: [],
      role: props.defaultRole
    }
  });
  const { reset, handleSubmit } = inviteFormMethods;

  const handleCloseDialog = () => {
    reset();
    props.onClose();
  };

  const inviteUserMutation = useInviteUserMutation({
    onSuccess: (result) => {
      queryClient.invalidateQueries(['findUsers']);
      queryClient.invalidateQueries(['getUserById', { userId: result.inviteUser.applicationUser?.id }]);
      props.onInviteComplete?.();
      handleCloseDialog();
    }
  });

  const inviteUserSubmit = (userFormData: InviteUserInput) => {
    inviteUserMutation.mutate({ input: userFormData, tenantId: props.defaultTenantId });
  };

  const disabled = inviteUserMutation.isLoading;

  return (
    <SlideDialog
      open={props.open}
      onClose={handleCloseDialog}
      topbar={{
        disabled: disabled,
        leftIcon: (<Close />),
        leftIconAction: handleCloseDialog,
        rightIcon: (<SendOutlined />),
        rightIconAction: handleSubmit(inviteUserSubmit),
        title: props.title ?? formatMessage({ id: 'Invite user' }),
      }}
    >
      <FormProvider {...inviteFormMethods}>
        <UserInviteForm disabled={disabled} allowedRoles={props.allowedRoles} />
      </FormProvider>
      <Stack direction='row' justifyContent='flex-end'>
        <LoadingButton
          variant='contained'
          type='submit'
          loading={disabled}
          startIcon={<SendOutlined />}
          onClick={handleSubmit(inviteUserSubmit)}
        >
          {formatMessage({ id: 'Invite' })}
        </LoadingButton>
      </Stack>
    </SlideDialog>
  );
};