import React from 'react';
import { Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { MemberStatDataGrid } from './MemberStatDataGrid';
import { StyledCard, StyledStack } from './TeamStatHeader';
import { useActivitySettings } from 'hooks/useActivitySettings';
import { useIntl } from 'react-intl';
import { activityTypePluralOrSigleMessages } from 'modules/home/messages';

export const TeamStatTableSkeleton: React.FC = () => {
  const { formatMessage } = useIntl();
  const { activitiesEnabled } = useActivitySettings();
  const theme = useTheme();

  return (
    <Stack gap={0}>
      <StyledCard>
        <Stack>
          <Skeleton variant='text' />
          <Stack direction='row' gap={1} alignItems='center'>
            <Typography variant='body2'>
              {formatMessage({ id: 'Last activity on {date}' }, { date: '' })}
            </Typography>
            <Skeleton variant='text' width={theme.spacing(10)} />
          </Stack>
        </Stack>
        <Stack direction='row' gap={0.5} flexWrap='wrap'>
          {activitiesEnabled.map((type, index) => (
            <StyledStack activitytype={type} key={index + type} >
              <Skeleton variant='text' />
              <Typography variant='body2'>{formatMessage(activityTypePluralOrSigleMessages[type], { activityCount: 0 })}</Typography>
            </StyledStack>
          ))}
        </Stack>
      </StyledCard>
      <MemberStatDataGrid
        teamStat={[]}
        isLoading
      />
    </Stack>
  );
};