import React, { useMemo } from 'react';
import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { allThemes } from '../../../themes';
import { useRecoilValue } from 'recoil';
import { currentLocaleState, currentThemeNameState } from '../atoms';
import { enUS, frFR } from '@mui/x-data-grid/locales';
import { frFR as pickersFrFR, enUS as pickersEnUS } from '@mui/x-date-pickers';
import { frFR as coreFrFR, enUS as coreEnUS } from '@mui/material/locale';

interface OwnProps {
  children?: React.ReactNode;
}

export type ThemeProviderProps =
  & OwnProps;

export const ThemeProvider: React.FC<ThemeProviderProps> = (props) => {
  const currentThemeName = useRecoilValue(currentThemeNameState);
  const currentLocale = useRecoilValue(currentLocaleState);

  const currentTheme = useMemo(() => responsiveFontSizes(createTheme(
    allThemes[currentThemeName] ?? allThemes.light,
    currentLocale.includes('fr') ? frFR : enUS,
    currentLocale.includes('fr') ? pickersFrFR : pickersEnUS,
    currentLocale.includes('fr') ? coreFrFR : coreEnUS,
  )), [currentThemeName, currentLocale]);

  return (
    <MuiThemeProvider theme={currentTheme}>
      <CssBaseline />

      {props.children}
    </MuiThemeProvider>
  );
};