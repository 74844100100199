import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { useIntl } from 'react-intl';
import { DeltaIcon } from '../CountStatsSection/DeltaIcon';
import { DashboardSimpleTable } from './DashboardSimpleTable';

export type Item = {
  id: number,
  name: string,
  quantity: number,
  delta: number | string,
  quantityReferenceYear: number,
  deltaReferenceYear: number | string
}

interface OwnProps {
  items: Item[]
  isLoading: boolean
}

export const TopDistributedItems: React.FC<OwnProps> = ({ items, isLoading }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const getDeltaCell = (value?: number | string) => (
    <>
      {value != undefined
        ?
        <Typography variant='body1'>
          {value}
        </Typography>
        : <Skeleton variant='text' width={theme.spacing(5)} />
      }
    </>
  );

  const columns: GridColDef<Item>[] = [
    {
      field: 'itemName',
      flex: 1,
      headerName: formatMessage({ id: 'Item' }),
      valueGetter: ({ row }) => row.name,
      renderCell: ({ row }) => row.name ? (
        <Box pl={1}>
          <Typography variant='body1' fontWeight={500}>
            {row.name}
          </Typography>
        </Box>
      ) : (
        <Box pl={1}>
          <Skeleton variant='text' width={theme.spacing(10)} />
        </Box>
      )
    },
    {
      field: 'quantity',
      minWidth: 100,
      headerName: formatMessage({ id: 'Quantity' }),
      headerAlign: 'right',
      align: 'right',
      valueGetter: ({ row }) => row.quantity,
      renderCell: ({ row }) => row.quantity ? (
        <Typography variant='body1'>
          {row.quantity}
        </Typography>
      ) : <Skeleton variant='text' width={theme.spacing(5)} />
    },
    {
      field: 'delta',
      minWidth: 100,
      headerAlign: 'right',
      align: 'right',
      headerName: formatMessage({ id: 'Delta' }),
      valueGetter: ({ row }) => row.delta,
      renderCell: ({ row }) => getDeltaCell(row.delta)
    },
    {
      field: 'QuantityReferanceYear',
      flex: 1,
      minWidth: 100,
      headerAlign: 'right',
      align: 'right',
      headerName: formatMessage({ id: 'Total quantity*' }),
      valueGetter: ({ row }) => row.quantityReferenceYear,
      renderCell: ({ row }) => row.quantityReferenceYear ? (
        <Typography variant='body1'>
          {row.quantityReferenceYear}
        </Typography>
      ) : <Skeleton variant='text' width={theme.spacing(5)} />
    },
    {
      field: 'deltaReferanceYear',
      minWidth: 130,
      headerAlign: 'right',
      align: 'right',
      headerName: formatMessage({ id: 'Total delta*' }),
      valueGetter: ({ row }) => row.deltaReferenceYear,
      renderCell: ({ row }) => getDeltaCell(row.deltaReferenceYear)
    },
  ];

  const loadingRow = new Array(5).fill(1).map((_, i) => (
    {
      id: i
    }
  ));

  return (
    <DashboardSimpleTable
      columns={columns}
      rows={isLoading ? loadingRow : items}
      noDataMessage={formatMessage({ id: 'There are no items' })}
      sectionTitle={formatMessage({ id: 'Top distributed items' })}
      bottomMessage={formatMessage({ id: '* Values since the beginning of the reference year' })}
    />
  );
};