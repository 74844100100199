import dayjs from 'dayjs';

export const getStartOfCurrentFiscalYear = (firstDayOfFiscalYear: Date | undefined) => {
  if (firstDayOfFiscalYear == null)
    return dayjs().startOf('year').utc();

  const fiscalYearStartForCurrentYear = dayjs(firstDayOfFiscalYear).utc().year(dayjs().year());
  const currentDate = dayjs().utc();

  return currentDate < fiscalYearStartForCurrentYear
    ? fiscalYearStartForCurrentYear.subtract(1, 'year')
    : fiscalYearStartForCurrentYear;
};