import { DragDropContext, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import { CustomFieldDto, CustomFieldEntityType, FieldSectionDto, SectionReorderInput } from 'gql';
import { useEffect, useState } from 'react';
import React from 'react';
import { Stack } from '@mui/system';
import { DraggableSection } from './DraggableSection';
import { EmptyState } from 'components';
import { useIntl } from 'react-intl';
import { FieldList } from './FieldList';

type CustomField = Pick<CustomFieldDto, 'id' | 'name' | 'description' | 'isRequired' | 'isSystemField' | 'customFieldType' | 'status'>;

type FieldEntityType = Pick<CustomFieldEntityType, 'id'> & {
  customFieldDto: CustomField;
};

type Section = Pick<FieldSectionDto, 'id' | 'name'> & {
  fieldEntityTypes: FieldEntityType[];
};

interface Props {
  onChange?: (fields: SectionReorderInput[]) => void;
  disabled?: boolean;
  sections: Section[];
  onFieldClick: (fieldId: number) => void;
  onSectionClick: (sectionId: number) => void;
  onNewfieldButtonClick?: (sectionId: number) => void;
  disableSections?: boolean;
  disableDragFields?: boolean
}

export const DraggableFieldList: React.FC<Props> = ({ onChange, onFieldClick, onNewfieldButtonClick, disabled, disableSections, disableDragFields, ...props }) => {
  const [sections, setSections] = useState(props.sections);
  const { formatMessage } = useIntl();

  useEffect(() => {
    setSections(props.sections);
  }, [props.sections]);

  const onSubmit = (sections: Section[]) => {
    const ordering: SectionReorderInput[] = sections.map(section => ({
      sectionId: section.id,
      fieldsOrdering: section.fieldEntityTypes.map(field => field.id)
    }));

    onChange?.(ordering);
  };

  const onDragEnd: OnDragEndResponder = ({ destination, source, type }) => {
    if (!destination || !source) return;

    setSections(sections => {
      if (type === 'sections') {
        const [removed] = sections.splice(source.index, 1);
        sections.splice(destination.index, 0, removed);
      } else if (type === 'fields') {
        const sourceSection = sections.find(section => section.id.toString() === source.droppableId);
        const destinationSection = sections.find(section => section.id.toString() === destination.droppableId);
        if (!sourceSection || !destinationSection) return sections;

        const [removed] = sourceSection.fieldEntityTypes.splice(source.index, 1);
        destinationSection.fieldEntityTypes.splice(destination.index, 0, removed);
      }

      onSubmit?.(sections);
      return sections;
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='sections' type='sections'>
        {(provided) => (
          <Stack ref={provided.innerRef} {...provided.droppableProps}>
            {(sections.length === 0 && !disabled) &&
              <EmptyState title={formatMessage({ id: 'There are no sections for this activity yet.' })} />
            }
            {sections.map((section, index) => (
              <DraggableSection
                onClick={props.onSectionClick}
                section={section}
                onClickNewFieldButton={onNewfieldButtonClick}
                draggableId={section.id.toString()}
                index={index}
                key={section.id.toString()}
                disable={disableSections}
              >
                <Droppable droppableId={section.id.toString()} type='fields'>
                  {(provided) => (
                    <FieldList
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      onFieldClick={onFieldClick}
                      fieldEntityTypes={section.fieldEntityTypes}
                      disabled={disableDragFields}
                    >
                      {provided.placeholder}
                    </FieldList>
                  )}
                </Droppable>
              </DraggableSection>
            ))}

            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
};