import React from 'react';
import { useIntl } from 'react-intl';
import { CustomFieldDto, CustomFieldEntityType, CustomFieldStatusType, CustomFieldType, CustomOptionInput, EntityType } from 'gql/index';
import { Alert, Divider, FormControlLabel, MenuItem, Stack, Switch, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { DragAndDropOptions } from '../DragAndDropOptions/DragAndDropOptions';
import _ from 'lodash';

type FieldEntityType = Pick<CustomFieldEntityType, 'entityType'>;

type CustomField = Pick<CustomFieldDto, 'id' | 'name' | 'description' | 'isRequired' | 'isSystemField' | 'customFieldType' | 'status' | 'allowMultipleValues'> & {
  entityTypes: FieldEntityType[];
};

interface OwnProps {
  customField?: CustomField;
  fieldType: CustomFieldType;
  disabled?: boolean;
}

export interface CustomFieldFormValues {
  allowMultipleValues: boolean;
  numberOfLines: number;
  customOptions?: CustomOptionInput[];
  entityType: EntityType;
  fieldType: CustomFieldType;
  isRequired: boolean;
  propertyDescription: string;
  propertyName: string;
  status: CustomFieldStatusType;
}

export const CustomFieldForm: React.FC<OwnProps> = (props) => {
  const { formatMessage } = useIntl();

  const formMethods = useFormContext<CustomFieldFormValues>();
  const { control, watch, setValue } = formMethods;

  const status = watch('status');

  const disabled = props.disabled || props.customField?.isSystemField;

  return <>
    {props.customField?.isSystemField && (
      <Alert square severity='info'>{formatMessage({ id: 'Only the options of a system field can be modified.' })}</Alert>
    )}

    {status === CustomFieldStatusType.Inactive && (<Alert severity='warning'>{formatMessage({ id: 'The field is inactive.' })} </Alert>)}

    <Stack spacing={3} px={2} py={3}>
      <Controller
        control={control}
        name='propertyName'
        rules={{ required: formatMessage({ id: 'The field name is required' }) }}
        render={({ field, fieldState }) => (
          <TextField
            required
            disabled={disabled}
            label={formatMessage({ id: 'Label' })}
            {...field}
            error={fieldState.error?.message != null}
            helperText={fieldState.error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name='propertyDescription'
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            disabled={disabled}
            label={formatMessage({ id: 'Description' })}
            error={fieldState.error?.message != null}
            helperText={fieldState.error?.message}
          />
        )}
      />
      {props.fieldType === CustomFieldType.String &&
        <Controller
          control={control}
          name='numberOfLines'
          render={({ field }) => (
            <TextField
              {...field}
              select
              label={formatMessage({ id: 'Number of lines' })}
              disabled={disabled}
            >
              {_.range(1, 11).map((value, index) => (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      }
      <Controller
        control={control}
        name='isRequired'
        render={({ field }) => (
          <FormControlLabel
            control={
              <Switch
                disabled={disabled}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />}
            label={formatMessage({ id: 'Required' })}
          />

        )}
      />
      <Controller
        control={control}
        name='status'
        render={({ field }) => (
          <FormControlLabel
            control={
              <Switch
                disabled={disabled}
                checked={field.value === CustomFieldStatusType.Active}
                onChange={(event, checked) => setValue('status', checked ? CustomFieldStatusType.Active : CustomFieldStatusType.Inactive, { shouldDirty: true })}
              />
            }
            label={formatMessage({ id: 'Active' })}
          />
        )}
      />
      {(props.fieldType === CustomFieldType.Lookup || props.fieldType === CustomFieldType.Selection) &&
        <Controller
          control={control}
          name='allowMultipleValues'
          render={({ field }) => (
            <FormControlLabel
              label={formatMessage({ id: 'Multiple choice' })}
              control={(
                <Switch
                  disabled={disabled}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          )}
        />
      }
    </Stack>

    {(props.fieldType === CustomFieldType.Selection) && <>
      <Divider />
      <DragAndDropOptions />
    </>}
  </>;
};