import { ListItemButton, ListItemButtonProps, ListItemText } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { SupportedLocale } from '../../../../../../../locales';
import { currentLocaleState } from '../../../../../atoms';

interface OwnProps {
  locale: SupportedLocale;
}

export type LanguageSwitcherButtonProps =
  & ListItemButtonProps
  & OwnProps;

export const LanguageSwitcherButton: React.FC<LanguageSwitcherButtonProps> = (props) => {
  const { formatMessage } = useIntl();
  const [currentLocaleCode, setCurrentLocaleCode] = useRecoilState(currentLocaleState);
  const { locale, ...listItemButtonProps } = props;

  const changeLocale = () => {
    setCurrentLocaleCode(locale.code);
    location.reload();
  };

  return (
    <ListItemButton
      selected={currentLocaleCode === locale.code}
      onClick={changeLocale}
      {...listItemButtonProps}
    >
      <ListItemText primary={formatMessage(locale.nameDescriptor)} />
    </ListItemButton>
  );
};