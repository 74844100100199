import { useQueryClient } from '@tanstack/react-query';
import { useAddActivityMutation, useEditActivityMutation } from 'gql';
import { ActivityFormValues } from './components/ActivityDialog';

interface Inputs {
  onSuccess?: () => void;
}

export const useActivityMutations = ({ onSuccess }: Inputs) => {
  const queryClient = useQueryClient();

  const addActivityMutation = useAddActivityMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['findActivities']);
      queryClient.invalidateQueries(['findIndividuals']);
      onSuccess?.();
    }
  });

  const editActivityMutation = useEditActivityMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['findActivities']);
      queryClient.invalidateQueries(['findIndividuals']);
      queryClient.invalidateQueries(['getActivityById', { 'id':  variables.input.id }]);
      onSuccess?.();
    }
  });

  const isSubmitting = (addActivityMutation.isLoading || editActivityMutation.isLoading);

  const removeReactHookFormUniqueId = <T extends { uniqueId?: string; }>(value: T) => {
    const { uniqueId, ...valueWithoutUniqueId } = value;
    return valueWithoutUniqueId;
  };

  const submitActivity = ({ values, activityType, individuals, realities, unknownGroups, distributions }: ActivityFormValues, editingInterventionId?: number) => {
    realities = realities?.map(removeReactHookFormUniqueId) ?? [];
    individuals = individuals?.map(removeReactHookFormUniqueId) ?? [];
    unknownGroups = unknownGroups?.map(removeReactHookFormUniqueId) ?? [];
    distributions = distributions?.map(removeReactHookFormUniqueId) ?? [];

    if (editingInterventionId) {
      editActivityMutation.mutate({ input: { id: editingInterventionId, values, individuals, realities, unknownGroups, distributions } });
    } else {
      if (!activityType) return;
      addActivityMutation.mutate({ input: { type: activityType, values, individuals, realities, unknownGroups, distributions } });
    }
  };

  return { submitActivity, isSubmitting };
};