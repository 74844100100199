import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { allSupportedLocales } from '../../../locales';
import { currentLocaleState } from '../atoms';

interface ErrorWithCode extends Error {
  code: string;
}

export const I18nProvider: React.FC<{ children?: React.ReactNode; }> = (props) => {
  const currentLocaleCode = useRecoilValue(currentLocaleState);
  const currentLocale = allSupportedLocales.find(locale => locale.code === currentLocaleCode);

  const ignoreMissingTranslations = (error: ErrorWithCode) => {
    if (error.code !== ReactIntlErrorCode.MISSING_TRANSLATION)
      throw error;
  };

  return (
    <IntlProvider
      locale={currentLocaleCode}
      messages={currentLocale?.messages}
      onError={ignoreMissingTranslations}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLocale?.code.toLowerCase() ?? 'fr-ca'}>
        {props.children}
      </LocalizationProvider>
    </IntlProvider >
  );
};