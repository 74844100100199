import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AddTenantInput, Tenant, useAddTenantMutation, useEditTenantMutation } from 'gql';
import { Close } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { SlideDialog } from 'components/Dialogs/SlideDialog';
import { OrganizationConfigurationStep } from './components/OrganizationConfigurationStep';
import { InviteAdminConfigurationStep } from './components/InviteAdminConfigurationStep';
import { useNotification } from 'hooks/useNotification';

interface IProps {
  organization?: Tenant;
  open: boolean,
  handleClose: () => void;
}

type StepAction = {
  header: string,
  render: (onSubmitForm: (organizationData: AddTenantInput) => void, setCurrentStep: (newStep: number) => void, onClose: () => void) => React.ReactNode;
};

export const OrganizationDialog: React.FC<IProps> = (props) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const { notifySuccess } = useNotification();

  const form = useForm<AddTenantInput>({
    defaultValues: {
      tenantInput: {
        name: '',
        identifier: '',
        region: '',
        town: '',
        telephone: '',
        extensionTelephone: '',
        email: ''
      },
      adminInput: {
        email: '',
        firstName: '',
        lastName: '',
        teamIds: []
      }
    }
  });

  const { reset } = form;

  const onClose = () => {
    props.handleClose();
    setCurrentStep(0);
    reset();
  };

  const addOrganizationMutation = useAddTenantMutation({
    onSuccess: () => {
      onClose();
      notifySuccess(formatMessage({ id: 'Organization created successfully. Please allow some time for the database to update.' }));
      queryClient.invalidateQueries(['findTenants']);
    }
  });

  const updateOrganizationMutation = useEditTenantMutation({
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries(['getTenantsById']);
    }
  });

  const isSubmittingOrganisation = (addOrganizationMutation.isLoading || updateOrganizationMutation.isLoading);


  const submitForm = (organizationFormData: AddTenantInput) => {
    if (props.organization?.id) {
      updateOrganizationMutation.mutate({
        input: {
          id: props.organization.id,
          name: organizationFormData.tenantInput.name,
          region: organizationFormData.tenantInput.region,
          telephone: organizationFormData.tenantInput.telephone,
          town: organizationFormData.tenantInput.town,
          email: organizationFormData.tenantInput.email,
          extensionTelephone: organizationFormData.tenantInput.extensionTelephone,
        }
      });
    } else {
      addOrganizationMutation.mutateAsync({ input: organizationFormData });
    }
  };

  const organizationSteps = useMemo(() => {
    let steps: StepAction[] = [{
      header: props.organization ? formatMessage({ id: 'Edit Organization' }) : formatMessage({ id: 'Add Organization' }),
      render: (onSubmitForm: (organizationData: AddTenantInput) => void, setCurrentStep: (newStep: number) => void, onClose: () => void) =>
        <OrganizationConfigurationStep
          organization={props.organization}
          changeStep={setCurrentStep}
          isDisable={isSubmittingOrganisation}
          onSubmit={onSubmitForm}
          handleClose={onClose}
        />
    }];

    if (!props.organization) {
      steps = steps.concat([{
        header: formatMessage({ id: 'Invite a Tenant Administrator' }),
        render: (onSubmitForm: (organizationData: AddTenantInput) => void, setCurrentStep: (newStep: number) => void, onClose: () => void) =>
          <InviteAdminConfigurationStep
            changeStep={setCurrentStep}
            isDisable={isSubmittingOrganisation}
            onSubmit={onSubmitForm}
            handleClose={onClose}
          />
      }]);
    }
    return steps;
  }, [props.organization, formatMessage, isSubmittingOrganisation]);

  const [currentStepIndex, setCurrentStep] = useState(0);

  useEffect(() => {
    if (props.organization) {
      reset({
        tenantInput: {
          name: props.organization.name ?? '',
          region: props.organization.region ?? '',
          town: props.organization.town ?? '',
          telephone: props.organization.telephone ?? '',
          identifier: props.organization.identifier ?? '',
          extensionTelephone: props.organization.extensionTelephone ?? '',
          email: props.organization.email ?? ''
        }
      });
    }
    else {
      reset();
    }
  }, [reset, props.organization]);

  return (
    <SlideDialog
      open={props.open}
      onClose={onClose}
      topbar={{
        leftIcon: (< Close />),
        leftIconAction: onClose,
        title: organizationSteps[currentStepIndex].header,
      }}
    >
      <FormProvider {...form}>
        {organizationSteps[currentStepIndex].render(submitForm, setCurrentStep, onClose)}
      </FormProvider>
    </SlideDialog >
  );
};