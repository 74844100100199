import dayjs from 'dayjs';
import { TimeRange } from './components/DropdownFilter';
import { Filter } from './hooks/useGetActivitiesFilter';
import { UseQueryOptions } from '@tanstack/react-query';
import { OneHourInMilliseconds } from '../../utils/timeUtils';

export const getPreviousPeriodFilter = (currentPeriodFilter: Filter, timeFilter: TimeRange) => {
  const period = dayjs(currentPeriodFilter.max).diff(currentPeriodFilter.min, 'day');
  const filter = { ...currentPeriodFilter };

  if (timeFilter === TimeRange.beginFiscalYear) {
    filter.max = dayjs(currentPeriodFilter.max).subtract(1, 'year').endOf('day').utc().toISOString();
    filter.min = dayjs(currentPeriodFilter.min).subtract(1, 'year').startOf('day').utc().toISOString();
  }
  else {
    filter.max = currentPeriodFilter.min;
    filter.min = dayjs(currentPeriodFilter.min).subtract(period, 'day').startOf('day').utc().toISOString();
  }

  return filter;
};

export const calculateActivitiesCountDelta = (current: number, previous: number) => {
  if (previous === 0) {
    return undefined;
  }

  return Math.floor((current - previous) / previous * 100);
};

export const calculateItemsDelta = (current: number, previous: number) => {
  if (previous === 0) {
    return null;
  }

  return Math.floor((current - previous) / previous);
};

export const dashboardQueryOptions = <TQuery>() : UseQueryOptions<TQuery> => ({ staleTime: OneHourInMilliseconds * 12, cacheTime: OneHourInMilliseconds * 13 });