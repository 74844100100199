import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RealityInput, useRealitiesDataQuery } from '../../../../../../../gql';

interface Props {
  stepIndex: number;
  activeStep: number;
  onStepComplete: () => void;
}

export const AccompanimentStep: React.FC<Props> = () => {
  const { data } = useRealitiesDataQuery();

  const { control } = useFormContext<RealityInput>();

  const availableOptions = data?.referrals;

  return (
    <Controller
      name="accompanimentIds"
      control={control}
      render={({ field }) => (
        <Stack spacing={4}>
          <FormGroup>
            {availableOptions?.map((accompaniment, index) => (
              <FormControlLabel
                key={accompaniment.id}
                name={`${field.name}[${index}]`}
                control={<Checkbox checked={field.value && field.value.includes(accompaniment.id)} />}
                onChange={(e, checked) => {
                  if (checked) {
                    field.onChange([...field.value, accompaniment.id]);
                  } else {
                    field.onChange(
                      field.value.filter((item) => item !== accompaniment.id)
                    );
                  }
                }}
                label={accompaniment.name} />
            ))}
          </FormGroup>
        </Stack>
      )}
    />
  );
};