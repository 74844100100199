import React from 'react';
import { UsersTable, UsersTableProps } from 'components';
import { ApplicationUser, Team } from '../../../../gql';
import { useNavigate } from 'react-router-dom';
import { getTenantIdentifier } from 'utils/tenantUtils';

type TeamInfo = Pick<Team, 'id' | 'name' | 'description'>;
type TableUser = Pick<ApplicationUser, 'id' | 'displayName' | 'email' | 'status' | 'roles'> & { teams: TeamInfo[]};

interface OwnProps extends Omit<UsersTableProps, 'onViewDetails' | 'onSearch' | 'users'> {
  users: TableUser[];
}

export const UsersTableWithDialog: React.FC<OwnProps> = ({ users, ...usersTableProps }) => {
  const navigate = useNavigate();

  const handleOnCellClick = (userId: string) => {
    navigate(`/${getTenantIdentifier()}/users/${userId}`);
  };

  return (
    <UsersTable
      {...usersTableProps}
      onViewDetails={handleOnCellClick}
      users={users}
    />
  );
};