import { Draggable, DraggableProps } from '@hello-pangea/dnd';
import { DragIndicator } from '@mui/icons-material';
import { Paper, Stack, Typography, Divider, ListItemButton, ListItem } from '@mui/material';
import { NewButton } from 'components';
import { FieldSectionDto } from 'gql';
import React from 'react';

type Section = Pick<FieldSectionDto, 'id' | 'name'>;

interface Props extends Omit<DraggableProps, 'children'> {
  children?: React.ReactNode;
  section: Section;
  onClick: (sectionId: number) => void;
  onClickNewFieldButton?: (sectionId: number) => void;
  disable?: boolean;
}

export const DraggableSection: React.FC<Props> = ({ children, section, onClick, onClickNewFieldButton, disable, ...props }) => {
  if (disable) {
    return (
      <Paper>
        {children}
      </Paper>
    );
  }

  return (
    <Draggable {...props}>
      {(provided) => (
        <Paper key={section.id.toString()} ref={provided.innerRef} {...provided.draggableProps} sx={{ mb: 2 }}>
          <ListItem
            disablePadding
            secondaryAction={onClickNewFieldButton &&
              <>
                <NewButton
                  iconButton
                  onClick={() => onClickNewFieldButton(section.id)}
                />
              </>
            }
          >
            <ListItemButton onClick={() => onClick(section.id)} sx={{ py: 2 }}>
              <Stack direction='row' alignItems='center' spacing={2}>
                <Stack {...provided.dragHandleProps} alignItems='center'>
                  <DragIndicator />
                </Stack>
                <Typography variant='h5'>
                  {section.name}
                </Typography>
              </Stack>
            </ListItemButton>
          </ListItem>
          <Divider />

          {children}
        </Paper>
      )}
    </Draggable >
  );
};