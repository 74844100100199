import { Draggable, DraggableProps, DraggableStyle } from '@hello-pangea/dnd';
import { DragHandle } from '@mui/icons-material';
import { Chip, darken, lighten, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { StatusChip } from 'components';
import { CustomFieldDto, SystemFieldId } from 'gql';
import { fieldDescriptionMessages } from 'modules/customizations/messages';
import { CustomFieldStatusToStatus } from 'modules/customizations/types';
import React from 'react';
import { useIntl } from 'react-intl';

type CustomField = Pick<CustomFieldDto, 'name' | 'description' | 'isRequired' | 'isSystemField' | 'customFieldType' | 'status' | 'systemFieldId'>;

interface Props extends Omit<DraggableProps, 'children'> {
  field: CustomField;
  divider?: boolean;
  onClick: () => void;
}

export const DraggableFieldListItem: React.FC<Props> = ({ divider, field, onClick, ...props }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const getDraggableStyle = (isDragging: boolean, draggableStyle?: DraggableStyle) => ({
    display: field.isSystemField && field.systemFieldId === SystemFieldId.RealityAccompaniments ? 'none' : '',
    background: isDragging ?
      (theme.palette.mode === 'light' ? darken(theme.palette.common.white, 0.04) : lighten(theme.palette.background.paper, 0.2))
      :
      (theme.palette.mode === 'light' ? theme.palette.common.white : 'inherit'),
    ...draggableStyle
  });

  const getCustomFieldName = () => {
    if (field.isSystemField && field.systemFieldId === SystemFieldId.RealityReferrals) {
      return formatMessage({ id: 'Reference places/accompaniments' });
    }
    else {
      return field.name + (field.isRequired ? ' *' : '');
    }
  };

  return (
    <Draggable {...props}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          divider={!snapshot.isDragging && divider}
          dense
          disablePadding
          sx={getDraggableStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <ListItemButton disableRipple>
            {!props.isDragDisabled &&
              <ListItemIcon {...provided.dragHandleProps}>
                <DragHandle />
              </ListItemIcon>
            }
            <ListItemText
              primary={getCustomFieldName()}
              secondary={formatMessage(fieldDescriptionMessages[field.customFieldType])}
              onClick={onClick}
            />
            {field.isSystemField ? (
              <Chip size='small' color='secondary' label={formatMessage({ id: 'System' })} />
            ) : (
              <StatusChip status={CustomFieldStatusToStatus[field.status]} size='small' />
            )}
          </ListItemButton>
        </ListItem>
      )}
    </Draggable>
  );
};