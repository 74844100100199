import React from 'react';
import { Box } from '@mui/material';
import { LanguageSwitcher } from './LanguageSwitcher';
import { SectionTitle } from '../SectionTitle';
import { useIntl } from 'react-intl';

export const LanguageSettingsSection: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Box padding={2} gap={2}>
      <SectionTitle title={formatMessage({ id: 'Language' })} />
      <LanguageSwitcher />
    </Box>
  );
};