import React from 'react';
import { TimeRange } from '../DropdownFilter';
import { ActivitiesCount, IndividualsCount } from '../CountStatsSection';
import { Stack, useTheme } from '@mui/material';
import { ActivityChart } from '../ActivitiesChart';
import { LatestActivitiesTable, LatestIndividualsTable } from '../Tables';
import { useGetActivitiesFilter } from 'modules/home/hooks/useGetActivitiesFilter';

interface OwnProps {
  timeRangeFilter: TimeRange
}

export const StreetOutreachWorkerView: React.FC<OwnProps> = ({ timeRangeFilter }) => {
  const theme = useTheme();
  const { activitiesFilter } = useGetActivitiesFilter(timeRangeFilter);

  return (
    <Stack gap={3}>
      <ActivitiesCount
        activitiesFilter={activitiesFilter}
        selectedTimeRange={timeRangeFilter}
      />
      <Stack direction={{ xs: 'column', md: 'row' }} gap={2} mb={3} minHeight={theme.spacing(40)}>
        <Stack width='50%'>
          <LatestActivitiesTable
            activitiesFilter={activitiesFilter}
          />
        </Stack>
        <Stack width='50%'>
          <ActivityChart
            activitiesFilter={activitiesFilter}
            selectedTimeRange={timeRangeFilter}
          />
        </Stack>
      </Stack>
      <IndividualsCount
        activitiesFilter={activitiesFilter}
      />
      <Stack direction={{ xs: 'column', md: 'row' }} gap={2} minHeight={theme.spacing(40)}>
        <Stack width='50%'>
          <LatestIndividualsTable
            activitiesFilter={activitiesFilter}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};