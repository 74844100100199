import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { UserStatusType } from 'gql';
import { useIntl } from 'react-intl';

interface OwnProps {
  status: UserStatusType
}

type UserChipStatus =
  & ChipProps
  & OwnProps;

export const UserChipStatus:React.FC<UserChipStatus> = (props) => {
  const { status, ...chipProps } = props;
  const { formatMessage } = useIntl();

  const chipStatusMap = new Map<UserStatusType, ChipProps>([
    [UserStatusType.Inactive,{ label: formatMessage({ id: 'Inactive' }), color: 'warning' }],
    [UserStatusType.Active,{ label: formatMessage({ id: 'Active' }), color: 'success' }],
    [UserStatusType.InvitePending,{ label: formatMessage({ id: 'Invitation pending' }), color: 'primary' }],
  ]);

  const unknownChipStatus:ChipProps = { label: formatMessage({ id: 'Unknown' }), color: 'warning' };
  const currentChipStatus = chipStatusMap.get(status) ?? unknownChipStatus;

  return (
    <Chip {...currentChipStatus} {...chipProps}/>
  );
};