import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Divider, List, Paper, Stack, Typography } from '@mui/material';
import { ResponsiveButton } from 'components/Buttons/ResponsiveButton';
import { Add, Edit } from '@mui/icons-material';

interface OwnProps {
  title: string,
  children?: ReactNode;
  onAdd: () => void;
}

export const SectionPaper: React.FC<OwnProps> = ({ title, children, onAdd: onClickEditButton }) => {
  const { formatMessage } = useIntl();

  return (
    <Paper>
      <Stack justifyContent='space-between' direction='row' alignItems='center' p={2}>
        <Typography variant='h5'>
          {title}
        </Typography>

        <ResponsiveButton
          variant='outlined'
          mobileIcon={(<Add />)}
          buttonText={formatMessage({ id: 'Add' })}
          onClick={onClickEditButton}
        />
      </Stack>
      <Divider />

      <List disablePadding>
        {children}
      </List>
    </Paper>
  );
};