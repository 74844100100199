import React from 'react';
import { Stack } from '@mui/material';

interface OwnProps {
  children?: React.ReactNode;
}

export type PageContentProps =
  & OwnProps;

export const PageContent: React.FC<PageContentProps> = (props) => {
  return (
    <Stack component="main" direction="row" p={{ xs: 1, md: 2, lg: 4 }} pt={{ lg: 2 }}>
      {props.children}
    </Stack>
  );
};