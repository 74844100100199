import { GenericCustomFieldInputProps } from './CustomFieldInput';
import React from 'react';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

export const BirthdayInput: React.FC<GenericCustomFieldInputProps> = (props) => {
  const { formatMessage } = useIntl();
  const { value, onChange } = props;

  const currentYear = dayjs().startOf('year');

  const valueAsDayjs = value && dayjs(value).isValid() ? dayjs(value) : undefined;
  const age = valueAsDayjs ? currentYear.diff(valueAsDayjs, 'year').toString() : '';

  const onAgeChange = (newValue: string) => {
    const asNumber = Number(newValue);
    if (!newValue || Number.isNaN(asNumber)) {
      onChange('');
      return;
    }

    if (asNumber > 150) {
      onChange(currentYear.subtract(150, 'years').format('YYYY-MM-DD'));
      return;
    }

    onChange(currentYear.subtract(asNumber, 'years').format('YYYY-MM-DD'));
  };

  return (
    <TextField
      {...props}
      fullWidth
      label={formatMessage({ id: 'Age' })}
      value={age}
      onChange={e => onAgeChange(e.target.value)}
      inputProps={{ inputMode: 'numeric' }}
    />
  );
};