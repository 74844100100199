import React from 'react';
import { CardProps, Skeleton, Stack, Typography } from '@mui/material';
import { StyledCard } from './CountStatsTile';

interface OwnProps {
  title?: string
  delta?: boolean
}

type CountStatTileSkeletonProps =
  & OwnProps
  & CardProps;

export const CountStatTileSkeleton: React.FC<CountStatTileSkeletonProps> = ({ title, delta, ...cardProps }) => {
  return (
    <StyledCard {...cardProps}>
      <Stack px={2} py={1}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='body1'><Skeleton width="3ch" /></Typography>
          {delta &&
            <Typography variant='subtitle2'>
              {'-%'}
            </Typography>
          }
        </Stack>
        <Typography variant='body2'>{title ?? <Skeleton variant='text' />}</Typography>
      </Stack>
    </StyledCard>
  );
};