import React, { useCallback, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Outlet, useNavigate } from 'react-router-dom';
import { useNotification } from 'hooks/useNotification';
import { useIntl } from 'react-intl';
import { ApiError, DomainError } from 'gql/graphql-fetcher';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
const isDevtoolsShown = process.env.REACT_APP_QUERY_DEVTOOLS === 'true';

export const QueryProvider: React.FC = () => {
  const { formatMessage } = useIntl();
  const { notifyFailure } = useNotification();
  const navigate = useNavigate();

  const handleError = useCallback((error: unknown) => {
    if (error instanceof ApiError) {
      if (error.errorCode === 401) {
        navigate('/error/401', { replace: true });
      } else if (error.errorCode === 500) {
        navigate('/error/500');
      } else {
        notifyFailure(`${error.errorCode} - ${error.message}`);
      }
    }
    else if (error instanceof DomainError) {
      notifyFailure(error.message);
    } else {
      notifyFailure(formatMessage({ id: 'An unexpected error has occurred.' }) + error);
    }
  }, [navigate, notifyFailure, formatMessage]);

  const client = useMemo(() => new QueryClient({
    defaultOptions: {
      queries: {
        onError: handleError,
        retry: false,
        staleTime: 1000 * 60 * 5, // 5 minutes
        refetchOnWindowFocus: false
      },
      mutations: {
        onError: handleError,
        retry: false
      }
    }
  }), [handleError]);

  return (
    <QueryClientProvider client={client}>
      {isDevtoolsShown && <ReactQueryDevtools initialIsOpen={false} />}
      <Outlet />
    </QueryClientProvider>
  );
};