import React from 'react';
import { Add } from '@mui/icons-material';
import { ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, styled } from '@mui/material';


interface OwnProps extends ListItemButtonProps {
  label: string,
  children?: React.ReactNode;
  onClick: () => void;
}

const ListItemButtonStyled = styled(ListItemButton)<ListItemButtonProps>(({ theme }) => ({
  padding: theme.spacing(2),
  '& .MuiListItemIcon-root:first-of-type': {
    minWidth: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    color: theme.palette.primary.main,
  },
  '& .MuiTypography-root': {
    fontWeight: 500,
  }
}));

export const ListItemAddButton: React.FC<OwnProps> = (props) => {
  const { label, children, onClick, ...listItemButtonProps } = props;

  return (
    <ListItemButtonStyled onClick={onClick} divider {...listItemButtonProps}>
      <ListItemIcon>
        <Add />
      </ListItemIcon>
      <ListItemText primary={label} />
      <ListItemIcon>
        {children}
      </ListItemIcon>
    </ListItemButtonStyled>
  );
};