import { CustomFieldType } from 'gql';
import { defineMessages, MessageDescriptor } from 'react-intl';

export const customFieldTypeMessages: Record<CustomFieldType, MessageDescriptor> = defineMessages({
  [CustomFieldType.String]: { id: 'Text' },
  [CustomFieldType.Numerical]: { id: 'Numerical' },
  [CustomFieldType.Date]: { id: 'Date' },
  [CustomFieldType.Selection]: { id: 'Selection' },
  [CustomFieldType.Lookup]: { id: 'Lookup' },
});

export const fieldDescriptionMessages:Record<CustomFieldType, MessageDescriptor> = defineMessages({
  [CustomFieldType.Date]: { id: 'Date field' },
  [CustomFieldType.Numerical]: { id: 'Numerical field' },
  [CustomFieldType.Selection]: { id: 'Selection Field' },
  [CustomFieldType.String]: { id: 'Text field' },
  [CustomFieldType.Lookup]: { id: 'Lookup field' }
});