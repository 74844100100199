import { ListItem, ListItemText, ListItemButton, Checkbox } from '@mui/material';
import { ResponsiveSelect } from 'components';
import { CustomFieldDto, CustomFieldOptionDto } from 'gql';
import React from 'react';
import { GenericCustomFieldInputProps } from './CustomFieldInput';

type CustomField = Pick<CustomFieldDto, 'isRequired' | 'allowMultipleValues'> & {
  options: Pick<CustomFieldOptionDto, 'id' | 'name'>[];
};

interface Props extends GenericCustomFieldInputProps {
  value?: string | null;
  onChange: (value: string) => void;
  field: CustomField;
}

export const SelectionInput: React.FC<Props> = ({ value, onChange, field, ...customFieldProps }) => {
  const { allowMultipleValues, isRequired, options } = field;

  const selectedIds = value?.split(',');
  const selectedOptions = options.filter(o => selectedIds?.includes(o.id.toString()));

  return (
    <ResponsiveSelect
      {...customFieldProps}
      getLabel={o => o.name}
      selectedValues={selectedOptions}
      availableValues={options}
      onChange={options => onChange(options.map(o => o.id.toString()).join(','))}
      getKey={o => o.id.toString()}
      searchFilter={(o, search) => o.name.toLowerCase().includes(search.toLowerCase())}
      renderListItem={(o, selected, onClick) => (
        <ListItem disablePadding sx={{ backgroundColor: selected ? 'action.hover' : undefined }} secondaryAction={(
          <Checkbox checked={selected} onClick={onClick} />
        )}>
          <ListItemButton onClick={onClick}>
            <ListItemText primary={o.name} />
          </ListItemButton>
        </ListItem>
      )}

      multiple={allowMultipleValues}
      required={isRequired}
    />
  );
};