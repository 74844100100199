import { FormHelperText, Stack, } from '@mui/material';
import { ResponsiveDatePicker } from 'components';
import dayjs from 'dayjs';
import { DateFilterInput as DateFilter } from 'gql';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  fromLabel?: string;
  toLabel?: string;
  value?: DateFilter | null;
  onChange: (value: DateFilter) => void;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

export const DateFilterField: React.FC<Props> = ({ fromLabel, toLabel, value, onChange, error, helperText, required, disabled }) => {
  const { formatMessage } = useIntl();

  return (
    <Stack>
      <Stack direction={'row'} spacing={2} alignItems='center'>
        <ResponsiveDatePicker
          label={fromLabel ?? formatMessage({ id: 'From' })}
          fullWidth
          value={value?.from}
          maxDate={value?.to ? dayjs(value.to) : undefined}
          onChange={from => {
            onChange({ from, to: value?.to });
          }}
          error={error}
          disabled={disabled}
          required={required}
        />

        <ResponsiveDatePicker
          label={toLabel ?? formatMessage({ id: 'To' })}
          fullWidth
          value={value?.to}
          minDate={value?.from ? dayjs(value.from) : undefined}
          onChange={to => {
            onChange({ from: value?.from, to });
          }}
          error={error}
          disabled={disabled}
          required={required}
        />
      </Stack>

      {helperText && (
        <FormHelperText error={error}>
          {helperText}
        </FormHelperText>
      )}
    </Stack>
  );
};