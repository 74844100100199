import { Button } from '@mui/material';
import { EmptyState, SectionPaper } from 'components';
import { SystemFieldId, useGetOptionsBySystemFieldIdQuery } from 'gql';
import React, { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getSystemFieldValue } from 'utils/customFieldsUtils';
import { UnknownGroupDialog, UnknownGroupFormValues } from '../../../../../../individuals/views';
import { ActivityFormValues } from '../../../ActivityDialog';
import { UnknownGroupListItem } from './UnknownGroupListItem';

type FieldArrayValues = UnknownGroupFormValues & {
  uniqueId: string;
};

export const ManageUnknownGroupsSection: React.FC = () => {
  const { formatMessage } = useIntl();

  const { control, clearErrors } = useFormContext<ActivityFormValues>();
  const { fields: unknownGroups, append, update, remove } = useFieldArray({
    name: 'unknownGroups',
    keyName: 'uniqueId',
    control
  });

  const { data: genderOptionsData } = useGetOptionsBySystemFieldIdQuery({ systemFieldId: SystemFieldId.Gender });
  const genderOptions = genderOptionsData?.options ?? [];

  const [isUnknownGroupDialogOpen, setIsUnknownGroupDialogOpen] = useState(false);
  const [unknownGroupToEdit, setUnknownGroupToEdit] = useState<FieldArrayValues>();

  const removeUnknownGroup = (unknownGroup: FieldArrayValues) => {
    const index = unknownGroups.indexOf(unknownGroup);
    remove(index);
  };

  const openEditDialog = (unknownGroup: FieldArrayValues) => {
    setUnknownGroupToEdit(unknownGroup);
    setIsUnknownGroupDialogOpen(true);
  };

  const onSubmitSuccess = (unknownIndividual: UnknownGroupFormValues) => {
    if (unknownGroupToEdit) {
      const editedIndividualIndex = unknownGroups.indexOf(unknownGroupToEdit);
      update(editedIndividualIndex, unknownIndividual);
      setUnknownGroupToEdit(undefined);
    } else {
      append(unknownIndividual);
    }
    setIsUnknownGroupDialogOpen(false);
    if (unknownIndividual.values.length > 0) {
      clearErrors('individuals');
    }
  };

  const onCancel = () => {
    setUnknownGroupToEdit(undefined);
    setIsUnknownGroupDialogOpen(false);
  };

  const getGenderValue = (value: string) => genderOptions.find(option => option.id === Number(value))?.name ?? value;

  return (
    <>
      <SectionPaper
        title={formatMessage({ id: 'Unknown individuals' })}
        onAdd={() => setIsUnknownGroupDialogOpen(true)}
      >
        {unknownGroups.map(unknownGroup => (
          <UnknownGroupListItem
            key={unknownGroup.uniqueId}
            numberOfIndividuals={Number(getSystemFieldValue(SystemFieldId.NumberOfUsers, unknownGroup.values))}
            averageAge={Number(getSystemFieldValue(SystemFieldId.AverageAge, unknownGroup.values))}
            gender={getGenderValue(getSystemFieldValue(SystemFieldId.Gender, unknownGroup.values) ?? '') ?? ''}
            onClick={() => openEditDialog(unknownGroup)}
            onDelete={() => removeUnknownGroup(unknownGroup)}
          />
        ))}

        {unknownGroups.length === 0 &&
          <EmptyState
            title={formatMessage({ id: 'No unknown individuals' })}
            subtitle={formatMessage({ id: 'Add a group of unknown individuals associated with this activity.' })}
            callToActionComponent={(
              <Button variant='contained' onClick={() => setIsUnknownGroupDialogOpen(true)}>
                {formatMessage({ id: 'Add unknown individuals' })}
              </Button>
            )}
          />
        }
      </SectionPaper>

      <UnknownGroupDialog
        individual={unknownGroupToEdit}
        isOpen={isUnknownGroupDialogOpen}
        onCancel={onCancel}
        onSubmitSuccess={onSubmitSuccess}
      />
    </>
  );
};

