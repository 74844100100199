import { ActivityType, EntityType, FieldValueInput } from 'gql';

export const ActivityTypeColor: Record<ActivityType, string> = {
  [ActivityType.Distribution]: '#0288D1',
  [ActivityType.Intervention]: '#76D2DD',
  [ActivityType.Presentation]: '#2E7D32',
  [ActivityType.Routine]: '#F6D50E',
  [ActivityType.Meeting]: '#F57C00'
};

export const isActivityType = (type: EntityType) =>
  type === EntityType.Distribution ||
  type === EntityType.Intervention ||
  type === EntityType.Presentation ||
  type === EntityType.Routine ||
  type === EntityType.Meeting;

export const activityTypeToEntityType: Record<ActivityType, EntityType> = {
  [ActivityType.Distribution]: EntityType.Distribution,
  [ActivityType.Intervention]: EntityType.Intervention,
  [ActivityType.Presentation]: EntityType.Presentation,
  [ActivityType.Routine]: EntityType.Routine,
  [ActivityType.Meeting]: EntityType.Meeting,
};

export type ActivityStep = 'individuals' | 'realities' | 'distributions';

export const activityTypeAvailableSteps: Record<ActivityType, ActivityStep[]> = {
  [ActivityType.Distribution]: ['individuals', 'distributions'],
  [ActivityType.Intervention]: ['individuals', 'distributions', 'realities'],
  [ActivityType.Presentation]: ['individuals', 'distributions'],
  [ActivityType.Routine]: [],
  [ActivityType.Meeting]: [],
};

export interface LookupType {
  id: string;
  customFieldValues: FieldValueInput[];
}
