import React from 'react';
import { DangerZone, DisplayInformation, DisplayInformationRow } from 'components';
import { Team, useDeleteTeamMutation } from 'gql';
import { useIntl } from 'react-intl';
import { Stack, Typography } from '@mui/material';
import { TeamDialog } from '../TeamDialog';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ConfirmButton } from 'components/Buttons';
import { useQueryClient } from '@tanstack/react-query';

interface OwnProps {
  team: Team;
}

export const TeamDetails: React.FC<OwnProps> = (props) => {
  const { formatMessage } = useIntl();
  const { team } = props;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const isDialogVisible = searchParams.get('edit') === 'true';

  const handleOpenDialog = () => {
    setSearchParams({ edit: 'true' });
  };

  const handleCloseDialog = () => {
    searchParams.delete('edit');
    setSearchParams(searchParams);
  };

  const deleteMutation = useDeleteTeamMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['findTeams']);
      navigate(-1);
    }
  });

  return (
    <Stack spacing={3}>
      <DisplayInformation title={formatMessage({ id: 'Information' })} onEdit={handleOpenDialog}>
        <DisplayInformationRow label={formatMessage({ id: 'Name' })}>
          <Typography variant='body2'>
            {team.name}
          </Typography>
        </DisplayInformationRow>

        <DisplayInformationRow label={formatMessage({ id: 'Description' })}>
          <Typography variant='body2'>
            {team.description}
          </Typography>
        </DisplayInformationRow>

        <TeamDialog
          open={isDialogVisible}
          team={team}
          handleClose={handleCloseDialog}
        />
      </DisplayInformation>

      {!team.isDefault && (
        <DangerZone
          rows={[{
            primary: formatMessage({ id: 'Delete this team' }),
            secondary: formatMessage({ id: 'Once deleted, there is no going back.' }),
            button: (
              <ConfirmButton
                confirmText={formatMessage({ id: 'Are you sure you want to delete this team?' })}
                confirmTitle={formatMessage({ id: 'Delete team' })}
                onConfirm={() => deleteMutation.mutate({ input: { id: team.id } })}
              />
            )
          }]}
        />
      )}
    </Stack>
  );
};