import React, { useMemo } from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Tenant } from 'gql';
import { useResponsive } from 'hooks';
import { Chip, Typography } from '@mui/material';
import { DataTable, DataTableProps } from 'components';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

type OrganizationTableProps =
  & Omit<DataTableProps, 'columns' | 'rows' | 'noSearchResultsMessage' | 'noDataMessage'>
  & { organizations: Tenant[]; };

export const OrganizationsTable: React.FC<OrganizationTableProps> = (props) => {
  const { formatMessage } = useIntl();
  const { organizations, ...searchTableProps } = props;
  const { isMobile } = useResponsive();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    const columns: GridColDef<Tenant>[] = [];

    if (isMobile) {
      columns.push({
        field: 'name',
        headerName: formatMessage({ id: 'Name' }),
        flex: 50,
        minWidth: 0,
        renderCell: (params) => (
          <Typography variant='body1' color='info.main' my={2} fontWeight={500}>{params.row.name}</Typography>
        )
      });
    } else {
      columns.push({
        field: 'name',
        headerName: formatMessage({ id: 'Name' }),
        flex: 50,
        minWidth: 0,
        renderCell: (params) => (
          <Typography color='info.main' fontWeight={500}>{params.row.name}</Typography>
        )
      });
      columns.push({
        field: 'region',
        headerName: formatMessage({ id: 'Region' }),
        flex: 25,
      });
      columns.push({
        field: 'town',
        headerName: formatMessage({ id: 'Town' }),
        flex: 25,
      });
    }

    columns.push({
      field: 'isProvisioned',
      headerName: '',
      flex: 50,
      align: 'right',
      renderCell: ({ row }) => (
        <Chip size='small' label={row.isProvisioned ? formatMessage({ id: 'Active' }) : formatMessage({ id: 'Creating' })} color={row.isProvisioned ? 'success' : 'info'} />
      )
    });

    return columns;
  }, [formatMessage, isMobile]);

  const handleOnCellClick = (params: GridCellParams) => {
    if (params.field === 'name') {
      navigate(`${params.id}/details`);
    }
  };

  return (
    <DataTable
      {...searchTableProps}
      columns={columns}
      rows={organizations}
      disableColumnSelector
      getRowHeight={() => isMobile ? 'auto' : undefined}
      hideFooter
      onCellClick={handleOnCellClick}
      noDataMessage={formatMessage({ id: 'There are no organizations yet' })}
      noSearchResultsMessage={formatMessage({ id: 'No organization found' })}
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        }
      }}
      sx={{
        '& .MuiDataGrid-cell:first-of-type': {
          cursor: 'pointer'
        }
      }}
    />
  );
};