import { Alert, Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { EmptyState, SectionPaper } from '../../../../../components';
import { RealityInput } from '../../../../../gql';
import { ActivityFormValues } from '../ActivityDialog';

import { RealityDrawer } from './components/RealityDrawer';
import { RealityListItem } from './components/RealityListItem';

export const RealitiesConfigurationStep: React.FC = () => {
  const { formatMessage } = useIntl();

  const { control, formState: { errors }, clearErrors } = useFormContext<ActivityFormValues>();
  const error = errors.realities;

  const realities = useFieldArray<ActivityFormValues, 'realities', 'uniqueId'>({ control: control, name: 'realities', keyName: 'uniqueId' });

  const [editingReality, setEditingReality] = useState<RealityInput | undefined>(undefined);
  const [isAddingReality, setIsAddingReality] = useState(false);

  const onRealityFormCancel = () => {
    setEditingReality(undefined);
    setIsAddingReality(false);
  };

  const onRealityFormSubmitted = (reality: RealityInput) => {
    const realityIndex = realities.fields.findIndex(p => p.id === reality.id);
    if (realityIndex === -1) {
      realities.append({ ...reality, id: null });
    }
    else {
      realities.update(realityIndex, reality);
    }
    clearErrors('realities');
    setEditingReality(undefined);
    setIsAddingReality(false);
  };

  return (
    <Stack spacing={2}>
      {error?.message && (
        <Alert severity='error'>
          {error.message}
        </Alert>
      )}

      <SectionPaper
        title={formatMessage({ id: 'Realities' })}
        onAdd={() => setIsAddingReality(true)}
      >
        {realities.fields.map((reality, index) => (
          <RealityListItem key={reality.uniqueId} reality={reality} onClick={() => setEditingReality(reality)} onDelete={() => realities.remove(index)} />
        ))}

        {realities.fields.length === 0 && (
          <EmptyState
            title={formatMessage({ id: 'No realities' })}
            subtitle={formatMessage({ id: 'Add a reality to continue' })}
            callToActionComponent={(
              <Button variant='contained' onClick={() => setIsAddingReality(true)}>
                {formatMessage({ id: 'Add Reality' })}
              </Button>)}
          />
        )}
      </SectionPaper>
      <RealityDrawer isOpen={isAddingReality || editingReality != null} editingReality={editingReality} onCancel={onRealityFormCancel} onCompleted={onRealityFormSubmitted} />
    </Stack>
  );
};