import React from 'react';
import { RouteObject } from 'react-router-dom';
import { OrganizationDetailsTab, OrganizationPage, OrganizationsPage } from './views';

export const routes: RouteObject[] = [
  { path: 'organizations', element: <OrganizationsPage /> },
  {
    path: 'organizations/:tenantId',
    element: <OrganizationPage />,
    children: [
      { path: 'details', element: <OrganizationDetailsTab /> },
    ]
  },
];

export default routes;