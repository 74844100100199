import { Theme } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormDrawer } from '../../../../../../components';
import { RealityInput, useRealitiesDataQuery } from '../../../../../../gql';
import { RealitySteps } from './RealitySteps';

interface Props {
  isOpen: boolean;
  editingReality?: RealityInput;
  onCancel: () => void;
  onCompleted: (reality: RealityInput) => void;
}

export const RealityDrawer: React.FC<Props> = ({ isOpen, onCancel, onCompleted, editingReality }) => {
  const { formatMessage } = useIntl();
  const form = useForm<RealityInput>();
  const { isLoading } = useRealitiesDataQuery();
  useEffect(() => form.reset(editingReality ? { ...editingReality }
    : {
      id: undefined,
      specificationId: undefined,
      themeId: undefined,
      referralIds: [],
      accompanimentIds: [],
      approachIds: []
    }
  ), [isOpen, editingReality, form]);

  return (
    <FormDrawer
      open={isOpen}
      isLoading={isLoading}
      isFormDirty={form.formState.isDirty}
      onClose={onCancel}
      sx={{ zIndex: (theme: Theme) => theme.zIndex.modal + 1 }}
      header={editingReality ? formatMessage({ id: 'Modify Reality' }) : formatMessage({ id: 'Add Reality' })}
      disablePadding
    >
      <FormProvider {...form}>
        <div style={{ position: 'relative', height: '100%' }}>
          <RealitySteps onCompleted={onCompleted} isEditMode={Boolean(editingReality)} />
        </div>
      </FormProvider>
    </FormDrawer>
  );
};