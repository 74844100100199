import { Box, BoxProps, styled } from '@mui/material';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useResponsive } from '../../../../../hooks';
import { isNavigationMenuCollapsedState } from '../../../atoms';
import { collapsedMenuWidth, expandedMenuWidth } from './NavigationMenu';

type CollapsibleSpacerProps =
    & BoxProps
    & { collapsed: boolean };

const StyledSpacer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'collapsed'
})<CollapsibleSpacerProps>(({ theme, collapsed }) => ({
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: collapsed ? theme.transitions.duration.leavingScreen : theme.transitions.duration.enteringScreen,
  }),
  marginLeft: collapsed ? `${collapsedMenuWidth}px` : `${expandedMenuWidth}px`,
}));

export const NavigationMenuSpacer: React.FC = () => {
  const isNavigationMenuCollapsed = useRecoilValue(isNavigationMenuCollapsedState);
  const { isMobile } = useResponsive();

  if (isMobile) {
    return null;
  }

  return (
    <StyledSpacer collapsed={isNavigationMenuCollapsed} />
  );
};