import { BuiltInRoles, useGetCurrentUserQuery } from 'gql';
import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';

export const AuthorizedRoutes: React.FC<{
  roles: BuiltInRoles[];
}> = ({ roles }) => {
  const { data: currentUser } = useGetCurrentUserQuery();

  if (!currentUser?.me.roles.some(role => roles.includes(role.name as BuiltInRoles))) {
    return <Navigate to='/error/401' replace />;
  }

  return (
    <Outlet />
  );
};