import { Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import { RealitySpecificationDto, RealityThemeDto, useEditSpecificationsMutation } from 'gql';
import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { SpecificationsFormValues, ThemeSpecificationsForm } from './ThemeSpecificationsForm';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { FormDrawer } from 'components';
import { Save } from '@mui/icons-material';

type Theme = Pick<RealityThemeDto, 'id' | 'name'> & {
  specifications: Pick<RealitySpecificationDto, 'id' | 'name'>[];
};

interface Props {
  themes: Theme[];
}

export const ThemeModifications: React.FC<Props> = ({ themes }) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const [selectedTheme, setSelectedTheme] = useState<Theme | undefined>();

  const form = useForm<SpecificationsFormValues>();
  const { reset, handleSubmit, formState: { isDirty } } = form;

  useEffect(() => {
    if (selectedTheme) {
      reset({ specifications: selectedTheme.specifications.map(s => ({ specificationId: s.id, name: s.name, })) });
    } else {
      reset({ specifications: [] });
    }
  }, [reset, selectedTheme, selectedTheme?.specifications]);

  const editSpecificationsMutation = useEditSpecificationsMutation({
    onSuccess: () => {
      toast.success(formatMessage({ id: 'Specifications updated successfully' }));
      queryClient.invalidateQueries(['findThemesWithSpecifications']);
      setSelectedTheme(undefined);
    }
  });

  const onSubmit = (data: SpecificationsFormValues) => {
    if (!selectedTheme?.id) return;
    editSpecificationsMutation.mutate({
      input: {
        themeId: selectedTheme.id,
        inputs: data.specifications.map(s => ({ id: s.specificationId, name: s.name }))
      }
    });
  };

  return (
    <Paper>
      <Typography variant='h5' p={2}>
        {formatMessage({ id: 'Themes' })}
      </Typography>
      <Divider />

      <List disablePadding>
        {themes.map((theme, index) => (
          <ListItem
            key={theme.id}
            disablePadding
            divider={index !== themes.length - 1}
          >
            <ListItemButton onClick={() => setSelectedTheme(theme)} >
              <ListItemText primary={theme.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <FormDrawer
        showFooter
        isFormDirty={isDirty}
        open={selectedTheme !== undefined}
        onClose={() => setSelectedTheme(undefined)}
        onSave={handleSubmit(onSubmit)}
        header={formatMessage({ id: 'Editing Theme' })}
        disablePadding
      >
        <FormProvider {...form}>
          <ThemeSpecificationsForm
            theme={selectedTheme}
            disabled={editSpecificationsMutation.isLoading}
          />
        </FormProvider>
      </FormDrawer>
    </Paper>
  );
};