import { Chip, Typography } from '@mui/material';
import { CustomFieldValue, FindActivitiesQuery } from 'gql';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { isArray } from 'lodash';
import React from 'react';

type Activity = NonNullable<NonNullable<Pick<FindActivitiesQuery, 'activities'>['activities']>['items']>[0];
type ActivityTableCellValue = string | Date | CustomFieldValue[] | undefined | string[];

export const MultiOptionsCell = ({ value }: GridRenderCellParams<Activity, ActivityTableCellValue>) => {
  if (!value || !isArray(value)) return null;

  let options: string[] = [];
  if (typeof value[0] === 'string') {
    options = value as string[];
  } else {
    options = (value as CustomFieldValue[]).map(v => v.displayString).filter(v => Boolean(v)) ?? [];
  }

  if (options.length === 0) return null;

  if (options.length === 1) return options[0];

  return (
    <>
      <Typography variant='body2' overflow='hidden' textOverflow='ellipsis'>
        {options[0]}
      </Typography>
      {options.length > 1 && (
        <Chip label={`+${options.length - 1}`} size='small' sx={{ ml: 1 }} />
      )}
    </>
  );
};
