import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, styled, Table, TableBody, TableCell, TableRow as MuiTableRow, Typography, Divider } from '@mui/material';
import { Activity, CustomField, CustomFieldOption, CustomFieldValue, SystemFieldId, useFindCustomFieldsQuery, useFindThemesWithSpecificationsQuery, useRealitiesDataQuery } from 'gql';
import React from 'react';
import { useIntl } from 'react-intl';


type FieldValue = Pick<CustomFieldValue, 'displayString'> & {
  field: Pick<CustomField, 'id' | 'systemFieldId'>;
  selectionValues: Pick<CustomFieldOption, 'id'>[];
};

type Reality = Pick<Activity['realities'][0], 'id' | 'themeId' | 'specificationId'> & {
  values: FieldValue[];
};

interface Props {
  realities: Reality[];
}

const TableRow = styled(MuiTableRow)(({ theme }) => ({
  '& td, & th': { borderColor: theme.palette.divider },
  '&:last-child td, &:last-child th': { border: 0 }
}));

export const ActivityRealities: React.FC<Props> = ({ realities }) => {
  const [expanded, setExpanded] = React.useState<number | false>();

  const { formatMessage } = useIntl();

  const themeIds = realities.map(r => r.themeId);
  const { data: themeData } = useFindThemesWithSpecificationsQuery({
    filter: { id: { in: themeIds } }
  });

  const fieldIds = realities.flatMap(r => r.values.map(v => v.field.id));
  const { data: fieldsData } = useFindCustomFieldsQuery({
    filter: { id: { in: fieldIds } }
  });
  const fields = fieldsData?.customFields ?? [];

  const { data: realitiesData } = useRealitiesDataQuery();
  const referralOptions = realitiesData?.referrals ?? [];

  const handleChange = (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    if (isExpanded) {
      setExpanded(panel);
    } else {
      setExpanded(false);
    }
  };

  return <>
    {realities.map((reality, index) => {
      const theme = themeData?.themes.find(t => t.id === reality.themeId);
      const specification = theme?.specifications.find(s => s.id === reality.specificationId);

      const values = reality.values.filter(v => v.field.systemFieldId !== SystemFieldId.RealityAccompaniments);

      const accompaniment = reality.values.filter(v => v.field.systemFieldId === SystemFieldId.RealityAccompaniments).at(0);

      return (
        <Accordion
          key={reality.id}
          expanded={expanded === reality.id}
          onChange={handleChange(reality.id)}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant='h6'>{formatMessage({ id: 'Reality {index}' }, { index: index + 1 })}</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails sx={{ p: 0 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell variant='head'>{formatMessage({ id: 'Theme' })}</TableCell>
                  <TableCell>{theme?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant='head'>{formatMessage({ id: 'Specification' })}</TableCell>
                  <TableCell>{specification?.name}</TableCell>
                </TableRow>
                {values.map(value => (
                  <TableRow key={value.field.id}>
                    <TableCell variant='head'>{fields.find(f => f.id == value.field.id)?.name}</TableCell>
                    <TableCell>{value.displayString}</TableCell>
                  </TableRow>
                ))}
                {(accompaniment && accompaniment.selectionValues.length > 0) && (
                  <TableRow>
                    <TableCell variant='head'>{formatMessage({ id: 'Accompaniments' })}</TableCell>
                    <TableCell>{accompaniment
                      .selectionValues
                      .map(a => referralOptions.find(o => o.id == a.id)?.name)
                      .join(', ')
                    }</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      );
    })}
  </>;
};