import { Stack } from '@mui/material';
import { ActivityIndividualInput, ActivityType, DistributionValueInput, FieldValueInput, GetActivityByIdQuery, RealityInput, SystemFieldId, useFindSectionsQuery, useGetActivityByIdQuery } from 'gql';
import { LookupType, activityTypeAvailableSteps, activityTypeToEntityType } from 'modules/activities/types';
import { CustomFieldInputForm } from 'modules/customizations/components/CustomFieldInputForm';
import { activityAddDialogTitleMessages, activityEditDialogTitleMessages } from 'modules/activities/messages';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getFormValueFromFieldValue } from 'utils/customFieldsUtils';
import { FormDialog } from '../../../../components';
import { ActivityDialogActions } from './ActivityDialogActions/ActivityDialogActions';
import { ActivityDialogTypeSelector } from './ActivityDialogTypeSelector';
import { IndividualsConfigurationStep } from './IndividualsStep';
import { RealitiesConfigurationStep } from './RealitiesStep/RealitiesConfigurationStep';
import { UnknownGroupFormValues } from 'modules/individuals/views';
import { DistributionConfigurationStep } from './DistributionsStep';

export interface ActivityFormValues {
  activityType?: ActivityType;
  unknownGroups: (UnknownGroupFormValues & { uniqueId?: string; })[];
  individuals: (ActivityIndividualInput & { uniqueId?: string; })[];
  values: FieldValueInput[];
  realities: (RealityInput & { uniqueId?: string; })[];
  distributions: (DistributionValueInput & { uniqueId?: string; })[];
}

type StepAction = {
  id: string;
  header: string;
  validate?: () => Promise<boolean>,
  canContinue?: () => boolean,
  render: () => React.ReactNode;
};

type Activity = NonNullable<NonNullable<GetActivityByIdQuery['activities']>['items']>[0];
type Individual = Activity['individuals'][0];
type UnknownGroup = Activity['unknownGroups'][0];

interface OwnProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (output: ActivityFormValues) => void;
  editingActivityId?: number;
}

const mapActivityToFormValues = (activity?: Activity): ActivityFormValues | undefined => {
  if (activity == null) {
    return undefined;
  }

  return {
    activityType: activity.type,
    individuals: mapIndividualsToFormValues(activity.individuals) ?? [],
    unknownGroups: mapUnknownIndividualsToFormValues(activity.unknownGroups) ?? [],
    values: activity.customFieldValues.map(v => getFormValueFromFieldValue(v)),
    realities: activity.realities.map(p => ({
      id: p.id ?? null,
      themeId: p.themeId,
      specificationId: p.specificationId,
      approachIds: p.values.filter(q => q.field.systemFieldId === SystemFieldId.RealityApproaches).flatMap(q => q.selectionValues.map(r => r.id)),
      accompanimentIds: p.values.filter(q => q.field.systemFieldId === SystemFieldId.RealityAccompaniments).flatMap(q => q.selectionValues.map(r => r.id)),
      referralIds: p.values.filter(q => q.field.systemFieldId === SystemFieldId.RealityReferrals).flatMap(q => q.selectionValues.map(r => r.id)),
    })),
    distributions: activity.distributions
  };
};

export const mapIndividualsToFormValues = (individuals?: Individual[]): ActivityIndividualInput[] | undefined => {
  if (individuals == null) {
    return undefined;
  }

  return individuals.map(individual => ({
    id: individual.id,
    edited: false,
    values: individual.customFieldValues.map(v => getFormValueFromFieldValue(v))
  }));

};

export const mapIndividualsLookupToFormValues = (individuals: LookupType[]): ActivityIndividualInput[] | undefined => {
  if (individuals == null) {
    return undefined;
  }

  return individuals.map(individual => ({
    id: Number.parseInt(individual.id),
    edited: false,
    values: individual.customFieldValues
  }));
};

const mapUnknownIndividualsToFormValues = (unknownGroups?: UnknownGroup[]) => unknownGroups === undefined ? undefined : (
  unknownGroups.map(unknownGroup => ({
    id: unknownGroup.id,
    values: unknownGroup.customFieldValues.map(v => getFormValueFromFieldValue(v))
  })));

export const ActivityDialog: React.FC<OwnProps> = ({ isOpen, isLoading, editingActivityId, ...props }) => {
  const { formatMessage } = useIntl();

  const { data: activityData } = useGetActivityByIdQuery({ id: editingActivityId ?? -1 }, { enabled: editingActivityId != null });
  const activity = activityData?.activities?.items?.at(0);

  const form = useForm<ActivityFormValues>({ reValidateMode: 'onChange' });
  const { reset, trigger, formState: { errors }, getValues, setError, clearErrors, handleSubmit, control, setValue } = form;
  const selectedActivityType = useWatch({ control, name: 'activityType' });

  const wasOpen = useRef(false);

  const values = useWatch({ control, name: 'values' });
  const teamId = useMemo(() => values?.find(v => v.systemFieldId === SystemFieldId.Team)?.value, [values]);
  const previousTeamId = useRef(teamId);

  useEffect(() => {
    if (previousTeamId.current != null) {
      setValue('individuals', []);
    }
  }, [setValue, teamId]);


  useEffect(() => { previousTeamId.current = teamId; }, [teamId]);

  const [currentStepIndex, setCurrentStep] = useState(0);

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(0);
    }
  }, [isOpen, setCurrentStep]);

  useEffect(() => {
    if (isOpen) {
      if (activity != null) {
        reset(mapActivityToFormValues(activity));
      } else {
        reset({ values: [], individuals: [], activityType: undefined });
      }

      if (!wasOpen.current) {
        setCurrentStep(0);
      }
    }

    wasOpen.current = isOpen;
  }, [activity, isOpen, reset, setCurrentStep]);

  const entityType = selectedActivityType && activityTypeToEntityType[selectedActivityType];
  const { data: customFieldsData, isLoading: isSectionsLoading } = useFindSectionsQuery({
    filter: { entityType: { eq: entityType }, }
  }, { enabled: Boolean(selectedActivityType), refetchOnWindowFocus: false });

  const activitySteps = useMemo(() => {
    const steps: StepAction[] = [];

    if (editingActivityId == null) {
      steps.push({
        id: 'activity-type',
        header: formatMessage({ id: 'Select activity type' }),
        canContinue: () => selectedActivityType !== undefined,
        render: () => <ActivityDialogTypeSelector onActivityTypeSelected={() => {
          setCurrentStep(1);
          clearErrors();
        }} />
      });
    }

    steps.push({
      id: 'activity-configuration',
      header: !selectedActivityType ? '' :
        editingActivityId
          ? formatMessage(activityEditDialogTitleMessages[selectedActivityType])
          : formatMessage(activityAddDialogTitleMessages[selectedActivityType]),
      validate: () => trigger('values'),
      canContinue: () => errors.values === undefined && !isSectionsLoading,
      render: () => <CustomFieldInputForm<ActivityFormValues>
        sections={customFieldsData?.sections}
        loading={isSectionsLoading}
      />
    });

    if (selectedActivityType && activityTypeAvailableSteps[selectedActivityType].includes('individuals')) {
      steps.push({
        id: 'individuals',
        header: formatMessage({ id: 'Select individuals' }),
        render: () => <IndividualsConfigurationStep />,
        canContinue: () => errors.individuals === undefined,
        validate: async () => {
          const hasIndividual = getValues('individuals')?.length > 0;
          const hasUnknownIndividual = getValues('unknownGroups')?.length > 0;

          if (hasIndividual || hasUnknownIndividual) {
            clearErrors('individuals');
            return true;
          }

          setError('individuals', { type: 'manual', message: formatMessage({ id: 'At least one individual or unknown group must be selected' }) });
          return false;
        }
      });
    }

    if (selectedActivityType && activityTypeAvailableSteps[selectedActivityType].includes('realities')) {
      steps.push({
        id: 'realities',
        header: formatMessage({ id: 'Select realities' }),
        canContinue: () => errors.realities === undefined,
        render: () => <RealitiesConfigurationStep />,
        validate: async () => {
          const hasRealities = getValues('realities').length > 0;

          if (!hasRealities) {
            setError('realities', { type: 'manual', message: formatMessage({ id: 'At least one reality must be selected' }) });
            return false;
          } else {
            clearErrors('realities');
            return true;
          }
        }
      });
    }

    if (selectedActivityType && activityTypeAvailableSteps[selectedActivityType].includes('distributions')) {
      steps.push({
        id: 'distributions',
        header: formatMessage({ id: 'Select items' }),
        render: () => <DistributionConfigurationStep />,
        canContinue: () => errors.distributions === undefined,
        validate: async () => {
          if (selectedActivityType !== ActivityType.Distribution) return true;

          const itemsDistributed = getValues('distributions')?.filter(i => i.distributedAmount > 0)?.length;

          if (itemsDistributed === 0) {
            setError('distributions', { type: 'manual', message: formatMessage({ id: 'At least one item must be distributed' }) });
            return false;
          } else {
            clearErrors('distributions');
            return true;
          }
        },
      });
    }

    return steps;
  }, [editingActivityId, selectedActivityType, formatMessage, setCurrentStep, trigger, errors.values, errors.individuals, errors.realities, errors.distributions, isSectionsLoading, customFieldsData?.sections, getValues, setError, clearErrors]);

  const handleValidate = useCallback(async () => {
    const isValid = await activitySteps[currentStepIndex]?.validate?.();
    if (isValid === false) {
      handleSubmit(() => {
        // This handleSubmit is supposed to submit nothing.
        // trigger() on its own won't allow the form to re-validate on field change.
        // This is only possible when the form is submitted.
        // Thus we submit so that the field will re-validate when there's a change.
      })();

      return false;
    }
    return true;
  }, [activitySteps, currentStepIndex, handleSubmit]);

  const nextStep = useCallback(async () => {
    if (!await handleValidate()) return;

    setCurrentStep(Math.min(currentStepIndex + 1, activitySteps.length - 1));
  }, [handleValidate, setCurrentStep, currentStepIndex, activitySteps.length]);

  const handleValidateThenSubmit = async () => {
    if (!await handleValidate()) return;

    handleSubmit(onSubmit)();
  };

  const previousStep = () => {
    setCurrentStep(Math.max(currentStepIndex - 1, 0));
  };

  const closeActivityDialog = () => {
    setCurrentStep(0);
    props.onClose?.();
  };

  const onSubmit = (formValues: ActivityFormValues) => {
    props.onSubmit?.(formValues);
  };

  const topDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (errors.distributions !== undefined
      || errors.individuals !== undefined
      || errors.realities !== undefined) {
      topDivRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [errors.distributions, errors.individuals, errors.realities]);

  return (
    <FormDialog
      open={isOpen}
      disabled={isLoading}
      isFormDirty={form.formState.isDirty}
      onClose={closeActivityDialog}
      title={activitySteps[currentStepIndex]?.header}
      sx={{ zIndex: t => t.zIndex.modal - 1 }}
    >
      <div ref={topDivRef} />

      <Stack pb={5} height='100%'>
        <FormProvider {...form}>
          {activitySteps[currentStepIndex].render()}
        </FormProvider>

        <ActivityDialogActions
          isEditMode={Boolean(editingActivityId)}
          isLoading={isLoading}
          numberOfSteps={activitySteps.length}
          previousStep={previousStep}
          nextStep={nextStep}
          currentStepIndex={currentStepIndex}
          canContinue={activitySteps[currentStepIndex]?.canContinue?.() ?? true}
          onSubmit={handleValidateThenSubmit}
        />
      </Stack>
    </FormDialog>
  );
};