import React from 'react';
import { Divider, List } from '@mui/material';
import { useIntl } from 'react-intl';
import { Event, Link, Numbers, TextFields } from '@mui/icons-material';
import { ListItemAddButton } from '../ListItemAddButton';
import { ReactComponent as CheckList } from '../../../../assets/images/CheckList.svg';
import { CustomFieldType } from 'gql';
import { fieldDescriptionMessages } from 'modules/customizations/messages';

interface OwnProps {
  onClick: (type: CustomFieldType | 'linkExistingField') => void;
}

export const CustomFieldList: React.FC<OwnProps> = (props) => {
  const { formatMessage } = useIntl();
  const { onClick } = props;

  return (
    <List disablePadding>
      <ListItemAddButton
        label={formatMessage(fieldDescriptionMessages[CustomFieldType.String])}
        onClick={() => onClick(CustomFieldType.String)}
      >
        <TextFields />
      </ListItemAddButton>
      <ListItemAddButton
        label={formatMessage(fieldDescriptionMessages[CustomFieldType.Numerical])}
        onClick={() => onClick(CustomFieldType.Numerical)}
      >
        <Numbers />
      </ListItemAddButton>
      <ListItemAddButton
        label={formatMessage(fieldDescriptionMessages[CustomFieldType.Date])}
        onClick={() => onClick(CustomFieldType.Date)}
      >
        <Event />
      </ListItemAddButton>
      <ListItemAddButton
        label={formatMessage(fieldDescriptionMessages[CustomFieldType.Selection])}
        onClick={() => onClick(CustomFieldType.Selection)}
      >
        <CheckList />
      </ListItemAddButton>

      <Divider sx={{ mt: 3 }} />

      <ListItemAddButton
        label={formatMessage({ id: 'Link an Existing Field' })}
        onClick={() => onClick('linkExistingField')}
      >
        <Link />
      </ListItemAddButton>
    </List>
  );
};