import React from 'react';
import { Collapse, IconButton, LinearProgress, List, ListItem, ListItemButton, ListItemButtonProps, ListItemText, Stack, Typography } from '@mui/material';
import { DeleteIconButton } from 'components/DeleteIconButton';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { EntityType, FieldValueInput, SystemFieldId, useFindCustomFieldsQuery } from 'gql';
import { useBirthdayFormatter } from 'modules/individuals/utils';
import { isNotNullOrUndefined } from 'utils/isNotNullOrUndefined';

interface Individual {
  customFieldValues: FieldValueInput[];
}

interface OwnProps {
  individual: Individual;
  onDelete?: () => void;
  divider?: boolean;
}

export type IndividualListItemProps =
  & ListItemButtonProps
  & OwnProps;

const hiddenSystemFields: SystemFieldId[] = [SystemFieldId.Birthday, SystemFieldId.NickName, SystemFieldId.Gender, SystemFieldId.Team];

export const IndividualListItem: React.FC<IndividualListItemProps> = (props) => {
  const { formattedAge } = useBirthdayFormatter();
  const { individual, onDelete, ...listItemButtonProps } = props;

  const nicknameField = individual.customFieldValues.find(p => p.systemFieldId === SystemFieldId.NickName);
  const nickname = nicknameField?.displayString ?? nicknameField?.value;

  const birthdayField = individual.customFieldValues.find(p => p.systemFieldId === SystemFieldId.Birthday);
  const birthday = birthdayField?.displayString ?? birthdayField?.value;

  const genderField = individual.customFieldValues.find(p => p.systemFieldId === SystemFieldId.Gender);
  const gender = genderField?.displayString;

  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const toggleDetailsOpen = () => setDetailsOpen(d => !d);

  const { data: customFieldsData, isFetching: isFetchingCustomFields } = useFindCustomFieldsQuery({
    filter: { entityTypes: { some: { entityType: { eq: EntityType.Individual } } } }
  }, { enabled: detailsOpen });
  const customFields = customFieldsData?.customFields ?? [];

  const loading = isFetchingCustomFields && detailsOpen;

  const fieldValues = individual.customFieldValues.map(v => {
    if (v.systemFieldId && hiddenSystemFields.includes(v.systemFieldId)) return null;

    const customField = customFields.find(p => p.id === v.customFieldId);

    if (!customField) return null;

    return (
      <ListItem key={v.customFieldId}>
        <ListItemText
          primary={customField.name}
          secondary={v.displayString ?? v.value}
        />
      </ListItem>
    );
  }).filter(isNotNullOrUndefined);

  return (
    <ListItem
      divider={props.divider}
      disablePadding
    >
      <Stack width='100%'>
        <ListItemButton {...listItemButtonProps} sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          p: 0
        }}>
          <Stack direction='row' justifyContent='space-between' width='100%' px={2} py={1}>
            <Stack>
              <Typography fontWeight={500}>
                {nickname}
              </Typography>

              <Typography variant='subtitle2' color='text.secondary'>
                {gender && `${gender} - `}{formattedAge(birthday)}
              </Typography>
            </Stack>

            {onDelete && (
              <DeleteIconButton edge="end" onClick={onDelete} />
            )}

            {fieldValues.length > 0 && (
              <IconButton onClick={e => {
                toggleDetailsOpen();
                e.stopPropagation();
              }}>
                {detailsOpen ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
          </Stack>

          {fieldValues.length > 0 && (
            <Collapse in={detailsOpen} unmountOnExit={false} sx={{ pl: 1 }}>
              <List disablePadding>
                {fieldValues}
              </List>
            </Collapse>
          )}

          {loading && (
            <LinearProgress sx={{ width: '100%' }} />
          )}
        </ListItemButton>
      </Stack>
    </ListItem>
  );
};