import React from 'react';
import { Stack, StackProps, styled, Typography, useTheme } from '@mui/material';
import { ReactComponent as Loader } from 'assets/icons/loader.svg';
import { useIntl } from 'react-intl';
import { ApplicationLogo } from 'components/ApplicationLogo';

const StyledStack = styled(Stack)<StackProps>(({ theme }) => ({
  background: theme.palette.primary.main,
}));

export const LoadingScreen: React.FC = () => {
  const { formatMessage } = useIntl();
  const { palette } = useTheme();

  return <>
    <StyledStack alignItems="center" justifyContent="center" height="100vh" position='relative'>
      <ApplicationLogo
        alwaysUseTheme='dark'
        textColor='secondary.main'
        sx={{ position: 'absolute', top: 16, left: 16 }}
      />

      <Stack alignItems="center" width="100%">
        <Stack
          direction="row"
          justifyContent="center"
          maxWidth="300px"
          width="100%"
        >
          <Loader fill={palette.secondary.main} />
        </Stack>
        <Typography
          variant="subtitle2"
          component="h1"
          textAlign="center"
          color='common.white'
          textTransform='uppercase'
          fontSize={{ xs: 25, sm: 30 }}
        >
          {formatMessage({ id: 'Loading' })}
        </Typography>
      </Stack>
    </StyledStack >
  </>;
};
