import React, { useMemo } from 'react';
import { Chip, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { useFindTeamsQuery, useGetTeamsActivitiesCountQuery } from 'gql';
import _ from 'lodash';
import { TeamStatTable } from './TeamStatTable';
import { TeamStatTableSkeleton } from './TeamStatTableSkeleton';
import { Filter } from 'modules/home/hooks/useGetActivitiesFilter';
import { dashboardQueryOptions } from '../../utils';

interface OwnProps {
  activitiesFilter: Filter
}

export const TeamsSection: React.FC<OwnProps> = ({ activitiesFilter }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const { data: teamActivitiesCountData, isFetching: isteamActivitiesCountLoading } = useGetTeamsActivitiesCountQuery(activitiesFilter, dashboardQueryOptions());
  const teamActivitiesCount = teamActivitiesCountData?.activitiesCountByTeam ?? [];

  const teamsIds = _.sortedUniq(teamActivitiesCount.map(t => t.teamId));

  const { data: teamsData, isFetching: isTeamLoading } = useFindTeamsQuery({
    filter: {
      id: { in: activitiesFilter.teamsId.filter(t => !teamsIds.includes(t)) }
    }
  });

  const teams = teamsData?.teams ?? [];

  teams.forEach(team => {
    teamActivitiesCount.push({
      id: '',
      name: '',
      teamId: team.id,
      teamName: team.name,
      lastActivityDate: null,
      distributions: 0,
      interventions: 0,
      meetings: 0,
      presentations: 0,
      routines: 0
    });

    teamsIds.push(team.id);
  });

  const totalTeams = teamsIds.length;

  const groupByTeam = _.forEach(_.groupBy(teamActivitiesCount.sort((a, b) => a.teamName.localeCompare(b.teamName)), t => t.teamId), team => team);

  const isLoading = isteamActivitiesCountLoading || isTeamLoading;

  return (
    <Stack spacing={2}>
      <Stack direction='row' spacing={2} alignItems='center'>
        <Typography variant='h5'>{formatMessage({ id: '{totalTeams, plural, zero {Teams} one {Team} other {Teams}}' }, { totalTeams: totalTeams })}</Typography>
        {isLoading
          ?
          <Skeleton variant='circular' width={theme.spacing(3)} height={theme.spacing(3)} />
          :
          <Chip label={totalTeams} color='primary' size='small' />
        }
      </Stack>
      {isLoading
        ?
        <TeamStatTableSkeleton />
        :
        <>
          {teamsIds.map((teamId) => (
            <TeamStatTable
              key={teamId}
              teamsStat={groupByTeam[teamId]}
            />
          ))}
        </>
      }
    </Stack>
  );
};