import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridColDef, GridFilterModel, GridValueFormatterParams, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { DataTable, DataTableProps } from 'components';
import dayjs from 'dayjs';
import { CustomFieldDto, CustomFieldType, EntityType, FindIndividualsQuery, Individual as IndividualEntity, SystemFieldId, useFindSectionsQuery, CustomFieldEntityType as CustomFieldEntityTypeEntity } from 'gql';
import orderBy from 'lodash/orderBy';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCustomFieldsSearchUtils } from '../../../../utils/dataGridSearch/customFieldsSearch';
import { notEmpty } from '../../../../utils/typescriptUtils';

type Individual = Pick<IndividualEntity, 'id' | 'birthday' | 'nickName' | 'gender' | 'customFieldValues'>;

type CustomFieldEntityType = Pick<CustomFieldEntityTypeEntity, 'entityType' | 'customFieldId'> & {
  customFieldDto: Pick<CustomFieldDto, 'customFieldType' | 'systemFieldId' | 'allowMultipleValues'>;
};

interface Props extends Omit<DataTableProps, 'rows' | 'columns'> {
  storageKey: string;
  onFilter?: (filter: GridFilterModel) => void,
  individuals: NonNullable<NonNullable<Pick<FindIndividualsQuery, 'searchIndividuals'>['searchIndividuals']>['items']>;
  onEditClicked?: (individual: Individual) => void;
}

const valueGetterByFieldType = (p: CustomFieldEntityType) => ({ row: { customFieldValues } }: GridValueGetterParams<Individual>) => {
  if (!customFieldValues) {
    return '';
  }

  const fieldType = p.customFieldDto.customFieldType;

  if (fieldType === CustomFieldType.Date) {
    return dayjs(customFieldValues.find(q => q.fieldId === p.customFieldId)?.dateValue).toDate();
  } else {
    return customFieldValues.find(q => q.fieldId === p.customFieldId)?.displayString;
  }
};

const valueFormatterFunction = (p: CustomFieldEntityType) => {
  const systemFieldId = p.customFieldDto.systemFieldId;
  if (systemFieldId === SystemFieldId.Birthday) {
    return ({ value }: GridValueFormatterParams<Date>) => {
      return dayjs().diff(dayjs(value), 'year');
    };
  }
};

export const IndividualsTable: React.FC<Props> = ({ storageKey, individuals, onEditClicked, onFilter, ...dataTableProps }) => {
  const { data: sections } = useFindSectionsQuery({ filter: { entityType: { eq: EntityType.Individual } } });
  const { formatMessage } = useIntl();
  const { columnMinWidth, customFieldTypeToColumnType, getOperatorsByCustomField } = useCustomFieldsSearchUtils();
  const wasGridStateRestored = React.useRef(false);
  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = useState<GridInitialStateCommunity>({});

  const columns = useMemo(() => {
    const fieldIds = new Set<number>();
    const visibilityConfig: Record<string, boolean> = {};
    const columnsToDisplay: GridColDef<Individual>[] =
      orderBy(sections?.sections.flatMap(p => p.fieldEntityTypes), p => p.order.toString())
        .map<GridColDef<Individual> | null>(p => {
          if (fieldIds.has(p.customFieldId)) {
            return null;
          }

          fieldIds.add(p.customFieldId);

          if (![SystemFieldId.Gender, SystemFieldId.NickName, SystemFieldId.Birthday].includes(p.customFieldDto.systemFieldId as SystemFieldId)) {
            visibilityConfig[`${p.entityType.toString()};${p.customFieldId.toString()}`] = false;
          }

          return {
            field: `${p.entityType.toString()};${p.customFieldId.toString()}`,
            type: customFieldTypeToColumnType[p.customFieldDto.customFieldType],
            sortable: false, // disable client side sorting which results in bad UX when combined with server side pagination
            filterOperators: getOperatorsByCustomField(p.customFieldDto),
            hideable: !(p.customFieldDto.systemFieldId === SystemFieldId.NickName),
            headerName: p.customFieldDto.name,
            flex: 1,
            minWidth: columnMinWidth[p.customFieldDto.customFieldType],
            valueGetter: valueGetterByFieldType(p),
            valueFormatter: valueFormatterFunction(p),
          };
        }).filter(notEmpty) ?? [];


    if (onEditClicked) {
      columnsToDisplay.push({
        field: formatMessage({ id: 'Actions' }),
        headerName: '',
        sortable: false,
        filterable: false,
        hideable: false,
        disableColumnMenu: true,
        align: 'right',
        renderCell: (params) => (
          <IconButton onClick={() => onEditClicked?.(params.row)}>
            <Edit />
          </IconButton>
        )
      });
    }

    setInitialState({
      sorting: {
        sortModel: []
      },
      columns: {
        columnVisibilityModel: visibilityConfig
      }
    });

    return columnsToDisplay;
  }, [columnMinWidth, customFieldTypeToColumnType, formatMessage, getOperatorsByCustomField, onEditClicked, sections?.sections]);

  useEffect(() => {
    if (!wasGridStateRestored.current) {
      const stateJSON = localStorage.getItem(storageKey);
      if (stateJSON) {
        const state: GridInitialStateCommunity = JSON.parse(stateJSON);
        if (state.columns) state.columns.orderedFields = undefined;
        apiRef.current.restoreState({ ...state, preferencePanel: { open: false } });
      } else {
        apiRef.current.restoreState(initialState);
      }

      wasGridStateRestored.current = true;
    }
  }, [apiRef, initialState, storageKey]);

  const saveTableState = useCallback(() => {
    const state = apiRef.current.exportState();
    localStorage.setItem(storageKey, JSON.stringify(state));
  }, [apiRef, storageKey]);

  const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
    if (onFilter != null) {
      onFilter(filterModel);
      saveTableState();
    }
  }, [onFilter, saveTableState]);

  return (
    <DataTable
      apiRef={apiRef}
      columns={columns}
      rows={individuals}
      noDataMessage={formatMessage({ id: 'There are no individuals yet' })}
      noSearchResultsMessage={formatMessage({ id: 'No individual found' })}
      rowHeight={64}
      {...dataTableProps}
      onColumnVisibilityModelChange={saveTableState}
      onFilterModelChange={onFilterChange}
      onSortModelChange={saveTableState}
    />
  );
};