import { ActivityType, useGetActivitiesByTypeInDateRangeQuery } from 'gql';
import { useActivitySettings } from 'hooks/useActivitySettings';
import _ from 'lodash';
import { ActivityTypeColor } from 'modules/activities/types';
import { Filter } from 'modules/home/hooks/useGetActivitiesFilter';
import { activityTypePluralOrSigleMessages } from 'modules/home/messages';
import { dashboardQueryOptions, getPreviousPeriodFilter } from 'modules/home/utils';
import React from 'react';
import { useIntl } from 'react-intl';
import { TimeRange } from '../DropdownFilter';
import { CountStatTileSkeleton } from './CountStatTileSkeleton';
import { CountStatsSection } from './CountStatsSection';
import { CountStatsTile } from './CountStatsTile';

interface OwnProps {
  activitiesFilter: Filter
  selectedTimeRange: TimeRange
}

export const ActivitiesCount: React.FC<OwnProps> = ({ activitiesFilter, selectedTimeRange }) => {
  const { formatMessage } = useIntl();
  const { activitiesEnabled } = useActivitySettings();


  const { data: activitiesData, isFetching: isActivitiesLoading } = useGetActivitiesByTypeInDateRangeQuery(activitiesFilter, dashboardQueryOptions());
  const activities = activitiesData?.activitiesCountByType ?? [];

  const { data: previousActivitiesData, isFetching: isPreviousActivitiesLoading } = useGetActivitiesByTypeInDateRangeQuery(getPreviousPeriodFilter(activitiesFilter, selectedTimeRange), dashboardQueryOptions());
  const previousActivities = previousActivitiesData?.activitiesCountByType ?? [];

  const getNumberOfPreviousActivities = (forType: ActivityType) => previousActivities.find(a => a.activityType === forType)?.count ?? 0;
  const getNumberOfActivities = (forType: ActivityType) => activities.find(a => a.activityType === forType)?.count ?? 0;

  const totalActivities = _.sumBy(activities, activity => activity.count);
  const totalPreviousActivities = _.sumBy(previousActivities, activity => activity.count);

  return (
    <CountStatsSection
      title={formatMessage({ id: '{totalActivities, plural, zero {Activities} one {Activity} other {Activities}}' }, { totalActivities: totalActivities })}
      totalCount={totalActivities}
      previousTotalCount={totalPreviousActivities}
      isLoading={isActivitiesLoading || isPreviousActivitiesLoading}
    >
      {isActivitiesLoading || isPreviousActivitiesLoading
        ?
        <>
          {activitiesEnabled.map((type, index) =>
            <CountStatTileSkeleton
              key={type + index}
              title={formatMessage(activityTypePluralOrSigleMessages[type], { activityCount: getNumberOfActivities(type) })}
              delta
              sx={{
                borderColor: ActivityTypeColor[type]
              }}
            />
          )}
        </>
        :
        <>
          {activitiesEnabled.map((type, index) =>
            <CountStatsTile
              key={index}
              title={formatMessage(activityTypePluralOrSigleMessages[type], { activityCount: getNumberOfActivities(type) })}
              count={getNumberOfActivities(type)}
              previousCount={getNumberOfPreviousActivities(type)}
              sx={{
                borderColor: ActivityTypeColor[type]
              }}
            />
          )}
        </>
      }
    </CountStatsSection >
  );
};