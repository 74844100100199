import React from 'react';
import { useIntl } from 'react-intl';
import { Stack, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

interface OwnProps {
  disabled?: boolean;
}

export interface FormValues {
  name: string;
}

export const CustomFieldSectionForm: React.FC<OwnProps> = (props) => {
  const { formatMessage } = useIntl();

  const formMethods = useFormContext<FormValues>();

  return (
    <Stack spacing={3} px={2} pt={3}>
      <Controller
        control={formMethods.control}
        name='name'
        rules={{ required: formatMessage({ id: 'The section name cannot be empty' }) }}
        render={({ field, fieldState }) => (
          <TextField
            required
            label={formatMessage({ id: 'Label' })}
            disabled={props.disabled}
            {...field}
            error={fieldState.error?.message != null}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </Stack>
  );
};