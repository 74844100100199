import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmCloseDialog: React.FC<Props> = ({ open, onClose, onConfirm }) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog open={open} onClose={onClose} sx={{ zIndex: (theme) => theme.zIndex.modal + 10 /* Confirm dialogs should be topmost */ }}>
      <DialogTitle>
        {formatMessage({ id: 'Close this form?' })}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {formatMessage({ id: 'You have unsaved changes that may be lost.' })}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} autoFocus>
          {formatMessage({ id: 'Cancel' })}
        </Button>
        <Button onClick={onConfirm} color='error' variant='contained'>
          {formatMessage({ id: 'Close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};