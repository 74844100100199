import { Divider, Typography, Stack } from '@mui/material';
import React from 'react';

interface OwnProps {
  label?: string,
  children?: React.ReactNode
}

export const DisplayInformationRow: React.FC<OwnProps> = (props) => {
  return (
    <Stack>
      <Divider />
      <Stack marginY={2} paddingX={2} gap={2} direction='row' alignItems='center' flexWrap='wrap' justifyContent={props.label ? 'flex-start' : 'space-between'}>
        {props.label &&
          <Typography width='30%' variant='body2'>
            {props.label}
          </Typography>
        }
        {props.children}
      </Stack>
    </Stack>
  );
};