import { ReportGenderBreakdown } from 'gql';
import { MessageDescriptor, defineMessages } from 'react-intl';

export const reportGenderBreakdownMessages: Record<ReportGenderBreakdown, MessageDescriptor> = defineMessages({
  None: { id: 'None' },
  ByColumn: { id: 'By column' },
  ByRow: { id: 'By row' },
});

export const reportGenderBreakdownDescriptionMessages: Record<ReportGenderBreakdown, MessageDescriptor> = defineMessages({
  None: { id: 'No breakdown will be shown.' },
  ByColumn: { id: 'Only the two most common genders will be shown, along with an "others" column.' },
  ByRow: { id: 'All genders will be shown in row form.' },
});