import React, { useEffect } from 'react';
import { createTheme, Paper, Stack, StackProps, styled, ThemeProvider } from '@mui/material';
import loginBackgroud from '../../../assets/images/login-background.jpeg';
import { LoginHeader } from '../components/LoginHeader/LoginHeader';
import { LoginBody } from '../components/LoginBody';
import { useResponsive } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import { useNotification } from 'hooks/useNotification';
import { allThemes } from 'themes';

type AuthentificationProps =
  & StackProps
  & { isMobile: boolean; };

const StyledStack = styled(Stack)<AuthentificationProps>(({ theme, isMobile }) => ({
  height: '100vh',
  '& .background': {
    display: isMobile ? 'none' : 'block',
    width: 'calc(100vw - 500px)',
    height: '100vh',
    objectFit: 'cover',
    objectPosition: 'center center'
  },
  flexDirection: 'row',
  '& .MuiPaper-root': {
    minWidth: isMobile ? '100%' : '500px',
    '& img': {
      padding: isMobile ? 0 : theme.spacing(2, 1.5),
    }
  }
}));


export const LoginPage: React.FC = () => {
  const { isMobile } = useResponsive();
  const { notifyFailure } = useNotification();
  const [params, setParams] = useSearchParams();

  useEffect(() => {
    const loginError = params.get('error');
    if (loginError) {
      notifyFailure(loginError);
      setParams((prevParams) => {
        prevParams.delete('error');
        return prevParams;
      });
    }
  }, [notifyFailure, params, setParams]);

  const loginTheme = createTheme(allThemes.light);

  return (
    <ThemeProvider theme={loginTheme}>
      <StyledStack spacing={0} isMobile={isMobile}>
        <img className='background' src={loginBackgroud} />
        <Paper square>
          <Stack spacing={0}>
            <LoginHeader />
            <LoginBody />
          </Stack>
        </Paper>
      </StyledStack>
    </ThemeProvider>
  );
};