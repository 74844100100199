import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, Grid, Stack, TextField } from '@mui/material';
import { Business, Email, Language, LocalPhone, LocationOn } from '@mui/icons-material';
import { AddTenantInput } from 'gql';
import { FormRowWithIcon, PaperForm, PhoneNumberTextField } from 'components';
import { isEmailValid, isPhoneNumberValid, isTenantIdentifierValid } from 'utils/validationUtils';
import { useIntl } from 'react-intl';
import { administrativeRegionsQuebec } from 'utils/tenantUtils';
import deburr from 'lodash/deburr';

export const OrganizationForm: React.FC<{
  editMode?: boolean;
  disabled?: boolean;
}> = ({ editMode, disabled }) => {
  const { formatMessage } = useIntl();
  const { control, setValue } = useFormContext<AddTenantInput>();

  const handleNameChange = (newName: string) => {
    if (editMode) return;
    setValue('tenantInput.identifier',
      deburr(newName)
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^a-z0-9-]/g, '')
    );
  };

  return (
    <PaperForm>
      <Stack spacing={3}>
        <FormRowWithIcon
          icon={<Business />}
        >
          <Controller
            control={control}
            name='tenantInput.name'
            rules={{ required: formatMessage({ id: 'The organization name is required' }) }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                autoFocus
                onChange={e => {
                  field.onChange(e);
                  handleNameChange(e.target.value);
                }}
                required
                disabled={disabled}
                label={formatMessage({ id: 'Name' })}
                fullWidth
                error={fieldState.error?.message != null}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormRowWithIcon>
        <FormRowWithIcon
          icon={<Language />}
        >
          <Controller
            control={control}
            name='tenantInput.identifier'
            rules={{
              required: formatMessage({ id: 'The organization identifier is required' }),
              validate: (value) => isTenantIdentifierValid(value) ? undefined : formatMessage({ id: 'Please, enter only letters or "-".' })
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                label={formatMessage({ id: 'Identifier' })}
                fullWidth
                disabled={editMode || disabled}
                error={fieldState.error?.message != null}
                helperText={fieldState.error?.message ||
                  (editMode ? formatMessage({ id: 'Identifier cannot be edited' }) : `https://stats.rocqtr.org/${field.value}`)}
              />
            )}
          />
        </FormRowWithIcon>
        <FormRowWithIcon
          icon={<LocationOn />}
        >
          <Controller
            control={control}
            name='tenantInput.region'
            rules={{ required: formatMessage({ id: 'The Region is required' }) }}
            render={({ field, fieldState }) => (
              <Autocomplete
                disablePortal
                options={administrativeRegionsQuebec}
                fullWidth
                disabled={disabled}
                disableClearable
                value={{ label: field.value, id: field.value }}
                renderInput={(params) =>
                  <TextField
                    required
                    label={formatMessage({ id: 'Region' })}
                    error={fieldState.error?.message != null}
                    helperText={fieldState.error?.message}
                    {...params}
                  />
                }
                onChange={(_, data) => field.onChange(data?.id)}
              />
            )}
          />
          <Controller
            control={control}
            name='tenantInput.town'
            rules={{ required: formatMessage({ id: 'The town name is required' }) }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                required
                disabled={disabled}
                label={formatMessage({ id: 'Town' })}
                error={fieldState.error?.message != null}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormRowWithIcon>
        <FormRowWithIcon
          icon={<LocalPhone />}
        >
          <Grid container spacing={1}>
            <Grid item xs={8} sm={4}>
              <Controller
                control={control}
                name='tenantInput.telephone'
                rules={{
                  required: formatMessage({ id: 'The telephone number is required' }),
                  validate: {
                    telephone: value => isPhoneNumberValid(value) ? undefined : formatMessage({ id: 'Please enter a valid phone number.' }),
                  }
                }}
                render={({ field, fieldState }) => (
                  <PhoneNumberTextField
                    {...field}
                    disabled={disabled}
                    fullWidth
                    required
                    label={formatMessage({ id: 'Telephone' })}
                    error={fieldState.error?.message != null}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={2}>
              <Controller
                control={control}
                name='tenantInput.extensionTelephone'
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={disabled}
                    label={formatMessage({ id: 'Ext' })}
                    error={fieldState.error?.message != null}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormRowWithIcon>
        <FormRowWithIcon
          icon={<Email />}
        >
          <Controller
            control={control}
            name='tenantInput.email'
            rules={{
              validate: (value) => isEmailValid(value) ? undefined : formatMessage({ id: 'Please enter a valid email address.' }),
              required: true
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                disabled={disabled}
                label={formatMessage({ id: 'Email' })}
                fullWidth
                required
                error={fieldState.error?.message != null}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </FormRowWithIcon>
      </Stack>
    </PaperForm>
  );
};