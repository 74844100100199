import { AvatarGroup } from '@mui/material';
import { CustomFieldValue, FindActivitiesQuery } from 'gql';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { isArray } from 'lodash';
import { UserAvatar } from 'components';
import React from 'react';

type Activity = NonNullable<NonNullable<Pick<FindActivitiesQuery, 'activities'>['activities']>['items']>[0];
type ActivityTableCellValue = string | Date | CustomFieldValue[] | undefined;

export const AdditionalWorkersCell = ({ value, }: GridRenderCellParams<Activity, ActivityTableCellValue>) => {
  if (!isArray(value)) return null;

  const usernames = value.at(0)?.displayString.split(', ').filter(v => Boolean(v)) ?? [];

  return (
    <AvatarGroup max={4} >
      {usernames.map((username, index) => (
        <UserAvatar key={index} displayName={username} size='small' />
      ))}
    </AvatarGroup>
  );
};