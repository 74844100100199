import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { Button, MobileStepper } from '@mui/material';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  numberOfSteps: number;
  currentStep: number;
  nextStep: () => void;
  previousStep: () => void;
  lastStepButton?: ReactNode;
  disableNext?: boolean;
}

export const FormMobileStepper: React.FC<Props> = ({ disableNext, numberOfSteps, currentStep, nextStep, previousStep, lastStepButton }) => {
  const { formatMessage } = useIntl();

  const isLastStep = currentStep === numberOfSteps - 1;

  return (
    <MobileStepper
      steps={numberOfSteps}
      position='bottom'
      activeStep={currentStep}
      nextButton={<>
        {(!lastStepButton || !isLastStep) ? (
          <Button size="small" onClick={nextStep} disabled={disableNext || isLastStep}>
            {formatMessage({ id: 'Next' })}
            <KeyboardArrowRight />
          </Button>
        ) : lastStepButton}
      </>}
      backButton={
        <Button size="small" onClick={previousStep} disabled={currentStep === 0}>
          <KeyboardArrowLeft />
          {formatMessage({ id: 'Back' })}
        </Button>
      }
      sx={{
        backgroundColor: 'background.paper',
      }}
    />
  );
};