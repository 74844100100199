import { Alert, DrawerProps, List, Tab, Tabs, Theme, Typography } from '@mui/material';
import { EmptyState, FormDrawer } from 'components';
import { DistributionValueInput, useFindDistributionCategoriesQuery, useFindDistributionItemsQuery } from 'gql';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ItemController } from './ItemController';

interface Props extends Omit<DrawerProps, 'onSubmit'> {
  onSubmit?: (values: DistributionValueInput[]) => void;
  defaultValues?: DistributionValueInput[];
}

interface FormValues {
  distributions: string[];
}

export const DistributionDrawer: React.FC<Props> = ({ onSubmit, defaultValues, ...drawerProps }) => {
  const { formatMessage } = useIntl();

  const { data: itemsData, isFetching: isItemsFetching } = useFindDistributionItemsQuery({
    filter: { isDisabled: { eq: false } }
  });
  const items = useMemo(() => itemsData?.items ?? [], [itemsData?.items]);

  const itemCategoryIds = useMemo(() => items.flatMap(item => item.categoryIds), [items]);

  const { data: categoriesData, isFetching: isCategoriesFetching } = useFindDistributionCategoriesQuery({
    input: { id: { in: itemCategoryIds } }
  }, { enabled: itemCategoryIds.length > 0 });
  const categories = categoriesData?.categories ?? [];

  const loading = isItemsFetching || isCategoriesFetching;

  const [selectedCategoryId, setSelectedCategoryId] = React.useState<number>(0);

  const { control, handleSubmit, reset, formState: { isDirty, errors } } = useForm<FormValues>();

  const onSubmitClicked = (values: FormValues) => {
    if (values.distributions.length < items.length || values.distributions.some(value => isNaN(Number(value)))) {
      return;
    }
    const distributions: DistributionValueInput[] = values.distributions.map((value, index) => ({
      itemId: items[index].id,
      distributedAmount: Number(value)
    })).filter(d => d.distributedAmount > 0);
    onSubmit?.(distributions);
  };

  useEffect(() => {
    setSelectedCategoryId(0);
  }, [drawerProps.open]);

  useEffect(() => {
    if (items.length === 0) return;

    const resetValues = items.map(item =>
      defaultValues
        ?.find(d => d.itemId === item.id)
        ?.distributedAmount
        ?.toString()
      ?? '0'
    );

    reset({
      distributions: resetValues
    });
  }, [reset, drawerProps.open, defaultValues, items]);

  const onTabChange = (newCategoryId: number) => {
    setSelectedCategoryId(newCategoryId);
  };

  const previouslyDistributedItemIds = useMemo(() => defaultValues?.filter(d => d.distributedAmount > 0).map(d => d.itemId) ?? [], [defaultValues]);
  const previouslyDistributedItemSortingFunction = useCallback((item: { id: number; }) => previouslyDistributedItemIds.includes(item.id) ? -1 : 1, [previouslyDistributedItemIds]);

  const sortedItems = useMemo(() => items.sort(previouslyDistributedItemSortingFunction), [items, previouslyDistributedItemSortingFunction]);

  const categoryItems = sortedItems.filter(i => i.categoryIds.includes(selectedCategoryId));

  return (
    <FormDrawer
      isFormDirty={isDirty}
      {...drawerProps}
      disablePadding
      header={formatMessage({ id: 'Edit distribution' })}
      isLoading={loading}
      sx={{ zIndex: (theme: Theme) => theme.zIndex.modal + 1 }}
      showFooter
      saveButtonMessage={formatMessage({ id: 'Save' })}
      onSave={handleSubmit(onSubmitClicked)}
    >
      <Tabs
        value={selectedCategoryId}
        onChange={(_, newCategoryId) => onTabChange(newCategoryId)}
        variant='scrollable'
        scrollButtons='auto'
      >
        <Tab value={0} label={formatMessage({ id: 'All' })} />
        {categories.map(category => (
          <Tab key={category.id} value={category.id} label={category.name} />
        ))}
      </Tabs>

      {Boolean(errors.distributions) && (
        <Alert severity='error' square sx={{ p: 2 }}>
          <Typography>
            {formatMessage({ id: 'Some distributed values are incorrect, please check all values are entered properly.' })}
          </Typography>
        </Alert>
      )}

      {selectedCategoryId ? (
        <List disablePadding>
          {categoryItems.map(item => (
            <ItemController key={item.id} item={item} items={items} control={control} />
          ))}
        </List>
      ) : (
        <List disablePadding>
          {sortedItems.map(item => (
            <ItemController key={item.id} item={item} items={items} control={control} />
          ))}
        </List>
      )}

      {(items.length === 0 && !isItemsFetching) && (
        <EmptyState
          title={formatMessage({ id: 'No items found' })}
          subtitle={formatMessage({ id: 'Have the distribution items been configured?' })}
        />
      )}
    </FormDrawer>
  );
};