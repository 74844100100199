import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { getTenantIdentifier } from '../../utils/tenantUtils';

const wsLink = new GraphQLWsLink(createClient({
  url: `wss://${window.location.host}/${getTenantIdentifier()}/graphql/ws`,
}));

const httpLink = new HttpLink({
  uri: `https://${window.location.host}/${getTenantIdentifier()}/graphql`,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([splitLink]),
});
