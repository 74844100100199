import { Stack, Typography } from '@mui/material';
import { RoutedTabs } from 'components';
import { useGetTeamByIdQuery } from 'gql';
import React from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useParams } from 'react-router-dom';

export const TeamPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { teamId } = useParams();
  const { data: data, isFetched } = useGetTeamByIdQuery({ teamId: Number(teamId) });
  const team = data?.teams[0];

  if (team == null) {
    if (isFetched)
      throw new Error(formatMessage({ id: 'Team not found' }));

    return null;
  }

  return (
    <Stack>
      <Typography variant='h4' fontWeight={500}>{team.name}</Typography>

      <RoutedTabs tabs={[
        { displayString: formatMessage({ id: 'Details' }), navigationString: 'details' },
        { displayString: formatMessage({ id: 'Users' }), navigationString: 'users' }
      ]} />

      <Stack mt={3}>
        <Outlet context={team} />
      </Stack>
    </Stack>
  );
};