import React from 'react';
import { Chip, ChipProps, styled } from '@mui/material';
import { calculateActivitiesCountDelta } from 'modules/home/utils';
import { DeltaIcon } from './DeltaIcon';

interface OwnProps {
  currentValue: number
  previousValue: number
}

type DeltaChipProps =
  & ChipProps
  & {
    previous: number
  };

const StyledChip = styled(Chip)<DeltaChipProps>(({ theme, previous }) => ({
  flexDirection: 'row-reverse',
  '& .MuiChip-label': {
    paddingRight: previous > 0 ? 0 : theme.spacing(1)
  },
  '& svg': {
    marginRight: theme.spacing(1)
  }
}));

export const DeltaChip: React.FC<OwnProps> = (props) => {
  const { currentValue, previousValue } = props;
  const delta = calculateActivitiesCountDelta(currentValue, previousValue);
  return (
    <StyledChip previous={previousValue} icon={delta != undefined ? <DeltaIcon {...props} /> : <></>} label={`${delta ?? '-'}%`} color='secondary' size='small' />
  );
};