import React from 'react';
import { useCallback } from 'react';
import { toast, ToastOptions } from 'react-toastify';

const successToastOptions: ToastOptions = { position: 'top-center', autoClose: 2000 };
const defaultToastOptions: ToastOptions = { position: 'top-center', autoClose: 5000 };

export const useNotification = () => {
  const notifySuccess = useCallback((message: string) => {
    toast.success(message, successToastOptions);
  }, []);

  const notifyFailure = useCallback((message: string) => {
    toast.error(message, defaultToastOptions);
  }, []);

  const notifyFailures = useCallback((messages: string[]) => {
    toast.error((
      <ul>
        {messages.map((message, index) => <li key={`error${index}`}>{message}</li>)}
      </ul>
    ), defaultToastOptions);
  }, []);

  return { notifySuccess, notifyFailure, notifyFailures };
};