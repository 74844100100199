import { useMemo } from 'react';
import { useFindTeamsQuery, useGetCurrentUserQuery } from 'gql';
import { useCurrentUserRoles } from '../../../hooks/useCurrentUserRoles';

export const useGetDropdownTeams = () => {
  const { isAnalyst } = useCurrentUserRoles();
  const { data: currentUser } = useGetCurrentUserQuery();
  const userTeamIds = currentUser?.me.teams.map(p => p.id);

  const { data: teamsData } = useFindTeamsQuery();
  const teams = useMemo(() => {
    if (isAnalyst && teamsData) {
      return teamsData.teams.filter(team => userTeamIds?.includes(team.id));
    }
    return teamsData?.teams ?? [];
  }, [teamsData]);

  return { teams };
};