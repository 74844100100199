import React from 'react';
import { Checkbox, MenuItem, TextField } from '@mui/material';
import { useResponsive } from 'hooks';
import { LookupInput, LookupInputProps } from 'components/LookupInput/LookupInput';
import { useIntl } from 'react-intl';

export const ResponsiveSelect = <T,>(props: LookupInputProps<T>) => {
  const { isMobile } = useResponsive();
  const { formatMessage } = useIntl();

  const { availableValues, selectedValues, getKey, getLabel, multiple, required } = props;

  const shouldShowLookup = isMobile && multiple;
  if (shouldShowLookup) {
    return (
      <LookupInput
        disableDrawerChips
        {...props}
      />
    );
  }


  const native = isMobile;

  const constructOption = (value: T) => native ? (
    <option key={getKey(value)} value={getKey(value)}>{getLabel(value)}</option>
  ) : (
    <MenuItem key={getKey(value)} value={getKey(value)}>
      {multiple && (
        <Checkbox checked={selectedValues.includes(value)} />
      )}
      {getLabel(value)}
    </MenuItem>
  );

  const emptyOption = native ? (
    <option value='' style={{ display: required ? 'none' : undefined }}>
      {formatMessage({ id: 'None' })}
    </option>
  ) : (
    <MenuItem value={''} style={{ display: required ? 'none' : undefined }}>
      {formatMessage({ id: 'None' })}
    </MenuItem>
  );

  const multiChangeEventHandler = (values: React.Key | React.Key[]) => {
    if (typeof values === 'string' || typeof values === 'number') {
      props.onChange(availableValues.filter(v => getKey(v) == values));
    } else {
      props.onChange(availableValues.filter(v => values.includes(getKey(v))));
    }
  };

  return (
    <TextField
      select
      fullWidth={!props.disableFullWidth}
      size='small'
      label={props.label}
      error={props.error}
      required={props.required}
      disabled={props.disabled}
      helperText={props.helperText}
      value={multiple ? selectedValues.map(getKey) : selectedValues.at(0) != null ? getKey(selectedValues[0]) : ''}
      onChange={event => multiChangeEventHandler(event.target.value)}
      SelectProps={multiple ? {
        multiple: true,
        renderValue: () => selectedValues.map(getLabel).join(', ')
      } : {
        native: native
      }}
      InputLabelProps={{
        shrink: native || undefined
      }}
    >
      {!multiple && emptyOption}
      {availableValues.map(constructOption)}
    </TextField>
  );
};
