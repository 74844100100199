import React from 'react';
import { NavigationMenuItem, NavigationMenuItemProps } from './NavigationMenuItem';
import { NavLink } from 'react-router-dom';

interface OwnProps {
  to: string;
}

export type NavigationMenuLinkProps =
  & OwnProps
  & NavigationMenuItemProps;

export const NavigationMenuLink: React.FC<NavigationMenuLinkProps> = (props) => {
  const { to, ...navigationMenuItemProps } = props;

  const menuItem = <NavigationMenuItem {...navigationMenuItemProps} />;

  if (props.disabled) {
    return (
      <div>
        {menuItem}
      </div>
    );
  } else {
    return (
      <NavLink to={to} className={({ isActive }) => isActive ? 'active' : ''} style={{ textDecoration: 'none' }}>
        {menuItem}
      </NavLink>
    );
  }
};