import { Save, Send } from '@mui/icons-material';
import { Stack, Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { FormMobileStepper } from '../../../../../components/FormMobileStepper';
import { useResponsive } from '../../../../../hooks';
import React from 'react';
import { LoadingButton } from '@mui/lab';

type ActivityDialogActionsProps = {
  isEditMode: boolean;
  isLoading: boolean;
  currentStepIndex: number;
  numberOfSteps: number;
  nextStep: () => void;
  previousStep: () => void;
  canContinue: boolean;
  onSubmit: () => void;
};

export const ActivityDialogActions = (props: ActivityDialogActionsProps) => {
  const { currentStepIndex, isLoading, isEditMode, numberOfSteps, nextStep, previousStep, canContinue, onSubmit } = props;
  const { isMobile } = useResponsive();
  const { formatMessage } = useIntl();
  const isLastStep = currentStepIndex === numberOfSteps - 1;

  return !isMobile ? (
    <Stack justifyContent='right' direction='row' mt={2} spacing={1}>
      {currentStepIndex > 0 && (
        <Button variant='contained' onClick={previousStep} color='inherit'>
          {formatMessage({ id: 'Back' })}
        </Button>
      )}
      {((isEditMode || currentStepIndex > 0) && currentStepIndex < numberOfSteps - 1) && (
        <Button variant='contained' onClick={nextStep} disabled={!canContinue}>
          {formatMessage({ id: 'Next' })}
        </Button>
      )}
      {isLastStep && (
        <LoadingButton
          startIcon={<Save />}
          loading={isLoading}
          color='primary'
          variant='contained'
          disabled={!canContinue}
          onClick={onSubmit}
        >
          {formatMessage({ id: 'Submit' })}
        </LoadingButton>
      )}
    </Stack>) : (
    <FormMobileStepper
      numberOfSteps={numberOfSteps}
      currentStep={currentStepIndex}
      nextStep={nextStep}
      previousStep={previousStep}
      disableNext={!canContinue}
      lastStepButton={(
        <LoadingButton endIcon={<Send />} size='small' onClick={onSubmit} loading={isLoading}>
          {formatMessage({ id: 'Submit' })}
        </LoadingButton>
      )}
    />
  );
};