import { Paper, Typography, Divider, Stack, LinearProgress } from '@mui/material';
import { CustomFieldDto, CustomFieldOptionDto, ReportFieldFilterInput, ReportParametersInput } from 'gql';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FilterInput } from '../FilterInput';

type CustomField = Pick<CustomFieldDto, 'id' | 'name' | 'customFieldType' | 'systemFieldId'> & {
  options: Pick<CustomFieldOptionDto, 'id' | 'name'>[];
};

interface Props {
  fields: CustomField[];
  loading?: boolean;
  disabled?: boolean;
}

export const ReportFieldsSection: React.FC<Props> = ({ fields, disabled, loading }) => {
  const { formatMessage } = useIntl();

  const { control } = useFormContext<ReportParametersInput>();
  const { fields: fieldFilters, append, update } = useFieldArray({ control, name: 'filters.customFieldFilters' });

  const onChange = (customFieldFilterInput: ReportFieldFilterInput) => {
    const index = fieldFilters.findIndex(f => f.customFieldId === customFieldFilterInput.customFieldId);
    if (index === -1) {
      append(customFieldFilterInput);
    } else {
      update(index, customFieldFilterInput);
    }
  };

  return (
    <Paper>
      <Typography variant='h6' p={2} pb={1}>{formatMessage({ id: 'Custom Fields' })}</Typography>

      <LinearProgress style={{ visibility: loading ? 'visible' : 'hidden' }} />
      <Divider />

      <Stack p={2} spacing={2}>
        {fields.map(field => (
          <FilterInput
            key={field.id}
            customField={field}
            value={fieldFilters.find(f => f.customFieldId === field.id) ?? { customFieldId: field.id }}
            onChange={onChange}
            disabled={disabled}
          />
        ))}
      </Stack>
    </Paper>
  );
};