import React from 'react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { NewButton, PageTitle, UsersTable } from 'components';
import { useIntl } from 'react-intl';
import { BuiltInRoles, useFindUsersQuery } from 'gql';
import { UserInviteDialog } from '../components/UserInviteDialog';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UsersPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const isShowDialog = searchParams.get('invite') === 'true';

  const handleOpenAddDialog = () => {
    setSearchParams({ invite: 'true' });
  };

  const handleCloseDialog = () => {
    searchParams.delete('invite');
    setSearchParams(searchParams);
  };

  const handleOnCellClick = (userId: string) => {
    navigate(`${userId}`);
  };

  const { data: users, isFetching } = useFindUsersQuery();

  const onInviteComplete = () => {
    handleCloseDialog();
    toast.success(formatMessage({ id: 'User Invited Successfully' }));
  };

  return (
    <>
      <PageTitle
        icon={<ManageAccountsIcon />}
        title={formatMessage({ id: 'Users' })}
        actionButton={(
          <NewButton onClick={handleOpenAddDialog} label={formatMessage({ id: 'Invite' })} />
        )}
      />

      <UsersTable
        onViewDetails={handleOnCellClick}
        loading={isFetching}
        users={users?.activeUsers ?? []}
      />

      <UserInviteDialog
        open={isShowDialog}
        onClose={handleCloseDialog}
        defaultRole={BuiltInRoles.StreetOutreachWorker}
        allowedRoles={[BuiltInRoles.StreetOutreachWorker, BuiltInRoles.Analyst, BuiltInRoles.TenantAdministrator]}
        onInviteComplete={onInviteComplete}
      />
    </>
  );
};