import React from 'react';
import { ActivityType, GetLatestActivitiesQuery, SystemFieldId, useGetLatestActivitiesQuery } from 'gql';
import { useIntl } from 'react-intl';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { DashboardSimpleTable } from './DashboardSimpleTable';
import { useNavigate } from 'react-router-dom';
import { ActivityTypeColor } from 'modules/activities/types';
import { Box, Skeleton, styled, Typography, useTheme } from '@mui/material';
import { activityTypeMessages } from 'modules/activities/messages';
import dayjs from 'dayjs';
import { Filter } from 'modules/home/hooks/useGetActivitiesFilter';
import { dashboardQueryOptions } from '../../utils';

type Activity = NonNullable<NonNullable<Pick<GetLatestActivitiesQuery, 'latestActivities'>['latestActivities']>>[0];

interface OwnProps {
  activitiesFilter: Filter
}

const ColoredStrip = styled('div')<{
  activityType: ActivityType;
}>(({ activityType }) => ({
  height: '100%',
  borderLeftStyle: 'solid',
  borderLeftWidth: '5px',
  borderColor: ActivityTypeColor[activityType],
}));

export const LatestActivitiesTable: React.FC<OwnProps> = ({ activitiesFilter }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: latestActivitiesData, isFetching: isLatestActivitiesLoading } = useGetLatestActivitiesQuery(activitiesFilter, dashboardQueryOptions());
  const latestActivities = latestActivitiesData?.latestActivities ?? [];

  const formatDateValue = (value: Date) => {
    return dayjs(value).format('YYYY-MM-DD');
  };

  const columns: GridColDef<Activity>[] = [
    {
      field: 'activityType',
      flex: 1,
      minWidth: 120,
      headerName: formatMessage({ id: 'Activity type' }),
      valueGetter: ({ row }) => row.type,
      renderCell: ({ row }) => row.type ? (
        <>
          <ColoredStrip activityType={row.type} />
          <Box pl={2}>
            <Typography variant='body1' fontWeight={500}>
              {formatMessage(activityTypeMessages[row.type])}
            </Typography>
          </Box>
        </>
      ) : (
        <Box pl={2}>
          <Skeleton variant='text' width={theme.spacing(10)} />
        </Box >
      )
    },
    {
      field: 'interventionDate',
      flex: 1,
      minWidth: 120,
      headerAlign: 'center',
      align: 'center',
      headerName: formatMessage({ id: 'Activity Date' }),
      valueGetter: ({ row }) => row.customFieldValues ? formatDateValue(row.customFieldValues.find(v => v.field.systemFieldId === SystemFieldId.InterventionDate)?.dateValue) : '',
      renderCell: ({ row }) => row.customFieldValues ? (
        <Typography variant='body1'>
          {formatDateValue(row.customFieldValues.find(v => v.field.systemFieldId === SystemFieldId.InterventionDate)?.dateValue)}
        </Typography>
      ) : <Skeleton variant='text' width={theme.spacing(10)} />
    },
    {
      field: 'createdAtDate',
      flex: 1,
      minWidth: 120,
      headerAlign: 'right',
      align: 'right',
      headerName: formatMessage({ id: 'Entry date' }),
      valueGetter: ({ row }) => formatDateValue(row.createdAt),
      renderCell: ({ row }) => row.createdAt ? (
        <Typography variant='body1'>
          {formatDateValue(row.createdAt)}
        </Typography>
      ) : <Skeleton variant='text' width={theme.spacing(10)} />
    }
  ];

  const handleOnCellClick = (params: GridCellParams<Activity>) => {
    navigate(`activities/${params.row.id}`);
  };

  const loadingRow = new Array(5).fill(1).map((_, i) => (
    {
      id: i
    }
  ));

  return (
    <DashboardSimpleTable
      defaultHeight
      columns={columns}
      rows={isLatestActivitiesLoading ? loadingRow : latestActivities}
      onCellClick={handleOnCellClick}
      noDataMessage={formatMessage({ id: 'There are no activities yet' })}
      sectionTitle={formatMessage({ id: 'LatestActitivitiesTable.mostRecent' })}
      buttonLabel={formatMessage({ id: 'LatestActitivitiesTable.seeAll' })}
      onClickButton={() => navigate('activities')}
    />
  );
};