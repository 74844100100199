import React from 'react';
import { useGetCurrentUserQuery } from 'gql';
import { PageLayout } from './PageLayout';
import { LoadingScreen } from 'components/LoadingScreen/LoadingScreen';

export const TenantLayout: React.FC = () => {
  const { isFetched } = useGetCurrentUserQuery();

  if (!isFetched) {
    return <LoadingScreen />;
  }

  return (
    <PageLayout />
  );
};