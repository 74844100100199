import React from 'react';
import { Divider, ListItem, ListItemIcon, ListItemText, Paper, PaperProps, Stack, styled, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

interface DangerRow {
  primary: string;
  secondary?: string;
  button?: React.ReactNode;
}

interface Props {
  rows: DangerRow[];
}

const StyledPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.palette.error.main,
  '& .MuiDivider-root': {
    borderColor: theme.palette.error.main
  }
}));

export const DangerZone: React.FC<Props> = ({ rows }) => {
  const { formatMessage } = useIntl();

  return (
    <StyledPaper>
      <Stack paddingX={2} paddingY={3}>
        <Typography variant='h5'>
          {formatMessage({ id: 'Danger zone' })}
        </Typography>
      </Stack>
      <Divider />

      {rows.map((row, index) => (
        <ListItem
          key={index}
          divider={index < rows.length}
          sx={{ py: 2 }}
        >
          <ListItemText
            primary={(
              <Typography fontWeight={600}>
                {row.primary}
              </Typography>
            )}
            secondary={row.secondary}
          />

          <ListItemIcon>
            {row.button}
          </ListItemIcon>
        </ListItem>
      ))}
    </StyledPaper>
  );
};