import { Alert, Button, Stack } from '@mui/material';
import { EmptyState, SectionPaper } from 'components';
import { DistributionValueInput, useGetDistributionItemsByIdsQuery } from 'gql';
import React, { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ActivityFormValues } from '../ActivityDialog';
import { DistributionListItem } from './components/DistributionListItem';
import { DistributionDrawer } from './DistributionDrawer/DistributionDrawer';

export const DistributionConfigurationStep: React.FC = () => {
  const { formatMessage } = useIntl();
  const { control, formState: { errors }, clearErrors } = useFormContext<ActivityFormValues>();
  const distributions = useFieldArray<ActivityFormValues, 'distributions', 'uniqueId'>({
    name: 'distributions',
    keyName: 'uniqueId',
    control,
  });

  const error = errors.distributions;

  const [isDistributionDialogOpen, setDistributionDialogOpen] = useState(false);
  const [editingItems, setEditingItems] = useState<DistributionValueInput[] | undefined>();
  const { data: distributionItemData } = useGetDistributionItemsByIdsQuery({ itemIds: distributions.fields.map(item => item.itemId) });

  const closeDialog = () => {
    setEditingItems(undefined);
    setDistributionDialogOpen(false);
  };

  const onDistributionSubmit = (distributionItems: DistributionValueInput[]) => {
    if (distributionItems.filter(i => i.distributedAmount > 0).length > 0) {
      clearErrors('distributions');
    }
    distributions.replace(distributionItems);
    closeDialog();
  };

  const handleOpenDistributionDialog = () => {
    if (distributions.fields.length > 0) {
      setEditingItems(distributions.fields);
    }

    setDistributionDialogOpen(true);
  };

  return (
    <Stack spacing={2}>
      {error?.message && (
        <Alert severity='error'>
          {error.message}
        </Alert>
      )}

      <SectionPaper
        title={formatMessage({ id: 'Distributions' })}
        onAdd={handleOpenDistributionDialog}
      >
        {distributions.fields.map((item, index) =>
          <DistributionListItem
            key={item.uniqueId}
            item={distributionItemData?.items.find(v => v.id === item.itemId)}
            itemValue={item}
            onClick={handleOpenDistributionDialog}
            onDelete={() => distributions.remove(index)}
          />
        )}
        {distributions.fields.length === 0 &&
          <EmptyState
            title={formatMessage({ id: 'No items' })}
            subtitle={formatMessage({ id: 'Add a new item' })}
            callToActionComponent={(
              <Button variant='contained' onClick={handleOpenDistributionDialog}>
                {formatMessage({ id: 'Add an item' })}
              </Button>
            )}
          />
        }
      </SectionPaper>

      <DistributionDrawer
        open={isDistributionDialogOpen}
        defaultValues={editingItems}
        onSubmit={onDistributionSubmit}
        onClose={closeDialog}
      />
    </Stack>
  );
};