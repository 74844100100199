import { Stack, DrawerProps, LinearProgress, Typography } from '@mui/material';
import { ResponsiveDrawerBase } from './ResponsiveDrawerBase';
import React, { ReactNode } from 'react';
import { DrawerHeader } from './DrawerHeader';

export interface ResponsiveDrawerProps extends Omit<DrawerProps, 'title'> {
  header: ReactNode | string;
  children?: ReactNode;
  defaultWidth?: string;
  isLoading?: boolean;
  disablePadding?: boolean;
  actionButtons?: ReactNode;
  drawerHeaderOnClose?: () => void;
}

export const ResponsiveDrawer: React.FC<ResponsiveDrawerProps> = ({ header, children, isLoading, disablePadding, defaultWidth, actionButtons, drawerHeaderOnClose, ...drawerProps }) => {
  if (!defaultWidth) defaultWidth = '400px';

  return (
    <ResponsiveDrawerBase anchor="right" defaultWidth={defaultWidth} {...drawerProps}>
      <DrawerHeader onClose={drawerHeaderOnClose ?? drawerProps.onClose}>
        {typeof header === 'string' ? (
          <Typography variant='body1' fontWeight='500' pl={2} width='100%'>
            {header}
          </Typography>
        ) : header}
        {actionButtons && (
          <Stack direction='row' pr={1}>
            {actionButtons}
          </Stack>
        )}
      </DrawerHeader>

      {isLoading && (<LinearProgress color="primary" />)}

      {disablePadding ? (
        children
      ) : (
        <Stack p={2} spacing={1}>
          {children}
        </Stack>
      )}
    </ResponsiveDrawerBase >
  );
};

ResponsiveDrawer.defaultProps = {
  defaultWidth: '400px',
  anchor: 'right'
};