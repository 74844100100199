import { DistributionItemDto } from 'gql';
import React from 'react';
import { DistributionListItem } from './DistributionListItem';
import { Controller, Control } from 'react-hook-form';
import { useIntl } from 'react-intl';

type Item = Pick<DistributionItemDto, 'id' | 'name' | 'description' | 'unit'>;

interface FormValues {
  distributions: string[];
}

interface Props {
  item: Item;
  items: Item[];
  control: Control<FormValues>;
}

export const ItemController = ({ item, items, control }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Controller
      key={item.id}
      control={control}
      name={`distributions.${items.findIndex(i => i.id === item.id)}`}
      rules={{
        validate: {
          isPositive: value => (Number(value) >= 0) ? true : formatMessage({ id: 'Value must be positive' })
        }
      }}
      defaultValue="0"
      render={({ field, fieldState: { error } }) => (
        <DistributionListItem
          item={item}
          value={field.value}
          onChange={field.onChange}
          error={Boolean(error)}
        />
      )}
    />
  );
};
