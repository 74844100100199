import { DrawerProps, Stack, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FormDrawer } from 'components';
import { ApplicationUser, useEditUserDetailsMutation } from 'gql';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface FormValues {
  firstName: string;
  lastName: string;
}

interface Props extends DrawerProps {
  user?: Pick<ApplicationUser, 'id' | 'firstName' | 'lastName'>;
}

export const UserDetailsDrawer: React.FC<Props> = ({ user, ...drawerProps }) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const { control, handleSubmit, reset, formState: { isDirty } } = useForm<FormValues>();

  useEffect(() => {
    if (user) {
      reset({ firstName: user.firstName, lastName: user.lastName });
    } else {
      reset({ firstName: '', lastName: '' });
    }
  }, [reset, user, drawerProps.open]);

  const editUserDetailsMutation = useEditUserDetailsMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['getUserById']);
      drawerProps.onClose?.({}, 'escapeKeyDown');
    }
  });

  const loading = editUserDetailsMutation.isLoading;

  const onSubmit = (data: FormValues) => {
    if (!user) return;

    editUserDetailsMutation.mutate({
      input: {
        id: user.id,
        firstName: data.firstName,
        lastName: data.lastName
      }
    });
  };

  return (
    <FormDrawer
      header={formatMessage({ id: 'Edit User Details' })}
      showFooter
      onSave={handleSubmit(onSubmit)}
      isFormDirty={isDirty}
      isLoading={loading}
      {...drawerProps}
    >
      <Stack spacing={2} pt={2}>
        <Controller
          control={control}
          name='firstName'
          rules={{ required: formatMessage({ id: 'First name is required' }) }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={formatMessage({ id: 'First name' })}
              {...field}
              disabled={loading}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='lastName'
          rules={{ required: formatMessage({ id: 'Last name is required' }) }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={formatMessage({ id: 'Last name' })}
              {...field}
              disabled={loading}
              error={Boolean(error)}
              helperText={error?.message}
            />
          )}
        />
      </Stack>
    </FormDrawer>
  );
};