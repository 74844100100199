import React from 'react';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { DraggableOptionListItem } from './DraggableOptionListItem';
import { AddCustomPropertyInput, EditCustomPropertyInput } from 'gql/index';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, TextField, Typography, Stack, List } from '@mui/material';
import { useIntl } from 'react-intl';
import { Add } from '@mui/icons-material';

export const DragAndDropOptions: React.FC = () => {
  const { formatMessage } = useIntl();
  const { control } = useFormContext<AddCustomPropertyInput | Omit<EditCustomPropertyInput, 'id'>>();

  const { fields, append, remove, move } = useFieldArray({
    name: 'customOptions',
    keyName: 'uniqueId',
    control
  });

  const handleAddNewOptionField = () => {
    append({
      name: '',
      order: fields.length != 0 ? fields[fields.length - 1].order + 1 : 0
    });
  };

  const handleDeleteOption = (index: number) => {
    remove(index);
  };

  const onDragEnd = ({ destination, source }: DropResult) => {

    if (!destination)
      return;
    if (destination.index === source.index)
      return;

    move(source.index, destination.index);
  };

  return (
    <Stack>
      <Stack alignItems='center' justifyContent='space-between' direction='row' p={2}>
        <Typography variant='h6'>
          {formatMessage({ id: 'Values' })}
        </Typography>
        <Button
          color='primary'
          variant='outlined'
          onClick={handleAddNewOptionField}
          startIcon={<Add />}
        >
          {formatMessage({ id: 'Add' })}
        </Button>
      </Stack>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='options'>
          {(provided) => (
            <List disablePadding ref={provided.innerRef} {...provided.droppableProps} >
              {fields.map((option, index) => (
                <DraggableOptionListItem
                  option={option}
                  index={index}
                  key={option.uniqueId}
                  divider={index !== fields.length - 1}
                  onDelete={() => handleDeleteOption(index)}
                >
                  <Controller
                    control={control}
                    name={`customOptions.${index}.name`}
                    render={({ field }) => (
                      <TextField
                        autoFocus={field.value.length === 0}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </DraggableOptionListItem>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Stack>
  );
};