import React from 'react';
import { Box, Stack } from '@mui/material';
import { collapsedMenuWidth, expandedMenuWidth, NavigationMenu, NavigationMenuSpacer } from './components/NavigationMenu';
import { TopBar } from './components/TopBar';
import { PageContent } from './components/PageContent';
import { SettingsDrawer } from './components/SettingsDrawer';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isNavigationMenuCollapsedState } from '../atoms';

export const PageLayout: React.FC = () => {
  const isNavigationMenuCollapsed = useRecoilValue(isNavigationMenuCollapsedState);
  return (
    <Stack>
      <TopBar />
      <NavigationMenu />
      <SettingsDrawer />

      <PageContent>
        <>
          <NavigationMenuSpacer />
          <Box sx={{ width: isNavigationMenuCollapsed ? `${collapsedMenuWidth}px` : `${expandedMenuWidth}px`, flexGrow: 1 }}>
            <Outlet />
          </Box>
        </>
      </PageContent>
    </Stack>
  );
};