import { Box, BoxProps, Divider, Typography } from '@mui/material';
import React from 'react';

interface OwnProps {
  title?: string;
  children?: React.ReactNode;
}

export type NavigationMenuSectionProps =
  & BoxProps
  & OwnProps;

export const NavigationMenuSection: React.FC<NavigationMenuSectionProps> = (props) => {
  const { title, children, ...boxProps } = props;

  return (
    <Box {...boxProps}>
      <Divider sx={{ marginTop: 1, marginBottom: title ? 1 : 0 }} />
      {title &&
        <Typography variant="subtitle2" color='primary.contrastText' sx={{ paddingX: 2 }}>
          {title}
        </Typography>
      }

      {children}
    </Box>
  );
};