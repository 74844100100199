import React from 'react';
import { Add, Remove } from '@mui/icons-material';
import { Stack, TextField, styled } from '@mui/material';
import { IncrementIconButton } from './IncrementIconButton';

interface OwnProps {
  value: string | number;
  minValue?: number;
  error?: boolean;
  onChange: (value: string | number) => void;
}

export type NumericInputProps =
  & OwnProps;

const StyledTextField = styled(TextField)(() => ({
  minWidth: '64px',
  maxWidth: '64px',
  '& .MuiInputBase-root': {
    height: '42px',
  },
}));

const parseNumber = (value: string | number) => isNaN(Number(value)) ? 0 : Number(value);

export const NumericInput: React.FC<NumericInputProps> = (props) => {
  const { value, minValue, error, onChange } = props;

  const numericValue = parseNumber(value);
  const decrement = () => onChange(Math.max(minValue ?? 0, numericValue - 1));
  const increment = () => onChange(numericValue + 1);

  return (
    <Stack direction='row' justifyContent="center" alignItems="center" spacing={1} pl={1}>
      <IncrementIconButton onClick={decrement}>
        <Remove />
      </IncrementIconButton>

      <StyledTextField
        size='small'
        inputMode='numeric'
        value={value}
        error={error}
        onChange={e => onChange(e.target.value)}
        onBlur={e => onChange(parseNumber(e.target.value))}
      />

      <IncrementIconButton onClick={increment}>
        <Add />
      </IncrementIconButton>
    </Stack>
  );
};