import { ListItem, ListItemButton, ListItemText, Stack } from '@mui/material';
import { LookupInput } from 'components';
import { CustomFieldOptionDto } from 'gql';
import { SelectionFilterInput as SelectionFilter } from 'gql';
import React from 'react';

interface Props {
  label: string;
  options: Pick<CustomFieldOptionDto, 'id' | 'name'>[];
  value?: SelectionFilter | null;
  onChange: (value: SelectionFilter) => void;
  disabled?: boolean;
}

export const SelectionFilterInput: React.FC<Props> = ({ label, options, value, onChange, disabled }) => {
  return (
    <Stack direction={'row'} spacing={2} alignItems='center'>
      <LookupInput
        multiple
        label={label}
        selectedValues={options.filter(o => value?.optionIds.includes(o.id))}
        availableValues={options}
        getKey={option => option.id}
        onChange={options => onChange({ optionIds: options.map(o => o.id) })}
        getLabel={option => option.name}
        searchFilter={(option, searchValue) => option.name.includes(searchValue)}
        renderListItem={(option, selected, onClick) => (
          <ListItem disablePadding>
            <ListItemButton selected={selected} onClick={onClick}>
              <ListItemText
                primary={option.name}
              />
            </ListItemButton>
          </ListItem>
        )}
        disabled={disabled}
      />
    </Stack>
  );
};