import React from 'react';
import { useIntl } from 'react-intl';
import { ListItem, ListItemButton, ListItemButtonProps, ListItemText } from '@mui/material';
import { DeleteIconButton } from 'components/DeleteIconButton';

interface OwnProps {
  numberOfIndividuals: number;
  averageAge: number;
  gender: string;
  onDelete: () => void;
}

export type UnknownGroupListItemProps =
  & ListItemButtonProps
  & OwnProps;

export const UnknownGroupListItem: React.FC<UnknownGroupListItemProps> = (props) => {
  const { formatMessage } = useIntl();
  const { numberOfIndividuals, averageAge, gender, onDelete, ...listItemButtonProps } = props;

  return (
    <ListItem disablePadding secondaryAction={<DeleteIconButton edge="end" onClick={onDelete} />}>
      <ListItemButton {...listItemButtonProps}>
        <ListItemText
          primary={formatMessage({ id: 'Group of {count}' }, { count: numberOfIndividuals })}
          secondary={formatMessage({ id: '{gender} ({averageAge} years old)' }, { gender, averageAge })}
        />
      </ListItemButton>
    </ListItem>
  );
};