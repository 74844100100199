import { Add, Delete } from '@mui/icons-material';
import { Button, Divider, IconButton, InputAdornment, List, ListItem, Paper, Stack, TextField as MuiTextField, Typography, styled } from '@mui/material';
import { ReportParametersInput } from 'gql';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

const TextField = styled(MuiTextField)(({ theme }) => ({
  width: theme.spacing(10)
}));

type FocusEvent = React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>;

interface Props {
  disabled?: boolean;
}

export const ReportAgeGroupSelector: React.FC<Props> = ({ disabled }) => {
  const { formatMessage } = useIntl();

  const { control, setValue } = useFormContext<ReportParametersInput>();
  const ageGroupBoundaries = useWatch({ control, name: 'ageGroupBoundaries' });

  const [newAgeGroupShown, setNewAgeGroupShown] = React.useState(false);

  const onBlurEditFunc = (ageGroup: number, index: number) => (e: FocusEvent) => {
    const value = e.target.value;

    if (value === '') {
      const values = [...ageGroupBoundaries];
      values.splice(index, 1);
      setValue('ageGroupBoundaries', values);
      return;
    }

    const number = Math.abs(Number(value));

    if (number == ageGroup) return;

    if (number
      && !isNaN(number)
      && !ageGroupBoundaries.includes(number)
      && number >= 0
    ) {
      const values = [...ageGroupBoundaries];
      values[index] = number;
      setValue('ageGroupBoundaries', values.sort((a, b) => a - b));
      return;
    }

    e.target.value = ageGroup.toString();
  };

  const onBlurAdd = (e: FocusEvent) => {
    const value = e.target.value;
    if (value && !isNaN(Number(value)) && !ageGroupBoundaries.includes(Number(value))) {
      const values = [...ageGroupBoundaries, Number(value)].sort((a, b) => a - b);
      setValue('ageGroupBoundaries', values);
    }
    setNewAgeGroupShown(false);
  };

  const onDelete = (index: number) => () => {
    const values = [...ageGroupBoundaries];
    values.splice(index, 1);
    setValue('ageGroupBoundaries', values);
  };

  return (
    <Paper>
      <Stack p={2} alignItems='center' justifyContent='space-between' direction='row'>
        <div>
          <Typography variant="h6">
            {formatMessage({ id: 'Modify Age Grouping' })}
          </Typography>
        </div>

        <Button startIcon={<Add />} variant='contained' onClick={() => setNewAgeGroupShown(true)} disabled={disabled}>
          {formatMessage({ id: 'Add' })}
        </Button>
      </Stack>
      <Divider />

      <List disablePadding>
        {ageGroupBoundaries.map((ageGroup, index) => (
          <ListItem
            key={ageGroup}
            sx={{
              '&:hover > button': {
                visibility: 'visible'
              }
            }}
            divider={index !== ageGroupBoundaries.length - 1}
          >
            <TextField
              size='small'
              key={ageGroup}
              defaultValue={ageGroup.toString()}

              disabled={disabled || index === 0}

              InputProps={{ startAdornment: <InputAdornment position='start'>{'≥'}</InputAdornment> }}
              inputProps={{ maxLength: 3 }}

              onBlur={onBlurEditFunc(ageGroup, index)}
            />

            <Typography flexGrow={1} pl={1}>
              {index !== ageGroupBoundaries.length - 1 && (
                formatMessage({ id: ' to {age} years old' }, { age: ageGroupBoundaries[index + 1] - 1 })
              )}
            </Typography>

            {index !== 0 && (
              <IconButton
                onClick={onDelete(index)}
                sx={{
                  visibility: { xs: 'visible', lg: 'hidden' }
                }}
                disabled={disabled}
              >
                <Delete />
              </IconButton>
            )}
          </ListItem>
        ))}

        {newAgeGroupShown && (
          <ListItem>
            <TextField
              size='small'
              autoFocus
              InputProps={{ startAdornment: <InputAdornment position='start'>{'≥'}</InputAdornment> }}
              inputProps={{ maxLength: 3 }}
              onBlur={onBlurAdd}
              disabled={disabled}
            />
          </ListItem>
        )}
      </List>
    </Paper>
  );
};