import React from 'react';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import { ApolloProvider } from '@apollo/client';
import { I18nProvider } from './I18nProvider';
import { ThemeProvider } from './ThemeProvider';
import { apolloClient } from '../apollo-client';
import 'react-toastify/dist/ReactToastify.css';
import { TelemetryProvider } from './TelemetryProvider';
import { RoutesProvider } from './RoutesProvider';
import { Helmet } from 'react-helmet-async';
import { getTenantIdentifier } from 'utils/tenantUtils';


export const App: React.FC = () => {
  const tenantIdentifier = getTenantIdentifier();

  return (
    <RecoilRoot>
      <I18nProvider>
        <ThemeProvider>
          <TelemetryProvider>
            <ApolloProvider client={apolloClient}>
              <Helmet>
                <title>{!tenantIdentifier ? 'ROCQTR' : tenantIdentifier.toUpperCase()}</title>
              </Helmet>
              <RoutesProvider />
            </ApolloProvider>
            <ToastContainer />
          </TelemetryProvider>
        </ThemeProvider>
      </I18nProvider>
    </RecoilRoot>
  );
};