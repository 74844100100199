import React, { useEffect } from 'react';
import { AddTeamInput, Team } from 'gql';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { CancelButton, PaperForm } from 'components';
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface OwnProps {
  disabled: boolean
  team?: Team
  onSubmit: (teamData: AddTeamInput) => void
  onClose: () => void
}

type FormValues = Pick<AddTeamInput, 'description' | 'name'>

export const TeamForm:React.FC<OwnProps> = ({ disabled, team, onSubmit, onClose }) => {
  const { formatMessage } = useIntl();
  const { control, reset, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      name: '',
      description: ''
    }
  });

  useEffect(() => {
    if(team) {
      reset({
        name: team.name,
        description: team.description
      });
    }
  }, [reset, team]);

  return (
    <Stack>
      <PaperForm>
        <Stack spacing={3}>
          <Typography variant='h5'>{formatMessage({ id: 'Information' }) }</Typography>
          <Controller
            control={control}
            name='name'
            rules={{ required: formatMessage({ id: 'The team name is required' }) }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                disabled={disabled}
                label={formatMessage({ id: 'Name' })}
                fullWidth
                error={fieldState.error?.message != null}
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='description'
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                disabled={disabled}
                multiline
                rows={4}
                label={formatMessage({ id: 'Description' })}
                fullWidth
                error={fieldState.error?.message != null}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Stack>
      </PaperForm>
      <Stack direction='row' justifyContent='flex-end' spacing={2}>
        <CancelButton onClick={onClose} disabled={disabled} />
        <LoadingButton loading={disabled} variant='contained' type='submit' onClick={handleSubmit(onSubmit)}>
          {team ? formatMessage({ id: 'Save' }) : formatMessage({ id: 'Add' })}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};