import React from 'react';
import { FormControl, FormControlProps, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useIntl } from 'react-intl';
import { useGetDropdownTeams } from 'modules/home/hooks/useGetDropdownTeams';

interface TeamDropdownProps {
  value?: string
  onChangeValue: (value: string) => void
}

type OwnProps =
  & TeamDropdownProps
  & FormControlProps;

export const TeamDropdownFilter: React.FC<OwnProps> = ({ value, onChangeValue, ...formControlProps }) => {
  const { formatMessage } = useIntl();
  const { teams } = useGetDropdownTeams();

  const handleChange = (event: SelectChangeEvent) => {
    onChangeValue(event.target.value);
  };

  return (
    <FormControl {...formControlProps}>
      <InputLabel>{formatMessage({ id: 'Team' })}</InputLabel>
      <Select
        label={formatMessage({ id: 'Team' })}
        value={value}
        onChange={handleChange}
      >
        <MenuItem value={'all'}>{formatMessage({ id: 'All teams' })}</MenuItem>
        {teams.map(team =>
          <MenuItem value={team.id} key={team.id}>{team.name}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};