import React from 'react';
import { ListItem } from '@mui/material';
import { DistributionItemDto } from 'gql';
import { DistributionListItemText } from './DistributionListItemText';
import { DistributionDetailsDialog } from './DistributionDetailsDialog';
import { NumericInput } from 'components/NumericInput';

interface Props {
  item: Pick<DistributionItemDto, 'name' | 'description' | 'unit'>;
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
  hidden?: boolean;
}

export const DistributionListItem: React.FC<Props> = ({ item, value, onChange, error, hidden }) => {
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = React.useState(false);
  const openDetailsDialog = () => setIsDetailsDialogOpen(true);
  const closeDetailsDialog = () => setIsDetailsDialogOpen(false);

  return (
    <ListItem sx={{ display: hidden ? 'none' : 'flex', }} >
      <DistributionListItemText item={item} onViewDetails={openDetailsDialog} />
      <NumericInput value={value} error={error} onChange={newValue => onChange(newValue.toString())} />

      <DistributionDetailsDialog
        open={isDetailsDialogOpen}
        item={item}
        onClose={closeDetailsDialog}
        sx={{ zIndex: theme => theme.zIndex.modal + 1 }}
      />
    </ListItem>
  );
};