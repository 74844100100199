import { Add, Delete } from '@mui/icons-material';
import { Button, IconButton, ListItem, ListItemButton, ListItemText, Stack, debounce } from '@mui/material';
import { EmptyState, LookupInputDrawer, SectionPaper } from 'components';
import { ActivityIndividualInput, EditIndividualInput, EntityType, IndividualFilterInput, InputMaybe, SystemFieldId, useFindCustomFieldsQuery } from 'gql';
import { useFindIndividualsCancellableQuery } from 'gql/cancellableQueries';
import uniq from 'lodash/uniq';
import { useBirthdayFormatter } from 'modules/individuals/utils';
import React, { useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getFormValueFromFieldValue } from 'utils/customFieldsUtils';
import { isNotNullOrUndefined } from 'utils/isNotNullOrUndefined';
import { IndividualDialog } from '../../../../../../individuals/views';
import { LookupType } from '../../../../../types';
import { ActivityFormValues, mapIndividualsLookupToFormValues } from '../../../ActivityDialog';
import { IndividualListItem } from './IndividualListItem';


export const ManageIndividualsSection: React.FC = () => {
  const { formatMessage } = useIntl();

  const { control, clearErrors } = useFormContext<ActivityFormValues>();
  const { fields, append, update, remove, replace } = useFieldArray<ActivityFormValues, 'individuals', 'uniqueId'>({
    name: 'individuals',
    keyName: 'uniqueId',
    control
  });

  const handleSearch = useMemo(() => debounce((searchTerm: string) => setSearchTerm(searchTerm), 500), []);

  const values = useWatch({ control, name: 'values' });
  const selectedTeamId = Number(values.find(v => v.systemFieldId === SystemFieldId.Team)?.value) || undefined;

  const [searchTerm, setSearchTerm] = useState('');

  const individualsFilter: IndividualFilterInput | undefined = useMemo(() => {
    const filters: InputMaybe<IndividualFilterInput[]> = [];

    if (selectedTeamId != null) {
      filters.push({
        customFieldValues: {
          some: {
            lookupTeamValue: {
              id: { eq: selectedTeamId }
            }
          }
        }
      });
    }

    return { and: filters };
  }, [selectedTeamId]);



  const { data: fieldsData } = useFindCustomFieldsQuery({
    filter: { entityTypes: { some: { entityType: { eq: EntityType.Individual } } } }
  });

  const getSystemFieldValue = (individual: ActivityIndividualInput, systemFieldId: SystemFieldId) => {
    return individual.values.find(p => p.customFieldId == fieldsData?.customFields.find(q => q.systemFieldId === systemFieldId)?.id)?.displayString
      ?? individual.values.find(p => p.customFieldId == fieldsData?.customFields.find(q => q.systemFieldId === systemFieldId)?.id)?.value;
  };

  const { formattedAge } = useBirthdayFormatter();

  const [isIndividualDialogOpen, setIsIndividualDialogOpen] = useState(false);


  const { data: individualsData, isFetching } = useFindIndividualsCancellableQuery({ pageSize: 25, skip: 0, input: individualsFilter, searchTerm: searchTerm?.trim() ?? '' });
  const individuals = individualsData?.searchIndividuals?.items ?? [];

  const availableValues: LookupType[] = [
    ...individuals.map<LookupType>(p => ({
      id: p.id.toString(),
      customFieldValues: p.customFieldValues.map(getFormValueFromFieldValue)
    })),
    ...fields.filter(p => !p.id)
      .map<LookupType>(individual => ({
        id: individual.uniqueId,
        customFieldValues: individual.values,
      }))
  ];

  const selectedValues = fields.map<LookupType>(individual => ({
    id: individual.id?.toString() ?? individual.uniqueId,
    customFieldValues: individual.values,
  }));

  const [individualToEdit, setIndividualToEdit] = useState<ActivityIndividualInput & { uniqueId?: string; }>();

  const [isLookupInputDrawerOpen, setIsLookupInputDrawerOpen] = useState(false);

  const removeIndividual = (id: number) => {
    remove(id);
  };

  const openEditDialog = (individual: ActivityIndividualInput) => {
    setIndividualToEdit(individual);
    setIsIndividualDialogOpen(true);
  };


  const onCancel = () => {
    setIndividualToEdit(undefined);
    setIsIndividualDialogOpen(false);
  };

  const onChange = (selectedItems: LookupType[]) => {
    const selectedItemIds = uniq(selectedItems.map(p => p.id));

    const existingIndividuals = selectedItems
      .filter(individual => fields.find(p => p.uniqueId === individual.id) == null)
      .filter(isNotNullOrUndefined);

    const existingIndividualsFormValues = mapIndividualsLookupToFormValues(existingIndividuals) ?? [];
    const individualsToCreate = selectedItemIds
      .map(id => fields.find(p => p.uniqueId === id))
      .filter(isNotNullOrUndefined);

    if (existingIndividuals.length + individualsToCreate.length > 0) {
      clearErrors('individuals');
    }

    replace([...existingIndividualsFormValues, ...individualsToCreate]);
  };

  const onIndividualCreateSuccess = (individual: EditIndividualInput) => {
    const fieldIndex = fields.findIndex(p => p.uniqueId === individualToEdit?.uniqueId);
    if (fieldIndex === -1) {
      append({ ...individual, edited: true });
    } else {
      update(fieldIndex, { ...individual, edited: true });
    }

    clearErrors('individuals');
    setIndividualToEdit(undefined);
    setIsIndividualDialogOpen(false);
  };

  return (
    <Stack>
      <SectionPaper
        title={formatMessage({ id: 'Individuals' })}
        onAdd={() => setIsLookupInputDrawerOpen(true)}
      >
        {fields.map((individual, index) => (
          <ListItem disablePadding key={individual.uniqueId} secondaryAction={(
            <IconButton onClick={() => removeIndividual(index)}>
              <Delete />
            </IconButton>
          )}>
            <ListItemButton onClick={() => openEditDialog(individual)}>
              <ListItemText
                primary={getSystemFieldValue(individual, SystemFieldId.NickName)}
                secondary={formattedAge(getSystemFieldValue(individual, SystemFieldId.Birthday))}
              />
            </ListItemButton>
          </ListItem>
        ))}
        {fields.length === 0 &&
          <EmptyState
            title={formatMessage({ id: 'No individuals' })}
            subtitle={formatMessage({ id: 'Add known individuals associated with this activity.' })}
            callToActionComponent={(
              <Button variant='contained' onClick={() => setIsLookupInputDrawerOpen(true)}>
                {formatMessage({ id: 'Add known individuals' })}
              </Button>
            )}
          />
        }
      </SectionPaper>

      <IndividualDialog
        disableTeamSelect
        individual={individualToEdit}
        open={isIndividualDialogOpen}
        onCancel={onCancel}
        onSuccess={onIndividualCreateSuccess}
      />

      <LookupInputDrawer
        open={isLookupInputDrawerOpen}
        setOpen={setIsLookupInputDrawerOpen}
        availableValues={availableValues ?? []}
        selectedValues={selectedValues ?? []}
        title={formatMessage({ id: 'Individuals' })}
        getKey={i => i.id}
        getLabel={i => {
          const nicknameField = i.customFieldValues.find(p => p.systemFieldId === SystemFieldId.NickName);
          return nicknameField?.displayString ?? nicknameField?.value ?? '';
        }}
        onChange={onChange}

        renderListItem={(individual, selected, onClick) => (
          <IndividualListItem
            individual={individual}
            selected={selected}
            onClick={onClick}
            divider
          />
        )}
        loading={isFetching}
        multiple={true}
        actionButton={(
          <IconButton onClick={() => {
            setIndividualToEdit(undefined);
            setIsIndividualDialogOpen(true);
          }}>
            <Add />
          </IconButton>
        )}
        searchMode='server'
        onServerSearch={handleSearch}
        hasMoreResults={individualsData?.searchIndividuals?.pageInfo?.hasNextPage ?? false}
      />
    </Stack >

  );
};