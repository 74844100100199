import React from 'react';
import { IconButton, Paper, PaperProps, Stack, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';

interface OwnProps {
  title: string,
  subtitle?: string,
  children?: React.ReactNode,
  onEdit?: () => void;
}

type DisplayInformationProps =
  & PaperProps
  & OwnProps;

export const DisplayInformation: React.FC<DisplayInformationProps> = (props) => {
  const { title, subtitle, children, onEdit, ...paperProps } = props;
  return (
    <Paper {...paperProps}>
      <Stack padding={2} direction='row' alignItems='center' justifyContent='space-between'>
        <Stack>
          <Typography variant='h5'>
            {title}
          </Typography>
          {subtitle &&
            <Typography variant='subtitle1'>
              {subtitle}
            </Typography>
          }
        </Stack>
        {onEdit &&
          <IconButton onClick={onEdit}>
            <Edit color='primary' />
          </IconButton>
        }
      </Stack>
      {children}
    </Paper>
  );
};