import { Paper, Typography, Divider, Stack, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { ReportParametersInput, useFindDistributionCategoriesQuery, useFindDistributionItemsQuery } from 'gql';
import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { LookupInput } from 'components';
import { NumericalFilterField } from '../FilterInputs/NumericalFilterField';
import { FilterInputLabel } from '../FilterInputLabel';

interface Props {
  disabled?: boolean;
}

export const ReportDistributionSection: React.FC<Props> = ({ disabled }) => {
  const { formatMessage } = useIntl();

  const { control, setValue } = useFormContext<ReportParametersInput>();

  const selectedItemIds = useWatch({ control, name: 'filters.itemIds' });
  const selectedCategoryIds = useWatch({ control, name: 'filters.categoryIds' });

  const { data: itemsData } = useFindDistributionItemsQuery();
  const items = useMemo(() => itemsData?.items ?? [], [itemsData?.items]);

  const availableItems = useMemo(() =>
    selectedCategoryIds.length > 0 ? items.filter(item => item.categoryIds.some(id => selectedCategoryIds.includes(id)))
      : items, [items, selectedCategoryIds]);

  useEffect(() => {
    const selectedItems = availableItems.filter(item => selectedItemIds.includes(item.id));
    setValue('filters.itemIds', selectedItems.map(item => item.id));
  }, [availableItems, setValue]);

  const { data: categoriesData } = useFindDistributionCategoriesQuery();
  const categories = useMemo(() => categoriesData?.categories ?? [], [categoriesData?.categories]);

  return (
    <Paper>
      <Typography variant='h6' p={2}>{formatMessage({ id: 'Distributions' })}</Typography>
      <Divider />

      <Stack p={2} spacing={2}>
        <Controller
          control={control}
          name='filters.distributedAmounts'
          render={({ field }) => (
            <FilterInputLabel label={formatMessage({ id: 'Distributed Amount' })}>
              <NumericalFilterField
                value={field.value}
                onChange={field.onChange}
                disabled={disabled}
              />
            </FilterInputLabel>
          )}
        />


        <Controller
          control={control}
          name='filters.categoryIds'
          render={({ field }) => (
            <LookupInput
              multiple
              label={formatMessage({ id: 'Categories' })}
              selectedValues={categories.filter(category => field.value?.includes(category.id))}
              availableValues={categories}
              getKey={i => i.id}
              getLabel={i => i.name}
              onChange={categories => field.onChange(categories.map(i => i.id))}
              searchFilter={(category, search) => category.name.toLowerCase().includes(search.toLowerCase())}
              renderListItem={(category, selected, onClick) => (
                <ListItem disablePadding>
                  <ListItemButton selected={selected} onClick={onClick}>
                    <ListItemText
                      primary={category.name}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              disabled={disabled}
            />
          )}
        />

        <Controller
          control={control}
          name='filters.itemIds'
          render={({ field }) => (
            <LookupInput
              multiple
              label={formatMessage({ id: 'Items' })}
              selectedValues={items.filter(item => field.value?.includes(item.id))}
              availableValues={availableItems}
              getKey={i => i.id}
              getLabel={i => i.name}
              onChange={items => field.onChange(items.map(i => i.id))}
              searchFilter={(item, search) => item.name.toLowerCase().includes(search.toLowerCase())}
              renderListItem={(item, selected, onClick) => (
                <ListItem disablePadding>
                  <ListItemButton selected={selected} onClick={onClick}>
                    <ListItemText
                      primary={item.name}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              disabled={disabled}
            />
          )}
        />
      </Stack>
    </Paper>
  );
};