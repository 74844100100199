import { AppBar, Box, Button, Chip, Divider, FormHelperText, Stack, Step, StepContent, StepLabel, Stepper, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { RealityInput, useRealitiesDataQuery } from '../../../../../../gql';
import { AccompanimentStep } from './Steps/AccompanimentStep';
import { ApproachStep } from './Steps/ApproachStep';
import { ReferralStep } from './Steps/ReferralStep';
import { SpecificationStep } from './Steps/SpecificationStep';
import { ThemeStep } from './Steps/ThemeStep';

interface RealityStep {
  label: string;
  renderStepContent: (stepIndex: number, onStepComplete: () => void) => JSX.Element;
  renderSelectedValues: () => JSX.Element;
  optional?: boolean;
}

interface Props { isEditMode: boolean, onCompleted: (reality: RealityInput) => void; }

export const RealitySteps = (props: Props) => {
  const { formatMessage } = useIntl();
  const [activeStep, setActiveStep] = React.useState(0);
  const { handleSubmit, formState: { errors }, getValues } = useFormContext<RealityInput>();
  const realitiesQuery = useRealitiesDataQuery();

  const [transitioning, setTransitioning] = React.useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setTransitioning(true);
  };

  const onTransitionEnd = () => {
    setTransitioning(false);
  };

  const steps: RealityStep[] = [
    {
      label: formatMessage({ id: 'Theme' }),
      renderStepContent: (stepIndex: number, onStepComplete: () => void) => <ThemeStep activeStep={activeStep} stepIndex={stepIndex} onStepComplete={onStepComplete} />,
      renderSelectedValues: () => (
        <Typography>
          {realitiesQuery.data?.themes.find(p => p.id === getValues().themeId)?.name}
        </Typography>
      )
    },
    {
      label: formatMessage({ id: 'Specification' }),
      renderStepContent: (stepIndex: number, onStepComplete: () => void) => <SpecificationStep activeStep={activeStep} stepIndex={stepIndex} onStepComplete={onStepComplete} />,
      renderSelectedValues: () => {
        const theme = realitiesQuery.data?.themes.find(p => p.id === getValues().themeId);

        return (<Typography>
          {theme?.specifications?.find(p => p.id === getValues().specificationId)?.name}
        </Typography>);
      }
    },
    {
      label: formatMessage({ id: 'Approaches' }),
      renderStepContent: (stepIndex: number, onStepComplete: () => void) => <ApproachStep activeStep={activeStep} stepIndex={stepIndex} onStepComplete={onStepComplete} />,
      renderSelectedValues: () => (
        <Typography>
          {getValues().approachIds.map(p => <Chip key={p} size='small' label={realitiesQuery.data?.approaches.find(r => r.id === p)?.name} />)}
        </Typography>
      ),
      optional: true
    },
    {
      label: formatMessage({ id: 'Referrals' }),
      renderStepContent: (stepIndex: number, onStepComplete: () => void) => <ReferralStep activeStep={activeStep} stepIndex={stepIndex} onStepComplete={onStepComplete} />,
      renderSelectedValues: () => (
        <Typography>
          {getValues().referralIds.map(p => <Chip key={p} size='small' label={realitiesQuery.data?.referrals.find(r => r.id === p)?.name} />)}
        </Typography>
      ),
      optional: true
    },
    {
      label: formatMessage({ id: 'Accompaniments' }),
      renderStepContent: (stepIndex: number) => (
        <AccompanimentStep activeStep={activeStep} stepIndex={stepIndex} onStepComplete={handleSubmit(props.onCompleted)} />
      ),
      renderSelectedValues: () => (
        <Typography>
          {getValues().accompanimentIds.map(p => <Chip key={p} size='small' label={realitiesQuery.data?.referrals.find(r => r.id === p)?.name} />)}
        </Typography>
      ),
      optional: true
    },
  ];

  useEffect(() => {
    if (props.isEditMode) {
      setActiveStep(steps.length - 1);
    }
  }, [steps.length, props.isEditMode]);

  const formHelperRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    if (errors.referralIds?.message) {
      formHelperRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors.referralIds?.message]);

  return (
    <>
      <Stack height='100%' sx={{ overflowY: 'auto' }}>
        <Stack p={2}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label} >
                <StepLabel optional={step.renderSelectedValues()}>
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    {step.label}

                    {index < activeStep && (
                      <Button variant='text' color='info' onClick={() => setActiveStep(index)} >
                        {formatMessage({ id: 'Modify' })}
                      </Button>
                    )}
                  </Stack>
                </StepLabel>

                <StepContent TransitionProps={{ unmountOnExit: false, onTransitionEnd }} sx={{ pointerEvents: transitioning ? 'none' : 'auto' }}>
                  <Box sx={{ mb: 2 }}>
                    {step.renderStepContent(index, handleNext)}
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>

          <FormHelperText error={true} ref={formHelperRef}>{errors.referralIds?.message}</FormHelperText>
        </Stack>

        {steps[activeStep].optional && <>
          <Toolbar sx={{ mt: 2 }} />
          <AppBar position='absolute' color='inherit' elevation={16} sx={{ top: 'auto', bottom: 0, boxShadow: 'none' }} >
            <Divider />
            <Stack p={2} direction='row' justifyContent={activeStep !== steps.length - 1 ? 'end' : 'start'} spacing={2}>
              {activeStep !== steps.length - 1 && (
                <Button variant='contained' onClick={handleNext}>
                  {formatMessage({ id: 'Next' })}
                </Button>
              )}

              {activeStep === steps.length - 1 && <>
                <Button variant='contained' onClick={handleSubmit(props.onCompleted)} >
                  {formatMessage({ id: 'Save' })}
                </Button>
              </>}
            </Stack>
          </AppBar>
        </>}
      </Stack>
    </>);
};