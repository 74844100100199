import { TableRow as MuiTableRow, TableCell, Typography, TableBody, Table, TableContainer, Paper, Stack, Divider, styled } from '@mui/material';
import { CustomFieldDto, CustomFieldEntityType, CustomFieldValue, FieldSectionDto } from 'gql';
import React from 'react';

type FieldEntityType = Pick<CustomFieldEntityType, 'id' | 'sectionId'> & {
  customFieldDto: Pick<CustomFieldDto, 'name' | 'description' | 'customFieldType' | 'options' | 'id'>;
};

type FieldSection = Pick<FieldSectionDto, 'id' | 'name'> & {
  fieldEntityTypes: FieldEntityType[];
};

type FieldValue = Pick<CustomFieldValue, 'id' | 'fieldId' | 'displayString'>;

interface Props {
  sections: FieldSection[];
  customFieldValues: FieldValue[];
}

const TableRow = styled(MuiTableRow)(({ theme }) => ({
  '& td, & th': { borderColor: theme.palette.divider },
  '&:last-child td, &:last-child th': { border: 0 }
}));

export const ActivityFieldValuesTable: React.FC<Props> = ({ customFieldValues, ...props }) => {
  const sections = props.sections.filter(s => s.fieldEntityTypes.some(f => customFieldValues.some(c => c.fieldId === f.customFieldDto.id)));
  const fieldEntityTypes = sections.flatMap(s => s.fieldEntityTypes);

  return (
    <Stack spacing={2}>
      {sections.map(section => (
        <TableContainer component={Paper} key={section.id}>
          <Stack mx={2} my={1} justifyContent='center' minHeight='40px'>
            <Typography variant='h6'>{section.name}</Typography>
          </Stack>
          <Divider />
          <Table>
            <TableBody>
              {customFieldValues.filter(c => {
                const fieldEntityType = fieldEntityTypes?.find(f => f.customFieldDto.id === c.fieldId);
                return fieldEntityType?.sectionId === section.id;
              }).map((customFieldValue) => {
                const field = fieldEntityTypes?.find(f => f.customFieldDto.id === customFieldValue.fieldId)?.customFieldDto;

                if (!field) return null;

                return (
                  <TableRow key={customFieldValue.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ width: { xs: undefined, md: '300px' } }}>
                      <Typography>{field.name}</Typography>
                      {field.description && (
                        <Typography color='text.secondary' >{field.description}</Typography>
                      )}
                    </TableCell>

                    <TableCell>
                      <pre>
                        <Typography>
                          {customFieldValue.displayString}
                        </Typography>
                      </pre>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </Stack>
  );
};