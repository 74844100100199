import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle } from '@mui/material';
import { DistributionItemDto } from 'gql';
import React from 'react';
import { useIntl } from 'react-intl';

interface OwnProps {
  item: Pick<DistributionItemDto, 'name' | 'description' | 'unit'>;
  onClose: () => void;
}

export type DistributionDetailsDialogProps =
  & DialogProps
  & OwnProps;

export const DistributionDetailsDialog: React.FC<DistributionDetailsDialogProps> = (props) => {
  const { item, onClose, ...dialogProps } = props;
  const { formatMessage } = useIntl();

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{item.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>{item.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {formatMessage({ id: 'Close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};