import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useGetUserByIdQuery } from 'gql';
import { UserDetails } from '../components/UserDetails';

export const UserDetailsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { userId } = useParams();
  const { data: data, isFetched } = useGetUserByIdQuery({ userId: userId ?? '' });
  const user = data?.activeUsers.at(0);

  if (user == null) {
    if (isFetched)
      throw new Error(formatMessage({ id: 'User not found' }));

    return null;
  }

  return (
    <UserDetails user={user} />
  );
};