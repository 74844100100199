import React, { useCallback } from 'react';
import { CancelButton } from 'components';
import { Button, Stack } from '@mui/material';
import { FormProvider, useFormContext } from 'react-hook-form';
import { AddTenantInput, Tenant,  } from 'gql';
import { useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import { OrganizationForm } from '../../OrganizationForm';

interface IProps {
  changeStep: (newStep: number) => void
  organization?: Tenant;
  isDisable: boolean,
  onSubmit: (organizationData: AddTenantInput) => void
  handleClose: () => void;
}

export const OrganizationConfigurationStep: React.FC<IProps> = (props) => {
  const { formatMessage } = useIntl();

  const form = useFormContext<AddTenantInput>();
  const { handleSubmit, formState, trigger } = form;

  const nextStep = useCallback(async () => {
    const isValid = await trigger('tenantInput');
    if (isValid === false) {
      handleSubmit(() => {
        // This handleSubmit is supposed to submit nothing.
        // trigger() on its own won't allow the form to re-validate on field change.
        // This is only possible when the form is submitted.
        // Thus we submit so that the field will re-validate when there's a change.
      })();

      return;
    }
    props.changeStep(1);
  }, [handleSubmit, props, trigger]);

  return (
    <Stack>
      <FormProvider {...form}>
        <OrganizationForm editMode={props.organization !== undefined} disabled={props.isDisable} />
      </FormProvider>

      <Stack direction='row' justifyContent='flex-end' spacing={2}>
        <CancelButton onClick={props.handleClose} disabled={props.isDisable} />
        {props.organization ?
          <LoadingButton loading={props.isDisable} variant='contained' type='submit' onClick={handleSubmit(props.onSubmit)}>
            {formatMessage({ id: 'Save' })}
          </LoadingButton>
          :
          <Button variant='contained' onClick={nextStep} disabled={!(formState.errors.tenantInput === undefined && !props.isDisable)}>
            {formatMessage({ id: 'Next' })}
          </Button>
        }
      </Stack>
    </Stack >
  );
};