import { Chip, ChipProps } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

type Status = 'active' | 'inactive';

interface OwnProps {
  status: Status;
}

type CustomFieldStatusChipProps =
  & ChipProps
  & OwnProps;

export const StatusChip: React.FC<CustomFieldStatusChipProps> = (props) => {
  const { status, ...chipProps } = props;
  const { formatMessage } = useIntl();

  const chipPropsByStatus: Record<Status, ChipProps> = {
    'active': { label: formatMessage({ id: 'Active' }), color: 'primary' },
    'inactive': { label: formatMessage({ id: 'Inactive' }), color: 'warning' }
  };

  return (
    <Chip {...chipPropsByStatus[status]} {...chipProps} />
  );
};