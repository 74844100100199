import React from 'react';
import { Stack, Typography } from '@mui/material';

interface OwnProps {
  title: string
  subtitle?: string
  callToActionComponent?: React.ReactNode;
}

export const EmptyState:React.FC<OwnProps> = (props) => {
  return (
    <Stack alignItems='center' justifyContent='center' p={3} spacing={3}>
      <Stack spacing={0.5}>
        <Typography fontWeight={500} textAlign='center'>{props.title}</Typography>
        {props.subtitle && <Typography textAlign='center'>{props.subtitle}</Typography>}
      </Stack>
      {props.callToActionComponent}
    </Stack>
  );
};