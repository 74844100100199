import React, { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { BuiltInRoles, useGetUserByIdQuery, useUpdateRoleMutation } from '../../../../gql';
import { DrawerProps, MenuItem, TextField, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FormDrawer } from 'components';
import { userRoleDescriptions, userRoleMessages } from 'modules/users/messages';

export interface UpdateUserRoleForm extends Omit<DrawerProps, 'title' | 'defaultWidth' | 'isLoading'> {
  userId: string;
}

export const UpdateUserRoleDrawer: React.FC<UpdateUserRoleForm> = (props: UpdateUserRoleForm) => {
  const { userId, ...drawerProps } = props;
  const queryClient = useQueryClient();
  const userQuery = useGetUserByIdQuery({ userId: userId ?? '' }, {});
  const user = userQuery.data?.activeUsers.at(0);
  const updateRoleMutation = useUpdateRoleMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['getUserById']);
      queryClient.invalidateQueries(['findUsers']);
    }
  });
  const { formatMessage } = useIntl();

  const currentRole = user?.roles.at(0)?.name as BuiltInRoles | undefined;

  const [role, setRole] = useState(currentRole);

  const canUpdate = currentRole !== BuiltInRoles.GlobalAdministrator;

  const submit = async () => {
    await updateRoleMutation.mutateAsync({ input: { userId: userId, role } });
    props.onClose?.({}, 'escapeKeyDown');
  };

  return (
    <FormDrawer
      header={formatMessage({ id: 'Update user role' })}
      isLoading={userQuery.isFetching || updateRoleMutation.isLoading}
      {...drawerProps}
      showFooter={true}
      isFormDirty={role !== currentRole}
      onSave={submit}
      saveButtonMessage={formatMessage({ id: 'Save' })}
    >
      <h4>{user?.displayName}</h4>

      <TextField
        select
        disabled={!canUpdate}
        value={role}
        label={formatMessage({ id: 'Role' })}
        helperText={canUpdate ? undefined : formatMessage({ id: 'You cannot change the role of a global administrator' })}
        onChange={event => event.target.value === '' ? setRole(undefined) : setRole(event.target.value as BuiltInRoles)}
      >
        <MenuItem value={''}>
          <em>{formatMessage({ id: 'No role' })}</em>
        </MenuItem>
        <MenuItem value={BuiltInRoles.StreetOutreachWorker}>{formatMessage(userRoleMessages[BuiltInRoles.StreetOutreachWorker])}</MenuItem>
        <MenuItem value={BuiltInRoles.Analyst}>{formatMessage(userRoleMessages[BuiltInRoles.Analyst])}</MenuItem>
        <MenuItem value={BuiltInRoles.TenantAdministrator}>{formatMessage(userRoleMessages[BuiltInRoles.TenantAdministrator])}</MenuItem>
        {currentRole === BuiltInRoles.GlobalAdministrator && (
          <MenuItem value={BuiltInRoles.GlobalAdministrator}>{formatMessage(userRoleMessages[BuiltInRoles.GlobalAdministrator])}</MenuItem>
        )}
      </TextField>

      {role && (
        <Typography mt={4}>
          {formatMessage({ id: 'A user with this role can:' })}

          <ul>
            {formatMessage<ReactNode>(userRoleDescriptions[role], {
              li: msg => <li>{msg}</li>
            })}
          </ul>
        </Typography>
      )}
    </FormDrawer>
  );
};

