import { TextField, TextFieldProps } from '@mui/material';
import dayjs from 'dayjs';
import { CustomFieldDto, CustomFieldOptionDto, CustomFieldType, FieldValueInput, SystemFieldId } from 'gql';
import React from 'react';
import { FieldError, useFormContext, useWatch } from 'react-hook-form';
import { SelectionInput } from './SelectionInput';
import { TeamLookupInput } from './TeamLookupInput';
import { UserLookupInput } from './UserLookupInput';
import { ResponsiveDatePicker } from 'components';
import { BirthdayInput } from './BirthdayInput';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

type CustomField = Pick<CustomFieldDto, 'id' | 'customFieldType' | 'isRequired' | 'name' | 'description' | 'allowMultipleValues' | 'numberOfLines' | 'systemFieldId'> & {
  options: Pick<CustomFieldOptionDto, 'id' | 'name'>[];
};

interface Props {
  customField: CustomField;
  onChange: (value: string) => void;
  value?: string | null;
  error?: FieldError;
  disabled?: boolean;
}

export interface GenericCustomFieldInputProps {
  label: string;
  helperText?: string;
  error: boolean;
  value?: string | null;
  required: boolean;
  disabled?: boolean;
  onChange: (value: string) => void;
  rows?: number;
}

const standardFieldPropsMap: Record<CustomFieldType.String | CustomFieldType.Numerical, TextFieldProps> = {
  [CustomFieldType.String]: { type: 'text', multiline: true },
  [CustomFieldType.Numerical]: { type: 'text', inputProps: { inputMode: 'decimal' } },
};

const customHandledSystemFields = [SystemFieldId.Birthday, SystemFieldId.AdditionalWorkers, SystemFieldId.OutreachWorker, SystemFieldId.Team, SystemFieldId.InterventionDate];

export const CustomFieldInput: React.FC<Props> = ({ customField, error, ...props }) => {
  const { name, isRequired, description, customFieldType, systemFieldId, numberOfLines } = customField;

  const { control } = useFormContext<{ values: FieldValueInput[]; }>();
  const values = useWatch({ control, name: 'values' });
  const teamField = values.find(f => f.systemFieldId === SystemFieldId.Team);

  const fieldProps: GenericCustomFieldInputProps = {
    label: name,
    helperText: error?.message || description || undefined,
    error: Boolean(error),
    required: isRequired,
    value: props.value,
    onChange: props.onChange,
    disabled: props.disabled,
    rows: numberOfLines
  };

  if (systemFieldId && customHandledSystemFields.includes(systemFieldId)) {
    switch (customField.systemFieldId) {
      case SystemFieldId.Birthday:
        return <BirthdayInput {...fieldProps} />;
      case SystemFieldId.InterventionDate:
        return <ResponsiveDatePicker {...fieldProps} maxDate={dayjs()} />;
      case SystemFieldId.AdditionalWorkers:
        return <UserLookupInput  {...fieldProps} multiple teamIdFilter={teamField?.value ? Number(teamField.value) : 0} />;
      case SystemFieldId.OutreachWorker:
        return <UserLookupInput {...fieldProps} disabled includeMe />;
      case SystemFieldId.Team:
        return <TeamLookupInput {...fieldProps} />;
    }
  } else {
    switch (customFieldType) {
      case CustomFieldType.String:
      case CustomFieldType.Numerical:
        return (
          <TextField
            {...standardFieldPropsMap[customFieldType]}
            {...fieldProps}

            onChange={e => props.onChange(e.target.value)}
          />
        );
      case CustomFieldType.Date:
        return (
          <ResponsiveDatePicker {...fieldProps} />
        );
      case CustomFieldType.Selection:
        return (
          <SelectionInput
            {...fieldProps}
            field={customField}
          />
        );
    }
  }

  console.error(`Unhandled custom field type: ${customFieldType}`);

  return null;
};