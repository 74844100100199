import React from 'react';
import { IconButton, ListItem, ListItemIcon, ListItemProps, Paper, styled } from '@mui/material';
import { Draggable } from '@hello-pangea/dnd';
import { Delete, DragHandle } from '@mui/icons-material';
import { FieldArrayWithId } from 'react-hook-form';
import { AddCustomPropertyInput, EditCustomPropertyInput } from 'gql';


interface OwnProps {
  option: FieldArrayWithId<AddCustomPropertyInput | Omit<EditCustomPropertyInput, 'id'>, 'customOptions', 'uniqueId'>;
  index: number;
  children?: React.ReactNode;
  onDelete: () => void;
  divider?: boolean;
}

const StyledListItem = styled(ListItem)<ListItemProps>(({ theme }) => ({
  paddingRight: theme.spacing(2),
  '& .MuiListItemIcon-root:first-of-type': {
    minWidth: '24px',
    marginRight: theme.spacing(2)
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  [theme.breakpoints.up('lg')]: {
    '&:hover .MuiIconButton-root': {
      visibility: 'visible'
    },
    '& .MuiIconButton-root': {
      visibility: 'hidden'
    },
  }
}));


export const DraggableOptionListItem: React.FC<OwnProps> = ({ option, index, children, divider, onDelete }) => {
  return (
    <Draggable draggableId={option.uniqueId} index={index}>
      {(provided, snapshot) => (
        <Paper square
          elevation={16}
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{ boxShadow: 'none' }}
        >
          <StyledListItem
            divider={divider}
            sx={{
              backgroundColor: snapshot.isDragging ? 'action.hover' : undefined
            }}
          >
            <ListItemIcon {...provided.dragHandleProps}>
              <DragHandle />
            </ListItemIcon>
            {children}
            <IconButton edge='end' onClick={onDelete} sx={{ ml: 1 }}>
              <Delete color='error' />
            </IconButton>
          </StyledListItem>
        </Paper>
      )}
    </Draggable>
  );
};