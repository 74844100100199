import React, { useCallback, useState } from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useIntl } from 'react-intl';
import { Box, Divider, LinearProgress, Paper, Typography } from '@mui/material';
import { EntityType, SectionReorderInput, useFindSectionsQuery, useFindThemesWithSpecificationsQuery, useReorderFieldsMutation } from 'gql';
import { PageTitle } from 'components/PageTitle';
import { entityTypePluralMessages } from 'modules/activities/messages';
import { DraggableFieldList } from '../../customizations/components/DraggableFieldList';
import { RoutedTabs } from 'components';
import { useSearchParams } from 'react-router-dom';
import { ThemeModifications } from '../components/ThemeModifications';
import { CustomizationFieldDrawer } from 'modules/customizations/components/CustomizationDrawer';

export const RealitiesPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const [isOpen, setOpen] = useState(false);
  const [selectedField, setSelectedField] = useState<number>();
  const [selectedSection, setSelectedSection] = useState<number>(0);

  const { data: sections, isFetching: isSectionsFetching, refetch } = useFindSectionsQuery({
    filter: { entityType: { eq: EntityType.Reality } }
  });

  const { data: themesData, isFetching: isThemesFetching } = useFindThemesWithSpecificationsQuery();

  const handleClose = () => {
    refetch();
    setSelectedSection(0);
    setSelectedField(undefined);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleEditField = (fieldId: number) => {
    setSelectedField(fieldId);
    handleOpen();
  };

  const handleEditSection = (sectionId: number) => {
    setSelectedSection(sectionId);
    handleOpen();
  };

  const reorderMutation = useReorderFieldsMutation({
    onSuccess: () => {
      refetch();
    }
  });

  const onFieldsDragged = useCallback((ordering: SectionReorderInput[]) => {
    reorderMutation.mutate({ input: { ordering, entityType: EntityType.Reality } });
  }, [reorderMutation]);

  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') || 'customizations';

  return (
    <>
      <PageTitle
        icon={<ViewListIcon />}
        title={formatMessage({ id: 'Customization' })}
        subtitle={formatMessage(entityTypePluralMessages[EntityType.Reality])}
      />

      <RoutedTabs
        tabs={[{
          displayString: formatMessage({ id: 'Customizations' }),
          navigationString: 'customizations'
        }, {
          displayString: formatMessage({ id: 'Themes / Specifications' }),
          navigationString: 'themes'
        }]}
        queryParam='tab'
        defaultNavigation='customizations'
        navigationStyle='queryParam'
      />

      {tab === 'themes' && (
        <Box sx={{ mt: 2 }}>
          <LinearProgress sx={{ visibility: isThemesFetching ? 'visible' : 'hidden' }} />
          <ThemeModifications
            themes={themesData?.themes ?? []}
          />
        </Box>
      )}

      {tab === 'customizations' && (
        <Box sx={{ mt: 2 }}>
          <LinearProgress sx={{ visibility: (isSectionsFetching || reorderMutation.isLoading) ? 'visible' : 'hidden' }} />

          <Paper>
            <Typography variant='h5' p={2}>
              {formatMessage({ id: 'Customizations' })}
            </Typography>
            <Divider />

            <DraggableFieldList
              disableSections
              disableDragFields
              sections={sections?.sections || []}
              onFieldClick={handleEditField}
              onSectionClick={handleEditSection}
              disabled={isSectionsFetching || reorderMutation.isLoading}
              onChange={onFieldsDragged}
            />
          </Paper>
        </Box>
      )}

      <CustomizationFieldDrawer
        sectionId={selectedSection}
        customFieldId={selectedField}
        isOpen={isOpen}
        onClose={handleClose}
        entityType={EntityType.Reality}
      />
    </>
  );
};