import React, { useEffect } from 'react';
import { FormDrawer } from 'components';
import { useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { ReportModelForm } from './components';
import { DrawerProps } from '@mui/material';

interface OwnProps extends Omit<DrawerProps, 'onSubmit'> {
  model?: string;
  isSaveAsCopy?: boolean;
  isSaveAsNew?: boolean;
  isEdit?: boolean;
  onSubmit: (values: ReportDetailsFormValues) => void;
  disabled?: boolean;
}

export interface ReportDetailsFormValues {
  name: string;
  description: string;
}

export const ReportDetailsDrawer: React.FC<OwnProps> = ({ model, isSaveAsCopy, isSaveAsNew, isEdit, onSubmit, disabled, ...drawerProps }) => {
  const { formatMessage } = useIntl();

  const formMethods = useForm<ReportDetailsFormValues>();
  const { formState: { isDirty }, handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (model) {
      reset({
        name: isSaveAsCopy ? `${model} (${formatMessage({ id: 'copy' })})` : model,
      });
    } else {
      reset({
        name: '',
        description: '',
      });
    }
  }, [formatMessage, isSaveAsCopy, model, reset]);

  const header = isSaveAsCopy
    ? formatMessage({ id: 'Save report model as copy' })
    : isSaveAsNew
      ? formatMessage({ id: 'New report model' })
      : isEdit
        ? formatMessage({ id: 'Edit report model' })
        : formatMessage({ id: 'Save report model' });

  return (
    <FormDrawer
      isFormDirty={isDirty}
      header={header}
      disablePadding
      onSave={handleSubmit(onSubmit)}
      showFooter
      disabledSaveButton={disabled || (!model && !isDirty)}
      {...drawerProps}
    >
      <FormProvider {...formMethods}>
        <ReportModelForm
          disabled={disabled}
        />
      </FormProvider>
    </FormDrawer>
  );
};