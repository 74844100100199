import React from 'react';
import { RouteObject } from 'react-router-dom';
import { TeamDetailsTab, TeamPage, TeamsPage, TeamUsersTab } from './views';

export const routes: RouteObject[] = [
  { path: 'teams', element: <TeamsPage /> },
  { path: 'teams/:teamId',
    element: <TeamPage />,
    children: [
      { path: 'details', element: <TeamDetailsTab /> },
      { path: 'users', element: <TeamUsersTab /> }
    ]
  }
];

export default routes;