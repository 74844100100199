import { Tooltip, TooltipProps } from '@mui/material';
import React from 'react';

interface Props extends TooltipProps {
  disabled?: boolean;
}

export const DisableableTooltip: React.FC<Props> = ({ disabled, children, ...tooltipProps }) => {
  return disabled ? <>{children}</> : (
    <Tooltip {...tooltipProps}>
      <span>
        {children}
      </span>
    </Tooltip>
  );
};