import { Paper, Typography, Divider, Stack, LinearProgress } from '@mui/material';
import { FieldValueInput, CustomFieldStatusType, FieldSectionDto, CustomFieldDto, CustomFieldEntityType, CustomFieldOptionDto } from 'gql';
import { useFormContext, PathValue, Controller, Path } from 'react-hook-form';
import { useFieldRulesGenerator } from 'utils/customFieldsUtils';
import { CustomFieldInput } from '../CustomFieldInput/CustomFieldInput';
import React from 'react';

interface FieldValues {
  values: FieldValueInput[];
}

type CustomField = Pick<CustomFieldDto, 'id' | 'customFieldType' | 'status' | 'isRequired' | 'name' | 'description' | 'allowMultipleValues' | 'numberOfLines' | 'systemFieldId'> & {
  options: Pick<CustomFieldOptionDto, 'id' | 'name'>[];
};

type FieldEntityType = Pick<CustomFieldEntityType, 'entityType'> & {
  customFieldDto: CustomField;
};

type FieldSection = Pick<FieldSectionDto, 'id' | 'name'> & {
  fieldEntityTypes: FieldEntityType[];
};

interface Props {
  shouldRenderField?: (field: CustomField) => boolean;
  sections?: FieldSection[];
  disabled?: boolean;
  loading?: boolean;
}

export const CustomFieldInputForm = <T extends FieldValues>({ sections, loading, disabled, shouldRenderField }: Props) => {
  const { control, setValue, getValues } = useFormContext<T>();
  const { generateFieldRules, getDefaultValue, defaultValuesReady } = useFieldRulesGenerator();

  if (sections === undefined || !defaultValuesReady) {
    return (
      <Paper sx={{ minHeight: '400px' }}>
        <LinearProgress sx={{ width: '100%' }} />
      </Paper>
    );
  }

  let newFieldIndex = getValues()?.values?.length ?? 0;
  return <>
    {loading && (
      <LinearProgress />
    )}

    <Stack spacing={2}>
      {sections.map(section => (
        <Paper key={section.id}>
          <Typography variant='h5' padding={2}>{section.name}</Typography>
          <Divider />

          <Stack p={2} spacing={2}>
            {section.fieldEntityTypes.map((customFieldEntityType, index) => {
              const customField = customFieldEntityType.customFieldDto;

              if (shouldRenderField && !shouldRenderField?.(customField)) {
                return null;
              }

              if (customField.status === CustomFieldStatusType.Inactive) return null;

              let indexToSet = getValues()?.values?.findIndex(v => v.customFieldId === customField.id);
              if (indexToSet == null || indexToSet === -1) {
                indexToSet = newFieldIndex++;
                setValue(`values.${indexToSet}.customFieldId` as Path<T>, customField.id as PathValue<T, Path<T>>);
                setValue(`values.${indexToSet}.systemFieldId` as Path<T>, customField.systemFieldId as PathValue<T, Path<T>>);
              }

              return (
                <Controller
                  key={index}
                  name={`values.${indexToSet}.value` as Path<T>}
                  control={control}
                  defaultValue={getDefaultValue(customField.systemFieldId) as PathValue<T, Path<T>> | undefined}
                  rules={generateFieldRules(customField)}
                  render={({ field, fieldState }) =>
                    <CustomFieldInput
                      disabled={disabled}
                      customField={customField}
                      value={field.value as FieldValueInput['value']}
                      onChange={field.onChange}
                      error={fieldState.error}
                    />
                  }
                />
              );
            })}
          </Stack>
        </Paper>
      ))}
    </Stack>
  </>;
};