import React from 'react';
import { GetLatestIndividualsQuery, SystemFieldId, useGetLatestIndividualsQuery, useGetOptionsbySystemFieldIdQuery } from 'gql';
import { useIntl } from 'react-intl';
import { GridColDef } from '@mui/x-data-grid';
import { DashboardSimpleTable } from './DashboardSimpleTable';
import { useNavigate } from 'react-router-dom';
import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { Filter } from 'modules/home/hooks/useGetActivitiesFilter';
import { dashboardQueryOptions } from '../../utils';

type Individuals = NonNullable<NonNullable<Pick<GetLatestIndividualsQuery, 'latestIndividuals'>['latestIndividuals']>>[0];

interface OwnProps {
  activitiesFilter: Filter
}

export const LatestIndividualsTable: React.FC<OwnProps> = ({ activitiesFilter }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: latestIndividualsData, isFetching: isLatestIndividualsLoading } = useGetLatestIndividualsQuery(activitiesFilter, dashboardQueryOptions());
  const latestIndividuals = latestIndividualsData?.latestIndividuals ?? [];

  const { data: genderOptionsData, isFetching: isGenderOptionsLoading } = useGetOptionsbySystemFieldIdQuery({ systemFieldId: SystemFieldId.Gender }, dashboardQueryOptions());
  const genderOptions = genderOptionsData?.options ?? [];

  const getGenderName = (id: number) => genderOptions.find(option => option.id === id)?.name;

  const formatDateValue = (value: Date) => {
    return dayjs(value).format('YYYY-MM-DD');
  };

  const isLoading = isLatestIndividualsLoading || isGenderOptionsLoading;

  const columns: GridColDef<Individuals>[] = [
    {
      field: 'nickname',
      flex: 1,
      headerName: formatMessage({ id: 'Nickname' }),
      valueGetter: ({ row }) => row.nickname,
      renderCell: ({ row }) => row.nickname ? (
        <Box pl={2}>
          <Typography variant='body1' fontWeight={500}>
            {row.nickname}
          </Typography>
        </Box>
      ) : (
        <Box pl={2}>
          <Skeleton variant='text' width={theme.spacing(10)} />
        </Box >
      )
    },
    {
      field: 'gender',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerName: formatMessage({ id: 'Gender' }),
      valueGetter: ({ row }) => row.genderId ? getGenderName(row.genderId) : '',
      renderCell: ({ row }) => row.genderId ? (
        <Typography variant='body1'>
          {getGenderName(row.genderId)}
        </Typography>
      ) : <Skeleton variant='text' width={theme.spacing(10)} />
    },
    {
      field: 'age',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerName: formatMessage({ id: 'Age' }),
      valueGetter: ({ row }) => row.birthday,
      renderCell: ({ row }) => row.birthday ? (
        <Typography variant='body1'>
          {dayjs().diff(dayjs(row.birthday), 'year')}
        </Typography>
      ) : <Skeleton variant='text' width={theme.spacing(5)} />
    },
    {
      field: 'interventionDate',
      flex: 1,
      minWidth: 120,
      headerAlign: 'right',
      align: 'right',
      headerName: formatMessage({ id: 'Activity Date' }),
      valueGetter: ({ row }) => row.activityDate,
      renderCell: ({ row }) => row.activityDate ? (
        <Typography variant='body1'>
          {formatDateValue(row.activityDate)}
        </Typography>
      ) : <Skeleton variant='text' width={theme.spacing(10)} />
    }
  ];

  const loadingRow = new Array(5).fill(1).map((_, i) => (
    {
      id: i
    }
  ));

  return (
    <DashboardSimpleTable
      defaultHeight
      columns={columns}
      rows={isLoading ? loadingRow : latestIndividuals}
      noDataMessage={formatMessage({ id: 'There are no individuals yet' })}
      sectionTitle={formatMessage({ id: 'LatestIndividualsTable.mostRecent' })}
      buttonLabel={formatMessage({ id: 'LatestIndividualsTable.seeAll' })}
      onClickButton={() => navigate('individuals')}
    />
  );
};