import { Alert, Chip, LinearProgress, Link, Stack, Typography } from '@mui/material';
import { PageTitle, RoutedTabs } from 'components';
import { ActivityType, useFindDistributionItemsQuery, useFindSectionsQuery, useGetActivityByIdQuery, useGetDeletedActivityByIdQuery, useGetIndividualsSettingsQuery } from 'gql';
import { useFindIndividualsCancellableQuery } from 'gql/cancellableQueries';
import { ItemsTable } from 'modules/distributions/components/ItemsTable';
import { IndividualsTable } from 'modules/individuals/components/IndividualsTable';
import { UnknownGroupTable } from 'modules/individuals/components/UnknownGroupTable';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getTenantIdentifier } from 'utils/tenantUtils';
import { ActivityActions } from '../components/ActivityActions/ActivityActions';
import { ActivityDialog, ActivityFormValues } from '../components/ActivityDialog';
import { ActivityFieldValuesTable } from '../components/ActivityFieldValuesTable';
import { ActivityRealities } from '../components/ActivityRealities/ActivityRealities';
import { activityTypeMessages } from '../messages';
import { activityTypeAvailableSteps, activityTypeToEntityType } from '../types';
import { useActivityMutations } from '../utils';

interface Tab { displayString: string, navigationString: string; }

export const ActivityPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { activityId } = useParams();
  const navigate = useNavigate();
  const { data: individualsSettingsData } = useGetIndividualsSettingsQuery();

  const { data, isFetching: isActivityFetching, refetch: refetchActivity } = useGetActivityByIdQuery({ id: Number(activityId) });
  const { data: deletedData, isFetching: isDeletedActivityFetching, refetch: refetchDeletedActivity } = useGetDeletedActivityByIdQuery({ id: Number(activityId) }, {
    onError: () => {
      // do nothing
    },
    enabled: data?.activities?.items?.length === 0
  });

  const isFetching = isActivityFetching || isDeletedActivityFetching;
  const refetch = () => {
    refetchActivity();
    refetchDeletedActivity();
  };

  const deletedActivity = deletedData?.deletedActivities.at(0);
  const activity = data?.activities?.items?.at(0) ?? deletedActivity;

  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get('tab') || 'values';

  const isEditing = searchParams.get('edit') === 'true';
  const openDialog = () => {
    searchParams.set('edit', 'true');
    setSearchParams(searchParams);
  };
  const closeDialog = () => {
    searchParams.delete('edit');
    setSearchParams(searchParams);
  };

  const individualIds = activity?.individuals.map(i => i.id);
  const { data: individualsData, isFetching: isFetchingIndividuals } = useFindIndividualsCancellableQuery({
    pageSize: 200, skip: 0,
    input: { id: { in: individualIds } },
    searchTerm: ''
  }, { enabled: (individualIds?.length ?? 0) > 0 });
  const individuals = individualsData?.searchIndividuals?.items ?? [];

  const { data: sectionsData, isFetching: isSectionsFetching } = useFindSectionsQuery({
    filter: {
      entityType: { eq: activity && activityTypeToEntityType[activity.type] }
    }
  }, { enabled: Boolean(activity) });
  const sections = sectionsData?.sections ?? [];

  const { data: itemsData, isFetching: isItemsFetching } = useFindDistributionItemsQuery({
    filter: { id: { in: activity?.distributions.map(d => d.itemId) } }
  }, { enabled: Boolean(activity) });
  const items = itemsData?.items ?? [];

  const entityTypeMessage = activity && formatMessage(activityTypeMessages[activity.type]);

  const { submitActivity, isSubmitting } = useActivityMutations({
    onSuccess: () => {
      closeDialog();
      refetch();
    }
  });

  const onInterventionSubmit = (values: ActivityFormValues) => {
    submitActivity(values, Number(activityId));
  };

  const handleOnClickCreatedBy = (userId: string) => {
    navigate(`/${getTenantIdentifier()}/users/${userId}`);
  };

  const isTabLoading = isSectionsFetching || isFetchingIndividuals || isItemsFetching;

  const availableSteps = activityTypeAvailableSteps[activity?.type ?? ActivityType.Routine];

  const tabs: (Tab | null)[] = [
    {
      displayString: formatMessage({ id: 'Values' }),
      navigationString: 'values'
    },
    availableSteps.includes('individuals') ? {
      displayString: formatMessage({ id: 'Individuals' }),
      navigationString: 'individuals'
    } : null,
    availableSteps.includes('distributions') ? {
      displayString: formatMessage({ id: 'Distributions' }),
      navigationString: 'distributions'
    } : null,
    availableSteps.includes('realities') ? {
      displayString: formatMessage({ id: 'Realities' }),
      navigationString: 'realities'
    } : null
  ];

  return <>
    {(isFetching || isFetchingIndividuals || isTabLoading) && <LinearProgress />}

    {activity && (
      <>
        <PageTitle
          title={`${entityTypeMessage} ${activityId}`}
          subtitle={(
            <Stack direction='row' alignItems='center' spacing={1} mb={1}>
              <Typography variant='subtitle1'>{formatMessage({ id: 'Created by: ' })}</Typography>
              <Chip component={Link} label={activity?.createdBy?.displayName} onClick={() => activity?.createdBy ? handleOnClickCreatedBy(activity?.createdBy.id) : ''} />
            </Stack>
          )}
          actionButton={(
            <ActivityActions
              activity={activity}
              onEdit={deletedActivity ? undefined : openDialog}
            />
          )}
        />

        {activity?.isDeleted && (
          <Alert severity='info'>
            {formatMessage({ id: 'This activity is deleted, and will not be visible to non-admins and in generated reports.' })}
          </Alert>
        )}

        <RoutedTabs
          tabs={tabs.filter(t => t !== null) as Tab[]}
          navigationStyle='queryParam'
          queryParam='tab'
          defaultNavigation='values'
          variant='scrollable'
          scrollButtons='auto'
          sx={{ maxWidth: '100%' }}
        />
      </>
    )}

    <Stack py={3} spacing={2}>
      {currentTab === 'values' && (
        <ActivityFieldValuesTable
          sections={sections}
          customFieldValues={activity?.customFieldValues ?? []}
        />
      )}

      {currentTab === 'individuals' && <>
        {individualsSettingsData?.settings.at(0)?.isKnownIndividualsEnabled &&
          <IndividualsTable
            storageKey='activity-individuals-table'
            sectionTitle={formatMessage({ id: 'Known individuals' })}
            individuals={individuals}
            filterMode='client'
          />
        }

        <UnknownGroupTable
          groups={activity?.unknownGroups ?? []}
        />
      </>}

      {currentTab === 'distributions' && (
        <ItemsTable
          items={items}
          distributions={activity?.distributions}
        />
      )}

      {currentTab === 'realities' && (
        <ActivityRealities
          realities={activity?.realities ?? []}
        />
      )}
    </Stack>

    <ActivityDialog
      isOpen={isEditing}
      isLoading={isSubmitting}
      onClose={closeDialog}
      onSubmit={onInterventionSubmit}
      editingActivityId={Number(activityId)}
    />
  </>;
};