import { Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { useResponsive } from 'hooks';
import React from 'react';
import { useIntl } from 'react-intl';
import loginBackgroud from '../../../../assets/images/login-background.jpeg';
import { GoogleLoginButton, MicrosoftLoginButton } from './LoginButtons';

export const LoginBody:React.FC = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { isMobile } = useResponsive();
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);

  return (
    <>
      <img
        src={loginBackgroud}
        style={{
          display: isMobile ? 'block' : 'none',
          width: '100vw',
          height: '30vh',
          objectFit: 'cover',
          objectPosition: 'center center'
        }}
      />
      <Stack paddingTop={{ xs: 2.5, md: 13 }} alignItems={{ xs: 'flex-start', md: 'center' }} flexWrap='wrap' alignContent='center' paddingX={{ xs: 1.5, md: 8.5 }}>
        <Typography variant='h4' color={theme.palette.primary.main}>{formatMessage({ id: 'Welcome' })}</Typography>
        <Typography variant='body1' paddingTop={1} marginBottom={{ xs: 5,md: 8 }}>{formatMessage({ id: 'Please identify yourself to access the application' })}</Typography>
        <Stack sx={{ minWidth: { xs: '100%', md: '360px' } }}>
          {isLoggingIn && <Stack alignItems='center' justifyContent='center'>
            <CircularProgress size={100} />
          </Stack>
          }

          <form id="external-account" style={{ visibility: isLoggingIn ? 'hidden' : 'visible' }} action='/api/Account/ExternalLogin' method="post" >
            <MicrosoftLoginButton fullWidth type='submit' name="provider" value="OpenIdConnect"
              onClickCapture={() => setIsLoggingIn(true) } />
          </form>

          <form id="external-account" style={{ visibility: isLoggingIn ? 'hidden' : 'visible' }} action='/api/Account/ExternalLogin' method="post">
            <GoogleLoginButton fullWidth type='submit' name='provider' value="Google"
              onClickCapture={() => setIsLoggingIn(true) } />
          </form>
        </Stack>
      </Stack>
    </>
  );
};