import { List, ListItemButton } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { RealityInput, useRealitiesDataQuery } from '../../../../../../../gql';
import React from 'react';

export const SpecificationStep = (props: { stepIndex: number, activeStep: number, onStepComplete: () => void}) => {
  const { control } = useFormContext<RealityInput>();
  const realitiesQuery = useRealitiesDataQuery();
  const themeId = useWatch({ control: control, name: 'themeId' });
  const spectifications =  realitiesQuery.data?.themes?.find(p => p.id === themeId)?.specifications ?? [];
  return (
    <Controller
      name="specificationId"
      control={control}
      render={({ field }) => (
        <List>
          {spectifications.map(specification => (
            <ListItemButton key={specification.id}
              selected={field.value === specification.id}
              onClick={() => { field.onChange(specification.id); props.onStepComplete(); }}>
              {specification.name}
            </ListItemButton>))}
        </List>
      )} />);
};