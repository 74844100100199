import React, { useMemo } from 'react';
import { Filter, useGetActivitiesFilter } from 'modules/home/hooks/useGetActivitiesFilter';
import { Chip, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { Item, TopDistributedItems } from '../Tables/TopDistributedItems';
import { useGetTopDistributedItemsQuery } from 'gql';
import _ from 'lodash';
import { calculateItemsDelta, dashboardQueryOptions, getPreviousPeriodFilter } from 'modules/home/utils';
import { TimeRange } from '../DropdownFilter';

interface OwnProps {
  filter: Filter
  selectedTimeRange: TimeRange
  selectedTeam: string
}

export const DistributionSection: React.FC<OwnProps> = ({ filter, selectedTimeRange, selectedTeam }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { activitiesFilter } = useGetActivitiesFilter(TimeRange.beginFiscalYear, selectedTeam);

  const { data: itemsData, isFetching: isItemsLoading } = useGetTopDistributedItemsQuery(filter, dashboardQueryOptions());
  const { data: previousItemsData, isFetching: isPreviousItemsLoading } = useGetTopDistributedItemsQuery(getPreviousPeriodFilter(filter, selectedTimeRange), dashboardQueryOptions());
  const { data: fiscalItemsData, isFetching: isFiscalItemsLoading } = useGetTopDistributedItemsQuery(activitiesFilter, dashboardQueryOptions());
  const { data: previousFiscalItemsData, isFetching: isPreviousFiscalItemsLoading } = useGetTopDistributedItemsQuery(getPreviousPeriodFilter(activitiesFilter, TimeRange.beginFiscalYear), dashboardQueryOptions());

  const getPreviousItemQuantity = (itemId: number) => previousItemsData?.topDistributedItems.find(i => i.id === itemId)?.quantity ?? 0;
  const getItemStatFromStartReferenceYear = (itemId: number) => fiscalItemsData?.topDistributedItems.find(i => i.id === itemId);
  const getPreviousItemQuantityFromStartReferenceYear = (itemId: number) => previousFiscalItemsData?.topDistributedItems.find(i => i.id === itemId)?.quantity ?? 0;

  const currentItems = itemsData?.topDistributedItems ?? [];
  const totalItems = _.sumBy(currentItems, i => i.quantity);

  const items = useMemo(() => {
    const items: Item[] = [];

    const topItems = currentItems.filter(i => i.quantity > 0).slice(0, 5);

    topItems.map(item => {
      const itemStatFromStartReferenceYear = getItemStatFromStartReferenceYear(item.id);
      const previousItemQuantityFromStartReferenceYear = getPreviousItemQuantityFromStartReferenceYear(item.id);
      const previousItemQuantity = getPreviousItemQuantity(item.id) ?? 0;

      items.push({
        id: item.id,
        name: item.name,
        quantity: item.quantity,
        delta: calculateItemsDelta(item.quantity, previousItemQuantity) ?? '-',
        quantityReferenceYear: itemStatFromStartReferenceYear?.quantity ?? 0,
        deltaReferenceYear: calculateItemsDelta(itemStatFromStartReferenceYear?.quantity ?? 0, previousItemQuantityFromStartReferenceYear) ?? '-'
      });
    });

    return items;
  }, [itemsData, previousItemsData, fiscalItemsData]);

  const isLoading = isItemsLoading || isPreviousItemsLoading || isFiscalItemsLoading || isPreviousFiscalItemsLoading;

  return (
    <Stack spacing={2}>
      <Stack direction='row' spacing={2} alignItems='center'>
        <Typography variant='h5'>{formatMessage({ id: '{totalItems, plural, zero {Distributions} one {Distribution} other {Distributions}}' }, { totalItems: totalItems })}</Typography>
        {isLoading
          ?
          <Skeleton variant='circular' width={theme.spacing(3)} height={theme.spacing(3)} />
          :
          <Chip label={totalItems} color='primary' size='small' />
        }
      </Stack>
      <TopDistributedItems
        items={items ?? []}
        isLoading={isLoading}
      />
    </Stack>
  );
};