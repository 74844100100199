import { Paper, Typography, Divider, Stack, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { ResponsiveSelect } from 'components';
import { ReportParametersInput } from 'gql';
import { useActivitySettings } from 'hooks/useActivitySettings';
import { activityTypeMessages } from 'modules/activities/messages';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { DateFilterField } from '../FilterInputs/DateFilterField';

interface Props {
  disabled?: boolean;
}

export const ReportDetailsSection: React.FC<Props> = ({ disabled }) => {
  const { formatMessage } = useIntl();
  const { activitiesEnabled } = useActivitySettings();

  const { control } = useFormContext<ReportParametersInput>();

  return (
    <Paper>
      <Typography variant='h6' p={2}>{formatMessage({ id: 'Details' })}</Typography>
      <Divider />

      <Stack p={2} spacing={2}>
        <Controller
          control={control}
          rules={{
            validate: value => {
              if (!value.from || !value.to) {
                return formatMessage({ id: 'A start and end date is required' });
              }
            }
          }}
          name='filters.period'
          render={({ field, fieldState: { error } }) => (
            <DateFilterField
              fromLabel={formatMessage({ id: 'Start Date' })}
              toLabel={formatMessage({ id: 'End Date' })}
              value={field.value}
              onChange={field.onChange}
              error={Boolean(error)}
              helperText={error?.message}
              disabled={disabled}
              required
            />
          )}
        />

        <Controller
          name='filters.activityTypes'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <ResponsiveSelect
              label={formatMessage({ id: 'Activity Types' })}
              availableValues={Object.values(activitiesEnabled)}
              selectedValues={field.value}
              onChange={field.onChange}
              error={Boolean(error)}
              helperText={error?.message}
              getKey={a => a}
              getLabel={a => formatMessage(activityTypeMessages[a])}
              searchFilter={() => true}
              multiple
              disabled={disabled}
              renderListItem={(value, selected, onClick) => (
                <ListItem disablePadding>
                  <ListItemButton selected={selected} onClick={onClick}>
                    <ListItemText
                      primary={formatMessage(activityTypeMessages[value])}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            />
          )}
        />
      </Stack>
    </Paper>
  );
};