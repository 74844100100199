import { CompareArrows } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FormDialog } from 'components';
import { FindIndividualsQuery, SystemFieldId, useMergeIndividualsMutation } from 'gql';
import { useFindIndividualsCancellableQuery } from 'gql/cancellableQueries';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IndividualMergeCard } from './IndividualMergeCard';

interface Props {
  open?: boolean;
  onClose?: () => void;
  individual1Id?: number;
  individual2Id?: number;
}

type Individual = NonNullable<NonNullable<FindIndividualsQuery['searchIndividuals']>['items']>[0];

const getTeamId = (individual?: Individual) =>
  individual?.customFieldValues.filter(v => v.field.systemFieldId === SystemFieldId.Team).at(0)?.lookupTeamValue?.id;

export const IndividualsMergeDialog: React.FC<Props> = ({ individual1Id, individual2Id, open, onClose }) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const { data: individual1Data, isFetching: isIndividual1Fetching } = useFindIndividualsCancellableQuery({
    pageSize: 1, skip: 0,
    input: { id: { eq: individual1Id } },
    searchTerm: ''
  }, { enabled: Boolean(individual1Id) });
  const individual1 = individual1Data?.searchIndividuals?.items?.at(0);

  const { data: individual2Data, isFetching: isIndividual2Fetching } = useFindIndividualsCancellableQuery({
    pageSize: 1, skip: 0,
    input: { id: { eq: individual2Id } },
    searchTerm: ''
  }, { enabled: Boolean(individual2Id) });
  const individual2 = individual2Data?.searchIndividuals?.items?.at(0);

  const loading = isIndividual1Fetching || isIndividual2Fetching;

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [selectedIndividual, setSelectedIndividual] = useState<Individual>();

  const onMergeClicked = (individual: Individual) => {
    setSelectedIndividual(individual);
    setIsConfirmDialogOpen(true);
  };

  const mergeIndividualsMutation = useMergeIndividualsMutation({
    onSuccess: () => {
      onClose?.();
      queryClient.invalidateQueries(['findIndividuals']);
    }
  });

  const mergeConfirmed = () => {
    const individualToKeepId = selectedIndividual?.id;
    const individualToMergeId = selectedIndividual?.id === individual1?.id ? individual2?.id : individual1?.id;

    if (!individualToKeepId || !individualToMergeId) return;

    mergeIndividualsMutation.mutate({
      input: { individualToKeepId, individualToMergeId }
    });
  };

  const isDifferentTeams = getTeamId(individual1) !== getTeamId(individual2);

  return (
    <FormDialog isFormDirty={false} open={open ?? false} onClose={onClose} title={formatMessage({ id: 'Merge Individuals' })}>
      {loading && (
        <LinearProgress />
      )}

      <Typography fontSize={18} pb={2}>
        {formatMessage({ id: 'Select the user to keep.' })}
      </Typography>

      {isDifferentTeams && (
        <Alert severity='error' sx={{ mb: 2 }}>
          {formatMessage({ id: 'You can only merge individuals from the same team.' })}
        </Alert>
      )}

      <Grid container columnGap={2} columns={11} sx={{ opacity: isDifferentTeams ? 0.5 : undefined }}>
        <Grid item xs={5}>
          {individual1 && (
            <IndividualMergeCard
              individual={individual1}
              onMergeClicked={() => onMergeClicked(individual1)}
              disabled={isDifferentTeams}
            />
          )}
        </Grid>

        <Grid item display='flex' alignItems='center'>
          <CompareArrows />
        </Grid>

        <Grid item xs={5}>
          {individual2 && (
            <IndividualMergeCard
              individual={individual2}
              onMergeClicked={() => onMergeClicked(individual2)}
              disabled={isDifferentTeams}
            />
          )}
        </Grid>
      </Grid>

      <Dialog open={isConfirmDialogOpen} onClose={() => setIsConfirmDialogOpen(false)}>
        <DialogTitle>{formatMessage({ id: 'Are you sure you want to merge?' })}</DialogTitle>
        <DialogContent>
          <Typography>
            {formatMessage({
              id: 'You have chosen to keep {nickname}. The other user will be deleted, and all references to them will be replaced with references to {nickname}.'
            }, {
              nickname: selectedIndividual?.nickName
            })}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmDialogOpen(false)}>
            {formatMessage({ id: 'Cancel' })}
          </Button>
          <LoadingButton onClick={mergeConfirmed} variant='contained' color='primary' loading={mergeIndividualsMutation.isLoading}>
            {formatMessage({ id: 'Merge' })}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormDialog>
  );
};