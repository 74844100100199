import { Alert, Checkbox, DrawerProps, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FormDrawer } from 'components';
import { ApplicationUser, Team, useFindTeamsQuery, useModifyUserTeamsMutation } from 'gql';
import { useNotification } from 'hooks/useNotification';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

type User = Pick<ApplicationUser, 'id' | 'displayName'> & {
  teams: Pick<Team, 'id'>[];
};

interface Props extends DrawerProps {
  user: User;
}

export const ModifyUserTeamsDrawer: React.FC<Props> = ({ user, ...props }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();
  const queryClient = useQueryClient();

  const initialTeams = useMemo(() => user.teams.map(t => t.id), [user.teams]);

  const { data: teamsData } = useFindTeamsQuery();
  const teams = teamsData?.teams ?? [];

  const [selectedTeams, setSelectedTeams] = useState<number[]>(initialTeams);

  useEffect(() => {
    setSelectedTeams(initialTeams);
  }, [initialTeams, props.open]);

  const onTeamClicked = (teamId: number) => () => {
    if (selectedTeams.includes(teamId)) {
      setSelectedTeams(selectedTeams.filter(t => t !== teamId));
    } else {
      setSelectedTeams([...selectedTeams, teamId]);
    }
  };

  const isDirty = initialTeams.sort().join(',') !== selectedTeams.sort().join(',');

  const noneSelected = selectedTeams.length === 0;

  const modifyUserTeamsMutation = useModifyUserTeamsMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Teams modified successfully' }));
      props.onClose?.({}, 'backdropClick');
      queryClient.invalidateQueries(['getUserById']);
    }
  });

  const onSave = () => {
    if (noneSelected) return;

    modifyUserTeamsMutation.mutate({
      input: {
        userId: user.id,
        teamIds: selectedTeams
      }
    });
  };

  return (
    <FormDrawer
      isFormDirty={isDirty}
      {...props}
      header={formatMessage({ id: 'Modify teams for {displayName}' }, { displayName: user.displayName })}
      disablePadding
      showFooter
      disabledSaveButton={noneSelected || !isDirty}
      onSave={onSave}
    >
      {noneSelected && (
        <Alert severity='error' square>
          {formatMessage({ id: 'A user must have at least one team' })}
        </Alert>
      )}
      {teams.map(team => {
        const selected = selectedTeams.includes(team.id);

        return (
          <ListItem
            key={team.id}
            disablePadding
          >
            <ListItemButton selected={selected} onClick={onTeamClicked(team.id)}>
              <ListItemIcon>
                <Checkbox checked={selected} />
              </ListItemIcon>
              <ListItemText
                primary={team.name}
                secondary={team.description}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </FormDrawer>
  );
};