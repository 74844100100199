import React from 'react';
import { Divider, IconButton, ModalProps, Paper, Stack, styled } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useResponsive } from 'hooks';

interface SettingsDrawerHeaderProps {
  children?: React.ReactNode;
  onClose: ModalProps['onClose'];
}

const HeaderBox = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  zIndex: theme.zIndex.drawer + 1,
}));

export const DrawerHeader: React.FC<SettingsDrawerHeaderProps> = (props) => {
  const { isMobile } = useResponsive();
  return (
    <HeaderBox square elevation={16}>
      <Stack direction='row' alignItems='center' minHeight='57px'>
        {isMobile && <>
          <IconButton onClick={() => props.onClose?.({}, 'backdropClick')} sx={{ mx: 1 }}>
            <ArrowBack />
          </IconButton>
          <Divider orientation='vertical' sx={{ height: '44px' }} />
        </>}
        {props.children}
      </Stack>
      <Divider />
    </HeaderBox>
  );
};
