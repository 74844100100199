import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack } from '@mui/material';
import { Team, UserStatusType, useAddTeamMembersMutation, useFindUsersQuery, useRemoveTeamMemberMutation } from 'gql';
import { UsersTableWithDialog } from 'modules/users/components/UsersTableWithDialog';
import { Add, RemoveCircle } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { UserSelectDialog } from '../components/UserSelectDialog';

export const TeamUsersTab: React.FC = () => {
  const { formatMessage } = useIntl();
  const team = useOutletContext<Team>();
  const queryClient = useQueryClient();

  const { data, isFetched, refetch } = useFindUsersQuery({ filter: { teams: { all: { id: { neq: team.id } } } } });
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [isSelectDialogVisible, setSelectDialogVisible] = useState(false);

  const addTeamMembers = useAddTeamMembersMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['getTeamById']);
      refetch();
      toast.success(formatMessage({ id: 'Successfully added team member' }));
      setSelectDialogVisible(false);
    }
  });

  const onUsersSelected = () => {
    if (selectionModel.length === 0) return;
    addTeamMembers.mutate({ input: { teamId: team.id, members: selectionModel as string[] } });
  };

  const [membersToRemove, setMembersToRemove] = useState<string>();
  const isRemoveDialogVisible = membersToRemove !== undefined;

  const removeTeamMember = useRemoveTeamMemberMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['getTeamById']);
      refetch();
      toast.success(formatMessage({ id: 'Successfully removed team member' }));
      setMembersToRemove(undefined);
    }
  });

  const handleRemoveMember = () => {
    if (!membersToRemove) return;
    removeTeamMember.mutate({ input: { teamId: team.id, memberId: membersToRemove } });
  };

  return (
    <Stack spacing={3}>
      <Alert severity='info'>
        <AlertTitle>{formatMessage({ id: 'Editing team members' })}</AlertTitle>
        {formatMessage({ id: 'Added or deleted members will need to log in again to apply the new permissions' })}
      </Alert>
      <UsersTableWithDialog
        users={team.members.filter(member => member.status !== UserStatusType.Inactive)}
        actionButtonAdornment={
          <Button variant='outlined' startIcon={<Add />} onClick={() => setSelectDialogVisible(true)}>
            {formatMessage({ id: 'Add' })}
          </Button>
        }
        renderRowActions={(params) => (
          <IconButton color='error' onClick={() => setMembersToRemove(params.row.id ?? undefined)}>
            <RemoveCircle />
          </IconButton>
        )}
      />

      <UserSelectDialog
        users={data?.activeUsers ?? []}
        open={isSelectDialogVisible}
        onSubmit={onUsersSelected}
        onClose={() => setSelectDialogVisible(false)}
        tableProps={{
          checkboxSelection: true,
          loading: !isFetched,
          rowSelectionModel: selectionModel,
          onRowSelectionModelChange: setSelectionModel
        }}
      />

      <Dialog
        open={isRemoveDialogVisible}
        onClose={() => setMembersToRemove(undefined)}
      >
        <DialogTitle>
          {formatMessage({ id: 'Remove a team member' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {formatMessage({ id: 'This will remove this user from the team. Are you sure?' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={removeTeamMember.isLoading} onClick={() => setMembersToRemove(undefined)} variant='contained'>{formatMessage({ id: 'Cancel' })}</Button>
          <Button onClick={handleRemoveMember} color='error' autoFocus>
            {formatMessage({ id: 'Remove' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};