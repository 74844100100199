import { IconButton, IconButtonProps, styled } from '@mui/material';
import React from 'react';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '36px',
  height: '36px',
  borderRadius: '4px',
  color: theme.palette.info.contrastText,
  backgroundColor: theme.palette.info.light,
  ':hover': {
    backgroundColor: theme.palette.info.main,
  }
}));

export const IncrementIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <StyledIconButton {...props} />
  );
};