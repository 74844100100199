import React from 'react';
import { ButtonProps } from '@mui/material';
import { ReactComponent as GoogleIcon } from '../../../../../assets/images/logo-google.svg';
import { useIntl } from 'react-intl';
import { LoginButton } from './LoginButton';

export const GoogleLoginButton:React.FC<ButtonProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <LoginButton {...props} startIcon={<GoogleIcon/>}>
      {formatMessage({ id: 'Log in with Google' })}
    </LoginButton>
  );
};