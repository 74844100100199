import { DialogProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { DialogContainer } from './DialogContainer';
import { DialogTopBar, DialogTopBarProps } from './DialogTopBar';
import { SlideDialogBase } from './SlideDialogBase';

export interface SlideDialogProps extends Omit<DialogProps, 'title'> {
  children?: ReactNode;
  topbar: DialogTopBarProps;
}

export const SlideDialog: React.FC<SlideDialogProps> = ({ children, topbar, ...dialogProps }) => {
  return (
    <SlideDialogBase direction="up" {...dialogProps}>
      <DialogTopBar {...topbar} />

      <DialogContainer>
        {children}
      </DialogContainer>
    </SlideDialogBase>
  );
};