import { Container, ContainerProps } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props extends ContainerProps {
  children: ReactNode;
}

export const DialogContainer: React.FC<Props> = ({ children, ...containerProps }) => {
  return (
    <Container
      {...containerProps}
      maxWidth='md'
      sx={{
        mt: { xs: 2, md: 3 },
        mb: 3,
        px: { xs: 1, md: 3 },
        ...containerProps.sx
      }}
    >
      {children}
    </Container>
  );
};