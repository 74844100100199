import { FormControl, FormControlProps, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

interface TimeRangeDropdownProps {
  value: string
  onChangeValue: (value: TimeRange) => void
}

type OwnProps =
  & TimeRangeDropdownProps
  & FormControlProps;

export enum TimeRange {
  last7Days = 'last7Days',
  lastMonth = 'lastMonth',
  beginFiscalYear = 'beginFiscalYear'
}

export const TimeRangeDropdownFilter: React.FC<OwnProps> = ({ value, onChangeValue, ...formControlProps }) => {
  const { formatMessage } = useIntl();

  const handleChange = (event: SelectChangeEvent) => {
    onChangeValue(event.target.value as TimeRange);
  };

  return (
    <FormControl {...formControlProps}>
      <InputLabel>{formatMessage({ id: 'Period' })}</InputLabel>
      <Select
        label={formatMessage({ id: 'Period' })}
        value={value}
        onChange={handleChange}
      >
        <MenuItem value={TimeRange.last7Days}>{formatMessage({ id: 'Last 7 days' })}</MenuItem>
        <MenuItem value={TimeRange.lastMonth}>{formatMessage({ id: 'Last 30 days' })}</MenuItem>
        <MenuItem value={TimeRange.beginFiscalYear}>{formatMessage({ id: 'Beginning of reference year' })}</MenuItem>
      </Select>
    </FormControl >
  );
};