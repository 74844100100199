import React from 'react';
import { List } from '@mui/material';
import { allSupportedLocales } from '../../../../../../../locales';
import { LanguageSwitcherButton } from './LanguageSwitcherButton';

export const LanguageSwitcher: React.FC = () => {
  return (
    <List>
      {allSupportedLocales.map(locale => (
        <LanguageSwitcherButton
          key={locale.code}
          locale={locale}
        />
      ))}
    </List>
  );
};