import { Clear, Search } from '@mui/icons-material';
import { IconButton, LinearProgress, Stack, List, styled, Typography, Alert, ListItem } from '@mui/material';
import { FormDrawer } from 'components/Drawers';
import { SearchTextField } from 'components/SearchTextField';
import { LookupInputProps, LookupInputValueChip } from './LookupInput';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const HigherDrawer = styled(FormDrawer)(({ theme }) => ({
  zIndex: theme.zIndex.modal
}));

interface Props<T> extends Pick<LookupInputProps<T>, 'searchFilter'
  | 'searchMode'
  | 'onServerSearch'
  | 'hasMoreResults'
  | 'isLoading'
  | 'renderEmptyState'
  | 'selectedValues'
  | 'renderListItem'
  | 'getLabel' | 'getChipProps'
  | 'availableValues'
  | 'loading'
  | 'getKey'
  | 'onChange'
  | 'required'
  | 'multiple'
  | 'disableDrawerChips'
  | 'title' | 'label' | 'anchor'> {
  open: boolean;
  setOpen: (open: boolean) => void;
  actionButton?: React.ReactNode;
}

export const LookupInputDrawer = <T,>({
  selectedValues, availableValues, getKey, onChange, renderListItem, getLabel, getChipProps, renderEmptyState, searchFilter,
  searchMode, onServerSearch, hasMoreResults,
  loading, required, multiple, disableDrawerChips, title, label,
  open, setOpen, actionButton, anchor
}: Props<T>) => {
  const { formatMessage } = useIntl();
  const [initialState, setInitialState] = useState(selectedValues);

  const selectedKeys = selectedValues.map(getKey);

  const handleMultipleClick = (value: T) => {
    if (selectedKeys.includes(getKey(value))) {
      onChange(selectedValues.filter(v => getKey(v) !== getKey(value)));
    } else {
      onChange([...selectedValues, value]);
    }
  };

  const handleSingleClick = (value: T) => {
    const shouldClose = selectedValues.includes(value);

    if (required) {
      onChange([value]);
    } else {
      if (selectedValues.includes(value)) {
        onChange([]);
      } else {
        onChange([value]);
      }
    }

    setOpen(shouldClose);
  };

  const onValueClick = multiple ? handleMultipleClick : handleSingleClick;

  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = React.useState('');

  useEffect(() => {
    searchMode === 'server' && onServerSearch?.(searchValue);
  }, [onServerSearch, searchMode, searchValue]);

  useEffect(() => {
    setInitialState(selectedValues);
    setSearchValue('');
    setSearchOpen(false);
  }, [open]);

  const filteredValues = searchMode === 'server'
    ? availableValues
    : availableValues.filter(value => searchFilter?.(value, searchValue) ?? true);

  const shouldShowFooter = multiple;

  const handleCloseNoSave = () => {
    setOpen(false);
    if (shouldShowFooter) {
      onChange(initialState);
    }
  };

  return (
    <HigherDrawer
      clickOutsideSaves
      isFormDirty={initialState.toString() != selectedValues.toString()}
      saveButtonMessage={formatMessage({ id: 'Save' })}
      onSave={() => setOpen(false)}
      disablePadding
      open={open}
      showFooter={shouldShowFooter}
      anchor={anchor ?? 'right'}
      onClose={handleCloseNoSave}
      header={<>
        {searchOpen ? (
          <SearchTextField
            InputProps={{
              startAdornment: undefined
            }}
            placeholder={formatMessage({ id: 'Search...' })}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            actionButtonAdornment={actionButton}
            autoFocus
            onBlur={() => setSearchOpen(Boolean(searchValue))}
            onClear={() => {
              setSearchOpen(false);
              setSearchValue('');
            }}
          />) : (
          <Stack alignItems='center' direction='row' width='100%' pl={2} pr={2}>
            <Typography flexGrow={1}>
              {title || label}
            </Typography>
            {(searchFilter || searchMode === 'server') && (
              <IconButton onClick={() => setSearchOpen(true)}>
                <Search />
              </IconButton>
            )}
            {actionButton}
          </Stack>
        )}
      </>}
    >
      {loading && (
        <LinearProgress />
      )}

      {(!disableDrawerChips && multiple && selectedValues.length > 0) && (
        <Stack alignItems='center' p={1} direction='row' flexWrap='wrap' rowGap={0.5}>
          {selectedValues.map(value => (
            <>
              <LookupInputValueChip
                label={getLabel(value)}
                {...getChipProps?.(value)}
                key={getKey(value)}
                deleteIcon={<Clear />}
                onDelete={() => onValueClick(value)}
              />
            </>
          ))}
        </Stack>
      )}

      {(filteredValues.length === 0 && !loading)
        ? renderEmptyState?.()
        : (

          <List disablePadding>
            {filteredValues.map(value =>
              <div key={getKey(value)}>
                {renderListItem(value, selectedKeys.includes(getKey(value)), () => onValueClick(value))}
              </div>
            )}
            {hasMoreResults && <ListItem key='alert-box'>
              <Alert severity='info'>
                {formatMessage({ id: 'The number of results displayed is limited. Use the search at the top to find individuals.' })}
              </Alert>
            </ListItem>}
          </List>
        )}
    </HigherDrawer>
  );
};