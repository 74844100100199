import { SxProps, Tab, TabProps } from '@mui/material';
import merge from 'lodash/merge';
import React from 'react';

interface ErrorTabProps extends TabProps {
  error?: boolean;
}

export const ErrorTab: React.FC<ErrorTabProps> = ({ error, ...props }) => {
  const errorProps: SxProps = { color: 'error.main' };

  merge(errorProps, props.sx);

  return (
    <Tab {...props}
      sx={error ? errorProps : (props.sx)}
    />
  );
};