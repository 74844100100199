import React from 'react';
import { Add } from '@mui/icons-material';
import { Button, ButtonBaseProps, Fab, IconButton } from '@mui/material';
import { useIntl } from 'react-intl';

interface OwnProps {
  label?: string;
  iconButton?: boolean;
}

type NewButtonProps =
  & ButtonBaseProps
  & OwnProps;

export const NewButton: React.FC<NewButtonProps> = (props) => {
  const { label, iconButton, ...buttonBaseProps } = props;
  const { formatMessage } = useIntl();

  return <>
    {iconButton
      ?
      <IconButton
        {...buttonBaseProps}
        color='primary'
        sx={{
          display: { xs: 'flex', md: 'none' }
        }}
      >
        <Add />
      </IconButton>
      :
      <Fab
        {...buttonBaseProps}
        color='primary'
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          display: { xs: 'flex', md: 'none' }
        }}
      >
        <Add />
      </Fab>
    }

    <Button
      {...buttonBaseProps}
      variant='contained'
      color='primary'
      startIcon={<Add />}
      sx={{ display: { xs: 'none', md: 'flex' } }}
    >
      {label ?? formatMessage({ id: 'New' })}
    </Button>
  </>;
};