import React from 'react';
import { useGetIndividualsCountByGenderQuery } from 'gql';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { CountStatsSection } from './CountStatsSection';
import { CountStatsTile } from './CountStatsTile';
import { CountStatTileSkeleton } from './CountStatTileSkeleton';
import { Filter } from 'modules/home/hooks/useGetActivitiesFilter';
import { dashboardQueryOptions } from '../../utils';


interface OwnProps {
  activitiesFilter: Filter
}

export const IndividualsCount: React.FC<OwnProps> = ({ activitiesFilter }) => {
  const { formatMessage } = useIntl();

  const { data: individualsCountByGenderData, isFetching: isIndividualsByGenderLoading } = useGetIndividualsCountByGenderQuery(activitiesFilter, dashboardQueryOptions());
  const individualsCountByGender = individualsCountByGenderData?.individualsCountByGender ?? [];

  const totalIndividuals = _.sumBy(individualsCountByGender, individual => individual.count);

  return (
    <CountStatsSection
      title={formatMessage({ id: '{totalIndividus, plural, zero {Individuals met} one {Individual met} other {Individuals met}}' }, { totalIndividus: totalIndividuals })}
      totalCount={totalIndividuals}
      isLoading={isIndividualsByGenderLoading}
    >

      {isIndividualsByGenderLoading
        ?
        <>
          {Array.from({ length: 5 },
            (_, i) => (
              <CountStatTileSkeleton
                key={i}
              />
            ))}
        </>
        :
        <>
          {individualsCountByGender.map((individual, index) =>
            <CountStatsTile key={index} title={individual.gender} count={individual.count} />
          )}
        </>
      }

    </CountStatsSection>
  );
};