import { Button, ButtonProps } from '@mui/material';
import { useIntl } from 'react-intl';
import React from 'react';

export const CancelButton: React.FC<ButtonProps> = (props) => {
  const { formatMessage } = useIntl();

  return <Button color='inherit' {...props}>
    {formatMessage({ id: 'Cancel' })}
  </Button>;
};