import React from 'react';
import { Stack } from '@mui/material';
import { ActivitiesCount, IndividualsCount } from '../CountStatsSection';
import { TimeRange } from '../DropdownFilter';
import { TeamsSection } from '../TeamsSection';
import { useGetActivitiesFilter } from 'modules/home/hooks/useGetActivitiesFilter';
import { DistributionSection } from '../DistributionSection';

interface OwnProps {
  teamFilter: string
  timeRangeFilter: TimeRange
}

export const AnalystView: React.FC<OwnProps> = ({ teamFilter, timeRangeFilter }) => {
  const { activitiesFilter } = useGetActivitiesFilter(timeRangeFilter, teamFilter);

  return (
    <Stack gap={3}>
      <ActivitiesCount
        activitiesFilter={activitiesFilter}
        selectedTimeRange={timeRangeFilter}
      />
      <TeamsSection
        activitiesFilter={activitiesFilter}
      />
      <DistributionSection
        filter={activitiesFilter}
        selectedTimeRange={timeRangeFilter}
        selectedTeam={teamFilter}
      />
      <IndividualsCount
        activitiesFilter={activitiesFilter}
      />
    </Stack>
  );
};