import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

export const useBirthdayFormatter = () => {
  const { formatMessage, formatDate } = useIntl();

  const formattedAge = (birthdate?: string | null) => {
    if (!birthdate) return '';

    if (!isNaN(Number(birthdate))) {
      return formatMessage({ id: '{age} years old' }, { age: birthdate });
    }

    const birthdateDayjs = dayjs(birthdate);

    const age = dayjs().diff(birthdateDayjs, 'year');
    return formatMessage({ id: '{age} years old' }, { age });
  };

  const formattedBirthdate = (birthdate?: string | null) => {
    if (!birthdate) return '';
    const birthdateDayjs = dayjs(birthdate);
    return formatDate(birthdateDayjs.toDate());
  };

  return { formattedAge, formattedBirthdate };
};