import { Close, Done } from '@mui/icons-material';
import React from 'react';
import { useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import { SlideDialog } from 'components/Dialogs/SlideDialog';
import { DialogProps, Stack } from '@mui/material';
import { UsersTableProps, UsersTable } from '../../../users/components/UsersTable';
import { ApplicationUser } from '../../../../gql';

export interface UserSelectDialogProps extends Omit<DialogProps, 'onSubmit'> {
  users: Pick<ApplicationUser, 'id' | 'displayName' | 'email' | 'status' | 'roles'>[];
  onSubmit?: () => void;
  tableProps?: Omit<UsersTableProps, 'users'>;
  loading?: boolean;
}

export const UserSelectDialog: React.FC<UserSelectDialogProps> = ({ users, tableProps, onSubmit, loading, ...dialogProps }) => {
  const { formatMessage } = useIntl();

  return (
    <SlideDialog
      {...dialogProps}
      topbar={{
        leftIcon: (<Close />),
        leftIconAction: () => dialogProps.onClose?.({}, 'backdropClick'),
        title: formatMessage({ id: 'Add team members' }),
      }}
    >
      <UsersTable
        users={users}
        disableRowSelectionOnClick={loading}
        {...tableProps}
      />

      <Stack direction='row' justifyContent='flex-end' marginTop={2}>
        <LoadingButton
          loading={loading}
          variant='contained'
          startIcon={<Done />}
          color='primary'
          onClick={onSubmit}
          disabled={loading || !tableProps?.rowSelectionModel}
        >
          {formatMessage({ id: 'Accept' })}
        </LoadingButton>
      </Stack>
    </SlideDialog >
  );
};