import { BuiltInRoles, useGetCurrentUserQuery } from 'gql';

export const useCurrentUserRoles = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const roles = currentUser?.me.roles.map(r => r.name as BuiltInRoles);

  const isStreetOutreachWorker = roles?.includes(BuiltInRoles.StreetOutreachWorker);
  const isTenantAdministrator = roles?.includes(BuiltInRoles.TenantAdministrator);
  const isGlobalAdministrator = roles?.includes(BuiltInRoles.GlobalAdministrator);
  const isAdministrator = isTenantAdministrator || isGlobalAdministrator;
  const isAnalyst = roles?.includes(BuiltInRoles.Analyst);

  return { isStreetOutreachWorker, isTenantAdministrator, isGlobalAdministrator, isAnalyst, isAdministrator };
};