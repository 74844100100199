import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GridColDef, GridValueFormatterParams, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid';
import { DataTable, DataTableProps } from 'components';
import { CustomFieldDto, UnknownGroup as UnknownGroupEntity, CustomFieldEntityType as CustomFieldEntityTypeEntity, EntityType, useFindSectionsQuery, SystemFieldId, CustomFieldType, GetActivityByIdQuery, FindCustomFieldsQuery, CustomFieldValue } from 'gql';
import { useIntl } from 'react-intl';
import orderBy from 'lodash/orderBy';
import { useCustomFieldsSearchUtils } from 'utils/dataGridSearch/customFieldsSearch';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { notEmpty } from '../../../../utils/typescriptUtils';
import dayjs from 'dayjs';

type UnknownGroup = Pick<UnknownGroupEntity, 'id' | 'averageAge' | 'gender' | 'numberOfUsers'>
  & {
    customFieldValues: Pick<CustomFieldValue, 'id' | 'fieldId' | 'displayString' | 'dateValue'>[]
  };

type CustomFieldEntityType = Pick<CustomFieldEntityTypeEntity, 'entityType' | 'customFieldId'> & {
  customFieldDto: Pick<CustomFieldDto, 'customFieldType' | 'systemFieldId' | 'allowMultipleValues'>;
};

interface Props extends Omit<DataTableProps, 'columns' | 'rows'> {
  groups: NonNullable<UnknownGroup[]>;
}

const valueGetterByFieldType = (p: CustomFieldEntityType) => ({ row: { customFieldValues } }: GridValueGetterParams<UnknownGroup>) => {
  if (!customFieldValues) {
    return '';
  }

  const fieldType = p.customFieldDto.customFieldType;

  if (fieldType === CustomFieldType.Date) {
    return dayjs(customFieldValues.find(q => q.fieldId === p.customFieldId)?.dateValue).toDate();
  } else {
    return customFieldValues.find(q => q.fieldId === p.customFieldId)?.displayString;
  }
};

const valueFormatterFunction = (p: CustomFieldEntityType) => {
  const systemFieldId = p.customFieldDto.systemFieldId;
  if (systemFieldId === SystemFieldId.Birthday) {
    return ({ value }: GridValueFormatterParams<Date>) => {
      return dayjs().diff(dayjs(value), 'year');
    };
  }
};

export const UnknownGroupTable: React.FC<Props> = ({ groups, ...searchTableProps }) => {
  const { data: sections } = useFindSectionsQuery({ filter: { entityType: { eq: EntityType.UnknownGroup } } });
  const { formatMessage } = useIntl();
  const { columnMinWidth, customFieldTypeToColumnType, getOperatorsByCustomField } = useCustomFieldsSearchUtils();
  const wasGridStateRestored = React.useRef(false);
  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = useState<GridInitialStateCommunity>({});

  const columns = useMemo(() => {
    const fieldIds = new Set<number>();
    const visibilityConfig: Record<string, boolean> = {};
    const columnsToDisplay: GridColDef<UnknownGroup>[] =
      orderBy(sections?.sections.flatMap(p => p.fieldEntityTypes), p => p.order.toString())
        .map<GridColDef<UnknownGroup> | null>(p => {
          if (fieldIds.has(p.customFieldId)) {
            return null;
          }

          fieldIds.add(p.customFieldId);

          if (![SystemFieldId.Gender, SystemFieldId.AverageAge, SystemFieldId.NumberOfUsers].includes(p.customFieldDto.systemFieldId as SystemFieldId)) {
            visibilityConfig[`${p.entityType.toString()};${p.customFieldId.toString()}`] = false;
          }

          return {
            field: `${p.entityType.toString()};${p.customFieldId.toString()}`,
            type: customFieldTypeToColumnType[p.customFieldDto.customFieldType],
            sortable: false, // disable client side sorting which results in bad UX when combined with server side pagination
            filterOperators: getOperatorsByCustomField(p.customFieldDto),
            hideable: !(p.customFieldDto.systemFieldId === SystemFieldId.NickName),
            headerName: p.customFieldDto.name,
            flex: 1,
            minWidth: columnMinWidth[p.customFieldDto.customFieldType],
            valueGetter: valueGetterByFieldType(p),
            valueFormatter: valueFormatterFunction(p),
          };
        }).filter(notEmpty) ?? [];

    setInitialState({
      sorting: {
        sortModel: []
      },
      columns: {
        columnVisibilityModel: visibilityConfig
      }
    });

    return columnsToDisplay;
  }, [columnMinWidth, customFieldTypeToColumnType, formatMessage, getOperatorsByCustomField, sections?.sections]);

  useEffect(() => {
    if (!wasGridStateRestored.current) {
      const stateJSON = localStorage.getItem('activity-unknown-groups-table');
      if (stateJSON) {
        const state: GridInitialStateCommunity = JSON.parse(stateJSON);
        if (state.columns) state.columns.orderedFields = undefined;
        apiRef.current.restoreState({ ...state, preferencePanel: { open: false } });
      } else {
        apiRef.current.restoreState(initialState);
      }

      wasGridStateRestored.current = true;
    }
  }, [apiRef, initialState]);

  const saveTableState = useCallback(() => {
    const state = apiRef.current.exportState();
    localStorage.setItem('activity-unknown-groups-table', JSON.stringify(state));
  }, [apiRef]);

  return (
    <DataTable
      apiRef={apiRef}
      columns={columns}
      rows={groups}
      noDataMessage={formatMessage({ id: 'There are no groups yet' })}
      rowHeight={64}
      hideFooter
      sectionTitle={formatMessage({ id: 'Unknown individuals' })}
      {...searchTableProps}
      onColumnVisibilityModelChange={saveTableState}
      onSortModelChange={saveTableState}
    />
  );
};