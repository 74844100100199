import React, { useState } from 'react';
import { NewButton, PageTitle } from 'components';
import { useIntl } from 'react-intl';
import { OrganizationDialog } from '../components/OrganizationDialog';
import { OrganizationsTable } from '../components/OrganizationsTable';
import { CorporateFare } from '@mui/icons-material';
import { Tenant, useFindTenantsQuery } from 'gql';
import { useSearchParams } from 'react-router-dom';

export const OrganizationsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const [searchValue, setSearchValue] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const isDialogVisible = searchParams.get('add') === 'true';

  const handleShowAddDialog = () => {
    searchParams.set('add', 'true');
    setSearchParams(searchParams);
  };

  const handleCloseDialog = () => {
    searchParams.delete('add');
    setSearchParams(searchParams);
  };

  const handleSearch = (searchTerm: string) => {
    setSearchValue(searchTerm);
  };

  const { data: tenants, isFetching } = useFindTenantsQuery({
    filter: searchValue != ''
      ? {
        or: [
          { name: { contains: searchValue } },
          { region: { contains: searchValue } },
          { town: { contains: searchValue } }
        ]
      }
      : undefined
  });

  return (
    <>
      <PageTitle
        icon={<CorporateFare />}
        title={formatMessage({ id: 'Organizations' })}
        actionButton={(
          <NewButton onClick={handleShowAddDialog} />
        )}
      />

      <OrganizationsTable
        onSearch={handleSearch}
        loading={isFetching}
        organizations={tenants?.tenants as Tenant[] ?? []}
      />

      <OrganizationDialog
        open={isDialogVisible}
        handleClose={handleCloseDialog}
      />
    </>
  );
};