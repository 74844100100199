import React from 'react';
import { Team } from 'gql';
import { useOutletContext } from 'react-router-dom';
import { Stack } from '@mui/material';
import { TeamDetails } from '../components/TeamDetails';

export const TeamDetailsTab: React.FC = () => {
  const team = useOutletContext<Team>();

  return (
    <TeamDetails team={team} />
  );
};