import { AppBar, Divider, Stack, Toolbar } from '@mui/material';
import { CancelButton } from 'components/CancelButton';
import React from 'react';
import { ConfirmCloseDialog } from '../ConfirmCloseDialog';
import { ResponsiveDrawer, ResponsiveDrawerProps, SaveButton } from 'components';
import { useResponsive } from 'hooks';

export interface FormDrawerProps extends ResponsiveDrawerProps {
  isFormDirty?: boolean;
  saveButtonMessage?: string;
  disabledSaveButton?: boolean;
  onSave?: () => void;
  showFooter?: boolean;
  clickOutsideSaves?: boolean;
}

export const FormDrawer: React.FC<FormDrawerProps> = ({
  children,
  open,
  onClose,
  onSave,
  isFormDirty,
  saveButtonMessage,
  disabledSaveButton,
  showFooter,
  clickOutsideSaves,
  ...drawerProps
}) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);

  const onConfirmClose = () => {
    setIsConfirmDialogOpen(false);
    onClose?.({}, 'escapeKeyDown');
  };

  const handleCloseDrawer = () => {
    if (isFormDirty) {
      setIsConfirmDialogOpen(true);
    } else {
      onConfirmClose();
    }
  };

  const onDrawerHeaderClose = () => {
    handleCloseDrawer();
  };

  const onClickOutside = () => {
    if (clickOutsideSaves) {
      onSave?.();
    } else {
      handleCloseDrawer();
    }
  };

  return (
    <>
      <ResponsiveDrawer {...drawerProps} open={open || isConfirmDialogOpen} onClose={onClickOutside} drawerHeaderOnClose={onDrawerHeaderClose}>
        <Stack sx={{ flex: 1, overflowY: 'auto' }}>
          {children}
        </Stack>
        {showFooter && <>
          <Stack color='inherit' width='100%' sx={{ boxShadow: 'none' }} >
            <Divider />
            <Stack justifyContent='right' direction='row' spacing={1} p={2}>
              <CancelButton onClick={handleCloseDrawer} />
              <SaveButton
                onClick={onSave}
                disabled={disabledSaveButton}
              >
                {saveButtonMessage}
              </SaveButton>
            </Stack>
          </Stack>

        </>}
      </ResponsiveDrawer>
      <ConfirmCloseDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={onConfirmClose}
      />
    </>
  );
};