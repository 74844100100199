import React, { useState } from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useIntl } from 'react-intl';
import { EntityType } from 'gql';
import { PageTitle } from 'components/PageTitle';
import { entityTypePluralMessages } from 'modules/activities/messages';
import { NewButton, RoutedTabs } from 'components';
import { Box } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

export const DistributionConfigurationPageLayout: React.FC = () => {
  const { formatMessage } = useIntl();

  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);

  const location = useLocation();
  const pathName = location.pathname;
  const isItems = pathName.includes('items');
  const isCategories = pathName.includes('categories');

  const buttonLabel = isItems
    ? formatMessage({ id: 'Add item' })
    : isCategories
      ? formatMessage({ id: 'Add category' })
      : formatMessage({ id: 'Add section' });

  return (
    <>
      <PageTitle
        icon={<ViewListIcon />}
        title={formatMessage({ id: 'Customization' })}
        subtitle={formatMessage(entityTypePluralMessages[EntityType.Distribution])}
        actionButton={(
          <NewButton label={buttonLabel} onClick={() => setIsAddDrawerOpen(true)} />
        )}
      />

      <RoutedTabs
        tabs={[{
          displayString: formatMessage({ id: 'Fields' }),
          navigationString: 'fields'
        }, {
          displayString: formatMessage({ id: 'Categories' }),
          navigationString: 'categories'
        }, {
          displayString: formatMessage({ id: 'Items' }),
          navigationString: 'items'
        }]}
        defaultNavigation='fields'
        onClick={() => setIsAddDrawerOpen(false)}
      />

      <Box sx={{ mt: 2 }}>
        <Outlet context={[isAddDrawerOpen, setIsAddDrawerOpen]} />
      </Box>
    </>
  );
};