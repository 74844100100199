import { Paper, Typography, Divider, Stack, LinearProgress } from '@mui/material';
import { EntityType, ReportFieldFilterInput, ReportParametersInput, SystemFieldId, useFindCustomFieldsQuery } from 'gql';
import React, { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FilterInput } from '../FilterInput';

interface Props {
  disabled?: boolean;
}

export const ReportIndividualsSection: React.FC<Props> = ({ disabled }) => {
  const { formatMessage } = useIntl();

  const { control } = useFormContext<ReportParametersInput>();
  const { fields: fieldFilters, append, update } = useFieldArray({ control, name: 'filters.individualsFieldFilters' });

  const { data: fieldsData, isFetching } = useFindCustomFieldsQuery({
    filter: {
      entityTypes: {
        some: {
          entityType: {
            eq: EntityType.Individual
          }
        }
      },
      systemFieldId: {
        neq: SystemFieldId.Team
      }
    }
  });
  const fields = useMemo(() => fieldsData?.customFields ?? [], [fieldsData]);

  const onChange = (customFieldFilterInput: ReportFieldFilterInput) => {
    const index = fieldFilters.findIndex(f => f.customFieldId === customFieldFilterInput.customFieldId);
    if (index === -1) {
      append(customFieldFilterInput);
    } else {
      update(index, customFieldFilterInput);
    }
  };

  return (
    <Paper>
      <Typography variant='h6' p={2} pb={1}>{formatMessage({ id: 'Known individuals' })}</Typography>

      <LinearProgress style={{ visibility: isFetching ? 'visible' : 'hidden' }} />
      <Divider />

      <Stack p={2} spacing={2}>
        {fields.map(field => (
          <FilterInput
            key={field.id}
            customField={field}
            value={fieldFilters.find(f => f.customFieldId === field.id) ?? { customFieldId: field.id }}
            onChange={onChange}
            disabled={disabled}
          />
        ))}
      </Stack>
    </Paper>
  );
};