import { BuiltInRoles } from 'gql';
import { MessageDescriptor, defineMessages } from 'react-intl';

export const userRoleMessages: Record<BuiltInRoles, MessageDescriptor> = defineMessages({
  [BuiltInRoles.TenantAdministrator]: { id: 'Tenant Administrator' },
  [BuiltInRoles.StreetOutreachWorker]: { id: 'Street Outreach Worker' },
  [BuiltInRoles.GlobalAdministrator]: { id: 'Global Administrator' },
  [BuiltInRoles.Analyst]: { id: 'Analyst' }
});

export const userRoleDescriptions: Record<BuiltInRoles, MessageDescriptor> = defineMessages({
  [BuiltInRoles.TenantAdministrator]: {
    id: '<li>Do everything that a street outreach worker can</li>\
    <li>Do everything that an analyst can</li>\
    <li>See and modify the settings of the tenant</li>'
  },
  [BuiltInRoles.StreetOutreachWorker]: {
    id: '<li>See and modify activities</li>\
  <li>See the activities of their team</li>\
  <li>See and modify individuals, if enabled</li>'
  },
  [BuiltInRoles.GlobalAdministrator]: {
    id: '<li>Do everything that a tenant administrator can</li>\
    <li>Create and modify tenants</li>'
  },
  [BuiltInRoles.Analyst]: {
    id: '<li>Do everything that a street outreach worker can</li>\
    <li>Create and view reports</li>'
  }
});