import { Alert, Stack } from '@mui/material';
import React from 'react';
import { ManageIndividualsSection } from './components/ManageIndividualsSection/ManageIndividualsSection';
import { ManageUnknownGroupsSection } from './components/ManageUnknownGroupsSection';
import { useFormContext } from 'react-hook-form';
import { ActivityFormValues } from '../ActivityDialog';
import { useGetIndividualsSettingsQuery } from 'gql';

export const IndividualsConfigurationStep: React.FC = () => {
  const { formState: { errors } } = useFormContext<ActivityFormValues>();
  const { data: individualsSettingsData } = useGetIndividualsSettingsQuery();
  const error = errors.individuals;

  return (
    <Stack spacing={2}>
      {error?.message && (
        <Alert severity="error">
          {error.message}
        </Alert>
      )}

      {individualsSettingsData?.settings.at(0)?.isKnownIndividualsEnabled && <ManageIndividualsSection />}

      <ManageUnknownGroupsSection />
    </Stack>
  );
};