import { DragDropContext, Draggable, DraggableStyle, Droppable, OnDragEndResponder } from '@hello-pangea/dnd';
import { DragHandle } from '@mui/icons-material';
import { LinearProgress, List, ListItem, ListItemButton, ListItemIcon, ListItemText, darken, lighten, useTheme } from '@mui/material';
import { EmptyState } from 'components';
import { useFindDistributionCategoriesQuery, useReorderDistributionCategoriesMutation } from 'gql';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  onCategoryClicked: (categoryId: number) => void;
}

export const DraggableCategoriesList: React.FC<Props> = ({ onCategoryClicked }) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const { data: categoriesData, isFetching, refetch } = useFindDistributionCategoriesQuery();

  const [categories, setCategories] = useState(categoriesData?.categories ?? []);

  useEffect(() => {
    setCategories(categoriesData?.categories ?? []);
  }, [categoriesData?.categories]);

  const reorderMutation = useReorderDistributionCategoriesMutation({
    onSuccess: () => refetch(),
  });

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination)
      return;

    const { source: { index: sourceIndex }, destination: { index: destinationIndex } } = result;

    setCategories((prevCategories) => {
      const reorderedCategories = [...prevCategories];
      const [removed] = reorderedCategories.splice(sourceIndex, 1);
      reorderedCategories.splice(destinationIndex, 0, removed);

      reorderMutation.mutate({ input: { input: { ordering: reorderedCategories.map(c => c.id) } } });

      return reorderedCategories;
    });
  };

  const loading = reorderMutation.isLoading || isFetching;

  const getDraggableStyle = (isDragging: boolean, draggableStyle?: DraggableStyle) => ({
    background: isDragging ?
      (theme.palette.mode === 'light' ? darken(theme.palette.common.white, 0.04) : lighten(theme.palette.background.paper, 0.2))
      :
      (theme.palette.mode === 'light' ? theme.palette.common.white : 'inherit'),
    ...draggableStyle
  });

  return <>
    <LinearProgress sx={{ visibility: loading ? 'visible' : 'hidden' }} />

    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='categories'>{(provided) => (
        <List disablePadding ref={provided.innerRef} {...provided.droppableProps}>
          {categories.map((category, index) => (
            <Draggable key={category.id} draggableId={category.id.toString()} index={index} isDragDisabled={loading}>{(provided, snapshot) => (
              <ListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                divider={index !== categories.length - 1}
                disablePadding
                sx={getDraggableStyle(snapshot.isDragging)}
              >
                <ListItemButton disabled={loading} disableRipple>
                  <ListItemIcon {...provided.dragHandleProps}>
                    <DragHandle />
                  </ListItemIcon>
                  <ListItemText
                    primary={category.name}
                    onClick={() => onCategoryClicked(category.id)}
                  />
                </ListItemButton>
              </ListItem>
            )}</Draggable>
          ))}
          {provided.placeholder}
        </List>
      )}</Droppable>
    </DragDropContext>

    {categories.length === 0 && (
      <EmptyState
        title={formatMessage({ id: 'No distribution categories created yet' })}
      />
    )}
  </>;
};