import { QuestionMark } from '@mui/icons-material';
import { Box, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { LookupInput, UserAvatar } from 'components';
import { useFindUsersQuery, ApplicationUser, UserStatusType, Team, useGetCurrentUserQuery } from 'gql';
import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { GenericCustomFieldInputProps } from './CustomFieldInput';
import deburr from 'lodash/deburr';

type TeamInfo = Pick<Team, 'id' | 'name' | 'description'>;
const UserListItem: React.FC<{
  user: Pick<ApplicationUser, 'id' | 'displayName' | 'email'> & { teams: TeamInfo[]; };
  selected?: boolean;
  onClick?: () => void;
}> = ({ user, selected, onClick }) => {

  return <Box sx={{ backgroundColor: selected ? 'action.hover' : undefined }}>
    <ListItem
      key={user.id}
      disablePadding
    >
      <ListItemButton onClick={onClick}>
        <UserAvatar displayName={user.displayName} size='medium' />

        <ListItemText
          primary={user.displayName}
          secondary={user.email}
          sx={{ ml: 1 }}
        />
      </ListItemButton>
    </ListItem>
  </Box>;
};

interface Props extends GenericCustomFieldInputProps {
  multiple?: boolean;
  teamIdFilter?: number;
  includeMe?: boolean;
  anchor?: 'left' | 'right';
}

export const UserLookupInput: React.FC<Props> = ({ value, onChange, multiple, teamIdFilter, error, disabled, helperText, label, required, includeMe, anchor }) => {
  const { formatMessage } = useIntl();

  const teamFilter = teamIdFilter ? { teams: { some: { id: { eq: teamIdFilter } } } } : {};

  const { data: whoAmIQuery } = useGetCurrentUserQuery();
  const me = whoAmIQuery?.me;

  const includeMeFilter = !includeMe ? { id: { neq: me?.id } } : {};

  const { data: userData, isFetching } = useFindUsersQuery({
    filter: {
      and: [{
        status: { neq: UserStatusType.Inactive }
      }, includeMeFilter, teamFilter]
    }
  });
  const users = useMemo(() => userData?.activeUsers ?? [], [userData?.activeUsers]);

  const selectedIds = useMemo(() => value?.split(',').filter(Boolean) ?? [], [value]);

  useEffect(() => {
    if (teamIdFilter && !isFetching) {
      onChange(users.filter(u => selectedIds.includes(u.id)).map(u => u.id).join(','));
    }
  }, [isFetching, onChange, selectedIds, teamIdFilter, users]);

  const handleIndividualsChange = (ids: string[]) => {
    onChange(ids.filter(Boolean).join(','));
  };

  return (
    <LookupInput
      anchor={anchor}
      selectedValues={users.filter(user => selectedIds.includes(user.id))}
      availableValues={includeMe ? users : users.filter(user => user.id !== me?.id)}
      getKey={user => user.id}
      getLabel={user => user.displayName}
      renderAsChips
      onChange={users => handleIndividualsChange(users.map(user => user.id))}
      renderListItem={(user, selected, onClick) => (
        <UserListItem key={user.id} user={user} selected={selected} onClick={onClick} />
      )}
      getChipProps={user => ({
        avatar: (<UserAvatar key={user.id} displayName={user.displayName} size='tiny' />),
        sx: {
          my: 0.5
        }
      })}

      renderEmptyState={() => (
        <Stack width='100%' alignItems='center' spacing={2} mt={3}>
          <QuestionMark />
          <Typography>
            {formatMessage({ id: 'No users found' })}
          </Typography>
        </Stack>
      )}

      disabled={disabled}
      label={label}
      required={required}
      error={error}
      multiple={multiple}
      helperText={helperText}
      loading={isFetching}
      searchFilter={(user, search) => deburr(user.displayName).toLowerCase().includes(deburr(search).toLowerCase())}
      SelectProps={{
        sx: {
          '& .MuiSelect-select': {
            whiteSpace: 'initial',
            px: 2
          }
        }
      }}
    />
  );
};