import React from 'react';
import { useRecoilState } from 'recoil';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { isNavigationMenuCollapsedState } from '../../../atoms';
import { NavigationMenuItem, NavigationMenuItemProps } from './NavigationMenuItem';

export const NavigationMenuCollapser: React.FC<NavigationMenuItemProps> = (props) => {
  const [isNavigationMenuCollapsed, setIsNavigationMenuCollapsed] = useRecoilState(isNavigationMenuCollapsedState);
  const toggleMenu = () => setIsNavigationMenuCollapsed(!isNavigationMenuCollapsed);

  return (
    <NavigationMenuItem
      icon={isNavigationMenuCollapsed ? <ArrowForwardIcon /> : undefined}
      onClick={toggleMenu}
      {...props}
    >
      <ArrowBackIcon />
    </NavigationMenuItem>
  );
};