import React, { useState } from 'react';
import { IconButtonProps, Theme as MuiTheme } from '@mui/material';
import { ThemeDetailsDialog } from './ThemeDetailsDialog';
import { InformationIconButton } from 'components/InformationIconButton';
import { RealitySpecificationDto, RealityThemeDto } from '../../../../../../../gql';

interface OwnProps {
  theme: Pick<RealityThemeDto, 'name' | 'description'> & { 'specifications': Pick<RealitySpecificationDto, 'id' | 'name'>[]; };
}

export type ThemeDetailsTriggerProps =
  & IconButtonProps
  & OwnProps;

export const ThemeDetailsTrigger: React.FC<ThemeDetailsTriggerProps> = (props) => {
  const { theme, ...iconButtonProps } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  return (
    <>
      <InformationIconButton onClick={openDialog} {...iconButtonProps} />
      <ThemeDetailsDialog
        open={isDialogOpen}
        onClose={closeDialog}
        theme={theme}
        sx={{ zIndex: (theme: MuiTheme) => theme.zIndex.modal + 1 }}
      />
    </>
  );
};