import React, { useState } from 'react';
import GroupsIcon from '@mui/icons-material/Groups';
import { NewButton, PageTitle, } from 'components';
import { useIntl } from 'react-intl';
import { TeamsTable } from '../components/TeamsTable';
import { Team, useFindTeamsQuery } from 'gql';
import { TeamDialog } from '../components/TeamDialog';
import { useSearchParams } from 'react-router-dom';

export const TeamsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const [searchValue, setSearchValue] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const isDialogVisible = searchParams.get('add') === 'true';

  const handleShowAddDialog = () => {
    setSearchParams({ add: 'true' });
  };

  const handleCloseDialog = () => {
    searchParams.delete('add');
    setSearchParams(searchParams);
  };

  const handleSearch = (searchTerm: string) => {
    setSearchValue(searchTerm);
  };

  const { data: teams, isFetching } = useFindTeamsQuery({
    filter: searchValue != ''
      ? {
        or: [
          { name: { contains: searchValue } },
        ]
      }
      : undefined
  });

  return (
    <>
      <PageTitle
        icon={<GroupsIcon />}
        title={formatMessage({ id: 'Teams' })}
        actionButton={(
          <NewButton onClick={handleShowAddDialog} />
        )}
      />

      <TeamsTable
        onSearch={handleSearch}
        loading={isFetching}
        teams={teams?.teams as Team[] ?? []}
      />

      <TeamDialog
        open={isDialogVisible}
        handleClose={handleCloseDialog}
      />
    </>
  );
};