import React, { useState } from 'react';
import { ApplicationUser, BuiltInRoles, Team, useDeactivateUserMutation, useGetCurrentUserQuery } from 'gql';
import { Divider, Stack, Typography } from '@mui/material';
import { DangerZone, DisplayInformation, DisplayInformationRow, UserAvatar } from 'components';
import { useIntl } from 'react-intl';
import { UpdateUserRoleDrawer } from '../UpdateUserRoleDrawer';
import { useResponsive } from 'hooks';
import { UserDetailsDrawer } from '../UserDetailsDrawer';
import { ConfirmButton } from 'components/Buttons';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useNotification } from 'hooks/useNotification';
import { getTenantIdentifier } from 'utils/tenantUtils';
import { ModifyUserTeamsDrawer } from '../ModifyUserTeamsDrawer';
import { userRoleMessages } from 'modules/users/messages';

type TeamInfo = Pick<Team, 'id' | 'name' | 'description'>;

interface OwnProps {
  user: Pick<ApplicationUser, 'id' | 'displayName' | 'firstName' | 'lastName' | 'email' | 'status' | 'roles'> & { teams: TeamInfo[]; };
}

export const UserDetails: React.FC<OwnProps> = (props) => {
  const { formatMessage } = useIntl();
  const { user } = props;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { notifySuccess } = useNotification();

  const me = useGetCurrentUserQuery();
  const [isEditingRole, setIsEditingRole] = useState(false);
  const { isMobile } = useResponsive();

  const currentRole = user?.roles.at(0)?.name as BuiltInRoles | undefined;
  const loggedInUserRole = me?.data?.me?.roles.at(0)?.name as BuiltInRoles | undefined;

  const canUpdateRole = loggedInUserRole === BuiltInRoles.GlobalAdministrator || currentRole === BuiltInRoles.TenantAdministrator;

  const [editDrawerOpen, setEditDrawerOpen] = useState(false);

  const deleteUserMutation = useDeactivateUserMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'User deleted successfully' }));
      queryClient.invalidateQueries(['findUsers']);
      queryClient.invalidateQueries(['findTeams']);
      queryClient.invalidateQueries(['getTeamById']);
      navigate(`/${getTenantIdentifier()}/users`);
    }
  });

  const [modifyUserTeamsDrawerOpen, setModifyUserTeamsDrawerOpen] = useState(false);

  return (
    <Stack spacing={3}>
      <Stack direction='row' spacing={{ xs: 2, md: 3 }} alignItems='center'>
        <UserAvatar displayName={user.displayName} size={isMobile ? 'large' : 'xlarge'} textVariant='h3' />
        <Typography variant='h4' fontWeight={500}>{user.displayName}</Typography>
      </Stack>
      <Divider />
      <DisplayInformation title={formatMessage({ id: 'Information' })}
        onEdit={() => setEditDrawerOpen(true)}
      >
        <DisplayInformationRow label={formatMessage({ id: 'Name' })}>
          {user.displayName}
        </DisplayInformationRow>
        <DisplayInformationRow label={formatMessage({ id: 'Email' })}>
          {user.email}
        </DisplayInformationRow>
      </DisplayInformation>
      <DisplayInformation title={formatMessage({ id: 'Role' })} onEdit={() => setIsEditingRole(true)}>
        <DisplayInformationRow>
          <Stack>
            <Typography variant='subtitle1' fontWeight={700}>
              {currentRole == null ? formatMessage({ id: 'No role' }) : formatMessage(userRoleMessages[currentRole])}
            </Typography>
            {currentRole == null &&
              (<Typography variant='body2'>{formatMessage({ id: 'User does not have access to the application' })}</Typography>)
            }
          </Stack>
        </DisplayInformationRow>
      </DisplayInformation>

      {props.user.id && (<UpdateUserRoleDrawer userId={props.user.id} open={isEditingRole} onClose={() => setIsEditingRole(false)} />)}

      <DisplayInformation title={formatMessage({ id: 'Teams' })} onEdit={canUpdateRole ? () => setModifyUserTeamsDrawerOpen(true) : undefined}>
        {user.teams?.map((team) => (
          <DisplayInformationRow key={team.id}>
            <Stack>
              <Typography variant='subtitle1' fontWeight={700}>{team.name}</Typography>
              <Typography variant='body2'>{team.description}</Typography>
            </Stack>
          </DisplayInformationRow>
        ))}
      </DisplayInformation>

      <ModifyUserTeamsDrawer
        user={user}
        open={modifyUserTeamsDrawerOpen}
        onClose={() => setModifyUserTeamsDrawerOpen(false)}
      />

      <UserDetailsDrawer
        user={user}
        open={editDrawerOpen}
        onClose={() => setEditDrawerOpen(false)}
      />

      <DangerZone
        rows={[{
          primary: formatMessage({ id: 'Delete user' }),
          secondary: formatMessage({ id: 'The activities filled by the user will not be deleted when deleting the user.' }),
          button: (
            <ConfirmButton
              confirmTitle={formatMessage({ id: 'Delete user' })}
              confirmText={formatMessage({ id: 'The user will lose access to this application.' })}
              onConfirm={() => deleteUserMutation.mutate({ input: { id: user.id } })}
            />
          )
        }]}
      />
    </Stack>
  );
};