import React, { ReactNode } from 'react';
import { Box, Stack, SvgIcon, Typography } from '@mui/material';

interface OwnProps {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  icon?: ReactNode;
  actionButton?: ReactNode;
}

export type PageTitleProps =
  & OwnProps;

export const PageTitle: React.FC<PageTitleProps> = (props) => {
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='space-between' alignItems={{ xs: 'flex-start', md: 'center' }} width='100%' mt={{ xs: 1.5, lg: 2 }} mb={{ xs: 2, md: 3 }} gap={2}>
      <Stack direction="row" alignItems='center' gap={2}>
        {props.icon && (
          <SvgIcon fontSize="large" color="primary">
            {props.icon}
          </SvgIcon>
        )}

        <Box>
          {typeof props.title === 'string' ? (
            <Typography variant="h4">{props.title}</Typography>
          ) : props.title}

          {props.subtitle && (
            typeof props.subtitle === 'string' ? (
              <Typography variant="subtitle2" lineHeight="14px">
                {props.subtitle}
              </Typography>
            ) : props.subtitle
          )}
        </Box>
      </Stack>

      {props.actionButton && (
        <Stack direction='row' spacing={2} style={{ minHeight: 'min-content' }}>
          {props.actionButton}
        </Stack>
      )}
    </Stack>
  );
};