import React, { ReactNode, useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { SplitButton, SplitButtonOption } from 'components';
import { useIntl } from 'react-intl';
import { GenerateReportButton, GenerateReportOption } from './GenenateReportButton';

export enum ButtonType {
  save,
  saveAs,
  load,
  generatePDF,
  generateExcel,
}

export type ActionButtonOption =
  SplitButtonOption
  & {
    loading?: boolean,
    icon: ReactNode
  }


interface OwnProps {
  isReportModel: boolean
  actionsOptions: Record<ButtonType, ActionButtonOption>
}

export const ReportActionsButton: React.FC<OwnProps> = ({ isReportModel, actionsOptions }) => {
  const { formatMessage } = useIntl();

  const splitButtonOptions = useMemo(() => {
    const splitButtonOptions: SplitButtonOption[] = [];

    splitButtonOptions.push({
      label: actionsOptions[ButtonType.save].label,
      onClick: actionsOptions[ButtonType.save].onClick,
      disabled: actionsOptions[ButtonType.save].disabled
    });

    if (isReportModel) {
      splitButtonOptions.push({
        label: actionsOptions[ButtonType.saveAs].label,
        onClick: actionsOptions[ButtonType.saveAs].onClick
      });
    }

    return splitButtonOptions;
  }, [isReportModel, actionsOptions]);

  const genareteReportButtonOptions = useMemo(() => {
    const genareteReportButtonOptions: GenerateReportOption[] = [];

    genareteReportButtonOptions.push({
      label: formatMessage({ id: 'As PDF file' }),
      onClick: actionsOptions[ButtonType.generatePDF].onClick
    });

    genareteReportButtonOptions.push({
      label: formatMessage({ id: 'As Excel file' }),
      onClick: actionsOptions[ButtonType.generateExcel].onClick
    });

    return genareteReportButtonOptions;
  }, [actionsOptions]);

  return (
    <Stack spacing={2} display={{ xs: 'none', xl: 'flex' }}>
      <Stack direction='row' spacing={1}>
        <SplitButton
          fullWidth
          variant='outlined'
          options={splitButtonOptions}
          loading={actionsOptions[ButtonType.save].loading || actionsOptions[ButtonType.saveAs].loading}
        />
        <Button fullWidth variant='outlined' onClick={actionsOptions[ButtonType.load].onClick}>
          {actionsOptions[ButtonType.load].label}
        </Button>
      </Stack>
      <GenerateReportButton
        options={genareteReportButtonOptions}
        loading={actionsOptions[ButtonType.generatePDF].loading || actionsOptions[ButtonType.generateExcel].loading}
      />
    </Stack>
  );
};