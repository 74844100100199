import { GridFilterInputDate, GridFilterInputValue, GridFilterItem, GridFilterOperator, GridValidRowModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { CustomFieldDto, CustomFieldType, CustomFieldValueFilterInput, EntityType, ListFilterInputTypeOfCustomFieldValueFilterInput, SystemFieldId } from '../../gql';
import { useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';

const customFieldTypeToColumnType = {
  [CustomFieldType.Date]: 'date',
  [CustomFieldType.Numerical]: 'number',
  [CustomFieldType.String]: 'string',
  [CustomFieldType.Lookup]: 'string',
  [CustomFieldType.Selection]: 'string'
};

const columnMinWidth = {
  [CustomFieldType.Selection]: 300,
  [CustomFieldType.Date]: 150,
  [CustomFieldType.Lookup]: 150,
  [CustomFieldType.Numerical]: 150,
  [CustomFieldType.String]: 150
};

export const useCustomFieldsSearchUtils = () => {
  const { formatMessage } = useIntl();

  const mapItemFilterToCustomFieldValueFilterInput = useCallback((item: GridFilterItem, customFields?: CustomField[]): CustomFieldValueFilterInput => {
    if (!customFields) return {};
    const fieldId = Number(item.field.split(';')[1]);
    const field = customFields?.find(p => p.id === fieldId);

    if (field?.systemFieldId === SystemFieldId.Birthday) {
      if (item.operator === 'eq') {
        return {
          and: [
            { dateValue: { lte: dayjs.utc().subtract(parseInt(item.value), 'years') } },
            { dateValue: { gt: dayjs.utc().subtract(parseInt(item.value) + 1, 'years') } }
          ]
        };
      }
      else if (item.operator === 'gt') {
        return { dateValue: { lt: dayjs.utc().subtract(item.value, 'years') } };
      }
      else if (item.operator === 'lt') {
        return { dateValue: { gt: dayjs.utc().subtract(item.value, 'years') } };
      }
    }

    switch (field?.customFieldType) {
      case CustomFieldType.Lookup: {
        return {
          or: [
            { lookupUserValues: { some: { or: [{ firstName: { [item.operator]: item.value } }, { lastName: { [item.operator]: item.value } }] } } },
            { lookupTeamValue: { name: { [item.operator]: item.value } } }
          ]
        };
      }
      case CustomFieldType.Selection: {
        return { selectionValues: { some: { i18n: { some: { name: { [item.operator]: item.value } } } } } };
      }
      case CustomFieldType.Date: {
        return { dateValue: { [item.operator]: dayjs(item.value).toDate() } };
      }
      case CustomFieldType.Numerical: {
        return { numericalValue: { [item.operator]: parseInt(item.value) } };
      }
      default:
        return { stringValue: { [item.operator]: item.value } };
    }
  }, []);

  type CustomField = Pick<CustomFieldDto, 'id' | 'customFieldType' | 'systemFieldId'>;

  const mapGridFilterToCustomValuesFilter = useCallback((filters: GridFilterItem[], customFields: CustomField[]): ListFilterInputTypeOfCustomFieldValueFilterInput => {
    return {
      some: {
        and:
          filters.map<CustomFieldValueFilterInput>(item => (
            {
              and: [
                { fieldId: { eq: Number(item.field.split(';').at(1)) } },
                mapItemFilterToCustomFieldValueFilterInput(item, customFields)
              ]
            }
          ))
      }
    };
  }, [mapItemFilterToCustomFieldValueFilterInput]);

  const numericalOperators = useMemo(() => [
    { label: formatMessage({ id: 'Equals' }), value: 'eq', InputComponent: GridFilterInputValue, InputComponentProps: { type: 'number' }, getApplyFilterFn: () => () => true },
    { label: formatMessage({ id: 'Greater than' }), value: 'gt', InputComponent: GridFilterInputDate, InputComponentProps: { type: 'number' }, getApplyFilterFn: () => () => true },
    { label: formatMessage({ id: 'Lesser than' }), value: 'lt', InputComponent: GridFilterInputDate, InputComponentProps: { type: 'number' }, getApplyFilterFn: () => () => true }
  ], [formatMessage]);

  const getOperatorsByCustomField = useCallback(<T extends GridValidRowModel,>(customField: Pick<CustomFieldDto, 'customFieldType' | 'systemFieldId'>): GridFilterOperator<T, any, any>[] => {
    if (customField.systemFieldId == SystemFieldId.Birthday) {
      return numericalOperators;
    }
    switch (customField.customFieldType) {
      case CustomFieldType.Date:
        return [
          { label: formatMessage({ id: 'Equals' }), value: 'eq', InputComponent: GridFilterInputDate, InputComponentProps: { type: 'date' }, getApplyFilterFn: () => () => true },
          { label: formatMessage({ id: 'Greater than' }), value: 'gt', InputComponent: GridFilterInputDate, InputComponentProps: { type: 'date' }, getApplyFilterFn: () => () => true },
          { label: formatMessage({ id: 'Lesser than' }), value: 'lt', InputComponent: GridFilterInputDate, InputComponentProps: { type: 'date' }, getApplyFilterFn: () => () => true }
        ];
      case CustomFieldType.Numerical:
        return numericalOperators;
      default:
        return [
          { label: formatMessage({ id: 'Starts with' }), value: 'startsWith', InputComponent: GridFilterInputValue, getApplyFilterFn: () => () => true },
        ];
    }
  }, [formatMessage, numericalOperators]);

  const getHeaderNamePrefix = useMemo(() => ({
    [EntityType.Reality]: `(${formatMessage({ id: 'Realities' })}) `,
    [EntityType.Individual]: `(${formatMessage({ id: 'Known individuals' })}) `,
    [EntityType.UnknownGroup]: `(${formatMessage({ id: 'Unknown individuals' })}) `,
    [EntityType.Distribution]: '',
    [EntityType.Intervention]: '',
    [EntityType.Meeting]: '',
    [EntityType.Presentation]: '',
    [EntityType.Routine]: ''
  }), [formatMessage]);

  return { mapGridFilterToCustomValuesFilter, getOperatorsByCustomField, customFieldTypeToColumnType, columnMinWidth, getHeaderNamePrefix };
};