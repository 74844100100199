import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useIntl } from 'react-intl';
import { getTenantIdentifier } from 'utils/tenantUtils';

export const SignOutButton: React.FC<ButtonProps> = (props) => {
  const { formatMessage } = useIntl();
  const tenantId = getTenantIdentifier();

  return (
    <form action={`/${tenantId}/api/Account/SignOut`} method='POST'>
      <Button
        variant="outlined"
        color="primary"
        disabled={false}
        startIcon={<ExitToAppIcon />}
        type='submit'
        {...props}
      >
        {formatMessage({ id: 'Sign out' })}
      </Button>
    </form>
  );
};


