import React from 'react';
import { RouteObject } from 'react-router-dom';
import { ActivitiesPage } from './views/ActivitiesPage';
import { ActivityPage } from './views/ActivityPage';

export const routes: RouteObject[] = [
  { path: 'activities/:activityId', element: <ActivityPage /> },
  { path: 'activities', element: <ActivitiesPage /> }
];

export default routes;