import { Button, Dialog, DialogTitle, Divider, Paper, Stack, Typography } from '@mui/material';
import { FindIndividualsQuery, useFindCustomFieldsQuery } from 'gql';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  individual: NonNullable<NonNullable<FindIndividualsQuery['searchIndividuals']>['items']>[0];
  onMergeClicked: () => void;
  disabled?: boolean;
}

export const IndividualMergeCard: React.FC<Props> = ({ individual, onMergeClicked, disabled }) => {
  const { formatMessage } = useIntl();

  const customFieldIds = individual.customFieldValues.map(cfv => cfv.fieldId);

  const { data: fieldsData } = useFindCustomFieldsQuery({
    filter: { id: { in: customFieldIds } }
  });
  const fields = fieldsData?.customFields ?? [];

  return (
    <Paper style={{ width: '100%' }}>
      <Typography p={2} variant='h6'>{individual.nickName}</Typography>
      <Divider />

      {fields.map(field => {
        const value = individual.customFieldValues.find(cfv => cfv.fieldId === field.id)?.displayString;

        return (
          <div key={field.id}>
            <Stack justifyContent='space-between' direction='row' p={2}>
              <Typography fontWeight={500}>{field.name}</Typography>
              <Typography>{value}</Typography>
            </Stack>

            <Divider />
          </div>
        );
      })}

      <Stack justifyContent='right' direction='row' p={1} width='100%'>
        <Button variant='contained' sx={{ width: 'fit-content' }} onClick={onMergeClicked} disabled={disabled}>
          {formatMessage({ id: 'Keep {nickname}' }, { nickname: individual.nickName })}
        </Button>
      </Stack>
    </Paper>
  );
};