import { Download, SaveAsOutlined, SaveOutlined, UploadFile } from '@mui/icons-material';
import { Backdrop, CircularProgress, Divider, Grid, SpeedDial, SpeedDialAction, SpeedDialIcon, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorTab, ErrorTabs } from 'components';
import dayjs from 'dayjs';
import { CustomFieldFilterInput, Report, ReportGenderBreakdown, ReportParametersInput, SystemFieldId, useAddReportModelMutation, useEditReportModelMutation, useFindCustomFieldsQuery, useGetSettingsQuery } from 'gql';
import { useActivitySettings } from 'hooks/useActivitySettings';
import { ActivityStep, activityTypeAvailableSteps, activityTypeToEntityType } from 'modules/activities/types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNotification } from '../../../hooks/useNotification';
import { ReportDetailsDrawer, ReportDetailsFormValues } from '../components/ModelDrawers/ReportDetailsDrawer';
import { ReportLoadDrawer } from '../components/ModelDrawers/ReportLoadDrawer';
import { ActionButtonOption, ButtonType, ReportActionsButton } from '../components/ReportActionsButton';
import { ReportCountPaper } from '../components/ReportCountPaper';
import { ReportPageTitle } from '../components/ReportPageTitle';
import { ReportDetailsSection } from '../components/Sections/DetailsSection';
import { ReportDistributionSection } from '../components/Sections/DistributionSection';
import { ReportFieldsSection } from '../components/Sections/FieldsSection';
import { ReportIndividualsSection } from '../components/Sections/IndividualsSection';
import { ReportRealitiesSection } from '../components/Sections/RealitiesSection';
import { ReportUnknownGroupSection } from '../components/Sections/UnknownGroupSection';
import { ReportAgeGroupSelector } from '../components/Settings/ReportAgeGroupSelector';
import { ReportParameters } from '../components/Settings/ReportParameters';
import { getStartOfCurrentFiscalYear } from '../utils';
const defaultValues: Partial<ReportParametersInput> = {
  filters: {
    period: {},
    activityTypes: [],

    customFieldFilters: [],
    individualsFieldFilters: [],
    unknownGroupFieldFilters: [],

    themeIds: [],
    specificationIds: [],
    realitiesFieldFilters: [],

    distributedAmounts: {},
    itemIds: [],
    categoryIds: [],
  },
  ageGroupBoundaries: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  includeUnknownIndividuals: false,
  genderBreakdown: ReportGenderBreakdown.None
};

enum ReportFileType {
  pdf,
  excel
}

export const ReportsPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { notifyFailure, notifySuccess } = useNotification();
  const [isOpenSpeedDial, setOpenSpeedDial] = useState(false);
  const { activitiesEnabled } = useActivitySettings();
  const queryClient = useQueryClient();

  const form = useForm<ReportParametersInput>({ defaultValues });
  const { control, handleSubmit, formState: { errors, isDirty }, reset, getValues, setValue } = form;

  const [isLoadDrawerOpen, setLoadDrawerOpen] = useState(false);

  const reportObjectUrl = useRef<string>();
  useEffect(() => {
    return () => {
      if (reportObjectUrl.current) {
        URL.revokeObjectURL(reportObjectUrl.current);
      }
    };
  }, []);

  const { data: settingsData } = useGetSettingsQuery();
  const { isKnownIndividualsEnabled } = settingsData?.settings.at(0) ?? {};
  const { fiscalYearStart } = settingsData?.settings.at(0) ?? {};

  useEffect(() => {
    setValue('includeUnknownIndividuals', isKnownIndividualsEnabled ?? false);
  }, [isKnownIndividualsEnabled, setValue]);

  useEffect(() => {
    const currentFiscalYearStart = getStartOfCurrentFiscalYear(fiscalYearStart);

    setValue('filters.period', {
      from: currentFiscalYearStart.format('YYYY-MM-DD'),
      to: currentFiscalYearStart.add(1, 'year').subtract(1, 'day').utc().format('YYYY-MM-DD')
    });
  }, [fiscalYearStart, setValue]);

  const selectedActivityTypes = useWatch({ control, name: 'filters.activityTypes' });
  const activityTypes = useMemo(() =>
    selectedActivityTypes.length > 0
      ? selectedActivityTypes
      : Object.values(activitiesEnabled), [activitiesEnabled, selectedActivityTypes]);

  const customFieldsFilter: CustomFieldFilterInput = useMemo(() => {
    const entityTypes = activityTypes.map(a => activityTypeToEntityType[a]);

    return {
      entityTypes: {
        some: {
          entityType: {
            in: entityTypes
          }
        }
      },
      systemFieldId: {
        neq: SystemFieldId.InterventionDate
      }
    };
  }, [activityTypes]);

  const availableSteps = useMemo(() => {
    const set: Set<ActivityStep> = new Set();
    for (const activityType of activityTypes) {
      for (const step of activityTypeAvailableSteps[activityType]) {
        set.add(step);
      }
    }
    return Array.from(set);
  }, [activityTypes]);

  const { data: customFieldsData, isFetching: isFieldsFetching } = useFindCustomFieldsQuery({ filter: customFieldsFilter });
  const customFields = useMemo(() => customFieldsData?.customFields ?? [], [customFieldsData?.customFields]);

  const [reportLoading, setReportLoading] = useState(false);

  const onSubmitPDF = async (values: ReportParametersInput) => {
    setReportLoading(true);

    const response = await fetch('api/Reporting/Statistics', {
      method: 'POST',
      headers: {
        'Accept': 'Application/pdf',
        'Content-Type': 'application/json',
        'Accept-Language': dayjs.locale()
      },
      body: JSON.stringify({
        reportType: ReportFileType.pdf,
        title: reportModelTitle,
        ...values
      }),
    });

    await handleReportExportResponse(response);

    setReportLoading(false);
  };

  const onSubmitExcel = async (values: ReportParametersInput) => {
    setReportLoading(true);

    const response = await fetch('api/Reporting/Statistics', {
      method: 'POST',
      headers: {
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Content-Type': 'application/json',
        'Accept-Language': dayjs.locale()
      },
      body: JSON.stringify({
        reportType: ReportFileType.excel,
        title: reportModelTitle,
        ...values
      }),
    });

    await handleReportExportResponse(response);

    setReportLoading(false);
  };

  const handleReportExportResponse = async (response: Response) => {
    if (!response.ok) {
      notifyFailure(formatMessage({ id: 'Failed to generate report' }));
      setReportLoading(false);
      return;
    }

    const blob = await response.blob();
    const file = window.URL.createObjectURL(blob);

    if (reportObjectUrl.current != null) {
      URL.revokeObjectURL(reportObjectUrl.current);
    }

    reportObjectUrl.current = file;
    window.open(file);
  };

  const [currentTab, setCurrentTab] = useState<'details' | 'settings'>('details');

  const [reportModelId, setReportModelId] = useState<number>();
  const [reportModelTitle, setReportModelTitle] = useState<string>();

  const handleOnLoadModel = (model?: Report) => {
    if (model) {
      reset({
        filters: {
          period: model.reportValues.filters.period,
          activityTypes: model.reportValues.filters.activityTypes,

          customFieldFilters: model.reportValues.filters.customFieldFilters,
          individualsFieldFilters: model.reportValues.filters.individualsFieldFilters,
          unknownGroupFieldFilters: model.reportValues.filters.unknownGroupFieldFilters,

          themeIds: model.reportValues.filters.themeIds,
          specificationIds: model.reportValues.filters.specificationIds,
          realitiesFieldFilters: model.reportValues.filters.realitiesFieldFilters,

          distributedAmounts: model.reportValues.filters.distributedAmounts,
          itemIds: model.reportValues.filters.itemIds,
          categoryIds: model.reportValues.filters.categoryIds,
        },
        ageGroupBoundaries: model.reportValues.ageGroupBoundaries,
        genderBreakdown: model.reportValues.genderBreakdown,
        includeUnknownIndividuals: model.reportValues.includeUnknownIndividuals
      });

      setReportModelId(model.id);
      setReportModelTitle(model.name);
    } else {
      reset(defaultValues);
      setReportModelId(undefined);
      setReportModelTitle(undefined);
    }
  };

  const shouldHide = (name: typeof currentTab) => currentTab === name ? 'flex' : 'none';

  const tabHasError = (name: typeof currentTab) => {
    if (name === 'details') {
      return Boolean(errors.filters);
    } else if (name === 'settings') {
      return Boolean(errors.ageGroupBoundaries);
    }
  };

  const formDisabled = reportLoading;

  const [isSaveAsCopy, setSaveAsCopy] = useState(false);
  const [isSavingNewReport, setIsSavingNewReport] = useState(false);

  const closeDetailsDrawer = () => {
    setSaveAsCopy(false);
    setIsSavingNewReport(false);
  };

  const addReportModel = useAddReportModelMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries(['getModels']);
      if (!data.addReportModel.report) return;
      handleOnLoadModel(data.addReportModel.report);
      notifySuccess(formatMessage({ id: 'Report model added successfully' }));
    }
  });

  const editReportModel = useEditReportModelMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['getModels']);
      notifySuccess(formatMessage({ id: 'Report model saved' }));
    }
  });

  const onSaveClicked = useCallback(() => {
    if (reportModelId) {
      editReportModel.mutate({
        input: {
          id: reportModelId,
          name: reportModelTitle ?? '',
          filterValues: getValues()
        }
      });
    } else {
      setIsSavingNewReport(true);
    }
  }, [editReportModel, getValues, reportModelId, reportModelTitle]);

  const onDetailsDrawerSubmit = useCallback((values: ReportDetailsFormValues) => {
    addReportModel.mutate({
      input: {
        name: values.name,
        filterValues: getValues()
      }
    });

    closeDetailsDrawer();
  }, [addReportModel, getValues]);

  const buttonActionsOptions = useMemo(() => {
    const buttonActionsOptions: Record<ButtonType, ActionButtonOption> = {
      [ButtonType.save]: {
        label: formatMessage({ id: 'Save' }),
        onClick: onSaveClicked,
        disabled: !(isDirty || reportModelId),
        icon: <SaveOutlined />,
        loading: addReportModel.isLoading
      },
      [ButtonType.saveAs]: {
        label: formatMessage({ id: 'Save as...' }),
        onClick: () => setSaveAsCopy(true),
        icon: <SaveAsOutlined />,
        loading: editReportModel.isLoading
      },
      [ButtonType.load]: {
        label: formatMessage({ id: 'Load' }),
        onClick: () => setLoadDrawerOpen(true),
        icon: <UploadFile />
      },
      [ButtonType.generatePDF]: {
        label: formatMessage({ id: 'Generate PDF report' }),
        onClick: handleSubmit(onSubmitPDF),
        loading: reportLoading,
        icon: <Download />
      },
      [ButtonType.generateExcel]: {
        label: formatMessage({ id: 'Generate Excel report' }),
        onClick: handleSubmit(onSubmitExcel),
        loading: reportLoading,
        icon: <Download />
      }
    };

    return buttonActionsOptions;
  }, [reportModelId, onSaveClicked, isDirty, reportLoading]);

  const handleDialOptionClick = (type: ButtonType) => {
    buttonActionsOptions[type].onClick();
    setOpenSpeedDial(false);
  };

  return <>
    <ReportPageTitle
      title={reportModelTitle}
      onChange={setReportModelTitle}
    />

    <FormProvider {...form} >
      <Grid container spacing={2} direction={{ xs: 'column-reverse', xl: 'row' }}
        sx={{
          '& .MuiGrid-item': {
            maxWidth: '-webkit-fill-available'
          }
        }}
      >
        <Grid item xs={12} xl={9} paddingTop={0}>
          <ErrorTabs
            value={currentTab}
            onChange={(_, tab) => setCurrentTab(tab)}
            error={tabHasError(currentTab)}
          >
            <ErrorTab
              label={formatMessage({ id: 'Details' })}
              value='details'
              error={tabHasError('details')}
            />
            <ErrorTab
              label={formatMessage({ id: 'Settings' })}
              value='settings'
              error={tabHasError('settings')}
            />
          </ErrorTabs>

          <Divider sx={{ mb: 2 }} />

          <Stack display={shouldHide('details')} spacing={2}>
            <ReportDetailsSection disabled={formDisabled} />

            <ReportFieldsSection loading={isFieldsFetching} fields={customFields} disabled={formDisabled} />

            {availableSteps.includes('individuals') && <>
              <ReportIndividualsSection disabled={formDisabled} />

              <ReportUnknownGroupSection disabled={formDisabled} />
            </>}

            {availableSteps.includes('distributions') && (
              <ReportDistributionSection disabled={formDisabled} />
            )}

            {availableSteps.includes('realities') && (
              <ReportRealitiesSection disabled={formDisabled} />
            )}
          </Stack>

          <Stack display={shouldHide('settings')} spacing={2}>
            <ReportParameters disabled={formDisabled} />

            <ReportAgeGroupSelector disabled={formDisabled} />
          </Stack>
        </Grid>

        <Grid item xs={12} xl={3} position='relative'>
          <Stack spacing={2} position='sticky' top='84px' mt={{ xs: 2, xl: '48px' }}>
            <ReportCountPaper />
            <ReportActionsButton
              isReportModel={Boolean(reportModelId)}
              actionsOptions={buttonActionsOptions}
            />
          </Stack>
        </Grid >
      </Grid >
    </FormProvider >

    <Backdrop open={isOpenSpeedDial || reportLoading}
      sx={{
        zIndex: (theme) => reportLoading ? theme.zIndex.speedDial + 1 : theme.zIndex.speedDial,
        display: { xs: 'flex', xl: 'none' }
      }}
    >
      {reportLoading &&
        <CircularProgress />
      }
    </Backdrop>

    <SpeedDial
      ariaLabel={formatMessage({ id: 'Report actions' })}
      icon={<SpeedDialIcon />}
      onClose={() => setOpenSpeedDial(false)}
      onOpen={(e, reson) => reson === 'toggle' ? setOpenSpeedDial(true) : ''}
      open={isOpenSpeedDial}
      sx={{ position: 'fixed', bottom: 16, right: 16, display: { xs: 'flex', xl: 'none' } }}
    >
      {Object.values(buttonActionsOptions).map((action, index) => (
        <SpeedDialAction
          key={index}
          icon={action.icon}
          tooltipTitle={action.label}
          tooltipOpen
          onClick={() => handleDialOptionClick(index as ButtonType)}
          sx={{
            display: index === ButtonType.saveAs && !reportModelId ? 'none' : 'flex',
            '& .MuiSpeedDialAction-staticTooltipLabel': {
              minWidth: 'max-content'
            }
          }}
        />
      ))}
    </SpeedDial>

    <ReportDetailsDrawer
      open={isSaveAsCopy || isSavingNewReport}
      onClose={closeDetailsDrawer}
      model={reportModelTitle}
      isSaveAsCopy={isSaveAsCopy}
      isSaveAsNew={isSavingNewReport}
      onSubmit={onDetailsDrawerSubmit}
    />

    <ReportLoadDrawer
      isOpen={isLoadDrawerOpen}
      setOpen={setLoadDrawerOpen}
      loadReportModel={handleOnLoadModel}
    />
  </>;
};