import React from 'react';
import { List } from '@mui/material';
import { ThemeSelectionButton } from './ThemeSelectionButton';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { RealityInput, useRealitiesDataQuery } from '../../../../../../../gql';

export const ThemeStep = (props: { stepIndex: number, activeStep: number, onStepComplete: () => void; }) => {
  const form = useFormContext<RealityInput>();
  const realitiesQuery = useRealitiesDataQuery();

  const onThemeChanged = (themeField: ControllerRenderProps<RealityInput, 'themeId'>, themeId: number) => {
    themeField.onChange(themeId);
    form.resetField('specificationId', { defaultValue: undefined, });
    props.onStepComplete();
  };

  return (
    <Controller
      name="themeId"
      control={form.control}
      render={({ field }) => (
        <List>
          {realitiesQuery.data?.themes.map(theme => (
            <ThemeSelectionButton key={theme.id}
              selected={field.value === theme.id}
              onClick={() => onThemeChanged(field, theme.id)}
              theme={theme} />))}
        </List>
      )} />);
};