import React, { useMemo } from 'react';
import { Tab, Tabs, TabsProps } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

interface Tab {
  navigationString: string;
  displayString: string;
}

interface Props extends TabsProps {
  tabs: Tab[];
  thin?: boolean;
  defaultNavigation?: string;
  navigationStyle?: 'queryParam' | 'path';
  queryParam?: string;
}

export const RoutedTabs: React.FC<Props> = ({ navigationStyle, queryParam, defaultNavigation, thin, tabs, ...tabsProps }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearch] = useSearchParams();

  if (navigationStyle === undefined) {
    navigationStyle = 'path';
  }

  if (navigationStyle === 'queryParam' && queryParam === undefined) {
    throw new Error('queryString must be defined when using queryParam navigation');
  }

  const onTabChange = (_: unknown, selectedTab: string) => {
    if (navigationStyle === 'queryParam' && queryParam !== undefined) {
      if (selectedTab === defaultNavigation) {
        searchParams.delete(queryParam);
      } else {
        searchParams.set(queryParam, selectedTab);
      }
      setSearch(searchParams, { replace: true });
    } else if (navigationStyle === 'path') {
      navigate(selectedTab);
    }
  };

  const selectedTab = useMemo(() => {
    let selectedFilter: string | undefined;
    if (navigationStyle === 'queryParam' && queryParam !== undefined) {
      const searchParams = new URLSearchParams(location.search);
      selectedFilter = searchParams.get(queryParam) || undefined;
    } else if (navigationStyle === 'path') {
      selectedFilter = location.pathname.split('/').pop();
    }
    return selectedFilter ?? defaultNavigation;
  }, [defaultNavigation, location.pathname, location.search, navigationStyle, queryParam]);

  const thinHeight = '36px';

  return (
    <Tabs
      {...tabsProps}
      value={selectedTab}
      onChange={onTabChange}
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        minHeight: thin ? thinHeight : undefined,
        width: '100%',
        ...tabsProps.sx
      }}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.navigationString}
          label={tab.displayString}
          value={tab.navigationString}
          sx={{ minHeight: thin ? thinHeight : undefined }}
        />
      ))}
    </Tabs>
  );
};