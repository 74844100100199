import { Divider } from '@mui/material';
import { ResponsiveDrawer } from 'components';
import React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { isSettingsMenuOpenState } from '../../../atoms';
import { LanguageSettingsSection } from './LanguageSettingsSection';
import { ThemeSettingsSection } from './ThemeSettingsSection';
import { UserSettingsSection } from './UserSettingsSection';

export const SettingsDrawer: React.FC = () => {
  const { formatMessage } = useIntl();
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useRecoilState(isSettingsMenuOpenState);
  const closeMenu = () => setIsSettingsMenuOpen(false);

  return (
    <ResponsiveDrawer
      open={isSettingsMenuOpen}
      onClose={closeMenu}
      header={formatMessage({ id: 'User settings' })}
      disablePadding
    >
      <UserSettingsSection />
      <Divider />

      <LanguageSettingsSection />
      <ThemeSettingsSection />
    </ResponsiveDrawer>
  );
};