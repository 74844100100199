import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import IMask from 'imask';

export const PhoneNumberTextField:React.FC<TextFieldProps> = (props) => {
  const phoneMask = IMask.createMask({ mask: '(000) 000-0000' });

  return (
    <TextField
      {...props}
      onChange={(e) => {
        e.target.value = phoneMask.resolve(e.target.value);

        if (props.onChange) {
          props.onChange(e);
        }
      }}
    />
  );
};