import { ArrowDropDown } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

interface OwnProps {
  options: GenerateReportOption[]
  loading?: boolean
}

export type GenerateReportOption = {
  label: string,
  onClick: () => void,
}

export const GenerateReportButton: React.FC<OwnProps> = (props) => {
  const { options, loading } = props;
  const { formatMessage } = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <LoadingButton
        loading={loading}
        variant='contained'
        fullWidth
        onClick={handleOpenMenu}
        endIcon={<ArrowDropDown />}
      >
        {formatMessage({ id: 'Generate report' })}
      </LoadingButton>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            disableRipple
            onClick={() => {
              option.onClick();
              handleClose();
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};