import { Box, BoxProps, useTheme } from '@mui/material';
import React from 'react';
import lightLogo from '../../assets/images/logo-rocqtr-light.png';
import darkLogo from '../../assets/images/logo-rocqtr-dark.png';
import { ApplicationName } from './ApplicationName';


interface Props extends BoxProps {
  disableName?: boolean;
  size?: 'small' | 'medium';
  textColor?: string;
  alwaysUseTheme?: 'light' | 'dark';
}

export const ApplicationLogo: React.FC<Props> = ({ disableName, size, textColor, alwaysUseTheme, ...props }) => {
  const { palette: { mode } } = useTheme();
  let isDark = mode === 'dark';

  if (alwaysUseTheme) {
    isDark = alwaysUseTheme === 'dark';
  }

  let height = '24px';
  let fontSize = '12pt';
  let lineHeight = '13px';
  switch (size) {
    case 'small':
      height = '18px';
      fontSize = '8pt';
      lineHeight = '8px';
      break;
    case 'medium':
      height = '24px';
      fontSize = '12pt';
      lineHeight = '13px';
      break;
  }


  return (
    <Box {...props}>
      <img src={isDark ? darkLogo : lightLogo} style={{ height }} />
      {!disableName && (
        <ApplicationName variant="subtitle2" fontSize={fontSize} lineHeight={lineHeight} color={textColor} />
      )}
    </Box >
  );
};