import React, { useMemo, useState } from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import { NewButton, PageTitle, RoutedTabs } from 'components';
import { entityTypePluralMessages } from 'modules/activities/messages';
import { EntityType, useGetIndividualsSettingsQuery } from 'gql';
import { useIntl } from 'react-intl';
import { Box, Tab } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const IndividualsConfigurationPageLayout: React.FC = () => {
  const { formatMessage } = useIntl();
  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);
  const { data: individualsSettingsData } = useGetIndividualsSettingsQuery();

  const tabs = useMemo(() => {
    const newTabs = [];

    if (individualsSettingsData?.settings.at(0)?.isKnownIndividualsEnabled) {
      newTabs.push({
        displayString: formatMessage({ id: 'Known individuals' }),
        navigationString: 'known-individuals'
      });
    }

    newTabs.push({
      displayString: formatMessage({ id: 'Unknown individuals' }),
      navigationString: 'unknown-individuals'
    });

    return newTabs;
  }, [individualsSettingsData?.settings.at(0)?.isKnownIndividualsEnabled]);

  return (
    <>
      <PageTitle
        icon={<ViewListIcon />}
        title={formatMessage({ id: 'Customization' })}
        subtitle={formatMessage({ id: 'Individuals' })}
        actionButton={(
          <NewButton label={formatMessage({ id: 'Add section' })} onClick={() => setIsAddDrawerOpen(true)} />
        )}
      />

      <RoutedTabs
        tabs={tabs}
        defaultNavigation={individualsSettingsData?.settings.at(0)?.isKnownIndividualsEnabled ? 'known-individuals' : 'unknown-individuals'}
        onClick={() => setIsAddDrawerOpen(false)}
        variant='scrollable'
        scrollButtons='auto'
      />

      <Box sx={{ mt: 2 }}>
        <Outlet context={[isAddDrawerOpen, setIsAddDrawerOpen]} />
      </Box>
    </>
  );
};