export const getInitials = (displayName: string): string => {
  const firstLettersOfEachWords = displayName
    .replaceAll(/[^a-zA-ZÀ-ž0-9 ]/g, '')
    .split(' ')
    .map(word => word[0]);

  if (firstLettersOfEachWords.length > 1) {
    return firstLettersOfEachWords[0] + firstLettersOfEachWords.at(-1) || '';
  }

  return firstLettersOfEachWords[0] || '';
};

export const getAvatarColor = (displayName: string): string => {
  const displayNameHash = displayName.split('').reduce(calculateLetterHash, 0);
  const [red, green, blue] = getRgbComponents(displayNameHash).map(getHexCode);

  return `#${red}${green}${blue}`;
};

export const getAvatarTextColor = (displayName: string): string => {
  const displayNameHash = displayName.split('').reduce(calculateLetterHash, 0);
  const [red, green, blue] = getRgbComponents(displayNameHash);

  const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

  if (luminance > 0.5) {
    return '#000000';
  } else {
    return '#FFFFFF';
  }
};

const getRgbComponents = (numericValue: number): [number, number, number] => {
  const red = (numericValue) & 0xFF;
  const green = (numericValue >> 8) & 0xFF;
  const blue = (numericValue >> 16) & 0xFF;

  return [red, green, blue];
};

const calculateLetterHash = (currentHash: number, letter: string) =>
  letter.charCodeAt(0) + ((currentHash << 5) - currentHash);

const getHexCode = (numericValue: number): string =>
  numericValue.toString(16).padStart(2, '0');