import React from 'react';
import { AddReportModelInput, Report } from 'gql';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { Stack } from '@mui/system';

interface OwnProps {
  reportModel?: Report;
  disabled?: boolean;
}
export const ReportModelForm: React.FC<OwnProps> = (props) => {
  const { control } = useFormContext<AddReportModelInput>();
  const { formatMessage } = useIntl();
  return (
    <Stack spacing={3} px={2} py={3}>
      <Controller
        name='name'
        control={control}
        rules={{ required: formatMessage({ id: 'The model name is required' }) }}
        render={({ field, fieldState }) => (
          <TextField
            required
            disabled={props.disabled}
            label={formatMessage({ id: 'Name' })}
            {...field}
            error={fieldState.error?.message != null}
            helperText={fieldState.error?.message}
          />
        )}
      />
    </Stack>
  );
};