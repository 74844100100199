import React from 'react';
import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ErrorBoundaryPage } from '../views';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env['REACT_APP_INSIGHTS_CONNECTION_STRING'],
    enableAutoRouteTracking: true,
    extensions: [reactPlugin]
  }
});

appInsights.loadAppInsights();

export const TelemetryProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  return (
    <AppInsightsErrorBoundary appInsights={reactPlugin} onError={ErrorBoundaryPage} >
      <AppInsightsContext.Provider value={reactPlugin}>
        {props.children}
      </AppInsightsContext.Provider>
    </AppInsightsErrorBoundary>
  );
};