import React from 'react';
import { Stack, StackProps, styled, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

interface OwnProps {
  title: string;
  image?: React.ReactNode;
  message?: string;
  errorCode?: string;
  correlationId?: string;
  timestamp?: string;
}

export type ErrorDetailsProps =
  & OwnProps;

const StyledStack = styled(Stack)<StackProps>(() => ({
  alignItems: 'center',
  '& svg': {
    maxWidth: '100%',
    maxHeight: '400px',
    height: '100%',
    marginBottom: 5
  }
}));

export const ErrorDetails: React.FC<ErrorDetailsProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <StyledStack>
      {props.image}

      <Stack>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>{props.title}</Typography>
        <Typography variant="body1">
          {props.message}
        </Typography>

        {props.errorCode &&
          <Typography sx={{ marginTop: 2 }}>
            {formatMessage({ id: 'Error code: {code}' }, { code: props.errorCode })}
          </Typography>
        }

        {props.correlationId && (
          <Typography variant='subtitle2' color='text.secondary'>
            {formatMessage({ id: 'Correlation ID: {correlationId}' }, { correlationId: props.correlationId })}
          </Typography>
        )}

        {props.timestamp && (
          <Typography variant='subtitle2' color='text.secondary'>
            {formatMessage({ id: 'Timestamp: {timestamp}' }, { timestamp: props.timestamp })}
          </Typography>
        )}
      </Stack>
    </StyledStack>
  );
};