import { ResponsiveSelect } from 'components';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

interface OwnProps {
  value: string;
  onChange: (value: string) => void;
}

export type FiscalYearInputProps =
    & OwnProps;

const nonLeapYear = 2023;
const availableMonths = _.range(0, 12);

export const FiscalYearInput: React.FC<FiscalYearInputProps> = (props) => {
  return (
    <>
      <ResponsiveSelect
        disableFullWidth
        required
        availableValues={availableMonths}
        selectedValues={[dayjs(props.value).month()]}
        getKey={monthIndex => monthIndex}
        getLabel={monthIndex => dayjs().year(nonLeapYear).month(monthIndex).format('MMMM')}
        renderListItem={p => p}
        onChange={(value) => {
          const newDate = dayjs(props.value).month(value[0]);
          props.onChange(newDate.toISOString());
        }}
      />

      <ResponsiveSelect
        disableFullWidth
        required
        availableValues={_.range(1, dayjs(props.value).daysInMonth() + 1)}
        selectedValues={[dayjs(props.value).date()]}
        getKey={p => p}
        getLabel={p => p.toString().padStart(2, '0')}
        renderListItem={p => p}
        onChange={(value) => {
          const newDate = dayjs(props.value).date(value[0]);
          props.onChange(newDate.toISOString());
        }}
      />
    </>
  );
};