import { Button, Container, Stack } from '@mui/material';
import React from 'react';
import { ErrorLayout } from '../layouts/ErrorLayout';
import { ReactComponent as UnexpectedErrorImage } from '../../../assets/images/unexpected-error.svg';
import { ErrorDetails } from '../components/ErrorDetails';
import { useIntl } from 'react-intl';


export const ErrorBoundaryPage: React.FC = () => {
  const { formatMessage } = useIntl();

  // The React history object is not available through an error boundary context. As such, we need to use the
  // window location to perform the navigation.
  const reloadApplication = () => window.location.reload();
  const navigateToHomePage = () => window.location.assign('/');

  return (
    <ErrorLayout>
      <Container maxWidth="sm">
        <ErrorDetails
          image={<UnexpectedErrorImage />}
          title={formatMessage({ id: 'Sorry...' })}
          message={formatMessage({ id: 'An unexpected error has occurred while processing your request. You can either reload the application and try again in a few minutes, or navigate back to the home page.' })}
          errorCode="Error boundary"
        />

        <Stack direction="row" spacing={2} sx={{ marginTop: 3 }}>
          <Button variant="outlined" onClick={reloadApplication}>{formatMessage({ id: 'Reload application' })}</Button>
          <Button variant="contained" onClick={navigateToHomePage}>{formatMessage({ id: 'Home page' })}</Button>
        </Stack>
      </Container>
    </ErrorLayout>
  );
};