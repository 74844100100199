import React, { useMemo, useState } from 'react';
import { GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DataTable, DataTableProps } from 'components';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { UserChipStatus } from 'modules/users/components/UserChipStatus';
import { UserRoleChip } from '../UserRoleChip';
import { ApplicationUser } from '../../../../gql';

type User = Pick<ApplicationUser, 'id' | 'displayName' | 'email' | 'status' | 'roles'>;
export interface UsersTableProps extends Omit<DataTableProps, 'columns' | 'rows' | 'onSearch' | 'noSearchResultsMessage' | 'noDataMessage'> {
  users: User[],
  onViewDetails?: (userId: string) => void;
  disableRolesColumn?: boolean;
  renderRowActions?: (params: GridRenderCellParams<User>) => React.ReactNode;
}

export const UsersTable: React.FC<UsersTableProps> = (props) => {
  const { users, onViewDetails, disableRolesColumn, renderRowActions, ...searchTableProps } = props;
  const { formatMessage } = useIntl();

  const columns: GridColDef<User>[] = useMemo(() => {
    const cols: GridColDef<User>[] = [];
    cols.push({
      field: 'name',
      headerName: formatMessage({ id: 'Name' }),
      flex: 40,
      valueGetter: ({ row }) => row.displayName,
      renderCell: ({ value }) => (
        <Typography color='info.main' fontWeight={500}>{value}</Typography>
      )
    });
    if (!disableRolesColumn) {
      cols.push({
        field: 'role',
        headerName: formatMessage({ id: 'Role' }),
        flex: 30,
        valueGetter: ({ row }) => row.roles.at(0)?.normalizedName,
        renderCell: ({ value }) => value ? (
          <UserRoleChip role={value} />
        ) : null
      });
    }
    cols.push({
      field: 'status',
      headerName: formatMessage({ id: 'Status' }),
      flex: 30,
      valueGetter: ({ row }) => row.status,
      renderCell: ({ value }) => (
        <UserChipStatus size='small' status={value} />
      )
    });
    if (renderRowActions) {
      cols.push({
        field: 'actions',
        headerName: '',
        sortable: false,
        renderCell: renderRowActions
      });
    }
    return cols;
  }, [disableRolesColumn, formatMessage, renderRowActions]);

  const handleOnCellClick = (params: GridCellParams) => {
    if (params.field === 'name' && onViewDetails) {
      onViewDetails(params.id as string);
    }
  };

  const [searchValue, setSearchValue] = useState('');
  const normalizedSearchValue = searchValue.normalize().toLowerCase();
  const searchFilteredUsers = users.filter(user => user.displayName.normalize().toLowerCase().includes(normalizedSearchValue));

  const handleSearch = (searchTerm: string) => {
    setSearchValue(searchTerm);
  };

  return (
    <DataTable
      {...searchTableProps}
      columns={columns}
      rows={searchFilteredUsers}
      onSearch={handleSearch}
      hideFooter
      disableColumnSelector
      onCellClick={handleOnCellClick}
      noDataMessage={formatMessage({ id: 'There are no users yet' })}
      noSearchResultsMessage={formatMessage({ id: 'No users found' })}
      initialState={{
        sorting: {
          sortModel: [{ field: 'name', sort: 'asc' }],
        }
      }}
      sx={{
        '& .MuiDataGrid-cell:first-of-type': {
          cursor: 'pointer'
        },
      }}
    />
  );
};