import dayjs from 'dayjs';
import { getTenantIdentifier } from 'utils/tenantUtils';

export class ApiError extends Error {
  public readonly errorCode: number;

  constructor(errorCode: number, message?: string) {
    super(message);
    this.errorCode = errorCode;
  }
}

export class DomainError extends Error {
  constructor(message?: string) {
    super(message);
  }
}

export const fetchData = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers'],
  signal?: AbortSignal
): (() => Promise<TData>) => {
  const tenantId = getTenantIdentifier();

  return async () => {
    if (!tenantId) {
      return null;
    }

    const response = await fetch(`/${tenantId}/graphql`, {
      signal: signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': dayjs.locale(),
        ...(options ?? {}),
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    if (response.status < 200 || response.status >= 300) {
      throw new ApiError(response.status, response.statusText);
    }

    const result = await response.json();

    if (result.error?.extensions?.code === 'AUTH_NOT_AUTHENTICATED') {
      throw new ApiError(401, result.error.message);
    }

    const resultQueryNames = Object.keys(result.data);
    for (const index in resultQueryNames) {
      if (result.data[resultQueryNames[index]]?.errors) {
        const error = result.data[resultQueryNames[index]].errors[0];
        throw new DomainError(error.message);
      }
    }

    return result.data;
  };
};
