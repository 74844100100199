import { Button, ButtonProps, IconButton } from '@mui/material';
import { useResponsive } from '../../hooks';
import React from 'react';

export const ResponsiveButton = (props: { mobileIcon: React.ReactNode, buttonText: string } & ButtonProps) => {
  const { isMobile } = useResponsive();
  const { mobileIcon, buttonText, onClick, ...buttonProps } = props;

  return isMobile ? (
    <IconButton onClick={onClick}>
      {mobileIcon}
    </IconButton>
  ) : (
    <Button variant='contained' onClick={onClick} {...buttonProps}>
      {buttonText}
    </Button>
  );
};