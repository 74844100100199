import { List, ListItem, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import { ActivityType } from 'gql';
import { useActivitySettings } from 'hooks/useActivitySettings';
import { activityDescriptionMessages, activityTypeMessages } from 'modules/activities/messages';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { ActivityFormValues } from './ActivityDialog';

const ActivityTypeListItem: React.FC<{
  activityType: ActivityType;
  onClick?: () => void;
}> = ({ activityType, onClick }) => {
  const { formatMessage } = useIntl();
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onClick}>
        <ListItemText primary={formatMessage(activityTypeMessages[activityType])} secondary={formatMessage(activityDescriptionMessages[activityType])} />
      </ListItemButton>
    </ListItem>
  );
};

export const ActivityDialogTypeSelector = (props: { onActivityTypeSelected: (activityType: ActivityType) => void }) => {
  const { setValue } = useFormContext<ActivityFormValues>();
  const { formatMessage } = useIntl();
  const { activitiesEnabled } = useActivitySettings();

  const handleChange = (activityType: ActivityType) => () => {
    setValue('activityType', activityType);
    props.onActivityTypeSelected(activityType);
  };

  return (
    <Paper sx={{ pt: 2 }}>
      <Typography variant='h4' sx={{ pl: 2, pr: 2 }}>
        {formatMessage({ id: 'Select the activity type' })}
      </Typography>

      <List disablePadding sx={{ pt: 2 }}>
        {Object.values(activitiesEnabled).sort((a, b) => formatMessage(activityTypeMessages[a]).localeCompare(formatMessage(activityTypeMessages[b]))).map(activityType => (
          <ActivityTypeListItem key={activityType} activityType={activityType} onClick={handleChange(activityType)} />
        ))}
      </List>
    </Paper>
  );
};