import React from 'react';
import { Button, Stack } from '@mui/material';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { AddTenantInput, AddTenantUserInput, BuiltInRoles } from 'gql';
import { useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import { UserInviteForm } from 'modules/users/components/UserInviteForm';

interface IProps {
  changeStep: (newStep: number) => void;
  isDisable: boolean,
  onSubmit: (organizationAdminData: AddTenantInput) => void;
  handleClose: () => void;
}

export const InviteAdminConfigurationStep: React.FC<IProps> = (props) => {
  const { formatMessage } = useIntl();
  const organizationForm = useFormContext<AddTenantInput>();
  const form = useForm<AddTenantUserInput>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      teamIds: []
    }
  });

  const submitAdminInput = (adminInput: AddTenantUserInput) => {
    organizationForm.setValue('adminInput', adminInput);
    props.onSubmit(organizationForm.getValues());
  };

  return (
    <Stack>
      <FormProvider {...form}>
        <UserInviteForm disabled={props.isDisable} isInvitingAdmin allowedRoles={[BuiltInRoles.TenantAdministrator]} />
      </FormProvider>

      <Stack direction='row' justifyContent='flex-end' spacing={2}>
        <Button variant='contained' onClick={() => props.changeStep(0)} color='inherit'>
          {formatMessage({ id: 'Back' })}
        </Button>
        <LoadingButton loading={props.isDisable} variant='contained' type='submit' onClick={form.handleSubmit(submitAdminInput)} disabled={!form.formState.isValid}>
          {formatMessage({ id: 'Save' })}
        </LoadingButton>
      </Stack>
    </Stack >
  );
};