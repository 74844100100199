import React from 'react';
import { Card, CardProps, Stack, styled, Typography } from '@mui/material';
import { calculateActivitiesCountDelta } from 'modules/home/utils';
import { DeltaIcon } from './DeltaIcon';


interface OwnProps {
  title: string
  count: number
  previousCount?: number
}

type CountStatsTileProps =
  & CardProps
  & OwnProps;

export const StyledCard = styled(Card)<CardProps>(({ theme }) => ({
  borderLeftStyle: 'solid',
  borderLeftWidth: '5px',
  borderColor: 'transparent',
  width: theme.spacing(20),
  height: theme.spacing(8)
}));

export const CountStatsTile: React.FC<CountStatsTileProps> = (props) => {
  const { title, count, previousCount, ...cardprops } = props;
  const delta = calculateActivitiesCountDelta(props.count, props.previousCount ?? 0);

  return (
    <StyledCard {...cardprops}>
      <Stack px={2} py={1}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='body1'>{count}</Typography>
          {props.previousCount != undefined &&
            <Typography variant='subtitle2' display='flex' alignItems='center'>
              {`${delta ?? '-'}%`}
              {delta != undefined ? <DeltaIcon currentValue={count} previousValue={previousCount ?? 0} /> : <></>}
            </Typography>
          }
        </Stack>
        <Typography variant='body2'>{title}</Typography>
      </Stack>
    </StyledCard>
  );
};