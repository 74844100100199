import React from 'react';
import { ListItem, ListItemButton, ListItemButtonProps, ListItemText } from '@mui/material';

import { ThemeDetailsTrigger } from './ThemeDetailsTrigger';
import { useIntl } from 'react-intl';
import { RealitySpecificationDto, RealityThemeDto } from '../../../../../../../gql';

interface OwnProps {
  theme: Pick<RealityThemeDto, 'name' | 'description'> & { 'specifications': Pick<RealitySpecificationDto, 'id' | 'name'>[]; };
}

export type ThemeButtonProps =
  & OwnProps
  & ListItemButtonProps;

export const ThemeSelectionButton: React.FC<ThemeButtonProps> = (props) => {
  const { formatMessage } = useIntl();
  const { key, theme, ...buttonProps } = props;

  return (
    <ListItem
      key={key}
      secondaryAction={<ThemeDetailsTrigger edge="end" aria-label={formatMessage({ id: 'More details' })} theme={theme} />}
      disablePadding
    >
      <ListItemButton {...buttonProps}>
        <ListItemText>{theme.name}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};