import React from 'react';
import { Card, Skeleton, Stack, StackProps, styled, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { ActivityTypeColor } from 'modules/activities/types';
import { ActivityType } from 'gql';
import { activityTypePluralOrSigleMessages } from 'modules/home/messages';
import { useActivitySettings } from 'hooks/useActivitySettings';

export type TeamHeader = {
  TeamName: string
  lastActivityDate?: string
  activitiesCount: Record<ActivityType, number>
}

type StyledStackProps =
  & StackProps
  & { activitytype: ActivityType };

interface OwnProps {
  teamHeader: TeamHeader
}

export const StyledCard = styled(Card)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap'
}));

export const StyledStack = styled(Stack)<StyledStackProps>(({ theme, activitytype }) => ({
  borderLeftStyle: 'solid',
  borderLeftWidth: '2px',
  paddingLeft: theme.spacing(1),
  borderColor: ActivityTypeColor[activitytype],
  width: theme.spacing(17),
  justifyContent: 'center'
}));

export const TeamStatHeader: React.FC<OwnProps> = ({ teamHeader }) => {
  const { formatMessage } = useIntl();
  const { activitiesEnabled } = useActivitySettings();
  return (
    <StyledCard>
      <Stack>
        <Typography variant='subtitle1' fontWeight={500}>{teamHeader.TeamName}</Typography>
        {teamHeader.lastActivityDate
          ?
          <Typography variant='body2'>{formatMessage({ id: 'Last activity on {date}' }, { date: teamHeader.lastActivityDate })}</Typography>
          :
          <Typography variant='body2'>{formatMessage({ id: 'No activities for this period' })}</Typography>
        }
      </Stack>
      <Stack direction='row' gap={0.5} flexWrap='wrap'>
        {activitiesEnabled.map((type, index) => (
          <StyledStack activitytype={type} key={index + type} >
            <Typography variant='body1'>{teamHeader.activitiesCount[type]}</Typography>
            <Typography variant='body2'>{formatMessage(activityTypePluralOrSigleMessages[type], { activityCount: teamHeader.activitiesCount[type] })}</Typography>
          </StyledStack>
        ))}
      </Stack>
    </StyledCard>
  );
};