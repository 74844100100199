import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItemIcon, ListItemText, MenuItem, Tooltip, useTheme } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { ReactNode } from 'react';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';

interface Props extends Omit<LoadingButtonProps, 'onClick' | 'deleteType'> {
  buttonVariant?: 'button' | 'iconButton' | 'menuItem';
  onConfirm?: () => void,
  onCancel?: () => void,
  confirmTitle: string,
  confirmText: ReactNode | string,
  confirmButtonText?: string;
  outerButtonIcon?: ReactNode;
  outerButtonText?: string;
  loading?: boolean;
  confirmColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  tooltip?: string;
}

export const ConfirmButton: React.FC<Props> = ({
  buttonVariant, loading, outerButtonIcon, confirmText, confirmTitle, outerButtonText, onConfirm, onCancel,
  confirmButtonText, confirmColor, tooltip, ...buttonProps
}) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const [isOpen, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    onCancel?.();
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirm?.();
  };

  const button = <>
    {(!buttonVariant || buttonVariant === 'button') && (
      <LoadingButton {...buttonProps} color='error' variant='contained' onClick={handleClickOpen}>
        {outerButtonText ? outerButtonText : formatMessage({ id: 'Delete' })}
      </LoadingButton>
    )}
    {buttonVariant === 'menuItem' && (
      <MenuItem onClick={handleClickOpen} disabled={buttonProps.disabled}>
        <ListItemIcon>
          {outerButtonIcon ?? <Delete />}
        </ListItemIcon>
        <ListItemText>
          {outerButtonText ? outerButtonText : formatMessage({ id: 'Delete' })}
        </ListItemText>
      </MenuItem>
    )}
    {buttonVariant === 'iconButton' && (
      <IconButton onClick={handleClickOpen} disabled={buttonProps.disabled}>
        {outerButtonIcon ?? <Delete />}
      </IconButton>
    )}
  </>;

  return <>
    {tooltip ? (
      <Tooltip title={tooltip} disableInteractive>
        <span>
          {button}
        </span>
      </Tooltip>
    ) : (button)}
    <Dialog
      open={isOpen}
      onClose={loading ? undefined : handleCancel}
      sx={{
        zIndex: theme.zIndex.modal + 1
      }}
    >
      <DialogTitle>
        {confirmTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {confirmText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleCancel}>{formatMessage({ id: 'Cancel' })}</Button>
        <LoadingButton loading={loading} onClick={handleConfirm} color={confirmColor || 'error'} variant='contained' autoFocus>
          {confirmButtonText || formatMessage({ id: 'Delete' })}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  </>;
};