import React from 'react';
import { Box, styled, BoxProps } from '@mui/material';
import loginLogo from '../../../../assets/images/rocqtr-login-logo.svg';
import { ApplicationLogo } from 'components/ApplicationLogo';
import { ApplicationName } from 'components/ApplicationLogo/ApplicationName';

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1.5),
  boxSizing: 'content-box',
  '& h6': {
    color: theme.palette.secondary.main
  }
}));

export const LoginHeader: React.FC = () => {
  return (
    <>
      <Box display={{ xs: 'none', md: 'block' }}>
        <img src={loginLogo} />
        <ApplicationName variant='h4' align='center' sx={{
          backgroundColor: 'primary.main',
          color: 'secondary.main',
          py: 2
        }} />
      </Box>
      <StyledBox display={{ xs: 'flex', md: 'none' }}>
        <ApplicationLogo />
      </StyledBox>
    </>
  );
};