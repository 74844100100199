import { LinearProgress } from '@mui/material';
import { EntityType, SectionReorderInput, useFindSectionsQuery, useReorderFieldsMutation } from 'gql';
import { CustomizationFieldDrawer, CustomizationSectionDrawer } from 'modules/customizations/components/CustomizationDrawer';
import { DraggableFieldList } from 'modules/customizations/components/DraggableFieldList';
import React, { useCallback, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

type BooleanUseState = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

export const KnownIndividualsConfigurationPage: React.FC = () => {
  const { data: sections, isFetching, refetch } = useFindSectionsQuery({
    filter: { entityType: { eq: EntityType.Individual } }
  });

  const [isSectionDrawerOpen, setIsSectionDrawerOpen] = useOutletContext<BooleanUseState>();
  const [selectedField, setSelectedField] = useState<number>();
  const [selectedSection, setSelectedSection] = useState<number>(0);
  const [isFieldDrawerOpen, setFieldDrawerOpen] = useState<boolean>(false);

  const handleCloseDrawer = () => {
    refetch();
    setSelectedSection(0);
    setSelectedField(undefined);
    setIsSectionDrawerOpen(false);
    setFieldDrawerOpen(false);
  };

  const handleNewField = (sectionId: number) => {
    setFieldDrawerOpen(true);
    setSelectedSection(sectionId);
  };

  const handleEditField = (fieldId: number) => {
    setSelectedField(fieldId);
    setFieldDrawerOpen(true);
  };

  const handleEditSection = (sectionId: number) => {
    setSelectedSection(sectionId);
    setIsSectionDrawerOpen(true);
  };

  const reorderMutation = useReorderFieldsMutation({
    onSuccess: () => {
      refetch();
    }
  });

  const onFieldsDragged = useCallback((ordering: SectionReorderInput[]) => {
    reorderMutation.mutate({ input: { ordering, entityType: EntityType.Individual } });
  }, [reorderMutation]);

  return <>
    <LinearProgress sx={{ visibility: (isFetching || reorderMutation.isLoading) ? 'visible' : 'hidden' }} />

    <DraggableFieldList
      sections={sections?.sections || []}
      onFieldClick={handleEditField}
      onSectionClick={handleEditSection}
      disabled={isFetching || reorderMutation.isLoading}
      onChange={onFieldsDragged}
      onNewfieldButtonClick={handleNewField}
    />

    <CustomizationFieldDrawer
      sectionId={selectedSection}
      customFieldId={selectedField}
      isOpen={isFieldDrawerOpen}
      onClose={handleCloseDrawer}
      entityType={EntityType.Individual}
    />

    <CustomizationSectionDrawer
      sectionId={selectedSection}
      entityType={EntityType.Individual}
      isOpen={isSectionDrawerOpen}
      onClose={handleCloseDrawer}
    />
  </>;
};