import React from 'react';
import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';
import { useTheme } from '@mui/material';

interface OwnProps {
  currentValue: number
  previousValue: number
}

export const DeltaIcon: React.FC<OwnProps> = ({ currentValue, previousValue }) => {
  const theme = useTheme();
  if (currentValue < previousValue) {
    return <TrendingDown color='error' sx={{ width: theme.spacing(2), height: theme.spacing(2) }} />;
  }

  if (currentValue > previousValue) {
    return <TrendingUp color='success' sx={{ width: theme.spacing(2), height: theme.spacing(2) }} />;
  }

  return <TrendingFlat color='primary' sx={{ width: theme.spacing(2), height: theme.spacing(2) }} />;
};