import { Save } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

export const SaveButton: React.FC<ButtonProps> = (props) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      variant='contained'
      startIcon={<Save />}
      {...props}
    >
      {props.children ?? formatMessage({ id: 'Save' })}
    </Button>
  );
};