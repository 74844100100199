import React, { ReactNode, useState } from 'react';
import { DataGrid, DataGridProps, GridToolbar } from '@mui/x-data-grid';
import { Box, IconButton, LinearProgress, Paper, PaperProps, Stack, styled, Typography, useTheme } from '@mui/material';
import { SearchTextField } from 'components/SearchTextField';
import { useIntl } from 'react-intl';
import { Search } from '@mui/icons-material';

export interface DataTableProps extends DataGridProps {
  onSearch?: (searchTerm: string) => void;
  actionButtonAdornment?: ReactNode;
  stackProps?: PaperProps;
  noSearchResultsMessage?: string;
  noDataMessage?: string;
  sectionTitle?: string;
  isFetching?: boolean;
  height?: number;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
  '& .MuiDataGrid-root': {
    borderRadius: '0 0 4px 4px',
    border: 'none',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  },
}));

const FloatingLinearProgress = styled(LinearProgress)(() => ({
  position: 'absolute',
  top: 0,
  width: '100%',
}));

export const DataTable: React.FC<DataTableProps> = ({
  onSearch, actionButtonAdornment, stackProps, noSearchResultsMessage, noDataMessage, loading, sectionTitle, isFetching, height,
  ...dataGridProps
}) => {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const [_showSearch, setShowSearch] = useState(false);
  const showSearch = !sectionTitle || _showSearch;
  const theme = useTheme();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    onSearch?.(e.target.value);
  };

  const onSearchClear = () => {
    setShowSearch(false);
    setSearchTerm('');
    onSearch?.('');
  };

  const hasData = dataGridProps.rows.length > 0;
  const heavyLoading = loading && !hasData;

  return (
    <StyledPaper {...stackProps}>
      <div style={{ position: 'relative' }}>
        {(showSearch && onSearch) && (
          <SearchTextField
            autoFocus={Boolean(sectionTitle)}
            placeholder={formatMessage({ id: 'Search' })}
            value={searchTerm}
            onChange={handleSearch}
            onClear={onSearchClear}
            onBlur={() => !searchTerm && setShowSearch(false)}
            actionButtonAdornment={actionButtonAdornment}
            sx={{
              '& .MuiInputBase-input': {
                p: 0
              },
              '& .MuiIconButton-root': {
                my: -1
              },
              pt: 2,
              pb: 0.5
            }}
          />
        )}

        {(Boolean(sectionTitle) && !showSearch) && (
          <Stack direction='row' px={2} mt={1} mb={0.5} justifyContent='space-between' alignItems='center' minHeight='40px'>
            <Typography variant='h6'>
              {sectionTitle}
            </Typography>

            {Boolean(onSearch) && (
              <IconButton onClick={() => setShowSearch(true)}>
                <Search />
              </IconButton>
            )}
          </Stack>
        )}

        {(!loading && isFetching) && (
          <FloatingLinearProgress />
        )}
      </div>
      <Box sx={{ height: height ? height : 'auto', width: '100%' }}>
        <DataGrid
          autoHeight={height == null}
          scrollbarSize={16} // fixes an issue that would cause the horizontal scrollbar to appear when the vertical scrollbar appears
          columnHeaderHeight={heavyLoading ? 0 : 34}
          disableRowSelectionOnClick
          {...dataGridProps}
          loading={heavyLoading}
          localeText={{
            noRowsLabel: searchTerm.length > 0 ? noSearchResultsMessage : noDataMessage,
            ...theme.components?.MuiDataGrid?.defaultProps?.localeText
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{ toolbar: { csvOptions: { utf8WithBom: true } } }}
        />
      </Box>
    </StyledPaper>
  );
};