import { CustomFieldDto, CustomFieldOptionDto, CustomFieldType, DateFilterInput, NumericalFilterInput, ReportFieldFilterInput, SystemFieldId, useFindCustomFieldsQuery } from 'gql';
import React, { ReactNode } from 'react';
import { TeamLookupInput } from 'modules/customizations/components/CustomFieldInput/TeamLookupInput';
import { SelectionFilterInput } from './FilterInputs/SelectionFilterInput';
import { FilterInputLabel } from './FilterInputLabel';
import { UserLookupInput } from 'modules/customizations/components/CustomFieldInput/UserLookupInput';
import { DateFilterField } from './FilterInputs/DateFilterField';
import { NumericalFilterField } from './FilterInputs/NumericalFilterField';
import dayjs from 'dayjs';

type CustomField = Pick<CustomFieldDto, 'id' | 'name' | 'customFieldType' | 'systemFieldId'> & {
  options: Pick<CustomFieldOptionDto, 'id' | 'name'>[];
};

interface Props {
  customField: CustomField;
  value: ReportFieldFilterInput;
  onChange: (customFieldFilterInput: ReportFieldFilterInput) => void;
  disabled?: boolean;
}

const today = dayjs();

const dateFilterToNumericalFilter = (dateFilter?: DateFilterInput | null): NumericalFilterInput | undefined => dateFilter ? ({
  min: (dateFilter.to && dayjs(dateFilter.to).isValid()) ? Math.abs(dayjs(dateFilter.to).diff(today, 'years')) : undefined,
  max: (dateFilter.from && dayjs(dateFilter.from).isValid()) ? Math.abs(dayjs(dateFilter.from).diff(today, 'years')) : undefined
}) : undefined;

const numericalFilterToDateFilter = (numericalFilter: NumericalFilterInput): DateFilterInput => ({
  from: numericalFilter.max ? today.subtract(numericalFilter.max, 'years').toISOString() : undefined,
  to: numericalFilter.min ? today.subtract(numericalFilter.min, 'years').toISOString() : undefined
});

export const FilterInput: React.FC<Props> = ({ customField, value, onChange, disabled }) => {
  const shouldShowExtraLabel = customField.customFieldType !== CustomFieldType.Selection
    && customField.customFieldType !== CustomFieldType.Lookup
    && customField.systemFieldId !== SystemFieldId.RealityAccompaniments;

  const { id: customFieldId } = customField;

  let input: ReactNode = null;
  switch (customField.customFieldType) {
    case CustomFieldType.String:
      input = null;
      break;
    case CustomFieldType.Numerical:
      input = (
        <NumericalFilterField
          value={value.numericalFilter}
          onChange={numericalFilter => onChange({ customFieldId, numericalFilter })}
          disabled={disabled}
        />
      );
      break;
    case CustomFieldType.Date:
      input = (
        <DateFilterField
          value={value.dateFilter}
          onChange={dateFilter => onChange({ customFieldId, dateFilter })}
          disabled={disabled}
        />
      );
      break;
    case CustomFieldType.Selection:
      input = (
        <SelectionFilterInput
          label={customField.name}
          options={customField.options}
          value={value.selectionFilter}
          onChange={selectionFilter => onChange({ customFieldId, selectionFilter })}
          disabled={disabled}
        />
      );
      break;
  }

  const { data: referralOptionsData } = useFindCustomFieldsQuery({
    filter: { systemFieldId: { eq: SystemFieldId.RealityReferrals } }
  }, { enabled: customField.systemFieldId === SystemFieldId.RealityAccompaniments });

  if (customField.systemFieldId === SystemFieldId.Team) {
    input = (
      <TeamLookupInput
        multiple
        label={customField.name}
        error={false}
        required={false}
        value={value.lookupFilter?.teamIds?.join(',') ?? ''}
        onChange={teamIds => onChange({ customFieldId, lookupFilter: { teamIds: teamIds.split(',').map(Number).filter(Boolean) } })}
        disabled={disabled}
      />
    );
  } else if (customField.systemFieldId === SystemFieldId.OutreachWorker
    || customField.systemFieldId === SystemFieldId.AdditionalWorkers) {
    input = (
      <UserLookupInput
        multiple
        includeMe
        label={customField.name}
        error={false}
        required={false}
        value={value.lookupFilter?.userIds?.join(',') ?? ''}
        onChange={userIds => onChange({ customFieldId, lookupFilter: { userIds: userIds.split(',').filter(Boolean) } })}
        disabled={disabled}
      />
    );
  } else if (customField.systemFieldId === SystemFieldId.RealityAccompaniments) {
    input = (
      <SelectionFilterInput
        label={customField.name}
        options={referralOptionsData?.customFields[0].options ?? []}
        value={value.selectionFilter}
        onChange={selectionFilter => onChange({ customFieldId, selectionFilter })}
        disabled={disabled}
      />
    );
  } else if (customField.systemFieldId == SystemFieldId.Birthday) {
    input = (
      <NumericalFilterField
        value={dateFilterToNumericalFilter(value.dateFilter)}
        onChange={numericalFilter => onChange({ customFieldId, dateFilter: numericalFilterToDateFilter(numericalFilter) })}
        disabled={disabled}
      />
    );
  }

  return input ? (
    <FilterInputLabel label={shouldShowExtraLabel ? customField.name : undefined}>
      {input}
    </FilterInputLabel>
  ) : null;
};