import React, { useEffect, useState } from 'react';
import { useCurrentUserRoles } from 'hooks/useCurrentUserRoles';
import { StreetOutreachWorkerView } from '../components/StreetOutreachWorkerView';
import { AnalystView } from '../components/AnalystView';
import { PageTitle } from 'components';
import { Dashboard } from '@mui/icons-material';
import { TeamDropdownFilter, TimeRange, TimeRangeDropdownFilter } from '../components/DropdownFilter';
import { useIntl } from 'react-intl';
import { Stack, useTheme } from '@mui/material';
import { getTenantIdentifier } from 'utils/tenantUtils';

type FilterStorageType = {
  timeFilter: TimeRange,
  teamFilter: string
}

export const HomePage: React.FC = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const [timeRangeFilter, setTimeRangeFilter] = useState(TimeRange.last7Days);
  const [teamFilter, setTeamFilter] = useState<string>('all');
  const { isAdministrator, isAnalyst } = useCurrentUserRoles();
  const dashboardFiltersKey = `${getTenantIdentifier()}-dashboard-${isAdministrator || isAnalyst ? 'admin' : 'streetWorker'}`;

  const setLocalStorageState = (
    ifLocalStorageKeyExistsfunc: (state: FilterStorageType) => void,
    ifLocalStorageKeyDontExistsFunc?: () => void
  ) => {
    const stateJSON = localStorage.getItem(dashboardFiltersKey);
    if (stateJSON) {
      const state: FilterStorageType = JSON.parse(stateJSON ?? '{}');
      ifLocalStorageKeyExistsfunc(state);
    } else {
      if (ifLocalStorageKeyDontExistsFunc) {
        ifLocalStorageKeyDontExistsFunc();
      }
    }
  };

  const handleChangeTimeFilter = (timeFilter: TimeRange) => {
    setTimeRangeFilter(timeFilter);
    setLocalStorageState((state: FilterStorageType) => {
      state.timeFilter = timeFilter;
      localStorage.setItem(dashboardFiltersKey, JSON.stringify(state));
    });
  };

  const handleChangeTeamFilter = (teamFilter: string) => {
    setTeamFilter(teamFilter);
    setLocalStorageState((state: FilterStorageType) => {
      state.teamFilter = teamFilter;
      localStorage.setItem(dashboardFiltersKey, JSON.stringify(state));
    });
  };

  useEffect(() => {
    setLocalStorageState(
      (state: FilterStorageType) => {
        setTimeRangeFilter(state.timeFilter);
        setTeamFilter(state.teamFilter);
      },
      () => {
        const filters = {
          timeFilter: TimeRange.last7Days,
          teamFilter: 'all'
        };

        localStorage.setItem(dashboardFiltersKey, JSON.stringify(filters));
      });
  }, []);

  return (
    <>
      <PageTitle icon={<Dashboard />} title={formatMessage({ id: 'Home' })}
        actionButton={
          <Stack gap={2} direction={'row'} flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
            {(isAdministrator || isAnalyst) &&
              <TeamDropdownFilter
                value={teamFilter}
                onChangeValue={handleChangeTeamFilter}
                size='small'
                sx={{
                  width: theme.spacing(31),
                  [theme.breakpoints.down('md')]: {
                    maxWidth: '45%'
                  }
                }}
              />
            }
            <TimeRangeDropdownFilter
              size='small'
              value={timeRangeFilter}
              onChangeValue={handleChangeTimeFilter}
              sx={{
                width: theme.spacing(31),
                [theme.breakpoints.down('md')]: {
                  maxWidth: '50%'
                }
              }}
            />
          </Stack>
        }
      />
      {isAdministrator || isAnalyst
        ?
        <AnalystView
          teamFilter={teamFilter}
          timeRangeFilter={timeRangeFilter}
        />
        :
        <StreetOutreachWorkerView
          timeRangeFilter={timeRangeFilter}
        />
      }
    </>
  );
};