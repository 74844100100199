import { Box, Typography, styled } from '@mui/material';
import { useIntl } from 'react-intl';
import { activityTypeMessages } from 'modules/activities/messages';
import { ActivityTypeColor } from 'modules/activities/types';
import { CustomFieldValue, ActivityType, FindActivitiesQuery } from 'gql';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { isArray } from 'lodash';
import React from 'react';

type Activity = NonNullable<NonNullable<Pick<FindActivitiesQuery, 'activities'>['activities']>['items']>[0];
type ActivityTableCellValue = string | Date | CustomFieldValue[] | undefined;

const ColoredStrip = styled('div')<{
  activityType: ActivityType;
}>(({ activityType }) => ({
  height: '100%',
  borderLeftStyle: 'solid',
  borderLeftWidth: '5px',
  borderColor: ActivityTypeColor[activityType],
}));

export const InterventionWorkerCell = ({ value, row: { type } }: GridRenderCellParams<Activity, ActivityTableCellValue>) => {
  const { formatMessage } = useIntl();

  if (!isArray(value)) return null;

  const displayString = value.map(v => v.displayString).join();

  return (
    <>
      <ColoredStrip activityType={type} />
      <Box ml={1}>
        <Typography variant='body2'>
          {displayString}
        </Typography>
        <Typography color='text.secondary' fontSize={14}>
          {formatMessage(activityTypeMessages[type])}
        </Typography>
      </Box>
    </>
  );
};