import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { RealitySpecificationDto, RealityThemeDto } from '../../../../../../../gql';


interface OwnProps {
  theme: Pick<RealityThemeDto, 'name' | 'description'> & { 'specifications': Pick<RealitySpecificationDto, 'id' | 'name'>[]; };
  onClose: () => void;
}

export type ThemeDetailsDialogProps =
  & DialogProps
  & OwnProps;

export const ThemeDetailsDialog: React.FC<ThemeDetailsDialogProps> = (props) => {
  const { theme, onClose, ...dialogProps } = props;
  const { formatMessage } = useIntl();
  const hasSpecifications = theme.specifications?.length > 0;

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{theme.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>{theme.description}</DialogContentText>
        <DialogContentText sx={{ paddingTop: 2 }}>
          <strong>{formatMessage({ id: 'Specifications' })}</strong>

          {hasSpecifications &&
            <ul>
              {theme.specifications.map(specification => <li key={specification.name}>{specification.name}</li>)}
            </ul>
          }

          {!hasSpecifications &&
            <Typography sx={{ marginTop: 0.5 }}>{formatMessage({ id: 'There are no specifications associated to this theme.' })}</Typography>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {formatMessage({ id: 'Close' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};