import { Stack } from '@mui/material';
import React from 'react';

interface OwnProps {
    children?: React.ReactNode;
}

export type ErrorLayoutProps =
    & OwnProps;

export const ErrorLayout: React.FC<ErrorLayoutProps> = (props) => {
  return (
    <Stack direction="column" flexGrow={1} alignItems="center" justifyContent="center">
      {props.children}
    </Stack>
  );
};