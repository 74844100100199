import { Event, Link, List, ManageSearch, Numbers, Search, TextFields, ViewList } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { SearchTextField } from 'components';
import { CustomFieldType } from 'gql';
import { fieldDescriptionMessages } from 'modules/customizations/messages';
import React from 'react';
import { useIntl } from 'react-intl';

type CustomizationType = CustomFieldType | 'section' | 'linkExistingField';
interface OwnProps {
  editMode?: boolean;
  customizationType: CustomizationType;
  searchValue?: string;
  onSearchChange?: (value: string) => void;
}

type HeaderTextIcon = {
  title: string,
  icon: React.ReactNode;
};

export const CustomizationFormHeader: React.FC<OwnProps> = ({ customizationType, editMode, searchValue, onSearchChange }) => {
  const { formatMessage } = useIntl();

  const addOrEdit = (fieldType: string) => editMode
    ? formatMessage({ id: 'Edit {fieldType}' }, { fieldType })
    : formatMessage({ id: 'Add {fieldType}' }, { fieldType });

  const HeaderMap: Record<CustomizationType, HeaderTextIcon> = {
    [CustomFieldType.Date]: {
      title: addOrEdit(formatMessage(fieldDescriptionMessages[CustomFieldType.Date])),
      icon: <Event />
    },
    [CustomFieldType.Selection]: {
      title: addOrEdit(formatMessage(fieldDescriptionMessages[CustomFieldType.Selection])),
      icon: <List />
    },
    [CustomFieldType.Numerical]: {
      title: addOrEdit(formatMessage(fieldDescriptionMessages[CustomFieldType.Numerical])),
      icon: <Numbers />
    },
    [CustomFieldType.String]: {
      title: addOrEdit(formatMessage(fieldDescriptionMessages[CustomFieldType.String])),
      icon: <TextFields />
    },
    [CustomFieldType.Lookup]: {
      title: addOrEdit(formatMessage(fieldDescriptionMessages[CustomFieldType.Lookup])),
      icon: <ManageSearch />
    },
    'section': {
      title: addOrEdit(formatMessage({ id: 'Section' })),
      icon: <ViewList />
    },
    'linkExistingField': {
      title: formatMessage({ id: 'Link an Existing Field' }),
      icon: <Link />
    }
  };

  const [showSearch, setShowSearch] = React.useState(false);

  const currentFieldHeaderType = HeaderMap[customizationType];
  return (
    <Stack direction='row' spacing={2} px={showSearch ? undefined : 2} alignItems='center' width='100%'>
      {!showSearch ? <>
        {currentFieldHeaderType.icon}
        <Typography fontWeight='500' textTransform='capitalize'>{currentFieldHeaderType.title}</Typography>
      </> : (
        <SearchTextField
          autoFocus
          value={searchValue}
          onClear={() => {
            onSearchChange?.('');
            setShowSearch(false);
          }}
          onChange={e => onSearchChange?.(e.target.value)}
          onBlur={e => e.target.value === '' && setShowSearch(false)}
        />
      )}

      {(customizationType === 'linkExistingField' && !showSearch) && (
        <IconButton style={{ marginLeft: 'auto' }} onClick={() => setShowSearch(true)}>
          <Search />
        </IconButton>
      )}
    </Stack>
  );
};