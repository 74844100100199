import dayjs from 'dayjs';
import { ApplicationUser, CustomFieldDto, CustomFieldOption, CustomFieldType, CustomFieldValue as CustomFieldValueEntity, FieldValueInput as FieldValueInputEntity, SystemFieldId, Team, useFindTeamsQuery, useGetCurrentUserQuery, CustomField as CustomFieldEntity } from 'gql';
import { RegisterOptions } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { isValidDecimalNumber } from './validationUtils';

type CustomFieldValue = Pick<CustomFieldValueEntity, 'fieldId' | 'stringValue' | 'dateValue' | 'numericalValue' | 'displayString'> & {
  field: Pick<CustomFieldEntity, 'systemFieldId'>;
  selectionValues: Pick<CustomFieldOption, 'id'>[];
  lookupTeamValue?: Pick<Team, 'id'> | null;
  lookupUserValues: Pick<ApplicationUser, 'id'>[];
};

export type FieldValueInput = Pick<FieldValueInputEntity, 'customFieldId' | 'value'> & {
  systemFieldId?: SystemFieldId,
  displayString?: string;
};

export const useFieldRulesGenerator = () => {
  const { formatMessage } = useIntl();

  const generateFieldRules = (field: Pick<CustomFieldDto, 'isRequired' | 'customFieldType' | 'systemFieldId'>) => {
    const rules: RegisterOptions = {};
    rules.validate = {};

    if (field.isRequired) {
      rules.required = formatMessage({ id: 'This field is required' });
    }

    if (field.customFieldType === CustomFieldType.Numerical) {
      rules.validate.isNumber = (value?: string | null) => value
        ? isValidDecimalNumber(value) || formatMessage({ id: 'This is not a valid number' })
        : true;
    }

    if (field.systemFieldId === SystemFieldId.Birthday) {
      rules.validate.isInPast = (value?: string | null) => value
        ? dayjs(value).isBefore(dayjs()) || formatMessage({ id: 'The birthday cannot be in the future' })
        : true;
    }

    if (field.systemFieldId === SystemFieldId.InterventionDate) {
      rules.validate.isInPast = (value?: string | null) => value
        ? dayjs(value).isBefore(dayjs()) || formatMessage({ id: 'The intervention date has to be in the past' })
        : true;
    }

    return rules;
  };

  const { data: currentUser, isSuccess: isUserSuccess } = useGetCurrentUserQuery();
  const me = currentUser?.me;

  const { data: defaultTeamData, isSuccess: isTeamSuccess } = useFindTeamsQuery();
  const defaultTeam = defaultTeamData?.teams.at(0);

  const defaultValuesReady = isUserSuccess && isTeamSuccess;

  const getDefaultValue = (systemFieldId?: SystemFieldId | null) => {
    if (!defaultValuesReady) {
      console.error('Attempted to get the default value before the default values were ready: values may be undefined');
      return;
    }

    if (systemFieldId === SystemFieldId.OutreachWorker) {
      return me?.id.toString();
    }

    if (systemFieldId === SystemFieldId.InterventionDate) {
      return dayjs().format('YYYY-MM-DD');
    }

    if (systemFieldId === SystemFieldId.Team) {
      return defaultTeam?.id.toString();
    }

    return undefined;
  };

  return { generateFieldRules, getDefaultValue, defaultValuesReady };
};

const getDateStringValue = (dateString?: string) => dateString
  ? dayjs(dateString).utc().format('YYYY-MM-DD')
  : undefined;

export const getFormValueFromFieldValue = (c: CustomFieldValue): FieldValueInput => ({
  customFieldId: c.fieldId,
  systemFieldId: c.field.systemFieldId ?? undefined,
  displayString: c.displayString,
  value: c.stringValue
    ?? getDateStringValue(c.dateValue)
    ?? c.numericalValue?.toString()
    ?? c.lookupTeamValue?.id?.toString()
    ?? (c.selectionValues.length > 0 ? c.selectionValues.map(v => v.id).join(',') : undefined)
    ?? (c.lookupUserValues.length > 0 ? c.lookupUserValues.map(v => v.id).join(',') : undefined)
    ?? ''
});

export const getSystemFieldValue = (
  systemFieldId: SystemFieldId,
  allValues: FieldValueInput[]) => {
  const systemFieldValue = allValues.find(value => value.systemFieldId === systemFieldId);

  return systemFieldValue?.displayString ?? systemFieldValue?.value?.toString();
};

